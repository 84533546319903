/** This module the base class for a bunch of the node editor utils classes.
 *  @module
 */

import { DataOceanKey, DataOceanMetadata } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { RunbookNode } from "utils/services/RunbookApiService";
import { GraphDef, NodeDef, NodeProperty } from "../../components/common/graph/types/GraphTypes";
import { VariableContextByScope } from "./RunbookContext.class";
import { RunbookContextUtils } from "./RunbookContextUtils.class";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { getTypes } from "utils/stores/GlobalDataSourceTypeStore";

/** this interface defines a generic key.  It extends the data ocean key and adds an id to the definition. */
export interface GenericKey extends DataOceanKey {
    /** a string with the id.  For a nested key object this might be in the form a.b.c. */
    id: string;
    /** a boolean value, true if the key should be hidden from the user. */
    hidden?: boolean;
}

/** The class houses all validation that are common across all the nodes.
 *  Extend this class and  override validateNode method to add node specific validations
 *  Invoke super.validateNode from the child class to perform generic validations.
 *  TODO this class can be reconcilled with the RunbookUtils file. */
export class NodeUtils {
    /** validates the node with the specified id.
     *  @param nodeId a String with the node id.
     *  @param errors a String array with any errors.
     *  @param graphDef the GraphDef object with the entire set of nodes and edges.
     *  @param variables the map of variables by scope.
     *  @param customProperties the array of CustomProperty objects with the custom properties for 
     *       all the entity types. */
    static validateNode(
        nodeId: string, errors: string[], graphDef: GraphDef, variables?: VariableContextByScope,
        customProperties?: CustomProperty[]
    ) {
        // Validations that are common for all node can go here.
    }

    /** returns the parent data ocean node of the node with the specified id.
     *  @param nodeId a String with the node id.
     *  @param graphDef the GraphDef object with the entire set of nodes and edges.
     *  @param nodeTypes array of node types to be considered as parent node. 
     *  @returns the parent data ocean node or undefined if none is found. */
    static getParentNode(nodeId: string, graphDef: GraphDef, nodeTypes: Array<string> = []): NodeDef | null {
        const parentNode = graphDef.nodes.filter(node => {
            const curEdgeNode = graphDef.edges?.filter(edge => edge.toNode === nodeId);
            if (curEdgeNode) {
                //TODO handle scn where there are multiple edge nodes 
                return node.id === curEdgeNode[0]?.fromNode;
            }
            return false;
        });
        if (parentNode && parentNode[0]) {
            if (nodeTypes.length === 0 || nodeTypes.includes(parentNode[0]?.type)) {
                return parentNode[0];
            } else {
                return NodeUtils.getParentNode(parentNode[0].id, graphDef, nodeTypes);
            }
        }
        return null;
    };

    /** returns the NodeProperty with the specified name.
     *  @param name a string the name of the property key.
     *  @param node the NodeDef node to check for the property.
     *  @returns a NodeProperty object with the property key and value or undefined if it isn't found.*/
    static getPropertyFromNode(name: string, node: NodeDef | undefined | null): NodeProperty | undefined {
        return node?.properties?.find((item) => {
            return item.key === name
        });
    }

    /** returns the value of the property with the specified name.
     *  @param name a string the name of the property key.
     *  @param node the NodeDef node to check for the property.
     *  @returns the value of the property with the property key or undefined if it isn't found.*/
    static getPropertyFromRunbookNode(name: string, node: RunbookNode): string | undefined {
        return node?.properties[name];
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param inputType the object type that is currently being displayed in the logic node.
     *  @returns an array with all the key definitions. */
    static getExpandedKeys(objMetricMetaData: DataOceanMetadata, inputType: string): Array<GenericKey> {
        return NodeUtils.getExpandedKeysForKeyList(objMetricMetaData, objMetricMetaData?.obj_types?.[inputType]?.keys || []);
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param keys the array of string keys to expand.
     *  @returns an array with all the key definitions. */
    static getExpandedKeysForKeyList(objMetricMetaData: DataOceanMetadata, keys: string[]): Array<GenericKey> {
        const expandedKeys: Array<GenericKey> = [];
        if (objMetricMetaData && keys?.length > 0) {
            for (const key of keys) {
                const keyDef = RunbookContextUtils.getAvailableKeys(objMetricMetaData.keys[key], [], getTypes());
                if (keyDef) {
                    NodeUtils.expandKeyProperties(keyDef, key, expandedKeys);
                }
            }
        }
        return expandedKeys;
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key.  Once the key is generated, the column def is created.  This function
     *      is called recursively.
     *  @param keyDef the Data Ocean key definition or one of the property definitions.
     *  @param parents a string with the parent information that should be appended to the key id.
     *  @param expandedKeys an array with all the keys in it for example {id: network_server.ipaddr, label: "Host", type: "string"}. 
     *  @param showOnlyPrimary a boolean value, if true we only want the expanded keys for primary keys. */
    static expandKeyProperties(keyDef: DataOceanKey, parents: string, expandedKeys: Array<GenericKey>, showOnlyPrimary?: boolean): void {
        if (!keyDef.properties) {
            if (!showOnlyPrimary || keyDef.primary_key) {
                expandedKeys.push({ id: parents, label: keyDef.label, type: keyDef.type, category: keyDef.category, unit: keyDef.unit || "", hidden: keyDef.hidden });
            }
        } else {
            for (const property in keyDef.properties) {
                NodeUtils.expandKeyProperties(keyDef.properties[property], parents + (parents?.length ? "." : "") + property, expandedKeys, showOnlyPrimary);
            }
        }
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key and returns that string.
     *  @param objMetricMetaData the JSON with the Data Ocean API details
     *  @param inputType the object type that is currently being displayed in the logic node.
     *  @returns an array with all the key definitions. */
    static getListOfExpandedKeys(objMetricMetaData: DataOceanMetadata, inputType: any): Array<string> {
        const expandedKeys: Array<string> = [];
        for (const key of objMetricMetaData.obj_types[inputType].keys) {
            const keyDef = objMetricMetaData.keys[key];
            if (keyDef.type === "object") {
                NodeUtils.expandKeyPropertiesObject(keyDef, key, expandedKeys);
            } else {
                expandedKeys.push(key);
            }
        }
        return expandedKeys;
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the data ocean
     *      properties for the key and returns that string.  This function is called recursively.
     *  @param keyDef the Data Ocean key definition or one of the property definitions.
     *  @param parents a string with the parent information that should be appended to the key id.
     *  @param expandedKeys an array with all the keys in it for example {id: network_server.ipaddr, label: "Host", type: "string"}. */
    static expandKeyPropertiesObject(keyDef: DataOceanKey, parents: string, expandedKeys: Array<string>): void {
        if (!keyDef.properties) {
            expandedKeys.push(parents);
        } else {
            for (const property in keyDef.properties) {
                NodeUtils.expandKeyPropertiesObject(keyDef.properties[property], parents + "." + property, expandedKeys);
            }
        }
    }

    /** returns the data ocean metadata label for the specified expanded key.
     *  @param expandedKey the key.
     *  @param objMetricMetaData the metadata for the data ocean.
     *  @returns a string with the label. */
    static getLabelForExpandedKey(expandedKey: string, objMetricMetaData: DataOceanMetadata): string {
        if (expandedKey) {
            const keyPath = expandedKey.split(".");
            if (keyPath.length > 0) {
                let keyObj: DataOceanKey | undefined = objMetricMetaData.keys[keyPath[0]];
                for (let index = 1; index < keyPath.length; index++) {
                    if (!keyObj) {
                        break;
                    }
                    keyObj = keyObj.properties ? keyObj.properties[keyPath[index]] : undefined;
                }
                return keyObj ? keyObj.label : "";
            }
        }
        return "";
    }

    /** static member function that goes through the variables map and returns the list of all
     *      variable names.
     *  @param variables the map of variables by scope.
     *  @returns the array of all variable names or an empty array. */
    static getVariablesList(variables?: VariableContextByScope): string[] {
        const primitiveVariables: string[] = NodeUtils.getPrimitiveVariablesList(variables);
        const structuredVariables: string[] = NodeUtils.getStructuredVariablesList(variables);
        return primitiveVariables.concat(structuredVariables);
    }    

    /** static member function that goes through the variables map and returns the list of primitive
     *      variable names.
     *  @param variables the map of variables by scope.
     *  @returns the array of primitive variable names or an empty array. */
    static getPrimitiveVariablesList(variables?: VariableContextByScope): string[] {
        const primitiveVariables: string[] = [];
        if (variables) {
            for (const key in variables) {
                const varCollection = variables[key];
                if (varCollection.primitiveVariables) {
                    for (const variable of varCollection.primitiveVariables) {
                        primitiveVariables.push(variable.name);
                    }
                }
            }    
        }
        return primitiveVariables;
    }

    /** static member function that goes through the variables map and returns the list of structured
     *      variable names.
     *  @param variables the map of variables by scope.
     *  @returns the array of structured variable names or an empty array. */
    static getStructuredVariablesList(variables?: VariableContextByScope): string[] {
        const structuredVariables: string[] = [];
        if (variables) {
            for (const key in variables) {
                const varCollection = variables[key];
                if (varCollection.structuredVariables) {
                    for (const variable of varCollection.structuredVariables) {
                        structuredVariables.push(variable.name);
                    }
                }
            }    
        }
        return structuredVariables;
    }
}
