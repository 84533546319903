import dev_tenants from "./static_tenant_data/tenants_dev.json";
import staging_tenants from "./static_tenant_data/tenants_staging.json";
export interface ITenant {
    halt: boolean;
    tenant_id: string;
    idp?: string;
    region?: string;
    api_scope?: string;
}

export type TenantMapProps = {
    [key: string]: ITenant
}

export const STATIC_TENANTS: {[key: string]: TenantMapProps} = {
    "dev": dev_tenants,
    "perfdev": dev_tenants,
    "staging": staging_tenants,
    //"preprod": staging_tenants,
    //"hyperion-eu-demo": production_tenants,
    //"prod": production_tenants
}
