/** This module contains a utility that has various functions for handling errors that come from
 *  the API.
 *  @module
 */
import { ErrorInfo } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";


/** returns the k/v pairs with the error details. 
 *  @param error the ErrorInfo object with the error information.
 *  @returns a map of the error details values to their keys.  The keys are referred to in the 
 *      error messages in the STRINGS file. */
export function getErrorDetails(error: ErrorInfo): Record<string, string> {
    const details: Record<string, string> = {};
    if (error?.innerError?.properties?.length) {
        for (const prop of error.innerError.properties) {
            // Replace any "." in the key with an __.  The localization API does not support
            // nested objects.
            details[prop.key.replace(/\./g, "__").replace(/^filter$/, "filterp")] = prop.value;
        }
    }
    return details;
}
