/** This module contains the component for displaying the query configuration step in the widget config dialog.  The
 *      query config step configures the query that will be used to retrieve the data.
 *  @module
 */

import React, { useRef, useEffect } from "react";
import { NavigatorWidgetConfig, QueryConfig } from "pages/navigator/Navigator.type";
import { GraphDef, NodeProperty, RunbookInfo, Variant } from "components/common/graph/types/GraphTypes";
import { UniversalNode } from "components/common/graph/UniversalNode";
import { Node } from "react-flow-renderer";
import rvbdLibraryJSON from "pages/create-runbook/views/create-runbook/node_library.json";
import { RunbookConfig } from "utils/services/RunbookApiService";
import { getGraphDefFromRunbookConfig } from "pages/create-runbook/views/create-runbook/CreateRunbookView";
import { NavigatorUtils } from "pages/navigator/NavigatorUtils";
import { NodeLibrary, NodeLibraryCategory, NodeLibraryNode, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import RunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library.json";
import { DataOceanNodeEditor } from "components/common/graph/editors/data-ocean/DataOceanNodeEditor";

/** Node type to editor mapping. */
const NodeEditors = {
    "data_ocean": DataOceanNodeEditor
};

/** this interface defines the properties passed into the QueryConfigStepProps React component. */
export interface QueryConfigStepProps {
    /** the configuration of this current dashboard widget. */
    config: NavigatorWidgetConfig;
    /** the current runbook configuration. */
    //runbookConfig?: RunbookConfig;
    /** the query configuration that descripts what data is being queried.  This object has all the changes
     *  incorporated into it from the multi-step dialog. */
    queryConfig?: QueryConfig;
    /** the handler for query change events. */
    onQueryChange?: (query: QueryConfig) => void;
    /** the handler for widget name change events. */
    //onWidgetNameChange?: (name: string) => void;
    /** the handler for widget type change events. */
    //onWidgetTypeChange?: (type: WIDGET_TYPE) => void;
}

/** this interface defines the functions available in the QueryConfigStep widget ref. */
export interface QueryConfigStepRef {
    /** returns the options from the node editor. */
    getQueryConfig: () => any;
}

/** Renders the query configuration step of the widget settings dialog.
 *  @param props the properties passed in.
 *  @returns JSX with the query configuration stepcomponent.*/
const QueryConfigStep = React.forwardRef<QueryConfigStepRef, QueryConfigStepProps>((props: QueryConfigStepProps, ref: any): JSX.Element => { 
    // The dashboards have their own node library, but here we use the runbooks node library because we are 
    // taking the dashboard runbook and creating a standard runbook from it with a visual node in it.  The 
    // dashboard runbooks don't have visualization nodes.
    const nodeLibrary = useRef<NodeLibrary>(new NodeLibrary(RunbookNodeLibrary as NodeLibrarySpec));
    let libraryNode: NodeLibraryNode | undefined = getNodeLibraryNode("data_ocean");

    const mockSelectedNode = useRef<UniversalNode>(NavigatorUtils.createSelectedQueryNodeForNodeEditor());
    NavigatorUtils.updateSelectedNodeProperties(mockSelectedNode.current, libraryNode, props.queryConfig?.properties);

    // Create a graph def that hooks up the chart node to the data ocean node.
    //let runbookConfig: RunbookConfig = NavigatorUtils.appendVisualizationNodeToRunbookConfig(props.runbookConfig, widgetType);
    let runbookConfig: RunbookConfig = NavigatorUtils.createDoNodeFromQuery(props.queryConfig);
    const graphDef: GraphDef = getGraphDefFromRunbookConfig(nodeLibrary.current, runbookConfig, []);

    const EditorComponent = NodeEditors["data_ocean"];
    const editorRef = useRef<any>(undefined);
    const editorProps = {
        selectedNode: mockSelectedNode.current,
        libraryNode: libraryNode,
        globalNodes: [],
        activeRunbook: {} as RunbookInfo,
        graphDef: graphDef,
        nodeLibrary: nodeLibrary.current
    };

    useEffect(() => {
            ref({
                getQueryConfig: () => {
                    let options: any = undefined;
                    const editor = editorRef.current;
                    if (editor) {
                        options = {};
                        let errorMessages = [];
                        if (editor.validate) {
                            errorMessages = editor.validate();
                        }
                        if (errorMessages && errorMessages.length) {
                            //this.setState({errorMessages: errorMessages});
                            editor.updateNode();
                            if (mockSelectedNode.current?.node) {
                                for (const prop of ((mockSelectedNode.current?.node as Node)?.data?.properties || []) as NodeProperty[]) {
                                    options[prop.key] = prop.value;
                                }
                            }
                        } else {
                            editor.updateNode();
                            if (mockSelectedNode.current?.node) {
                                for (const prop of ((mockSelectedNode.current?.node as Node)?.data?.properties || []) as NodeProperty[]) {
                                    options[prop.key] = prop.value;
                                }
                            }
                        }
                    }
                    return {properties: options};                
                }
            });
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return <div className="h-100"><table><tbody>
        <EditorComponent {...editorProps} ref={editorRef} variant={Variant.INCIDENT} subflows={[]} />
    </tbody></table></div>;
});
  
export default QueryConfigStep;

/** returns the library node for the specified type.
 *  @param nodetype the node type whose node is requested.
 *  @returns the NodeLibraryNode for the node type or undefined. */
export function getNodeLibraryNode(nodeType: string): NodeLibraryNode | undefined {
    if (rvbdLibraryJSON?.categories?.length) {
        const category: NodeLibraryCategory | undefined = (rvbdLibraryJSON as NodeLibrarySpec)?.categories?.find((category) => category.name === "dataOcean");
        if (category?.nodes?.length) {
            const node = category.nodes?.find((node) => {
                return nodeType === node.type;
            });
            if (node) {
/*
                const propsToRemove = ["title", "row", "notes", "notesPosition"];
                for (let propIndex = (node.properties || []).length -1; propIndex >= 0; propIndex--) {
                    if (propsToRemove.includes(node.properties![propIndex].name)) {
                        node.properties?.splice(propIndex, 1);
                    }
                }
*/
                return node;
            }
        }        
    }
    return undefined;
}
