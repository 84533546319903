import React, { useMemo, useState } from "react";
import { ErrorToaster, IconNames, Modal, SuccessToaster } from "@tir-ui/react-components";
import { Button, Radio, RadioGroup } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { ModalProps } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { useCustomProperties } from "utils/hooks/useCustomProperties";
import { Form, SelectField } from "components/common/form";
import { PARAM_NAME } from "components/enums/QueryParams";
import { useApolloClient } from "@apollo/client";
import { CUSTOM_PROPERTY_PERMISSION } from "../../../../../pages/incident-search/IncidentSearchPage";
import "./SetCustomPropertiesModal.scss";

interface SetCustomModalProps extends ModalProps {
    selectedIds: Array<string>,
    totalCount: number,
}

interface CustomPropertyValue {
    id: string,
    filter: {
        filters: [
            {
                type: string,
                values: Array<string>
            }
        ]
    },
    search: string,
    searchFields: Array<string>
}

export const SetCustomPropertiesModal = (props: SetCustomModalProps) => {
    const customPropertiesQuery = useCustomProperties({});
    const customProperties = useMemo(() => {
        return customPropertiesQuery.data || [];
    }, [customPropertiesQuery.data]);

    const apolloClient = useApolloClient();

    const { selectedIds, facets, searchType, searchRequest, totalCount = 0, customPropertyId, assignedValue } = props;
    const hasNoSelectedItems = selectedIds.length === 0;

    const [propertyID, setPropertyID] = useState(customPropertyId ? customPropertyId : "<Select Custom Property>");
    const [propertyValueID, setPropertyValueID] = useState(assignedValue ? assignedValue : 'unset');
    const [selectedOption, setSelectedOption] = useState(hasNoSelectedItems ? "all" : "selected");

    const QUERY_DATA = {
        application: {
            queryAdd: loader("src/components/modals/explorer/custom-properties/SetCustomPropertiesModal/queries/add-custom-property-value-applications-mutation.graphql"),
            queryRemove: loader("src/components/modals/explorer/custom-properties/SetCustomPropertiesModal/queries/remove-custom-property-applications-mutation.graphql"),
            validType: "application"
        },
        device: {
            queryAdd: loader("src/components/modals/explorer/custom-properties/SetCustomPropertiesModal/queries/add-custom-property-value-devices-mutation.graphql"),
            queryRemove: loader("src/components/modals/explorer/custom-properties/SetCustomPropertiesModal/queries/remove-custom-property-devices-mutation.graphql"),
            validType: "network_device"
        },
        interface: {
            queryAdd: loader("src/components/modals/explorer/custom-properties/SetCustomPropertiesModal/queries/add-custom-property-value-interfaces-mutation.graphql"),
            queryRemove: loader("src/components/modals/explorer/custom-properties/SetCustomPropertiesModal/queries/remove-custom-property-interfaces-mutation.graphql"),
            validType: "network_interface"
        },
        location: {
            queryAdd: loader("src/components/modals/explorer/custom-properties/SetCustomPropertiesModal/queries/add-custom-property-value-locations-mutation.graphql"),
            queryRemove: loader("src/components/modals/explorer/custom-properties/SetCustomPropertiesModal/queries/remove-custom-property-locations-mutation.graphql"),
            validType: "location"
        }
    }

    // Mutation - Bulk Match/Add custom Property with value
    const [addCustomPropertyValue] = useMutation<any, CustomPropertyValue>(
        QUERY_DATA[searchType].queryAdd, {
        onCompleted: (data) => {
            SuccessToaster({
                message: STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.successAddMsg,
            });
        },
        onError: (err) => {
            ErrorToaster({
                message: STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.errorAddMsg,
            });
            console.error(err?.message);
        }
    });

    // Mutation - Bulk UnMatch/Remove custom Property with value
    const [removeCustomPropertyValue] = useMutation<any, CustomPropertyValue>(
        QUERY_DATA[searchType].queryRemove, {
        onCompleted: (data) => {
            SuccessToaster({
                message: STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.successRemoveMsg,
            });
        },
        onError: (err) => {
            ErrorToaster({
                message: STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.errorRemoveMsg,
            });
            console.error(err?.message);
        },
    });

    //Needs case to select All (not only paginated 100)
    const handleSubmit = async () => {
        const selectedItems = {
            type: "ID",
            values: selectedIds
        };
        const searchFilters = Object.entries(facets).map(([key, value]) => ({
            type: key,
            values: value
        }));
        const filterVariables = {
            ...(propertyValueID === 'unset' ? {id: propertyID} : {valueId: propertyValueID}),
            filter: {
                filters: selectedOption === 'all' ? searchFilters : [selectedItems]
            },
            search: searchRequest?.current?.search,
            searchFields: searchRequest?.current?.searchFields
        }

        try {
            if (propertyValueID === 'unset') {
                await removeCustomPropertyValue({
                    variables: filterVariables as CustomPropertyValue,
                });
            } else {
                await addCustomPropertyValue({
                    variables: filterVariables as CustomPropertyValue,
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const linkToNewCPValue = {
        href: `explorer?${PARAM_NAME.searchType}=properties&${PARAM_NAME.incidentId}=${propertyID}&${PARAM_NAME.facets}={"NAME":["${customProperties?.filter(el => el.id === propertyID)?.[0]?.name}"]}`
    }

    let showUnsetOption: boolean | undefined = true;
    let allowSetValue: boolean = false;
    if (customProperties && customProperties.length) {
        let permissionKey: any = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_UNASSIGN_PROPERTY_VALUE)?.[0];
        showUnsetOption = customProperties?.find(x => x.id === propertyID)?.permissions?.includes(permissionKey);
        permissionKey = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_ASSIGN_PROPERTY_VALUE)?.[0];
        allowSetValue = customProperties?.find(x => x.id === propertyID)?.permissions?.includes(permissionKey) || false;
    }

    return (
        <Modal
            title={STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.title}
            customLabels={{
                submit: STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.apply,
                close: STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.cancel
            }}
            onSubmit={() => handleSubmit()}
            usePortal={false}
            {...props}
        >
            <div className="d-flex custom-property-setCProp-modal">
                <Form
                    loading={false}
                    initialValues={{
                        customProperty: propertyID,
                        customPropertyValue: propertyValueID,
                    }}
                >
                    <div className="d-flex align-items-center">
                        <SelectField
                            label={STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.labelCProp}
                            disabled={props.cPropVal}
                            name="customProperty"
                            onChange={(e) => {
                                setPropertyID(e.currentTarget?.value);
                                const permissionKey: any = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_UNASSIGN_PROPERTY_VALUE)?.[0];
                                const newShowUnsetOption = customProperties?.find(x => x.id === e.currentTarget?.value)?.permissions?.includes(permissionKey);
                                if (!newShowUnsetOption) {
                                    // The user cannot select the unset value, in this case set the first option as the value
                                    const valueId = customProperties?.filter(x => x.id === e.currentTarget?.value)?.[0]?.values?.[0]?.id;
                                    setPropertyValueID(valueId);
                                }
                            }}
                            value={propertyID}
                        >
                            <option>{STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.emptyCProp}</option>
                            {customProperties?.filter(el => el.types.includes(QUERY_DATA[searchType].validType))?.map((cProp, i) => <option key={i} value={cProp.id}>{cProp.name}</option>)}
                        </SelectField>
                        <div className="ml-2">
                            <Button
                                icon={IconNames.REFRESH}
                                minimal={true}
                                onClick={async () => {
                                    await apolloClient.refetchQueries({
                                        include: "active",
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <SelectField
                            name="customPropertyValue"
                            label={STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.labelCPropVal}
                            onChange={(e) => setPropertyValueID(e.currentTarget?.value)}
                            disabled={!allowSetValue}
                            value={propertyValueID}
                        >
                            {showUnsetOption && <option value="unset">{STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.emptyCPropVal}</option>}
                            {customProperties?.filter(x => x.id === propertyID)?.[0]?.values?.map((y, i) => <option key={i} value={y.id}>{y.name}</option>)}
                        </SelectField>
                        <div className="ml-2">
                            <Button
                                disabled={propertyID === "<Select Custom Property>"}
                                icon={IconNames.REFRESH}
                                minimal={true}
                                onClick={async () => {
                                    await apolloClient.refetchQueries({
                                        include: "active",
                                    });
                                }}
                            />
                        </div>
                    </div>
                </Form>
                <div>
                    <p style={{ marginBottom: '50px' }}>
                        <a
                            href={`explorer?${PARAM_NAME.searchType}=properties&${PARAM_NAME.fromExplorerEntity}=${searchType}&${PARAM_NAME.createNewCP}=true`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {
                                STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.createNewLink
                            }
                        </a>
                    </p>
                    {!!customProperties?.find(x => x.id === propertyID)?.permissions?.includes(
                        Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_CREATE_PROPERTY_VALUE)?.[0]!
                    ) && <p>
                        <a
                            {...(propertyID !== "<Select Custom Property>" && linkToNewCPValue)}
                            rel="noreferrer"
                            className={propertyID !== "<Select Custom Property>" ? "" : "disabledLink"}
                            target={propertyID !== "<Select Custom Property>" ? "_blank" : "_self"}
                        >
                            {
                                STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.addNewValueLink
                            }
                        </a>
                    </p>}
                </div>
            </div>
            {!!totalCount && <RadioGroup
                name="delete-options"
                onChange={e => setSelectedOption(e.currentTarget.value)}
                selectedValue={selectedOption}
                inline={true}
                className="d-flex flex-column"
            >
                <Radio
                    label={STRINGS.formatString(STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.selected, selectedIds.length)}
                    value={"selected"}
                    className="my-2"
                    disabled={hasNoSelectedItems}
                />
                <Radio
                    label={STRINGS.formatString(STRINGS.CUSTOM_PROPERTIES_PAGE.setCPropModal.all, totalCount)}
                    value={"all"}
                    className="my-2"
                />
            </RadioGroup>}
        </Modal >
    );
};
