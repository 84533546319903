/** This file defines the RunbookContext class.  The RunbookContext collects the contexts from a
 *      branch of the runbook tree up to a specified node.
 *  @module */
import { AggregateNodeUtil } from "components/common/graph/editors/aggregator/AggregatorNodeUtils";
import { DataOceanMetadata, DataOceanKey, DataOceanMetric } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { TransformKey, TransformNodeUtils } from "components/common/graph/editors/transform/TransformNodeUtils";
import { GraphDef, InputType, LIFECYCLE_TRIGGER_TYPES, NodeDef } from "components/common/graph/types/GraphTypes";
import { GenericKey, NodeUtils } from "./NodeUtil";
import {
    aggregatorNodes, aiNodes, dataOceanNodes, decisionNodes, getFirstParentOfTypeFromGraphDef, getParentsFromGraphDef, getProperty, getTriggerMetricIds,
    httpNodes, isAggregatorNodeFromGraphDef, isAiNodeFromGraphDef, isChartNodeFromGraphDef, isDataOceanNodeFromGraphDef, isDecisionNodeFromGraphDef, isHttpNodeFromGraphDef, 
    isLogicalNodeFromGraphDef, isPriorityNodeFromGraphDef, isSubflowNodeFromGraphDef, isSubflowOutputNodeFromGraphDef, isTagNodeFromGraphDef, 
    isTransformNodeFromGraphDef, isTriggerNodeFromGraphDef, isVariablesNodeFromGraphDef, logicalNodes, subflowNodes, transformNodes, triggerNodes, 
    variableNodes
} from "./RunbookUtils";
import RunbookContextKeys from "./RunbookContextKeys.json";
import RunbookContextKeysForLiquid from "./RunbookContextKeysForLiquid.json";
import { STRINGS } from "app-strings";
import { INDICATOR_TO_LABEL_MAP, INDICATOR_TYPE } from "components/enums";
import { PrimitiveVariableType, VariableCollection } from "./VariablesUtils";
import { GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils';
import { getArDataSources, getTypes } from "utils/stores/GlobalDataSourceTypeStore";
import { dataSourceTypeOptions } from "utils/services/DataSourceApiService";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import { ALLOW_MULTI_TYPE, SHOW_JSON_DO } from "components/enums/QueryParams";
import { RunbookContextUtils } from "./RunbookContextUtils.class";

/** these variables get the environment and help URI from the runtime config. */
let { ENV } = window["runConfig"] || {};

const COMPATIBLE_FILTERS_ENVS: string[] = ["dev", "staging", "prod"];

/** an enum that specified the different modes that are supported. */
export enum ContextMode {
    /** specifies that the context can only come from a direct parent. */
    DIRECT_PARENT = "DIRECT_PARENT",
    /** specifies that the context can only come from the closest ancestor. */
    CLOSEST_PARENT = "CLOSEST_PARENT",
    /** specifies that the context can come from any parent node. */
    ANY_PARENT = "ANY_PARENT",
}

/** a constant which specifies which of the three context modes should be used in the runbook editor. */
export const CONTEXT_MODE: ContextMode = ContextMode.CLOSEST_PARENT;

/** this interface defines a context that describes the filters that are available to pass to a data ocean query. */
export interface Context {
    /** the type of context. */
    type: ContextType;
    /** the source of the context, this is typically a runbook node. */
    source: ContextSource;
    /** the keys provided by this context.  This needs to be unified it can have two different types of objects. */
    keys: Array<string>;
    /** the list of expanded key definitions where the nested keys are expanded to values like a.b.c. */
    expandedKeys: Array<GenericKey>;
    /** an Array with the metric ids for the node context. */
    metrics: Array<GenericKey>;
    /** the data ocean object type. */
    objType?: string;
    /** specifies whether the context (applies to do nodes) has a comparison. */
    comparedTo?: string;
    /** a boolean value, if true the context is a time series context, if not defined or false it is summary data. */
    isTimeseries?: boolean;
    /** this is for a DO context, this is the limit or number of rows to return. */
    limit?: number;
    /** this is for a DO context, this is the duration in seconds. */
    duration?: number;
    /** this is used when the context is being generated to show the variables to enter in Liquid.  In this
     *  case the RBO remaps some of the trigger variables like {{trigger["runbook_status"]}}, this parameter
     *  specifies that string. */
    liquid_key?: string;
}

/** this interface defines a context source.  A context source is a node that provides context to the 
 *      specified node whose context is being generated. */
export interface ContextSource {
    /** the id of the node providing the context. */
    id: string;
    /** the name of the node providing the context. */
    name: string;
    /** the type of node providing the context. */
    type: string;
    /** the array of filter keys for the context source. */
    keys: Array<string>;
}

/** this interface defines a key or metric column for the decision branch. */
export interface DecisionBranchColumn {
    /** a String with the id of the column. */
    id: string;
    /** a String with the label for the column. */
    label: string;
    /** the field type. */
    type: string;
    /** the unit for the column. */
    unit?: string;
    /** an object with the raw column data. */
    raw?: GenericKey
}

/** ths interface defines the metadata for the decision branch that specifies the keys, metrics, etc. */
export interface DataColumns {
    /** the keys that should be shown in the decision branch. */
    keys?: Array<DecisionBranchColumn>;
    /** the metrics that should be shown in the decision branch. */
    metrics?: Array<DecisionBranchColumn>;
    /** the trigger keys that should be shown in the decision branch. */
    triggerKeys?: Array<DecisionBranchColumn>;
    /** the trigger metric that should be shown in the decision branch. */
    triggerMetrics?: Array<DecisionBranchColumn>;
    /** the trigger metric that should be shown in the decision branch. */
    triggerGenericMetrics?: Array<DecisionBranchColumn>;
    /** any variables that apply at this point in the context. */
    variables?: Array<DecisionBranchColumn>;
    /** any additional properties that need to be passed to the decision branch to help it configure. */
    properties?: AdditionalContextProps;
}

/** this interface defines additional properties that are needed to configure the decision branch */
export interface AdditionalContextProps {
    /** a String which if populated specifies the data ocean comparison is turned on. */
    comparedTo: string| undefined;
    /** a boolean value which if true specifies that the decision branch is connected to the HTTP node. */
    isHttp?: boolean;
    /** a boolean value which if true specifies that the decision branch is connected to the trigger node. */
    isTrigger?: boolean;
    /** a boolean value, true if this list of properties has the trigger metric, false otherwise. */
    //hasTriggerMetric?: boolean;
    /** an optional array with the list of triggering metrics. */
    triggerMetrics?: Array<{value: string, label: string}>;
    /** these are the trigger metrics that were excluded. */
    excludedTriggerMetrics?: Array<{value: string, label: string}>;
    /** an optional array with the list of triggering entity types. */
    triggerEntityTypes?: Array<{value: string, label: string}>;
    /** the optional array with the list of analysis types. */
    analysisTypes?: Array<{value: string, label: string}>;
    /** do not use the lhs options for the list of keys, but allow the user to type it in. */
    userProvidesKey?: boolean;
}

/** this interface defines options that can be passed in to the RunbookContext constructor. */
export interface RunbookContextOptions {
    /** a boolean value, true if the context if being used to show the context of a liquid template. */
    forLiquid?: boolean;
}

/** an enum that specified all the valid context types. */
export enum ContextType {
    /** specifies that the context comes from a runbook trigger. */
    TRIGGER = "TRIGGER",
    /** specifies that the context comes from a runbook node. */
    NODE = "NODE",
}

/** this interface defines the object that returns all the variable definitions for each scope. */
export interface VariableContextByScope {
    /** the VariableCollection with the variables for the runtime scope. */
    [RUNTIME_SCOPE]: VariableCollection;
    /** the VariableCollection with the variables for the incident scope. */
    [INCIDENT_SCOPE]: VariableCollection;
    /** the VariableCollection with the variables for the global scope. */
    [GLOBAL_SCOPE]: VariableCollection;
}

/** this class encapsulates the functionality for a runbook context. */
export class RunbookContext {
    /** an array with the list of compound keys. */
    public static COMPOUND_KEYS: Array<string> = ["network_client_server", "network_client_server_protoport", "client_server_location"];
    /** this array contains arrrays of compatible filter keys.  These filters may be used interchangeably. */
    public static COMPATIBLE_FILTERS: Array<Array<string>> = (COMPATIBLE_FILTERS_ENVS.includes(ENV) ? [
        ["location", "client_location", "server_location", "physical_location"],
        ["network_device", "connected_device"],
        ["network_interface", "connected_interface"],
        ["json", "requestBody", "requestQueryParameters", "requestHeaders"]
    ] : []);

    /** a Map of the array of keys indexed by their compound keys names. */
    private static KEY_ARRAYS_BY_COMPOUND_KEYS: Record<string, Array<string>>;

    /** the trigger context or undefined if this runbook does not yet have a trigger node or if the specified
     *      node used to generate the context is not connected to the trigger. */
    public triggerContext: Context | undefined = undefined;
    /** an array with the context of all runbook nodes in the branch of the specified runbook node.  These contexts
     *      are sorted in the order of traversal from the root of the tree.  Nodes that have no relevant context are
     *      not included in the context. */
    public nodeContexts: Array<Context> = [];
    /** the data ocean metadata which is used to get the keys and metrics. */
    private dataOceanMetaData: DataOceanMetadata;
    /** the type of trigger for the runbook or undefined if none is defined. */
    private triggerType: InputType | undefined = undefined;
     /** the array of CustomProperty objects with the custom properties for all entity types. */
    private customProperties: CustomProperty[] = [];

    /** creates a new instance of RunbookContext.
     *  @param node the NodeDef that the context is being created for.
     *  @param graphDef the GraphDef with all the nodes in the runbook.  This graph is used to build 
     *      up the context.
     *  @param dataOceanMetaData a reference to the data ocean meta data that has information on all the data ocean keys, metrics,
     *      filters, etc. 
     *  @param customProperties the array of CustomProperty objects. 
     *  @param options an optional RunbookContextOptions object with any options that need to be passed in via the contructor. */
    public constructor(node: NodeDef, graphDef: GraphDef, dataOceanMetaData: any, customProperties?: CustomProperty[], options?: RunbookContextOptions) {
        if (!RunbookContext.KEY_ARRAYS_BY_COMPOUND_KEYS) {
            /** the first time through we want to setup the map with subkeys indexed by the compound keys. */
            RunbookContext.KEY_ARRAYS_BY_COMPOUND_KEYS = {};
            for (const compoundKey of RunbookContext.COMPOUND_KEYS) {
                if (dataOceanMetaData.keys[compoundKey]?.properties) {
                    const subKeys: Array<string> = [];
                    for (const property in dataOceanMetaData.keys[compoundKey].properties) {
                        subKeys.push(property);
                    }
                    RunbookContext.KEY_ARRAYS_BY_COMPOUND_KEYS[compoundKey] = subKeys;
                }
            }
        }

        this.customProperties = customProperties || [];

        // The first thing we want to do is to get the context from the runbook trigger.  There should only be runbook trigger
        // context and it should 
        const triggerNode = isTriggerNodeFromGraphDef(node) ? node : getFirstParentOfTypeFromGraphDef(node, graphDef, triggerNodes);
        if (triggerNode) {
            const runbookContextMetadata: DataOceanMetadata = JSON.parse(JSON.stringify(RunbookContextKeys));
            if (options?.forLiquid) {
                runbookContextMetadata.keys = {...runbookContextMetadata.keys, ...RunbookContextKeysForLiquid.keys as Record<string, DataOceanKey>};
            }
            runbookContextMetadata.keys = RunbookContextUtils.getAvailableKeysForKeyMap(runbookContextMetadata.keys, [], getTypes());

            const triggerType = triggerNode.properties ? triggerNode.properties.filter(prop => prop.key === "triggerType")[0].value : "";
            this.triggerType = triggerType;
            let keys: Array<string> = [];
            let metrics: GenericKey[] = [];
            let expandedKeys: Array<GenericKey> = [];
            let liquidKey: string | undefined = undefined;

            // The data source trigger should only be visible on app response
            const arDsArray = getArDataSources() || [];

            if (triggerType !== InputType.SUBFLOW && triggerType !== InputType.ON_DEMAND) {
                const attributesObject: DataOceanKey = runbookContextMetadata.keys["attributes"];
                expandedKeys = RunbookContext.getExpandedKeysForTriggerWithParent(triggerType, this.customProperties, options);
    
                switch (triggerType) {
                    case InputType.DEVICE:
                        keys.push("network_device");
                        attributesObject.properties!.metadata.properties = {
                            ...dataOceanMetaData.keys[triggerType].properties
                        };
                        break;
                    case InputType.INTERFACE:
                        keys.push("network_interface");
                        attributesObject.properties!.metadata.properties = {
                            ...dataOceanMetaData.keys[triggerType].properties
                        };
                        break;
                    case InputType.APPLICATION_LOCATION:
                        keys.push("application");
                        keys.push("client_location");
                        if (arDsArray?.length) {
                            keys.push("data_source");
                        }
                        liquidKey = runbookContextMetadata.keys.metadata_application_location?.liquid_key;
                        attributesObject.properties!.metadata.properties = {
                            [triggerType]: dataOceanMetaData.keys[triggerType],
                            location: dataOceanMetaData.keys.location
                        };
                        break;
                    case InputType.LOCATION:
                        keys.push("location");
                        if (arDsArray?.length) {
                            keys.push("data_source");
                        }
                        attributesObject.properties!.metadata.properties = {
                            [triggerType]: dataOceanMetaData.keys[triggerType],
                            location: dataOceanMetaData.keys.location
                        };
                        break;
                    case InputType.APPLICATION:
                        keys.push("application");
                        if (arDsArray?.length) {
                            keys.push("data_source");
                        }
                        liquidKey = runbookContextMetadata.keys.metadata_application?.liquid_key;
                        attributesObject.properties!.metadata.properties = {
                            [triggerType]: dataOceanMetaData.keys[triggerType],
                            location: dataOceanMetaData.keys.location
                        };
                        break;
                    case InputType.WEBHOOK:
                        if (SHOW_JSON_DO) {
                            keys.push("requestBody", "requestQueryParameters", "requestHeaders");
                        } else {
                            keys.push("no_key");
                        }
                        break;
                    case InputType.IMPACT_ANALYSIS_READY:
                        keys.push("no_key");
                        liquidKey = runbookContextMetadata.keys.metadata_impact_analysis_ready?.liquid_key;
                        break;
                    case InputType.INCIDENT_NOTE_ADDED:
                        keys.push("no_key");
                        liquidKey = runbookContextMetadata.keys.metadata_note_added?.liquid_key;
                        break;
                    case InputType.INCIDENT_NOTE_UPDATED:
                        keys.push("no_key");
                        liquidKey = runbookContextMetadata.keys.metadata_note_updated?.liquid_key;
                        break;
                    case InputType.INCIDENT_NOTE_DELETED:
                        keys.push("no_key");
                        liquidKey = runbookContextMetadata.keys.metadata_note_deleted?.liquid_key;
                        break;
                    case InputType.INCIDENT_ONGOING_CHANGED:
                        keys.push("no_key");
                        liquidKey = runbookContextMetadata.keys.metadata_ongoing_state_changed?.liquid_key;
                        break;
                    case InputType.INCIDENT_STATUS_CHANGED:
                        keys.push("no_key");
                        liquidKey = runbookContextMetadata.keys.metadata_status_changed?.liquid_key;
                        break;
                    case InputType.INCIDENT_INDICATORS_UPDATED:
                        keys.push("no_keys");
                        liquidKey = runbookContextMetadata.keys.metadata_indicators_updated?.liquid_key;
                        break;
                }
                //NodeUtils.expandKeyProperties(runbookContextMetadata.keys["kind"], "kind", expandedKeys);
                //NodeUtils.expandKeyProperties(attributesObject, "attributes", expandedKeys);
                //NodeUtils.expandKeyProperties(runbookContextMetadata.keys["primaryIndicator"], "primaryIndicator", expandedKeys);
                
                metrics = RunbookContext.getMetricsForTrigger(
                    triggerType, dataOceanMetaData, getTypes()
                );    
            } else {
                const properties = triggerNode?.properties ? triggerNode.properties.filter(prop => prop.key === "synthKeys") : undefined;
                const synthKeys = properties?.length === 1 ? properties[0].value : "";
                if (synthKeys?.length) {
                    for (const synthKey of synthKeys) {
                        if (synthKey.dataOceanId) {
                            // We have a data ocean context so now we can get the runbook context from it.
                            const key: string = synthKey.dataOceanId;
                            keys.push(key);
                            const keyDef = RunbookContextUtils.getAvailableKeys(dataOceanMetaData.keys[key], [], getTypes());
                            if (keyDef) {
                                NodeUtils.expandKeyProperties(keyDef, key, expandedKeys, triggerType === InputType.ON_DEMAND);
                            }
                            RunbookContext.addCustomPropertiesToExpandedKeys(expandedKeys, key, this.customProperties);
                        } else {
                            expandedKeys.push({...synthKey, synthetic: true});
                        }
                    }
                }
                const synthMetricsRaw = getProperty(triggerNode, "synthMetrics") || [];
                metrics = synthMetricsRaw.map((metricDef) => {
                    return (!metricDef) ? undefined : metricDef;
                }).filter(item => item);
            }

            this.triggerContext = {
                type: ContextType.TRIGGER,
                source: { id: triggerNode.id, name: triggerNode.name, type: triggerNode.type, keys: keys },
                keys: keys, expandedKeys: expandedKeys, metrics: metrics, isTimeseries: false, liquid_key: liquidKey
            };
        }
        this.dataOceanMetaData = dataOceanMetaData;
        // We need to traverse the tree and collect up the context for the specified node.
        this.generateContext(node, graphDef);
    }

    /** adds all the custom property keys to the expanded keys based on the type.
     *  @param expandedKeys the Array with all the expanded keys. 
     *  @param key the key for which the custom properties are to be added to, for example network_interface or network_device.
     *  @param customProperties the array of CustomPropertys. */
    public static addCustomPropertiesToExpandedKeys(expandedKeys: Array<GenericKey>, key: string, customProperties: CustomProperty[]): void {
        for (const property of customProperties) {
            if (RunbookContext.areCustomPropertyTypesCompatible(property.types, key)) {
                let propSuffix: string = "";
                if (ALLOW_MULTI_TYPE && ["network_device", "network_interface"].includes(key) && property) {
                    // Some objects have both a network_device and network_interface as keys and if those have the same properties you 
                    // will not be able to tell them apart without prefixing the name with "Device" or "Interface"
                    propSuffix = key === "network_device" ? " (Device)" : " (Interface)";
                }
                expandedKeys.push({
                    id: key + ".custom." + property.id + ".id", label:  property.name + " (ID)" + propSuffix, 
                    type: "property", unit: "none", hidden: true
                });
                expandedKeys.push({
                    id: key + ".custom." + property.id + ".name", label: property.name + propSuffix, 
                    type: "string", unit: "none"
                });    
            } else if (["client_server_location"].includes(key) && property.types.includes("location")) {
                expandedKeys.push({
                    id: key + ".client_location.custom." + property.id + ".id", label:  "Client " + property.name + " (ID)", 
                    type: "property", unit: "none", hidden: true
                });
                expandedKeys.push({
                    id: key + ".client_location.custom." + property.id + ".name", label: "Client " + property.name, 
                    type: "string", unit: "none"
                });
                expandedKeys.push({
                    id: key + ".server_location.custom." + property.id + ".id", label:  "Server " + property.name + " (ID)", 
                    type: "property", unit: "none", hidden: true
                });
                expandedKeys.push({
                    id: key + ".server_location.custom." + property.id + ".name", label: "Server " + property.name, 
                    type: "string", unit: "none"
                });    
            }
        }
    }

    /** returns the trigger context.
     *  @returns the trigger context or undefined.*/
    public getTriggerContext(): Context | undefined {
        return this.triggerContext;
    }

    /** returns the context for the branch of the specified node in the runbook graph.
     *  @returns an Array of Contexts with the context of each node that can produce a context in 
     *      a specified branch of the runbook graph. */
    public getNodeContexts(): Array<Context> {
        return this.nodeContexts;
    }

    /** returns the triggger type or undefined.
     *  @returns a string with the trigger type or undefined. */
    public getTriggerType(): InputType | undefined {
        return this.triggerType;
    }

    /** generates the runbook context by walking the runbook graph tree.
     *  @param node the NodeDef that the context is being created for.
     *  @param graphDef the GraphDef with all the nodes in the runbook.  This graph is used to build 
     *      up the context. */
    public generateContext(node: NodeDef, graphDef: GraphDef): void {
        const parents: Array<NodeDef> = getParentsFromGraphDef(node, graphDef);
        if (parents && parents.length > 0) {
            let context: Context | undefined = this.getNodeContext(parents[0], graphDef, node);
            if (context) {
                this.nodeContexts.unshift(context);
                if (CONTEXT_MODE === ContextMode.DIRECT_PARENT) {
                    // If we are only supporting direct parents, then exit after one parent is found
                    return;
                }    
            }
            this.generateContext(parents[0], graphDef);
        }
    }

    /** returns the context object for a node.  If the node does not have a context it returns undefined.
     *  @param node the NodeDef that the context is being created for.
     *  @param graphDef the GraphDef with all the nodes in the runbook.  This graph is used to build 
     *  @returns the Context for the node or undefined if the node has no context. */
    public getNodeContext(node: NodeDef, graphDef: GraphDef, childNode?: NodeDef): Context | undefined {
        // I think for the sake of generating the context we can assume there is only one parent.  The
        // chart nodes allow more than one parent, but the DO and Logic nodes only allow one parent
        if (
            isLogicalNodeFromGraphDef(node) || isDecisionNodeFromGraphDef(node) || isVariablesNodeFromGraphDef(node) ||
            isSubflowOutputNodeFromGraphDef(node) || isChartNodeFromGraphDef(node) || isPriorityNodeFromGraphDef(node) ||
            isTagNodeFromGraphDef(node)
        ) {
            // These nodes just use the same context as their parents
            const parents: Array<NodeDef> = getParentsFromGraphDef(node, graphDef);
            if (parents?.length) {
                // They can only have one parent so take the first parent.
                const nodeContext = this.getNodeContext(parents[0], graphDef);
                if (nodeContext) {
                    nodeContext.source = { id: node.id, name: node.name, type: node.type, keys: nodeContext.keys };
                    return nodeContext;
                } else if (triggerNodes.includes(parents[0].type)) {
                    // If we are connected directly to a trigger it's node context is not in the node context
                    // list so get it from the trigger context
                    const triggerContext = this.getTriggerContext();
                    if (triggerContext) {
                        triggerContext.source = { id: node.id, name: node.name, type: node.type, keys: triggerContext.keys };
                        return triggerContext;
                    }
                }
            }
        } else if (isDataOceanNodeFromGraphDef(node)) {
            const objectTypes = this.dataOceanMetaData.obj_types;
            let objType: string | undefined = getProperty(node, "objType");
            let isTimeseries: boolean = getProperty(node, "timeSeries") === true;
            let metrics: Array<any> = getProperty(node, "metrics") || [];
            let groupBys: Array<string> = getProperty(node, "groupBy") || [];
            let limit: number = getProperty(node, "limit");
            let duration: number = getProperty(node, "duration");
            metrics = metrics.filter((id) => {
                return this.dataOceanMetaData.metrics[id] !== undefined;
            });
            metrics = metrics.map((id) => {
                return this.dataOceanMetaData.metrics[id];
            });
            const comparedTo = getProperty(node, "comparedTo");
            if (objType) {
                const currentNodeMetaData = objectTypes[objType];
                let keys: string[] = [];
                let applicableKeys: Array<GenericKey> = NodeUtils.getExpandedKeys(this.dataOceanMetaData, objType);
                if (this.dataOceanMetaData.obj_types[objType].group_by_required) {
                    // We have a dynamic heirarchy and the group by set by the user determines the keys and 
                    // column list.
                    const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForKeyList(
                        this.dataOceanMetaData, this.dataOceanMetaData.obj_types[objType].keys || []
                    );
                    applicableKeys = [];
                    expandedKeys.forEach((keyDef) => {
                        if (groupBys.includes(keyDef.id)) {
                            applicableKeys.push(keyDef);
                        }
                    });
                    keys = [];
                    groupBys.forEach((groupBy) => {
                        // For the dynamic key heirarchy that XC created, the keys are like npm_plus.network_client.ipaddr
                        // and we need the network_client for the filters.
                        const tokens: string[] = groupBy.split(".");
                        keys.push(tokens[1]);
                    });
                } else if (groupBys?.length > 0) {
                    // We have the old npm+ demo and the groupBys array contains top level keys
                    applicableKeys = NodeUtils.getExpandedKeysForKeyList(this.dataOceanMetaData, groupBys);
                } else {
                    keys = currentNodeMetaData.keys;
                }

                const objKeys: string[] = currentNodeMetaData.keys || [];
                for (const key of objKeys) {
                    RunbookContext.addCustomPropertiesToExpandedKeys(applicableKeys, key, this.customProperties);
                }

                return {
                    type: ContextType.NODE,
                    source: { id: node.id, name: node.name, type: node.type, keys },
                    keys: keys || [], expandedKeys: applicableKeys, metrics: metrics,
                    objType: objType, comparedTo: comparedTo, isTimeseries, limit, duration
                };
            }
        } else if (isAggregatorNodeFromGraphDef(node)) {
            // The group by can be a subkey such as "network_server.location"
            const keys: Array<string> = [];
            const groupBy = getProperty(node, "groupBy");
            if (groupBy?.length) {
                for (const groupByKey of groupBy) {
                    if (groupByKey.includes(".")) {
                        // We have an expanded out key, see if it is a primary key, if it is a 
                        // primary key just add the root key for that key path.  Right now the 
                        // group by only has one string in it which simplifies our task.  If it
                        // had multiple keys then we would need to beef this up and check the 
                        // tree of keys
                        let primaryKeyCount = 0;
                        const groupByKeys = groupByKey.split(".");
                        let doMetaDataKeyObj: any = null;
                        for (let index = 0; index < groupByKeys.length; index++) {
                            if (index === 0) {
                                doMetaDataKeyObj = this.dataOceanMetaData.keys[groupByKeys[index]];
                                if (doMetaDataKeyObj) {
                                    primaryKeyCount = RunbookContext.getPrimaryKeyCount(doMetaDataKeyObj);
                                }
                            } else {
                                doMetaDataKeyObj = doMetaDataKeyObj.properties[groupByKeys[index]];
                            }
                            if (!doMetaDataKeyObj) {
                                break;
                            }
                        }
                        // We can only group by one key right now so if that key is a primary key and the primary key count is 1, then 
                        // use the root key
                        keys.push(primaryKeyCount === 1 && doMetaDataKeyObj?.primary_key ? groupByKeys[0] : groupByKey);
                    } else if (groupByKey !== "") {
                        // Check for empty string because there was a version where group by all was 
                        // saved as an empty string, so only add the key if it is not empty
                        keys.push(groupByKey);
                    }
                }
            }
            const parentDoOrTransformNode: NodeDef | null = getFirstParentOfTypeFromGraphDef(node, graphDef, [...dataOceanNodes, ...transformNodes]);
            const synthKeys = parentDoOrTransformNode ? getProperty(parentDoOrTransformNode, "synthKeys") || [] : [];
            let expandedKeys: Array<GenericKey> = AggregateNodeUtil.getExpandedKeysForGroupBy(this.dataOceanMetaData, synthKeys, this.customProperties, groupBy);
            const synthMetricsRaw = getProperty(node, "synthMetrics") || [];
            const synthMetrics = synthMetricsRaw.map((metricDef) => {
                return (!metricDef || !metricDef.included) ? undefined : metricDef;
            }).filter(item => item);

            return {
                type: ContextType.NODE,
                source: { id: node.id, name: node.name, type: node.type, keys: keys },
                keys: keys, expandedKeys: expandedKeys, metrics: synthMetrics || [], isTimeseries: false
            };
        } else if (isHttpNodeFromGraphDef(node)) {
            const runbookContextMetadata: DataOceanMetadata = RunbookContextKeys as any as DataOceanMetadata;
            const httpType = "http_response";
            const applicableHttpKeys: Array<GenericKey> = [];
            NodeUtils.expandKeyProperties(runbookContextMetadata.keys[httpType], httpType, applicableHttpKeys);
            return {
                    type: ContextType.NODE,
                    source: { id: node.id, name: node.name, type: node.type, keys: ["http"] },
                    keys: ["http"], expandedKeys: applicableHttpKeys, metrics: []
            };
        } else if (isAiNodeFromGraphDef(node)) {
            const runbookContextMetadata: DataOceanMetadata = RunbookContextKeys as any as DataOceanMetadata;
            const genAiType = "gen_ai";
            const applicableGenAiKeys: Array<GenericKey> = [];
            NodeUtils.expandKeyProperties(runbookContextMetadata.keys[genAiType], genAiType, applicableGenAiKeys);
            return {
                    type: ContextType.NODE,
                    source: { id: node.id, name: node.name, type: node.type, keys: ["genai"] },
                    keys: ["genai"], expandedKeys: applicableGenAiKeys, metrics: []
            };
        } else if (isTransformNodeFromGraphDef(node)) {
            const synthKeysRaw = getProperty(node, "synthKeys") || [];
            const synthMetricsRaw = getProperty(node, "synthMetrics") || [];
            const synthMetrics = synthMetricsRaw.map((metricDef) => {
                return (!metricDef) ? undefined : metricDef;
            }).filter(item => item);
            const isTimeseries = getProperty(node, "outputDataFormat") === "timeseries";

            const keys: Array<string> = synthKeysRaw.map(key => key.id);
            const transformKeys: Array<TransformKey> = TransformNodeUtils.getExpandedKeysForSynthKeys(
                this.dataOceanMetaData, this.customProperties, synthKeysRaw
            ) || [];

            return {
                    type: ContextType.NODE,
                    source: { id: node.id, name: node.name, type: node.type, keys: keys },
                    keys: keys, expandedKeys: transformKeys, metrics: synthMetrics || [],
                    isTimeseries
            };
        } else if (isSubflowNodeFromGraphDef(node)) {
            let handle: number = 0;
            for (const edge of graphDef.edges) {
                if (edge.fromNode === node.id && edge.toNode === childNode?.id) {
                    handle = parseInt(edge.fromPort || "0");
                    break;
                }
            }
            if (childNode) {
                //const outputDetails = getProperty(node, "out") || [];
                return this.getSubflowNodeContext(node, graphDef, handle);
            }
        }

        return undefined;
    }

    /** returns the context object for a node.  If the node does not have a context it returns undefined.
     *  @param node the NodeDef that the context is being created for.
     *  @param graphDef the GraphDef with all the nodes in the runbook.  This graph is used to build.
     *  @param handle the index of the handle that we want the context for. 
     *  @returns the Context for the node or undefined if the node has no context. */
    public getSubflowNodeContext(node: NodeDef, graphDef: GraphDef, handle: number | undefined): Context | undefined {
        const handleIndex: number = handle || 0;
        const outputContext: Context = node?.wires?.out?.length > handleIndex && node?.wires?.out[handleIndex].wires?.length 
            ? node.wires?.out[handleIndex].wires[0].context 
            : undefined;
        if (outputContext) {
            // We have a data ocean context so now we can get the runbook context from it.
            const metrics = outputContext.metrics;
            const keys = outputContext.keys;
            const expandedKeys: Array<GenericKey> = outputContext.expandedKeys;
            return {
                type: ContextType.NODE,
                source: { id: node.id, name: node.name, type: node.type, keys },
                keys, expandedKeys: expandedKeys, metrics: metrics, isTimeseries: outputContext.isTimeseries === true
            };
        }
    }

    /** checks to see if the specified filter is supported by the filter. 
     *  @param filterKey a string with the filter key.
     *  @param exactMatch a boolean value which specifies whether we require an exact match.  If false 
     *      an exact match is not required and the compatible keys are checked. 
     *  @returns a boolean value, true if the trigger in this context provides the specified filter, false otherwise. */
    public triggerProvidesFilter(filterKey: string, exactMatch: boolean = false): boolean {
        return this?.triggerContext?.keys ? 
            this.triggerContext.keys.includes(filterKey) || (!exactMatch && RunbookContext.isFilterCompatibleWithContext(filterKey, this.triggerContext)) : 
            false;
    }

    /** checks to see if the specified filter key is supported by any of the node contexts. 
     *  @param filterKey a string with the filter key.
     *  @param nodeId if the node id is specified only consider that ancestor node for providing the filter.
     *  @param exactMatch a boolean value which specifies whether we require an exact match.  If false 
     *      an exact match is not required and the compatible keys are checked. 
     *  @returns a boolean value, true if the one of the node contexts provides the specified filter, false otherwise. */
    public nodeProvidesFilter(filterKey: string, nodeId: string | undefined = undefined, exactMatch: boolean = false): boolean {
        if (this.nodeContexts.length > 0) {
            for (const context of this.nodeContexts) {
                const contextKeys: Array<string> = context.keys;
                if (
                    (
                        contextKeys.includes(filterKey) || RunbookContext.hasAllSubKeysForCompoundKey(filterKey, context) || 
                        (!exactMatch && RunbookContext.isFilterCompatibleWithContext(filterKey, context))
                    ) && 
                    (nodeId === undefined || nodeId === context.source.id)
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    /** return the list of ContextSources that can provide the specified filter.
     *  @param filterKey a string with the filter key.
     *  @param exactMatch a boolean value which specifies whether we require an exact match.  If false 
     *      an exact match is not required and the compatible keys are checked. 
     *  @returns the list of ContextSources that can provide the specified filter. */
    public getFilterNodeSources(filterKey: string, exactMatch: boolean = false): Array<ContextSource> {
        const sources: Array<ContextSource> = [];
        if (this.nodeContexts.length > 0) {
            for (const context of this.nodeContexts) {
                const contextKeys: Array<string> = context.keys;
                if (
                    contextKeys.includes(filterKey) || 
                    RunbookContext.hasAllSubKeysForCompoundKey(filterKey, context) || 
                    (!exactMatch && RunbookContext.isFilterCompatibleWithContext(filterKey, context))
                ) {
                    sources.push(context.source);
                }
            }
        }
        return sources;
    }

    /** This function returns all the applicable key/metric columns based on the closest parent node that is either a dataocean node
     *  or an aggregate node. In case of the closest parent node is an aggregator node it only returns the metrics that are included.
     *  @param variables the dictionary of variables by scope that have been defined.
     *  @param customProperties the array of CustomProperty objects with the custom properties for all the entity types.
     *  @returns DataColumns*/
    public getApplicableKeysMetrics(variables: VariableContextByScope, customProperties: CustomProperty[]): DataColumns | undefined {
        let parentContextIndex = -1;
        let parentNodeWithContext: Context | undefined = this.nodeContexts?.slice(parentContextIndex)[0];
        while (
            parentNodeWithContext && ((-1 * parentContextIndex) < this.nodeContexts.length) && 
            [...variableNodes].includes(parentNodeWithContext?.source?.type)
        ) {
            // For variable nodes we want to find the first parent node that is not a variable node since variable
            // nodes are pass through nodes.
            parentNodeWithContext = this.nodeContexts?.slice(--parentContextIndex)[0];
        }
        if ([...variableNodes].includes(parentNodeWithContext?.source?.type)) {
            parentNodeWithContext = undefined;
        }

        const expandedKeys: Array<GenericKey> = parentNodeWithContext?.expandedKeys || [];
        const keyColumns: Array<DecisionBranchColumn> = expandedKeys.map((item: GenericKey) => {
            return {
                id: item.id,
                // For custom properties in the decision branch we are only showing the id column and not the name, we appended the "(ID)" to the 
                // id column so you could distinguish it from the name, but since we are not showing the name remove the " (ID)"
                label: (((item.id.includes("custom.") && item.id.includes(".id")) || item.id === "data_source.id") && item.label.includes(" (ID)")
                    ? item.label.substring(0, item.label.indexOf(" (ID)")) 
                    : item.label
                ),
                type: item.type,
                unit: item.unit,
                raw: item
            }
        }).filter((key) => {
            // Filter out custom property names
            return !key.id.includes("custom.") || !key.id.includes(".name");
        }).filter((key) => {
            // Filter out JSON columns
            return key.type !== "json";
        }).filter((key) => {
            // Don't show the data_source name and hostname fields
            return key.id !== "data_source.name" && key.id !== "data_source.hostname"
        });
        const metricsFromContext = parentNodeWithContext?.metrics || [];

        const triggerType: InputType = this.triggerType ? this.triggerType : this.triggerContext?.keys[0] as InputType;
        const columns: DataColumns = {};
        /* This will show the trigger properties always, we just want to show it when connected to the trigger
        if (triggerType) {
            const applicableTriggerKeys: Array<GenericKey> = [];
            NodeUtils.expandKeyProperties(this.dataOceanMetaData.keys[triggerType], triggerType, applicableTriggerKeys);
            const triggerKeyColumns: Array<DecisionBranchColumn> = applicableTriggerKeys.map((item) => {
                return {
                    id: item.id,
                    label: item.label,
                    raw: item
                }
            })
            columns.triggerKeys = triggerKeyColumns;
        }
        */

        let triggerMetricIds: Array<string> = getTriggerMetricIds(triggerType, [], this.dataOceanMetaData, getTypes());
        const triggerMetricDefs = triggerMetricIds.map(metricId => {
            const metricDef = this.dataOceanMetaData.metrics[metricId];
            return {value: metricDef.id, label: metricDef.label};
        });

        let excludedTriggerMetricIds: Array<string> = getTriggerMetricIds(triggerType, [], this.dataOceanMetaData, getTypes(), false);
        const excludedTriggerMetricDefs = excludedTriggerMetricIds.map(metricId => {
            const metricDef = this.dataOceanMetaData.metrics[metricId];
            return {value: metricDef.id, label: metricDef.label};
        });

        columns.variables = [];
        for (const key in variables) {
            const varCollection = variables[key];
            if (varCollection.primitiveVariables) {
                for (const variable of varCollection.primitiveVariables) {
                    if (
                        ![
                            PrimitiveVariableType.CONNECTOR, PrimitiveVariableType.JSON, 
                            PrimitiveVariableType.AUTH_PROFILE, PrimitiveVariableType.ALLUVIO_EDGE
                        ].includes(variable.type)
                    ) {
                        columns.variables.push(
                            {
                                id: variable.name, label: variable.name, type: variable.type, 
                                unit: variable.unit ? variable.unit.toString() : "", 
                                raw: {...variable, unit: variable.unit ? variable.unit.toString() : ""}
                            }
                        );    
                    }
                }
            }
        }

        if (triggerType && !parentNodeWithContext) {
            // We are connected directly to a trigger node
            // Add back in when RO supports trigger node
            if (triggerType !== InputType.SUBFLOW && triggerType !== InputType.ON_DEMAND) {
                if (this.triggerContext && this.triggerContext.expandedKeys) {
                    let triggerKeyColumns: Array<DecisionBranchColumn | GenericKey> = [];
                    triggerKeyColumns = RunbookContext.getExpandedKeysForTrigger(triggerType, customProperties, "", true);   
                    triggerKeyColumns = triggerKeyColumns.map((item: GenericKey) => {
                        return {
                            id: item.id,
                            label: (((item.id.includes("custom.") && item.id.includes(".id")) || item.id === "data_source.id") && item.label.includes(" (ID)")
                                ? item.label.substring(0, item.label.indexOf(" (ID)")) 
                                : item.label),
                            type: item.type,
                            unit: item.unit,
                            raw: item
                        }
                    }).filter((key) => {
                        // Filter out custom property names
                        return !key.id.includes("custom.") || !key.id.includes(".name");
                    }).filter((key) => {
                        // Filter out JSON columns
                        return key.type !== "json";
                    }).filter((key) => {
                        // Don't show the data_source name and hostname fields
                        return key.id !== "data_source.name" && key.id !== "data_source.hostname"
                    });
                    columns.triggerKeys = triggerKeyColumns;
                }
                let triggerEntityTypes: Array<{value: string, label: string}> = [];
                switch (triggerType) {
                    case InputType.DEVICE:
                        triggerEntityTypes = [{value: "network_device", label: STRINGS.incidents.entityKinds.network_device}];
                        break;
                    case InputType.INTERFACE:
                        triggerEntityTypes = [{value: "network_interface", label: STRINGS.incidents.entityKinds.network_interface}];
                        break;
                    case InputType.APPLICATION_LOCATION:
                        triggerEntityTypes = [
                            {value: "application_location", label: STRINGS.incidents.entityKinds.application_location}, 
                            //{value: "application_server", label: STRINGS.incidents.entityKinds.application_server}
                        ];
                        break;
                    case InputType.LOCATION:
                        triggerEntityTypes = [
                            {value: "location", label: STRINGS.incidents.entityKinds.location}
                        ];
                        break;
                    case InputType.APPLICATION:
                        triggerEntityTypes = [
                            {value: "application", label: STRINGS.incidents.entityKinds.application}
                        ];
                        break;
                    case InputType.WEBHOOK:
                        triggerEntityTypes = [];
                        break;
                }
                let analysisTypes: Array<{value: string, label: string}> = [];
                for (const type of Object.values(INDICATOR_TYPE)) {
                    analysisTypes.push({value: type.toLocaleLowerCase(), label: INDICATOR_TO_LABEL_MAP[type]})
                }
                if (this.triggerContext) {
                    columns.triggerMetrics = triggerMetricIds.map((metricId): DecisionBranchColumn => {
                        const metric = {...this.dataOceanMetaData.metrics[metricId]};
                        metric.label += " *";
                        return {
                            id: metric.id,
                            label: metric.label,
                            type: metric.type,
                            unit: metric.unit,
                            raw: {...metric},
                        }
                    });
                    columns.triggerGenericMetrics = triggerType === "webhook" ? [] : [{
                        id: "$INDICATOR.KEY.metric",
                        label: "Triggering Metric",
                        type: "string",
                        unit: "none",
                        raw: {
                            id: "$INDICATOR.KEY.metric",
                            label: "Triggering Metric",
                            type: "string",
                            unit: "none"   
                        },
                    }, {
                        id: "$ENTITY.KEY.kind",
                        label: "Entity Type",
                        type: "string",
                        unit: "none",
                        raw: {
                            id: "$ENTITY.KEY.kind",
                            label: "Entity Type",
                            type: "string",
                            unit: "none"   
                        },
                    /* Let's wait on exposing this    
                    }, {
                        id: "$INDICATOR.KEY.kind",
                        label: "Anomaly Type",
                        type: "string",
                        unit: "none",
                        raw: {
                            id: "PRIMARY_INDICATOR.KEY.kind",
                            label: "Anomaly Type",
                            type: "string",
                            unit: "none"   
                        },
                    */
                    }];
                }
                columns.properties = { 
                    comparedTo: undefined, 
                    isTrigger: true,
    // This needs to be re-enabled but the filter operation on the editor needs to be fixed                
                    //hasTriggerMetric: true,
                    triggerMetrics: triggerMetricDefs,
                    excludedTriggerMetrics: excludedTriggerMetricDefs,
                    triggerEntityTypes: triggerEntityTypes,
                    analysisTypes: analysisTypes,
                    // For Webhooks this would allow the user to enter the key in a text box rather than picking from a combo box
                    //userProvidesKey: triggerType === InputType.WEBHOOK
                };    
            } else {
                // For the subflow input trigger, make it look like any other node
                if (this.triggerContext) {
                    let triggerKeyColumns: Array<DecisionBranchColumn | GenericKey> = [];
                    triggerKeyColumns = this.triggerContext.expandedKeys;   
                    triggerKeyColumns = triggerKeyColumns.map((item: GenericKey) => {
                        return {
                            id: item.id,
                            label: (((item.id.includes("custom.") && item.id.includes(".id")) || item.id === "data_source.id") && item.label.includes(" (ID)")
                                ? item.label.substring(0, item.label.indexOf(" (ID)")) 
                                : item.label
                            ),
                            type: item.type,
                            unit: item.unit,
                            raw: item
                        }
                    }).filter((key: DecisionBranchColumn) => {
                        // Eliminate the custom property name columns from the decision branch
                        return !key.id.includes(".custom.") || !key.id.endsWith(".name");
                    }).filter((key) => {
                        // Filter out JSON columns
                        return key.type !== "json";
                    });
                    columns.keys = triggerKeyColumns;
                    const metricColumns: Array<DecisionBranchColumn> = (this.triggerContext.metrics || []).map((item): DecisionBranchColumn => {
                        return {
                            id: item.id,
                            label: item.label,
                            type: item.type,
                            unit: item.unit,
                            raw: item,
                        }
                    });
                    columns.metrics = metricColumns;
                }
            }
        } else if (httpNodes.includes(parentNodeWithContext?.source?.type || "")) {
            // We are connected directly to an http node.
            const httpKeyColumns: Array<DecisionBranchColumn> = keyColumns;
            // Use this if you want them to re-use the keys format
            //columns.keys = httpKeyColumns;
            // Use this if you want them to have a custom format
            columns.keys = httpKeyColumns;
            columns.properties = { comparedTo: undefined, isHttp: true };
        } else if (
            [...transformNodes, ...dataOceanNodes, ...logicalNodes, ...decisionNodes, ...subflowNodes, ...aiNodes].includes(parentNodeWithContext?.source?.type || "")
        ) {
            const metricColumns: Array<DecisionBranchColumn> = metricsFromContext.map((item): DecisionBranchColumn => {
                return {
                    id: item.id,
                    label: item.label,
                    type: item.type,
                    unit: item.unit,
                    raw: item,
                }
            });
            const triggerMetricColumns: Array<DecisionBranchColumn> = [];
            if (triggerType !== InputType.WEBHOOK) {
                // The webhook trigger has no triggerring metric so hide it
                triggerMetricColumns.push({
                    id: "$INDICATOR.KEY.metric",
                    label: "Triggering Metric",
                    type: "string",
                    unit: "none",
                    raw: {
                        id: "$INDICATOR.KEY.metric",
                        label: "Triggering Metric",
                        type: "string",
                        unit: "none"   
                    }
                });
            }
            columns.keys = keyColumns;
            columns.metrics = metricColumns;
            columns.triggerGenericMetrics = triggerMetricColumns;
            columns.properties = { 
                comparedTo: parentNodeWithContext!.comparedTo, 
                //hasTriggerMetric: true, 
                triggerMetrics: triggerMetricDefs,
                excludedTriggerMetrics: excludedTriggerMetricDefs
            };
        
            // Mark all trigger metrics with an asterisk
            columns.metrics = columns.metrics.map(metric => {
                const updatedMetric = {...metric, raw: {...metric.raw} as GenericKey};
                if (triggerMetricIds.includes(updatedMetric.id)) {
                    updatedMetric.label += " *";
                    updatedMetric.raw.label += " *";
                }
                return updatedMetric;
            })
        } else if (aggregatorNodes.includes(parentNodeWithContext?.source?.type || "")) {
            const metricColumns: Array<DecisionBranchColumn> = (metricsFromContext.map((item: any): DecisionBranchColumn => {
                return {
                    id: item.id,
                    label: item.label,
                    type: item.type,
                    unit: item.unit,
                    raw: item,
                }
            }));
            columns.keys = keyColumns;
            columns.metrics = metricColumns;
        }
        return columns;
    }

    /** Infer input type from the closest parent Data ocean node
    *  @returns input type */
    private getInputTypeFromClosestParentDataOceanNode() {
        let inpType: string | undefined = undefined;
        if (this.nodeContexts) {
            for (let i = this.nodeContexts.length - 1; i >= 0; i--) {
                let curNode = this.nodeContexts[i];
                if (curNode && dataOceanNodes.includes(curNode.source.type)) {
                    inpType = curNode.objType;
                    break;
                }
            }
        }
        return inpType;
    }

    /** returns the primary key count for a branch of keys.
     *  @param key the branch of keys to check.
     *  @returns a number with the primary key count. */
    public static getPrimaryKeyCount(key: Record<string, any>): number {
        let primaryKeyCount = 0;
        if (key) {
            if (key.primary_key) {
                primaryKeyCount++;
            }
            if (key.properties) {
                for (const subKey in key.properties) {
                    primaryKeyCount += RunbookContext.getPrimaryKeyCount(key.properties[subKey]);
                }
            }
        }
        return primaryKeyCount;
    }

    /** returns true if the specified context contains all the compound keys.
     *  @param compoundKey a string with the compound key, for example network_client_server.
     *  @param context the Context to check.
     *  @returns true if the context has all the keys from the compound key, false otherwise. */
    public static hasAllSubKeysForCompoundKey(compoundKey: string, context: Context): boolean {
        if (RunbookContext.KEY_ARRAYS_BY_COMPOUND_KEYS[compoundKey]) {
            const requiredKeys = RunbookContext.KEY_ARRAYS_BY_COMPOUND_KEYS[compoundKey];
            let hasAllKeys = true;
            const contextKeys: Array<string> = context.keys;
            for (const requiredKey of requiredKeys) {
                if (!contextKeys.includes(requiredKey)) {
                    hasAllKeys = false;
                    break;
                }
            }
            if (hasAllKeys) {
                return true;
            }
        }
        return false;
    }

    /** checks to see if the filter is compabtible with the specified context.  The initial check
     *      outside of this function checks for an exact match of the filter key, while this function
     *      then looks at the compatibility arrays to see if the filter is possibly not an exact match
     *      but is nonetheless compatible.
     *  @param filter a String with the filter to check.
     *  @param context the Context object with the node or trigger context.
     *  @returns a boolean value, true if the filters is compatible with the context, false otherwise. */
    public static isFilterCompatibleWithContext(filter: string, context: Context): boolean {
        const contextKeys: Array<string> = context.keys;
        for (const key of contextKeys) {
            if (RunbookContext.areFiltersCompatible(filter, key)) {
                return true;
            }
        }
        return false;
    }

    /** checks to see if the two filter strings are compatible.
     *  @param filter1 a String with the first filter to check.
     *  @param filter2 a String with the second filter to check.
     *  @returns a boolean value, which if true, specifies that the two filters are compatible and may be used interchangeably. */
    public static areFiltersCompatible(filter1: string, filter2: string): boolean {
        for (const compFilters of RunbookContext.COMPATIBLE_FILTERS) {
            if (compFilters.includes(filter1) && compFilters.includes(filter2)) {
                return true;
            }
        }
        return false;
    }

    /** checks to see if the two custom property types and the specified key is compatible.
     *  @param types a String array with the types that the custom property supports.
     *  @param filter2 a String with the second filter to check.
     *  @returns a boolean value, which if true, specifies that the custom property types includes the specified key. */
    public static areCustomPropertyTypesCompatible(types: string[], key: string): boolean {
        for (const type of types) {
            if (type === key || RunbookContext.areFiltersCompatible(type, key)) {
                return true;
            }
        }
        return false;
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the aggregator node 
     *      groupBy key.  Once the key is generated, the column def is created.
     *  @param triggerType the trigger InputType.
     *  @param customProperties the array of CustomProperty objects with the custom properties for all entity types.
     *  @param options an optional RunbookContextOptions object with any options that need to be passed in via the contructor. 
     *  @returns an array with all the key definitions. */
    public static getExpandedKeysForTriggerWithParent(triggerType: InputType, customProperties: CustomProperty[], options?: RunbookContextOptions): Array<GenericKey> {
        let parent: string = "";
        switch (triggerType) {
            case InputType.DEVICE:
                parent = "network_device";
                break;
            case InputType.INTERFACE:
                parent = "network_interface";
                break;
            case InputType.APPLICATION_LOCATION:
                parent = "";// "application_location"
                break;
            case InputType.LOCATION:
                parent = "location";
                break;
            case InputType.APPLICATION:
                parent = "application"
                break;
        }
        return RunbookContext.getExpandedKeysForTrigger(triggerType, customProperties, parent, false, options);
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the aggregator node 
     *      groupBy key.  Once the key is generated, the column def is created.
     *  @param triggerType the trigger InputType.
     *  @param customProperties the array of CustomProperty objects with the custom properties for all entity types.
     *  @param parent a String with the parent path to append to the id.
     *  @param hideCustomPropertyNames in the decision branch the custom property names are not supported only 
     *      the ids so add a flag to hide them.
     *  @param options an optional RunbookContextOptions object with any options that need to be passed in via the contructor. 
     *  @returns an array with all the key definitions. */
    public static getExpandedKeysForTrigger(
        triggerType: InputType, customProperties: CustomProperty[], parent: string,
        hideCustomPropertyNames: boolean = false, options?: RunbookContextOptions
    ): Array<GenericKey> {
        const runbookContextMetadata: DataOceanMetadata = JSON.parse(JSON.stringify(RunbookContextKeys));
        if (options?.forLiquid) {
            runbookContextMetadata.keys = {...runbookContextMetadata.keys, ...RunbookContextKeysForLiquid.keys as Record<string, DataOceanKey>};
        }
        runbookContextMetadata.keys = RunbookContextUtils.getAvailableKeysForKeyMap(runbookContextMetadata.keys, [], getTypes());

        let expandedKeys: Array<GenericKey> = [];
        switch (triggerType) {
            case InputType.DEVICE:
                NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_network_device, parent, expandedKeys);
                RunbookContext.appendCustomPropertiesToExpandedKeys(triggerType, customProperties, parent, "", hideCustomPropertyNames, expandedKeys);
                break;
            case InputType.INTERFACE:
                NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_network_interface, parent, expandedKeys);
                RunbookContext.appendCustomPropertiesToExpandedKeys(triggerType, customProperties, parent, "", hideCustomPropertyNames, expandedKeys);
                break;
            case InputType.APPLICATION_LOCATION:
                NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_application_location, parent, expandedKeys);
                RunbookContext.appendCustomPropertiesToExpandedKeys(
                    InputType.APPLICATION_LOCATION, customProperties, parent || "application", ALLOW_MULTI_TYPE ? " (Application)" : "", hideCustomPropertyNames, expandedKeys
                );
                RunbookContext.appendCustomPropertiesToExpandedKeys(
                    InputType.LOCATION, customProperties, parent || "location", ALLOW_MULTI_TYPE ? " (Location)" : "", hideCustomPropertyNames, expandedKeys
                );
                break;
            case InputType.LOCATION:
                NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_location, parent, expandedKeys);
                RunbookContext.appendCustomPropertiesToExpandedKeys(
                    triggerType, customProperties, parent || "location", "", hideCustomPropertyNames, expandedKeys
                );
                break;
            case InputType.APPLICATION:
                NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_application, parent, expandedKeys);
                RunbookContext.appendCustomPropertiesToExpandedKeys(
                    triggerType, customProperties, parent || "application", "", hideCustomPropertyNames, expandedKeys
                );
                break;
            case InputType.WEBHOOK:
                if (runbookContextMetadata.keys.metadata_webhook) {
                    NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_webhook, parent, expandedKeys);
                }
                break;
            case InputType.IMPACT_ANALYSIS_READY:
                if (runbookContextMetadata.keys.metadata_impact_analysis_ready) {
                    NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_impact_analysis_ready, parent, expandedKeys);
                }
                break;
            case InputType.INCIDENT_NOTE_ADDED:
                if (runbookContextMetadata.keys.metadata_note_added) {
                    NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_note_added, parent, expandedKeys);
                }
                break;
            case InputType.INCIDENT_NOTE_UPDATED:
                if (runbookContextMetadata.keys.metadata_note_updated) {
                    NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_note_updated, parent, expandedKeys);
                }
                break;
            case InputType.INCIDENT_NOTE_DELETED:
                if (runbookContextMetadata.keys.metadata_note_deleted) {
                    NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_note_deleted, parent, expandedKeys);
                }
                break;
            case InputType.INCIDENT_ONGOING_CHANGED:
                if (runbookContextMetadata.keys.metadata_ongoing_state_changed) {
                    NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_ongoing_state_changed, parent, expandedKeys);
                }
                break;
            case InputType.INCIDENT_STATUS_CHANGED:
                if (runbookContextMetadata.keys.metadata_status_changed) {
                    NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_status_changed, parent, expandedKeys);
                }
                break;
            case InputType.INCIDENT_INDICATORS_UPDATED:
                if (runbookContextMetadata.keys.metadata_indicators_updated) {
                    NodeUtils.expandKeyProperties(runbookContextMetadata.keys.metadata_indicators_updated, parent, expandedKeys);
                }
                break;
        }
        
        return expandedKeys;
    }

    /** appends the custom property keys to the expanded keys.
     *  @param triggerType the trigger InputType.
     *  @param customProperties the array of CustomProperty objects with the custom properties for all entity types.
     *  @param parent a String with the parent path to append to the id.
     *  @param nameSuffix a String with the prefix to add to the name of the key.
     *  @param hideCustomPropertyNames in the decision branch the custom property names are not supported only 
     *      the ids so add a flag to hide them.
     *  @param expandedKeys the array of keys that has the list of expanded keys. */
    public static appendCustomPropertiesToExpandedKeys(
        triggerType: InputType, customProperties: CustomProperty[], parent: string, nameSuffix: string = "",
        hideCustomPropertyNames: boolean = false, expandedKeys: Array<GenericKey>
    ): void {
        for (const property of customProperties) {
            if (property.types.includes(triggerType)) {
                expandedKeys.push({
                    id: parent + (parent ? "." : "") + "custom." + property.id + ".id", 
                    label: property.name + (hideCustomPropertyNames ? "" : " (ID)") + nameSuffix, 
                    type: "property", unit: "none", hidden: true
                });
                if (!hideCustomPropertyNames) {
                    expandedKeys.push({
                        id: parent + (parent ? "." : "") + "custom." + property.id + ".name", label: property.name + nameSuffix, 
                        type: "string", unit: "none"
                    });
                }
            }
        }
    }

    /** Creates a key of the form network_host.ipaddr and network_host.location.name from the trigger node keys.  
     *      Once the key is generated, the column def is created.
     *  @param inputType the trigger InputType.
     *  @param dataOceanMetaData the DataOceanMetaData object with the full metadata for the data ocean.
     *  @param availableDataSources the array of dataSourceTypeOptions with the data sources to check.
     *  @returns an array with all the metric definitions. */
    public static getMetricsForTrigger(
        triggerType: InputType, dataOceanMetaData: DataOceanMetadata, availableDataSources: dataSourceTypeOptions[]
    ): Array<GenericKey> {
        let baseMetrics: Array<string | DataOceanMetric> = getTriggerMetricIds(triggerType, [], dataOceanMetaData, availableDataSources);
        baseMetrics = baseMetrics.map((id) => {
            return dataOceanMetaData.metrics[id as string];
        });
        let metrics: Array<GenericKey> = [];
        if (![...LIFECYCLE_TRIGGER_TYPES, InputType.WEBHOOK].includes(triggerType)) {
            metrics.push({id: 'trigger_metric.actual_value', label: "Trigger Metric (Actual)", type: "float", unit: "none"});
            //metrics.push({id: 'trigger_metric.expected', label: "Trigger Metric (Expected)", type: "float", unit: "none"});
            metrics.push({id: 'trigger_metric.acceptable_low_value', label: "Trigger Metric (Acceptable Low)", type: "float", unit: "none"});
            metrics.push({id: 'trigger_metric.acceptable_high_value', label: "Trigger Metric (Acceptable High)", type: "float", unit: "none"});
            for (const metric of baseMetrics as DataOceanMetric[]) {
                metrics.push({...metric, id: metric.id + '.actual_value', label: metric.label + " (Actual)"});
                //metrics.push({...metric, id: metric.id + '.expected', label: metric.label + " (Expected)"});
                metrics.push({...metric, id: metric.id + '.acceptable_low_value', label: metric.label + " (Acceptable Low)"});
                metrics.push({...metric, id: metric.id + '.acceptable_high_value', label: metric.label + " (Acceptable High)"});
            }
        }
        return metrics;
    }

    /** Removes all properties from a metric definition that the user should not see when outputting a metric.
     *  @param metrics the list of metric definitions.
     *  @param dataOceanMetaData the DataOceanMetaData object with the full metadata for the data ocean.
     *  @returns an array with all the sanitized metric definitions. */
    public static sanitizeMetricsForOutput(metrics: DataOceanMetric[], dataOceanMetaData: DataOceanMetadata): GenericKey[] {
        const outputMetrics = (metrics || []).map((metric) => {
            return {
                id: metric.id, label: metric.label, unit: metric.unit, type: metric.type, enum: metric.enum, 
                order_by_weight: metric.order_by_weight
            };
        });
        return outputMetrics;
    }
} 
