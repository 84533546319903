/** This file defines the TimeChart React component.  The TimeChart React component renders a
 *  a basic time chart with n-groups and one metric.
 *  @module */
import React, { useCallback, useRef, useState } from "react";
import { Classes, Dialog } from "@blueprintjs/core";
import { scaleMetric } from "reporting-infrastructure/utils/formatters";
import {
    CHART_SERIES_COLORS,
    CHART_COLORS,
    TIME_FORMAT,
} from "components/enums";
import { STRINGS } from "app-strings";
import { formatToLocalTimestamp } from "reporting-infrastructure/utils/formatters/GeneralFormatter";
import { HighchartsData } from "components/reporting/utils/Types";
import { cloneDeep, merge } from "lodash";
import { parseTimeFromDAL, useGlobalTime } from "utils/hooks";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import OfflineExporting from "highcharts/modules/offline-exporting";
import { DEFAULT_TIME_SERIES_OPTIONS } from "components/reporting/charts/defaults/HighchartDefaults";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { BaseChartProps, GroupMetricSource } from "../chart-base/ChartBase";
import {
    ChartType,
    showSettingsDialog,
    LegendPosition,
    LineStyle,
    TimeChartSettings,
    ToolbarAction,
} from "../chart-base/ChartToolbar";
import {
    BasicDialog,
    updateDialogState,
} from "components/common/basic-dialog/BasicDialog";
import "components/common/chart-base/ChartBase.css";
import useResizeObserver from "utils/hooks/useResizeObserver";

// This is needed to enable the highcharts no data functionality
NoDataToDisplay(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

const defaultOptions = DEFAULT_TIME_SERIES_OPTIONS;

/** This interface defines the properties passed into the time series chart React component.*/
export interface TimeChartProps extends BaseChartProps {
    /** a boolean with the loading status. */
    loading?: boolean;
    /** The primary data which should have the same keys as the groupsKeyData object.*/
    primaryData?: Array<TimeChartDatum>;
    /** The comparison data which should have the same keys as the groupsKeyData object.*/
    compData?: Array<TimeChartDatum>;
    /** a boolean value, if true show the chart title.  If the value is false do not show the title.  If this property
     *  is undefined it is assumed to be true. */
    showChartSubtitle?: boolean;
    /** the TimeChartSetings object with the basic settings for the chart such as the style and legend position. */
    settings?: TimeChartSettings;
    /** the offset of the comparison data in seconds. */
    comparisonOffset?: number;
    /** the suffix to use in the legend when displaying comparison data. */
    comparisonSuffix?: string;
    /** A flag to force a reflow after a window resize happens */
    reflowOnResize?: boolean;
    /** displays the highcharts export menu */
    showMenu?: boolean;
}

/** this interface defines the data structure for holding one line on the time series chart. */
export interface TimeChartDatum {
    /** the name of the group, if any. */
    groupName?: string;
    /** the id of the group, if any. */
    groupId?: string;
    /** the name of the metric, if any. */
    metricName?: string;
    /** the id of the metric if any. */
    metricId?: string;
    /** the units for the data. */
    unit?: Unit;
    /** the time series data. */
    data?: HighchartsData;
    /** the data that is passed when there is a selection. */
    group?: any;
}

/** Renders the time series React component.
 *  @param props the properties passed in.
 *  @returns JSX with the time series chart component.*/
export function TimeChart(props: TimeChartProps): JSX.Element {
    const [settings, setSettings] = useState<TimeChartSettings>(
        props.settings || {},
    );
    const [dialogState, setDialogState] = useState<any>({
        showDialog: false,
        loading: false,
        title: "",
        dialogContent: null,
        dialogFooter: null,
    });
    const handleSettingsOpen = useCallback(() => {
        showSettingsDialog(
            settings,
            ChartType.timeseries,
            setDialogState,
            (action: ToolbarAction, value: any) => {
                if (action === ToolbarAction.SETTINGS_CHANGED) {
                    setSettings(value);
                }
            },
        );
    }, [settings]);

    const { absoluteTime } = useGlobalTime();
    const DEFAULT_SERIES_TEMPLATE = {
        name: "",
        visible: true,
        showInLegend: true,
        color: "",
        data: [],
        dashStyle: "solid",
        yAxis: 0,
    };

    let isMultiGroup = false;
    let isMultiMetric = false;
    if (props.primaryData?.length) {
        const initGroup = props.primaryData[0].groupName;
        const initMetric = props.primaryData[0].metricName;
        for (let index = 1; index < props.primaryData.length; index++) {
            if (props.primaryData[index].groupName !== initGroup) {
                isMultiGroup = true;
            }
            if (props.primaryData[index].metricName !== initMetric) {
                isMultiMetric = true;
            }
        }
    }

    let yAxes: Array<any> = [];
    let seriesData: Array<any> = [];
    if (props.primaryData?.length) {
        let colorIndex = 0;
        const seriesColors = props.seriesColors
            ? props.seriesColors
            : CHART_SERIES_COLORS;
        for (const datum of props.primaryData) {
            const chartColor = seriesColors[colorIndex]
                ? seriesColors[colorIndex]
                : seriesColors[0];
            let tsData: HighchartsData = cloneDeep(datum.data || []);
            sortData(tsData);
            markIsolatedPoints(tsData);
            let yAxisIndex = -1;
            const datumUnit = datum?.unit?.unit || "none";
            for (let axisIndex = 0; axisIndex < yAxes.length; axisIndex++) {
                if (yAxes[axisIndex].name === datumUnit) {
                    yAxisIndex = axisIndex;
                    break;
                }
            }
            if (yAxisIndex === -1) {
                // All data on an axis needs to be in the same units, for now convert everything down to the base unit
                const unit = datum.unit;
                const yAxisUnit = unit?.clone();
                if (yAxisUnit && yAxisUnit.getDisplayName() !== "ms") {
                    // Remove any unit prefix for everything except milliseconds.  We don't want to convert ms to s
                    yAxisUnit.prefix = "";
                }
                yAxisIndex = yAxes.length;
                yAxes.push({
                    name: yAxisUnit?.unit || "none",
                    unit: yAxisUnit,
                    visible: true,
                    opposite: yAxisIndex !== 0,
                    lineWidth: 1,
                    gridLineWidth: 0,
                    title: {
                        text: getAxisLabel(datum, isMultiGroup, isMultiMetric),
                    },
                    labels: {
                        overflow: "justify",
                        formatter: function (this: any) {
                            return scaleMetric(this.value, new Unit())
                                .formatted;
                        },
                    },
                    seriesCount: 0,
                });
            }
            if (
                yAxes[yAxisIndex].unit &&
                datum.unit &&
                !yAxes[yAxisIndex].unit.isEqual(datum.unit)
            ) {
                // Convert the ts data to the base unit
                for (let dIndex = 0; dIndex < tsData.length; dIndex++) {
                    (tsData[dIndex] as any).y = datum.unit.convert(
                        (tsData[dIndex] as any).y,
                        yAxes[yAxisIndex].unit,
                    );
                }
            }

            // Update the axis title based on the number of series shown on the axis
            if (props.options?.yAxis && props.options?.yAxis["visible"]) {
                yAxes[yAxisIndex].seriesCount++;
                yAxes[yAxisIndex].title.text = getAxisLabel(
                    datum,
                    isMultiGroup,
                    isMultiMetric,
                    yAxes[yAxisIndex].seriesCount,
                );
                yAxes[yAxisIndex].plotLines =
                    props.options?.yAxis &&
                    props.options?.yAxis["plotLines"][yAxisIndex]
                        ? [props.options?.yAxis["plotLines"][yAxisIndex]]
                        : null;
            } else if (
                props.options?.yAxis &&
                !props.options?.yAxis["visible"]
            ) {
                yAxes[yAxisIndex]["visible"] = false;
            }
            yAxes[yAxisIndex].max =
                props.options?.yAxis &&
                props.options?.yAxis["max"] &&
                props.options?.yAxis["max"];

            let seriesTempData = cloneDeep(DEFAULT_SERIES_TEMPLATE);
            seriesTempData.name = getSeriesName(
                datum,
                isMultiGroup,
                isMultiMetric,
            );
            seriesTempData.color = chartColor;
            seriesTempData.yAxis = yAxisIndex;
            seriesTempData.data = tsData;
            seriesTempData.metric = datum.metricName;
            seriesTempData.unit = datum.unit;
            seriesTempData.groupData = datum.group;
            seriesTempData.metricData = datum.metricId;
            if (
                settings?.style === LineStyle.stepArea ||
                settings?.style === LineStyle.stepLine
            ) {
                seriesTempData.step = true;
            }
            if (props.onGroupMetricSelection) {
                seriesTempData.events = {
                    click: (event) => {
                        const selected = !event.point.selected;
                        event.point.select(selected, false);
                        props.onGroupMetricSelection!({
                            source: GroupMetricSource.SERIES,
                            selected,
                            //groups: [series.point.name],
                            groups: [event.point.series.userOptions.groupData],
                            metrics: [
                                event.point.series.userOptions.metridData,
                            ],
                        });
                    },
                    legendItemClick: (event) => {
                        const selected = !event.target.selected;
                        event.target.select(selected, false);
                        props.onGroupMetricSelection!({
                            source: GroupMetricSource.LEGEND,
                            selected,
                            groups: [event.target.userOptions.groupData],
                            metrics: [event.target.userOptions.metricData],
                        });
                    },
                };
            }
            seriesData.push(seriesTempData);
            colorIndex++;

            if (props.compData?.length) {
                for (const compDatum of props.compData) {
                    if (
                        datum.groupName === compDatum.groupName &&
                        datum.metricName === compDatum.metricName
                    ) {
                        let tsData: HighchartsData = cloneDeep(compDatum.data);
                        sortData(tsData);
                        markIsolatedPoints(tsData);
                        if (
                            yAxes[yAxisIndex].unit &&
                            datum.unit &&
                            !yAxes[yAxisIndex].unit.isEqual(datum.unit)
                        ) {
                            // Convert the ts data to the base unit
                            for (
                                let dIndex = 0;
                                dIndex < tsData.length;
                                dIndex++
                            ) {
                                (tsData[dIndex] as any).y = datum.unit.convert(
                                    (tsData[dIndex] as any).y,
                                    yAxes[yAxisIndex].unit,
                                );
                            }
                        }

                        let compColor = new Highcharts.Color(
                            seriesTempData.color,
                        )
                            .setOpacity(0.4)
                            .get();
                        let compSeries = cloneDeep(seriesTempData);
                        compSeries.name =
                            getSeriesName(
                                compDatum,
                                isMultiGroup,
                                isMultiMetric,
                            ) +
                                " - " +
                                props.comparisonSuffix || "";
                        compSeries.color = compColor;
                        compSeries.data = tsData;
                        compSeries.metric = compDatum.metricName;
                        compSeries.unit = compDatum.unit;
                        compSeries.comparisonOffset = props.comparisonOffset;
                        compSeries.groupData = compDatum.group;
                        compSeries.metricData = compDatum.metricId;
                        seriesData.push(compSeries);
                    }
                }
            }
        }
    } else {
        // Empty charts fail without this, this happens in the unit test
        yAxes.push({
            name: "none",
            unit: new Unit(),
            visible: true,
            opposite: false,
            lineWidth: 1,
            gridLineWidth: 0,
        });
    }
    let seriesOptions = seriesData;

    const showSubtitle =
        props.showChartSubtitle !== null &&
        props.showChartSubtitle !== undefined
            ? props.showChartSubtitle
            : true;
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
    const handleClose = useCallback(() => setIsOpen(false), []);

    const metric = props.primaryData?.length
        ? props.primaryData[0].metricName
        : "";
    const unit = props.primaryData?.length
        ? props.primaryData[0].unit
        : new Unit();

    /** The onResize function must preserve its references between component re-renders,
     * that's why I wrap it with the useCallback hook.
     * Otherwise, the resize observer would be re-created on every re-render,
     * which may lead to performance issues. */
    const onResize = useCallback((target: HTMLDivElement) => {
        chartRef.current?.chart.reflow();
    }, []);
    const resizedRef = useResizeObserver(onResize);

    const getChart = (popup: boolean = false) => {
        return (
            <div
                aria-label="timeSeriesMetrics card"
                className={
                    popup
                        ? Classes.DIALOG_BODY
                        : "flex timeSeriesChart" +
                          (props.transparent ? "" : " bg-light") +
                          (props.transparent || props.hideShadow
                              ? ""
                              : " shadow")
                }
                ref={resizedRef}
            >
                <HighchartsReact
                    highcharts={Highcharts}
                    immutable={true}
                    options={getChartOptions(
                        seriesOptions,
                        absoluteTime,
                        metric,
                        unit,
                        yAxes,
                        showSubtitle,
                        settings,
                        props.options,
                        handleOpen,
                        handleSettingsOpen,
                        handleClose,
                        props.fullScreenTitle,
                        props.showMenu
                    )}
                    containerProps={{
                        style: {
                            width: props.width ? props.width : "100%",
                            height: popup
                                ? 0.9 * window.innerHeight - 40 + "px"
                                : props.height
                                  ? props.height
                                  : "100%",
                            padding: popup ? "10px" : "",
                        },
                    }}
                    ref={chartRef}
                />
            </div>
        );
    };

    return (
        <>
            <BasicDialog
                dialogState={dialogState}
                onClose={() =>
                    setDialogState(
                        updateDialogState(dialogState, false, false, []),
                    )
                }
            />
            <Dialog
                title={props.fullScreenTitle ? props.fullScreenTitle : ""}
                isOpen={isOpen}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                onClose={handleClose}
                style={{
                    width: 0.75 * window.innerWidth,
                    height: 0.9 * window.innerHeight,
                }}
            >
                {getChart(true)}
            </Dialog>
            {getChart(false)}
        </>
    );
}

/** returns the time chart options for the specified series.  This function merges the default time
 *      chart options with the options specific to this chart.
 *  @param seriesData the data series to put in the time chart.
 *  @param absoluteTime the time to use in the chart.
 *  @param metric a String with the metric name.
 *  @param unit a String with the unit.
 *  @param yAxes an array of y-axis objects.
 *  @param showSubtitle specifies whether or not to show the subtitle.
 *  @param settings the TimeChartSettings object with some of the settings for the chart like the style and legend position.
 *  @param options additional options that should be merged into the chart options.
 *  @returns the chart options for the specified series.*/
function getChartOptions(
    seriesData,
    absoluteTime,
    metric: string = "",
    unit: Unit = new Unit(),
    yAxes: Array<any>,
    showSubtitle: boolean,
    settings: TimeChartSettings,
    options: Highcharts.Options | undefined,
    handleOpen: () => void,
    handleSettingsOpen: () => void,
    handleClose: () => void,
    chartTitle: string = "",
    showMenu: boolean = true,
): Highcharts.Options {
    const {
        style = LineStyle.area,
        showLegend = true,
        legendPosition = LegendPosition.top,
    } = settings;

    let optionsCopy = cloneDeep(defaultOptions);

    let legendLayout = "horizontal";
    let legendAlign = "left";
    let legendVerticalAlign = "top";
    switch (legendPosition) {
        case LegendPosition.top:
            // Defaults are set for top, nothing to do
            break;
        case LegendPosition.bottom:
            legendVerticalAlign = "bottom";
            break;
        case LegendPosition.left:
            legendLayout = "vertical";
            break;
        case LegendPosition.right:
            legendLayout = "vertical";
            legendAlign = "right";
            break;
    }

    merge(optionsCopy, {
        series: seriesData,
        subtitle: {
            style: {
                fontWeight: "bold",
            },
        },
        exporting: {
            enabled: showMenu,
            fallbackToExportServer: false,
            filename: `${chartTitle ? chartTitle : "timechart"}-riverbed`,
            buttons: {
                contextButton: {
                    menuItems: [
                        {
                            text: STRINGS.chartToolbar.toggleFullScreen,
                            onclick: () => {
                                handleOpen();
                            },
                        },
                        {
                            text: STRINGS.chartToolbar.settingsMenuItem,
                            onclick: () => {
                                handleClose();
                                handleSettingsOpen();
                            },
                        },
                        "downloadCSV",
                        "downloadPNG",
                    ],
                },
            },
            csv: {
                columnHeaderFormatter: (item: any) => {
                    if (item instanceof Highcharts.Axis) {
                        return "DateTime (local)";
                    }
                    return item.name;
                },
            },
            chartOptions: {
                chart: {
                    backgroundColor: "#fff",
                },
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            split: false,
            useHTML: true,
            formatter: function (this: any) {
                let time = this.x;
                if (
                    this.points?.length &&
                    this.points[0].series?.options?.comparisonOffset
                ) {
                    time =
                        time -
                        this.points[0].series?.options?.comparisonOffset * 1000;
                }
                let toolTip = formatToLocalTimestamp(
                    new Date(time),
                    TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT,
                );
                for (let i in this.points) {
                    const unit = this.points[i].series.options.unit
                        ? this.points[i].series.options.unit
                        : new Unit();
                    const symbol = "&#9632;";
                    toolTip +=
                        '<div><span style="font-size:16px;color:' +
                        this.points[i].color +
                        '">' +
                        symbol +
                        "</span>" +
                        "<b><span> " +
                        this.points[i].series.name +
                        "</span></b> : <b>" +
                        scaleMetric(this.points[i].y, unit).formatted +
                        "</b></div>";
                }
                return toolTip;
            },
        },
        xAxis: {
            type: "datetime",
            visible: true,
            crosshair: {
                snap: true,
                width: 1,
            },
            labels: {
                formatter: function (this: any) {
                    return formatToLocalTimestamp(
                        this.value,
                        TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT,
                    );
                },
            },
        },
        yAxis: yAxes,
        legend: {
            enabled: showLegend,
            lineHeight: 8,
            symbolRadius: 0,
            layout: legendLayout,
            align: legendAlign,
            verticalAlign: legendVerticalAlign,
            floating: false,
            x: 0, //85
            y: 0,
            symbolHeight: 12,
            symbolWidth: 12,
            labelFormatter: function (this: any) {
                if (this && this.name) {
                    return this.name;
                }
            },
            itemStyle: {
                color: CHART_COLORS.LEGEND_DEFAULT,
            },
            itemHoverStyle: {
                color: CHART_COLORS.LEGEND_DEFAULT,
            },
        },
        //disable legend click
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function (event) {
                        return undefined;
                    },
                },
                fillOpacity: 0.2,
                lineWidth: 2,
                // Two days of 1 minute data
                turboThreshold: 2 * 1440,
            },
        },
    });
    if (showSubtitle) {
        const parsedStartTime = parseTimeFromDAL(absoluteTime.startTime);
        const parsedEndTime = parseTimeFromDAL(absoluteTime.endTime);
        // If both start and end time are valid
        if (parsedStartTime && parsedEndTime) {
            optionsCopy.subtitle.text =
                formatToLocalTimestamp(
                    parsedStartTime,
                    TIME_FORMAT.DISPLAY_24HOUR_FORMAT,
                ) +
                " - " +
                formatToLocalTimestamp(
                    parsedEndTime,
                    TIME_FORMAT.DISPLAY_24HOUR_FORMAT,
                );
        }
    }
    if (style === "bar") {
        merge(optionsCopy, {
            chart: {
                type: "column",
            },
            plotOptions: {
                column: {
                    borderColor: "transparent",
                    stacking: "normal",
                },
            },
        });
        const timeSeriesOptions = optionsCopy.series[0];
        delete timeSeriesOptions.type;
        delete timeSeriesOptions.borderRadius;
        delete timeSeriesOptions.maxPointWidth;
        delete optionsCopy.plotOptions.series;
    } else if (style === "line" || style === "stepLine") {
        merge(optionsCopy, {
            chart: {
                type: "line",
            },
            series: [{ type: "line" }],
        });
    } else if (style === "area" || style === "stackedArea") {
        merge(optionsCopy, {
            chart: {
                type: "area",
            },
            series: [{ type: "area" }],
        });
        if (style === "stackedArea") {
            merge(optionsCopy, {
                plotOptions: {
                    area: {
                        stacking: "normal",
                    },
                },
            });
        }
    }
    if (options) {
        merge(optionsCopy, options);
    }
    return optionsCopy;
}

/** takes all the points that are not part of a line segment and adds a marker to those points so
 *      can be displayed.
 *  @param points the array of point to search for isolated points that are not part of any line segments. */
function markIsolatedPoints(points: Array<any>): void {
    for (let index = 0; index < points.length; index++) {
        if (index === 0 && points.length > 1 && points[1].y === null) {
            points[index].marker = { enabled: true, radius: 2 };
        } else if (
            index === points.length - 1 &&
            points.length > 1 &&
            points[points.length - 2].y === null
        ) {
            points[index].marker = { enabled: true, radius: 2 };
        } else if (
            index > 0 &&
            index < points.length - 1 &&
            points[index - 1].y === null &&
            points[index + 1].y === null
        ) {
            points[index].marker = { enabled: true, radius: 2 };
        } else if (points.length === 1) {
            points[index].marker = { enabled: true, radius: 2 };
        }
    }
}

/** sorts the specified point array in ascending order.
 *  @param points the array of points to sort. */
function sortData(points: Array<any>): void {
    if (
        points &&
        points.length > 0 &&
        points[0].x !== null &&
        points[0].x !== undefined
    ) {
        points.sort((a, b) => {
            return a && b ? (a.x > b.x ? 1 : a.x < b.x ? -1 : 0) : 0;
        });
    }
}

/** returns the series name depending on whether this chart is showing multiple groups, multiple metrics or both
 *  @param datum the TimeChartDatum with the time series data.
 *  @param isMultiGroup a boolean, true if there are more than one group on the chart.
 *  @param isMultiMetric a boolean, true if there is more than one metric on the chart.
 *  @returns a String with the name for the series. */
function getSeriesName(
    datum: TimeChartDatum,
    isMultiGroup: boolean,
    isMultiMetric: boolean,
): string {
    const unit = datum.unit;
    const yAxisUnit = unit?.clone();
    if (yAxisUnit && yAxisUnit.getDisplayName() !== "ms") {
        yAxisUnit.prefix = "";
    }
    return isMultiGroup && isMultiMetric
        ? (datum.groupName || "") +
              " - " +
              datum.metricName +
              (yAxisUnit && yAxisUnit.getDisplayName() !== ""
                  ? " (" + yAxisUnit.getDisplayName() + ")"
                  : "")
        : isMultiMetric
          ? (datum.metricName || "") +
            (yAxisUnit && yAxisUnit.getDisplayName() !== ""
                ? " (" + yAxisUnit.getDisplayName() + ")"
                : "")
          : datum.groupName || "";
}

/** returns the series name depending on whether this chart is showing multiple groups, multiple metrics or both
 *  @param datum the TimeChartDatum with the time series data.
 *  @param isMultiGroup a boolean, true if there are more than one group on the chart.
 *  @param isMultiMetric a boolean, true if there is more than one metric on the chart.
 *  @param seriesCount the number of series on the axis, if it is multi-metric but only one series, continue to show
 *      the metric name.
 *  @returns a String with the name for the series. */
function getAxisLabel(
    datum: TimeChartDatum,
    isMultiGroup: boolean,
    isMultiMetric: boolean,
    seriesCount: number = 1,
): string {
    const unit = datum.unit;
    const yAxisUnit = unit?.clone();
    if (yAxisUnit && yAxisUnit.getDisplayName() !== "ms") {
        yAxisUnit.prefix = "";
    }
    if (!isMultiMetric) {
        return (
            datum.metricName +
            " " +
            (yAxisUnit && yAxisUnit.getDisplayName() !== ""
                ? "(" + yAxisUnit.getDisplayName() + ")"
                : "")
        );
    } else {
        return yAxisUnit && yAxisUnit.getDisplayName() !== ""
            ? yAxisUnit.getDisplayName()
            : "";
    }
}
