/** This module contains constants and utilities that are used in generating fake data
 *  for the runbooks.
 *  @module
 */

import { PRIORITY, SEVERITY } from "components/enums";
import { RunbookConfig, RunbookNode } from "utils/services/RunbookApiService";
import { 
    getParents, isLogicalNode, dataOceanNodes, isDataOceanNode, isAggregatorNode, isDecisionNode, 
    isTransformNode, transformNodes, getFirstParentOfType, isTriggerNode, triggerNodes, isSubflowNode, 
    subflowNodes, isVariablesNode, logicalNodes, decisionNodes,
    isOnDemandInputNode, isAiNode,
    isHttpNode,
    aiNodes,
    httpNodes
} from 'utils/runbooks/RunbookUtils';
import { 
    RunbookOutput, DATA_TYPE, COLUMN_TYPE, Column, DataPoint, AverageData, TimeData, DataSetInfo, DataPointKeys, 
    DebugOutput, DEBUG_OUTPUT_TYPE, DataSet 
} from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { InputType, Variant } from "components/common/graph/types/GraphTypes";
import { STRINGS } from "app-strings";
import { 
    getDataOceanFiltersMap, hasTriggerOrConnectedNodeOrVariableOption, TRIGGER_OPTION_VALUE 
} from "components/common/graph/editors/data-ocean/DataOceanFilters";
import { ENTITY_KIND } from "components/enums/EntityKinds";
import { Entity } from "./EntityUtils";
import { getUuidV4 } from "utils/unique-ids/UniqueIds";
import { GenericKey, NodeUtils } from "./NodeUtil";
import { TransformNodeUtils } from "components/common/graph/editors/transform/TransformNodeUtils";
import { DataOceanMetadata, DataOceanMetric } from "components/common/graph/editors/data-ocean/DataOceanMetadata.type";
import { AggregateNodeUtil } from "components/common/graph/editors/aggregator/AggregatorNodeUtils";
import { RunbookContext } from "./RunbookContext.class";
import { getTypes } from "utils/stores/GlobalDataSourceTypeStore";
import { CustomProperty } from "pages/create-runbook/views/create-runbook/CustomPropertyTypes";
import RunbookContextKeys from "./RunbookContextKeys.json";

// specifies whether the dataset id should have the wire index appended to the id.
const appendWireIndexToDatasetId = true;

/** the list of tags to use when generating the fake data. */
const TAGS = [["Tag 1", "Tag A", "Tag B"], ["Tag 2"], ["Tag 3"], ["Tag 4"], ["Tag 5"]];
/** the list of apps to use when generating the fake data. */
const APPS = [
    '360_AntiVirus', 'Microsoft_Teams', 'DNS', 'YouTube', 'BitTorrent', 'Skype', 'SSH', 'SSL', 'TFTP',
    'MSSQL', 'Oracle','Facebook','RTP','Amazon_Web_Services','MS_Office_365','VoIP','AMQP','ARP','CAPWAP',
    'CCP','CHAP','DCCP','DNP3','ESP','ETH','Google_Safe','GOOSE','ICMP','IP','IPCP','IPV6CP','LCP','PAP',
    'PPP','PUP','TCP','UDP','WCCP','X.25','Amazon','Apple','Apple_Update','Box.net','Citrix_Online','Dropbox',
    'Facebook_Apps','Facebook_Messages','Facebook_Post','Facebook_Video','Flixster','FTP_Data','FTPS','Google',
    'Google_Maps','GoToMeeting','Grooveshark','H.225','H.245','McAfee','NetMeeting','Reddit','unclassified',
    'Windows_Live','Windows_Media','Yelp','Zynga','050Plus','17173.com','4399.com','4Shared','51.com','56.com',
    'about.com','accuweather.com','Adcash.com','adnStream','Adobe','Adobe_Flash','Adrive.com','ADTECH','Aizhan.com',
    'Akamai','Alibaba','Alipay.com','Allegro.pl','Answers.com','APNS','Apple_Maps','Apple_Music','AppNexus','Asana',
    'Autodesk','Avast.com','AVG','Avoidr','Babylon','Baidu','Bebo','Bet365','BigUpload','BJNP','Blokus','Bloomberg',
    'Booking.com','Brighttalk','Carbonite','Cedexis','Channel4','china.com','Chinaren.com','Classmates','ClickTale',
    'CloudFlare','Clubbox','CNN','CNZZ','Concur','Conduit','Coral_CDN','Crashlytics','Criteo','Crittercism','Cyworld',
    'Dailymotion','dcinside.com','Delicious','Delta_Search','DeviantART','Diameter','Digg','divShare','Docstoc',
    'DocuSign','EarthCam','Eastmoney','eBay','eBuddy','ESPN','etao.com','Eve_Online','Evony','Farmville','FC2.com',
    'Filer.cx','FileServe','FilesTube','Flurry','Fogbugz','Free','freeetv','Freenet','Friendster','Funshion','Ganji',
    'goal.com','GoGoBox','Google_Ads','Google_Analytics','Google_App_Engine','Googlebot','Google_Calendar','Google_Desktop',
    'Google_Translate','goo.ne.jp','Groupon','Hamachi','Hao123.com','hi5','Hotfile','Hotspot_Shield','HSRP','HTTP_Tunnel',
    'Huffington_Post','Hyves','iCall','IEC_60870_5_104','Ifeng.com','ifile.it','IKEA','ImageShack','Imgur','Iperf',
    'IRC_file_transfer','ISOIP','Jango','Java_Update','Kaixin','Kaspersky','Kooora.com','Krux','Kugou','Leboncoin',
    'Letv','Level3','Ligatus','LINE','LINE_Games','LiveJournal','LiveRail','Lokalisten','Lotame','Lync','Lync_Audio',
    'Lync_Video','MagicJack','Mail.ru','MANET','Marca','match.com','MediaFire','Media_Innovation_Group','Meerkat',
    'Meetup','Megashares','Metacafe','Microsoft',
];
/** the list of application types. */
const APP_TYPES = ["Desktop", "Web", "Desktop", "Web", "Desktop"];
/** the list of application versions. */
const APP_VERSIONS = ["69.0.1", "SAP 4.7", "5.8.6900", "SAP 4.6", "SAP 3.5"];
/** the list of devices to use when generating the fake data. */
const DEVICES = [
    "10.38.128.1", "10.38.128.2", "10.38.128.3", "10.38.128.4", "10.38.128.5",
    "10.38.128.6", "10.38.128.7", "10.38.128.8", "10.38.128.9", "10.38.128.10",
    "10.38.128.11", "10.38.128.12", "10.38.128.13", "10.38.128.14", "10.38.128.15",
    "10.38.128.16", "10.38.128.17", "10.38.128.18", "10.38.128.19", "10.38.128.20"
];
/** the list of interfaces to use when generating the fake data. */
const INTERFACES = ["10.38.128.8:1000", "10.38.128.8:1001", "10.38.128.8:1002", "10.38.128.8:1003", "10.38.128.8:1004"];
/** the list of interface ips to use when generating the fake data. */
const INTERFACE_IPS = ["10.38.128.8", "10.38.128.9", "10.38.128.10", "10.38.128.11", "10.38.128.12"];
/** the list of interfaces to use when generating the fake data. */
const INTERFACE_IFINDEXES = ["1000", "1001", "1002", "1003", "1004"];
/** the list of interface if aliases to use when generating the fake data. */
const INTERFACE_IF_ALIASES = ["ifalias1", "ifalias2", "ifalias3", "ifalias4", "ifalias5"];
/** the list of interface if aliases to use when generating the fake data. */
const INTERFACE_IF_DESCRS = ["ifdescr1", "ifdescr2", "ifdescr3", "ifdescr4", "ifdescr5"];
/** the list of interface ips to use when generating the fake data. */
const INTERFACE_IP_ARRAYS = [
    "[\"10.38.116.8\",\"10.38.117.8\",\"10.38.118.8\",\"10.38.119.8\",\"10.38.120.8\",\"10.38.121.8\",\"10.38.122.8\",\"10.38.123.8\",\"10.38.124.8\",\"10.38.125.8\",\"10.38.126.8\",\"10.38.127.8\",\"10.38.128.8\"]", 
    "[\"10.38.127.9\",\"10.38.128.9\"]", 
    "[\"10.38.128.10\"]", 
    "[\"10.38.128.11\"]", 
    "[\"10.38.128.12\"]"
];
/** the list of interface types to use when generating the fake data. */
const INTERFACE_TYPES = ["netflow", "netflow", "netflow", "netflow", "netflow"];
/** the list of interface speeds to use when generating the fake data. */
const INTERFACE_SPEEDS = ["1000000000", "1000000", "1000", "1000000", "1000000000"];
/** the list of hosts to use when generating the fake data. */
const HOSTS = [
    "10.38.128.1", "10.38.128.2", "10.38.128.3", "10.38.128.4", "10.38.128.5",
    "10.38.128.6", "10.38.128.7", "10.38.128.8", "10.38.128.9", "10.38.128.10",
    "10.38.128.11", "10.38.128.12", "10.38.128.13", "10.38.128.14", "10.38.128.15",
    "10.38.128.16", "10.38.128.17", "10.38.128.18", "10.38.128.19", "10.38.128.20"
];
/** the list of host names to use when generating the fake data. */
const NAMES = [
    "pc1.riverbed.com", "pc2.riverbed.com", "pc3.riverbed.com", "pc4.riverbed.com", "pc5.riverbed.com",
    "pc6.riverbed.com", "pc7.riverbed.com", "pc8.riverbed.com", "pc9.riverbed.com", "pc10.riverbed.com",
    "pc11.riverbed.com", "pc12.riverbed.com", "pc13.riverbed.com", "pc14.riverbed.com", "pc15.riverbed.com",
    "pc16.riverbed.com", "pc17.riverbed.com", "pc18.riverbed.com", "pc19.riverbed.com", "pc20.riverbed.com"
];
/** the list of clients to use when generating the fake data. */
const CLIENTS = [
    "154.38.128.1", "154.38.128.2", "154.38.128.3", "154.38.128.4", "154.38.128.5", 
    "154.38.128.6", "154.38.128.7", "154.38.128.8", "154.38.128.9", "154.38.128.10",
    "154.38.128.11", "154.38.128.12", "154.38.128.13", "154.38.128.14", "154.38.128.15", 
    "154.38.128.16", "154.38.128.17", "154.38.128.18", "154.38.128.19", "154.38.128.20"
];
/** the list of host names to use when generating the fake data. */
const CLIENT_NAMES = [
    "client1.riverbed.com", "client2.riverbed.com", "client3.riverbed.com", "client4.riverbed.com", "client5.riverbed.com",
    "client6.riverbed.com", "client7.riverbed.com", "client8.riverbed.com", "client9.riverbed.com", "client10.riverbed.com",
    "client11.riverbed.com", "client12.riverbed.com", "client13.riverbed.com", "client14.riverbed.com", "client15.riverbed.com",
    "client16.riverbed.com", "client17.riverbed.com", "client18.riverbed.com", "client19.riverbed.com", "client20.riverbed.com"
];
/** the list of client device names to use when generating the fake data. */
const CLIENT_DEVICE_NAMES = [
    "Desktop1", "Desktop2", "LabMachine1", "LabMachine2", "DesktopInConfRoom"
];
/** the list of client usernames to use when generating the fake data. */
const CLIENT_USERNAMES = [
    "john-smith", "michele-jones", "jan-rogers", "bob-smith", "john-rogers"
];
/** the list of client isps to use when generating the fake data. */
const CLIENT_ISPS = [
    "isp1", "isp2", "isp3", "isp4", "isp5"
];
/** the list of location names to use when generating the fake data. */
const USER_CONN_LOCATIONS = ["In-Office", "Remote (VPN On)", "Remote (VPN Off)", "In-Office", "Remote (VPN On)"];
/** the list of wifi bssid strings to use when generating the fake data. */
const WIFI_BSSIDS = ["50:60:28:21:86:A3", "50:60:28:21:86:A6", "50:60:28:21:86:A7", "49:60:28:21:86:A7", "LAN"];
/** the list of wifi channel strings to use when generating the fake data. */
const WIFI_CHANNELS = ["9", "11", "4", "6", "LAN"];
/** the list of wifi ssid strings to use when generating the fake data. */
const WIFI_SSIDS = ["22:44:28:21:86:A3", "28:44:28:21:86:A3", "29:44:28:21:86:B3", "34:44:28:21:86:A3", "LAN"];
/** the list of server to use when generating the fake data. */
const SERVERS = [
    "176.5.4.1", "176.5.4.2", "176.5.4.3", "176.5.4.4", "176.5.4.5",
    "176.5.4.6", "176.5.4.7", "176.5.4.8", "176.5.4.9", "176.5.4.10",
    "176.5.4.11", "176.5.4.12", "176.5.4.13", "176.5.4.14", "176.5.4.15",
    "176.5.4.16", "176.5.4.17", "176.5.4.18", "176.5.4.19", "176.5.4.20",
];
/** the list of host names to use when generating the fake data. */
const SERVER_NAMES = [
    "server1.riverbed.com", "server2.riverbed.com", "server3.riverbed.com", "server4.riverbed.com", "server5.riverbed.com",
    "server6.riverbed.com", "server7.riverbed.com", "server8.riverbed.com", "server9.riverbed.com", "server10.riverbed.com",
    "server11.riverbed.com", "server12.riverbed.com", "server13.riverbed.com", "server14.riverbed.com", "server15.riverbed.com",
    "server16.riverbed.com", "server17.riverbed.com", "server18.riverbed.com", "server19.riverbed.com", "server20.riverbed.com"
];
/** the list of location names to use when generating the fake data. */
const LOCATIONS = ["Boston", "Cambridge", "Sunnyvale", "San Francisco", "New York"];
/** the list of location types to use when generating the fake data. */
const LOCATION_TYPES = ["Type 1", "Type 2", "Type 3", "Type 4", "Type 5"];
/** the list of client location names to use when generating the fake data. */
const CLIENT_LOCATIONS = ["San Diego", "Morgan Hill", "Gilroy", "Mountain View", "Los Angeles"];
/** the list of server location names to use when generating the fake data. */
const SERVER_LOCATIONS = ["Bethpage", "Utica", "Rome", "Ithaca", "Lake Placid"];
/** the list of physical location names to use when generating the fake data. */
const PHYSICAL_LOCATIONS = ["United States / Connecticut / Hartford", "United States / California / San Jose", "United States / Massachusetts / Boston", "United States / Washington / Seattle", "United States / Maryland / Bethesda"];
/** the list of geo city names to use when generating the fake data. */
const GEO_CITIES = ["United States / Connecticut / Hartford", "United States / California / San Jose", "United States / Massachusetts / Boston", "United States / Washington / Seattle", "United States / Maryland / Bethesda"];
/** the list of geo state names to use when generating the fake data. */
const GEO_STATES = ["United States / Connecticut", "United States / California", "United States / Massachusetts", "United States / Washington", "United States / Maryland"];
/** the list of geo country names to use when generating the fake data. */
const GEO_COUNTRIES = ["United States", "United Kingdom", "Canada", "Mexico", "France"];
/** the list of cpu types to use when generating the fake data. */
const CPU_TYPES = ["cpmCPUTotal1minRev", "cpmCPUTotal5minRev", "cpmCPUTotal10minRev", "cpmCPUTotal20minRev", "cpmCPUTotal30minRev"];
/** the list of disk types to use when generating the fake data. */
const DISK_TYPES = ["/bootmgr", "/", "/tmp", "/boot", "/dev/shm", "/var", "/lib/firmware", "/proxy", "/lib/modules", "/config"];
/** the list of memory types to use when generating the fake data. */
const MEMORY_TYPES = ["Physical/Processor Memory", "I/O", "Swap", "Shared", "Disk"];
/** the list of resource names to use when generating the fake data. */
const RESOURCES = ["Resource 1", "Resource 2", "Resource 3", "Resource 4", "Resource 5"];
/** the list of type names to use when generating the fake data. */
const TYPES = ["Azure Monitor", "Type 2", "Type 3", "Type 4", "Type 5"];
/** the list of serial numbers to use when generating the fake data. */
const SERIAL_NUMBERS = ["sn_1", "sn_2", "sn_3", "sn_4", "sn_5"];
/** the list of OS Versions to use when generating the fake data. */
const OS_VERSIONS = ["1.0.0", "2.0.0", "3.0.0", "4.0.0", "5.0.0"];
/** the list of OS Versions to use when generating the fake data. */
const OS_TYPES = ["Windows 11", "Windows 10 Enterprise", "MacOs 10", "MacOs 12", "MacOs 13"];
/** the list of models to use when generating the fake data. */
const MODELS = ["Juniper 1", "Juniper 2", "Juniper 3", "Juniper 4", "Juniper 5"];
/** the list of vendors to use when generating the fake data. */
const VENDORS = ["Juniper", "Juniper", "Juniper", "Cisco", "Cisco"];
/** the list of device types to use when generating the fake data. */
const DEVICE_TYPES = ["Router", "Switch", "Router", "Switch", "Router"];
/** the list of device is gateways values to use when generating the fake data. */
const DEVICE_GATEWAYS = ["true", "false", "true", "false", "true"];
/** the list of protocol numbers to use when generating the fake data. */
const PROTOCOLS = [6, 17, 1, 2, 8];
/** the list of protocol names to use when generating the fake data. */
const PROTOCOL_NAMES = ["TCP", "UDP", "ICMP", "IGMP", "EGP"];
/** the list of port numbers to use when generating the fake data. */
const PORTS = [80, 443, 8080, 8443, 22];
/** the list of protocol numbers to use when generating the fake data. */
const DSCPS = [10, 12, 14, 18, 46];
/** the list of protocol names to use when generating the fake data. */
const DSCP_NAMES = ["AF11", "AF12", "AF13", "AF21", "EF"];
/** the list of key to use when generating the fake data. */
const KEYS = ["Key1", "Key2", "Key3", "Key4", "Key5"];
/** the list of property names to use when generating the fake data. */
const PROPERTIES = ["Property 1", "Property 2", "Property 3", "Property 4", "Property 5"];
/** the list of client usernames to use when generating the fake data. */
const USERS = ["john-smith", "michele-jones", "jan-rogers", "bob-smith", "john-rogers"];
/** the list of client usernames to use when generating the fake data. */
const USER_SYSTEM_NAMES = ["john-smith", "michele-jones", "jan-rogers", "bob-smith", "john-rogers"];
/** the list of process ids to use when generating the fake data. */
const PROCESS_IDS = ["pid1", "pid2", "pid3", "pid4", "pid5"];
/** the list of client usernames to use when generating the fake data. */
const PROCESS_NAMES = ["svchost.exe", "svchost.exe", "svchost.exe", "svchost.exe", "svchost.exe"];
/** the list of process ids to use when generating the fake data. */
const MACHINE_IDS = [
    "90fb34ab-c668-11ee-964b-5f18dd622fc4", "75fb34ab-c668-11ee-964b-5f18dd622fd5", "22fb34ab-c668-11ee-964b-5f18dd622fa3", 
    "11fb34ab-c668-11ee-964b-5f18dd622fe8", "51fb34ab-c668-11ee-964b-5f18dd622fd7"
];
/** the list of client usernames to use when generating the fake data. */
const INTERFACE_NAMES = ["ifc1", "ifc2", "ifc3", "ifc4", "ifc5"];
/** the list of data source names to use when generating the fake data. */
const DATA_SOURCE_NAMES = ["AR1", "AR2", "AR3", "AR4", "AR5"];
/** the list of data source names to use when generating the fake data. */
const DATA_SOURCE_HOST_NAMES = ["AR1-host", "AR2-host", "AR3-host", "AR4-host", "AR5-host"];
/** the list of data source ids to use when generating the fake data. */
const DATA_SOURCE_IDS = ["id1", "id2", "id3", "id4", "id5"];
/** the list of http response status codes to show in the fake data. */
const STATUS_CODES = ["200", "300", "400", "500", "201"];
/** the list of http response status codes to show in the fake data. */
const HEADERS = ["Content-Type: text/html", "Content-Type: text/json", "Content-Type: json", "Content-Type: csv", "Content-Type: html"];
/** the list of http response status codes to show in the fake data. */
const BODIES = ["{\"body\": \"body1\"}", "{\"body\": \"body2\"}", "{\"body\": \"body3\"}", "{\"body\": \"body4\"}", "{\"body\": \"body5\"}"];
/** the list of GenAI responses to show in the fake data. */
const GENAI_RESP = ["application", "server", "network", "application", "application"];
/** the list of JSON data to use when generating the fake data. */
const JSON_DATA = [
    JSON.stringify({json: "1"}), JSON.stringify({json: "2"}), JSON.stringify({json: "3"}), JSON.stringify({json: "4"}), JSON.stringify({json: "5"})
];
/** the list of JSON data to use when generating the fake data. */
const RAW_JSON_DATA = [
    {key1: "value1"}, {key2: "value2"}, {key3: "value3"}, {key4: "value4"}, {key5: "value5"}
];
/** the list of path data to use when generating the fake data. */
const PATH = [
    "http://www.riverbed.com", "http://www.riverbed.com/support", "http://www.riverbed.com/it", "http://www.riverbed.com/marketing", "http://www.riverbed.com/help"
];
/** the list of fake debug data to add to the runbook dataset. */
const DEBUG_DATA = [
    [
        {name: "data ocean", type: DEBUG_OUTPUT_TYPE.JSON, value: JSON.stringify({key1: "value1", key2: "value2", key3: {objKey1: "objValue1", objKey2: "objValue2", objKey3: ["arrValue1", "arrValue2", "arrValue3"]}})},
        {name: "runbook orchestrator", type: DEBUG_OUTPUT_TYPE.JSON, value: JSON.stringify({key1: ["value1", "value2", "value3"]})}
    ],
    [
        {name: "data ocean", type: DEBUG_OUTPUT_TYPE.JSON, value: JSON.stringify({key1: "value1", key2: [{arr0Key1: "arr0Value1", arr0Key2: "arr0Value2"}, {arr1Key1: "arr1Value1", arr1Key2: "arr1Value2"}]})}
    ]
];

/** creates fake data for the impacted users.
 *  @returns a string array with the impacted users. */
export function createImpactedUsers(): Array<string> {
    const count = Math.floor(Math.random() * 10);
    const result: Array<string> = [];
    for (let index = 0; index < count; index++) {
        result[index] = CLIENTS[index % CLIENTS.length];
    }
    return result;
}
    
/** creates fake data for the impacted sites.
 *  @returns a string array with the impacted sites. */
export function createImpactedSites(): Array<string> {
    const count = Math.floor(Math.random() * 10);
    const result: Array<string> = [];
    for (let index = 0; index < count; index++) {
        result[index] = LOCATIONS[index % LOCATIONS.length];
    }
    return result;
}

/** creates fake data for the impacted apps.
 *  @returns a string array with the impacted apps. */
export function createImpactedApps(): Array<string> {
    const count = Math.floor(Math.random() * 10);
    const result: Array<string> = [];
    for (let index = 0; index < count; index++) {
        result[index] = APPS[index % APPS.length];
    }
    return result;
}

/** create fake entity data for the runbook.
 *  @param runbook the RunbookConfig object with the configuration of the runbook that is returned from the API.
 *  @returns a fake Entity object that is representative of the runbook configuration. */
export function createEntity(runbook: RunbookConfig): Entity {
    const entity: Entity = {id: getUuidV4()};
    switch (runbook.triggerType) {
        case InputType.DEVICE:
            entity.name = DEVICES[0];
            entity.kind = ENTITY_KIND.DEVICE;
            break;
        case InputType.INTERFACE:
            entity.name = INTERFACES[0];
            entity.kind = ENTITY_KIND.INTERFACE;
            entity.parent = {
                id: getUuidV4(),
                name: NAMES[0],
                ipAddress: DEVICES[0]
            };
            break;
        case InputType.APPLICATION_LOCATION:
            entity.name = APPS[0];
            entity.kind = ENTITY_KIND.APPLICATION_LOCATION;
            entity.attributes = [];
            entity.attributes.push({field: "location", value: "{\"id\":\"" + getUuidV4() + "\",\"name\":\"" + LOCATIONS[0] + "\"}"});
            entity.attributes.push({field: "application", value: "{\"id\":\"" + getUuidV4() + "\",\"name\":\"" + APPS[0] + "\"}"});
            break;
        case InputType.LOCATION:
            entity.name = LOCATIONS[0];
            entity.kind = ENTITY_KIND.LOCATION;
            entity.attributes = [];
            //entity.attributes.push({field: "location", value: "{\"id\":\"" + getUuidV4() + "\",\"name\":\"" + LOCATIONS[0] + "\"}"});
            //entity.attributes.push({field: "application", value: "{\"id\":\"" + getUuidV4() + "\",\"name\":\"" + APPS[0] + "\"}"});
            break;
        case InputType.APPLICATION:
            entity.name = APPS[0];
            entity.kind = ENTITY_KIND.APPLICATION;
            entity.attributes = [];
            //entity.attributes.push({field: "location", value: "{\"id\":\"" + getUuidV4() + "\",\"name\":\"" + LOCATIONS[0] + "\"}"});
            //entity.attributes.push({field: "application", value: "{\"id\":\"" + getUuidV4() + "\",\"name\":\"" + APPS[0] + "\"}"});
            break;
    }
    return entity;
}

/** create fake data for the incident details trigger row.
 *  @param runbook the RunbookConfig object with the configuration of the runbook that is returned from the API.
 *  @returns a map with the column accessor keys and their values. */
export function createTriggerRow(runbook: RunbookConfig): Record<string, any> {
    let entityName = "entity";
    let description = "description";
    switch (runbook.triggerType) {
        case InputType.DEVICE:
            entityName = DEVICES[0];
            description = STRINGS.fakeData.deviceRunbookName;
            break;
        case InputType.INTERFACE:
            entityName = INTERFACES[0];
            description = STRINGS.fakeData.interfaceRunbookName;
            break;
        case InputType.APPLICATION_LOCATION:
            entityName = APPS[0];
            description = STRINGS.fakeData.applicationLocationRunbookName;
            break;
        case InputType.LOCATION:
            entityName = LOCATIONS[0];
            description = STRINGS.fakeData.locationRunbookName;
            break;
        case InputType.APPLICATION:
            entityName = APPS[0];
            description = STRINGS.fakeData.locationRunbookName;
            break;
    }
    const tableRow = {entityName, description, generated: new Date(), indicatorsCount: Math.floor(Math.random() * 50)};
    return tableRow;
}

/** creates fake data for the preview mode.
 *  @param runbook the RunbookConfig object with the configuration of the runbook that is returned from the API.
 *  @param showTrueBranch when logic nodes are encountered if this is true, then choose the true branch when 
 *      deciding which chart to show, if false pick the chart connected to the false branch.
 *  @param decisionOutputIndex the index of the decision branch output to show.
 *  @param apiSpec the DataOceanMetadata object with the metadata that defines all the data ocean objects, metrics and keys.
 *  @param customProperties the array of CustomProperty objects with the custom properties for all the entity types.
 *  @param subflows the array of all the subflows.
 *  @returns a valid RunbookOutput object with fake data that conforms to the runbook configuration.*/
export function createData(
    runbook: RunbookConfig, showTrueBranch: boolean, decisionOutputIndex: number, apiSpec: DataOceanMetadata,
    customProperties: CustomProperty[], subflows?: RunbookNode[]
): RunbookOutput {
    const fakeRunbookOutput: RunbookOutput = {
        id: "0",
        targetId: "1",
        name: "Interface has high Utilization and is congested.  The VoIP application is degraded",
        entity: createEntity(runbook),
        severity: {value: SEVERITY.CRITICAL},
        template: JSON.stringify(runbook),
        priority: PRIORITY.CRITICAL,
        datasets: []
/*
        datasets: [],
        triggerInputs: {
            requestBody: [],
            requestPath: "www.riverbed.com",
            requestHeaders: [
*/                
//                {field: "ACCEPT", values: ["*/*"]}
/*
            ],
            requestQueryParameters: [
                {field: "my_param1", values: ["test-param"]}
            ]
        }
        indicators: [{
            entityId: getUuidV4(),
            metric: "throughput",
            sourceId: getUuidV4(),
            kind: INDICATOR_TYPE.ABNORMALLY_HIGH,
            isPrimary: true,
            entity: createEntity({triggerType: InputType.DEVICE})
        }],
        errors: [{
            code: "ORCHESTRATOR_VARIABLES_OVERRIDE_FAILED",
            message: "",
            innerError: {
                properties: [{key: "dataSourceType", value: "NetProfiler"}, {key: "dataSourceBaseUrl", value: "https://www.riverbed.com"}]
            }
        }]
*/
    };

    if (runbook) {
        if (runbook.nodes) {
            // Generate fake data for nodes that need data
            for (const node of runbook.nodes) {
                if (isTriggerNode(node)) {
                    createDatasetFromNodes(
                        node, runbook, showTrueBranch, decisionOutputIndex, apiSpec, customProperties, 
                        subflows || [], fakeRunbookOutput.datasets
                    );
                    break;
                }
            } 
            if (runbook.variant === Variant.INCIDENT && runbook.triggerType !== InputType.WEBHOOK) {
                fakeRunbookOutput.priorityReasons = [
                    {id: "priority-node-id", priority: PRIORITY.CRITICAL, text: "This runbook is critical!"}
                ];
            } else {
                fakeRunbookOutput.executionInsights = [
                    {id: "priority-node-id", text: "This is the first insight!", timestamp: "1728924163.725287500"},
                    {id: "priority-node-id", text: "This is the second insight!", timestamp: "1728924163.725287500"}
                ];        
            }
        }
    }
    return fakeRunbookOutput;
}

/** traverses all the nodes in the tree and outputs the datasets with fake data. 
 *  @param node the RunbookNode whose data is to be output.
 *  @param runbook the RunbookConfig object with the configuration of the runbook that is returned from the API.
 *  @param showTrueBranch when logic nodes are encountered if this is true, then choose the true branch when 
 *      deciding which chart to show, if false pick the chart connected to the false branch.
 *  @param decisionOutputIndex the index of the decision branch output to show.
 *  @param apiSpec the DataOceanMetadata object with the metadata that defines all the data ocean objects, metrics and keys.
 *  @param customProperties the array of CustomProperty objects with the custom properties for all the entity types.
 *  @param subflows the array of all the subflows. 
 *  @param datasets the array the holds all the created DataSets with the fake data. */
function createDatasetFromNodes(
    node: RunbookNode, runbook: RunbookConfig, showTrueBranch: boolean, decisionOutputIndex: number, apiSpec: DataOceanMetadata,
    customProperties: CustomProperty[], subflows: RunbookNode[], datasets: DataSet[]
): void {
    /*****************************************************************************************************************************/
    /* Need to protect against cyclic dependencies                                                                               */
    /*****************************************************************************************************************************/
    if (
        isDataOceanNode(node) || isAggregatorNode(node) || isTransformNode(node) || isTriggerNode(node) || isSubflowNode(node) ||
        isHttpNode(node) || isAiNode(node)
    ) {
        const newDatasets: DataSet[] = createDatasetsForNode(node, runbook, apiSpec, customProperties, subflows, undefined);
        for (const dataset of newDatasets) {
            datasets.push(dataset);
        }
    } else if (isVariablesNode(node) || isDecisionNode(node) || isLogicalNode(node)) {
        const dataNode: RunbookNode | undefined = getFirstParentNodeOfType(
            node, 
            [...dataOceanNodes, ...logicalNodes, ...decisionNodes, ...transformNodes,...triggerNodes, ...subflowNodes, ...aiNodes, ...httpNodes], 
            runbook.nodes || []
        );
        if (dataNode) {
            const newDatasets: DataSet[] = createDatasetsForNode(
                dataNode, runbook, apiSpec, customProperties, subflows, getSubflowIndex(node, dataNode, runbook)
            );
            for (const dataset of newDatasets) {
                const idTokens = dataset.id.split(":");
                if (isVariablesNode(node)) {
                    dataset.id = node.id + (idTokens.length > 1 ? ":" + idTokens[1] : "") + (idTokens.length > 2 ? ":" + idTokens[2] : "");
                } else if (isDecisionNode(node)) {
                    dataset.id = node.id + (appendWireIndexToDatasetId ? ":" + (decisionOutputIndex - 1) : "") + (idTokens.length > 2 ? ":" + idTokens[2] : "");
                } else if (isLogicalNode(node)) {
                    dataset.id = node.id + (appendWireIndexToDatasetId ? ":" + (showTrueBranch ? "0" : "1") : "") + (idTokens.length > 2 ? ":" + idTokens[2] : "");
                }        
                datasets.push(dataset);
            }    
        }
    } else if (isAiNode(node)) {
        // This is not needed anymore and this will never be called since there is an isAiNode in the first condition statement
        // AI nodes emit text, just emit some demo text
        datasets.push(
            { 
                id: node.id + ":0", type: DATA_TYPE.TEXT, timeReference: undefined, keys: [],
                metrics: [], datapoints: [], text: "This is the text", info: {}, debug: []
            }
        );
    }

    if (node.wires?.length) {
        for (let newWireIndex = 0; newWireIndex < node.wires.length; newWireIndex++) {
            if (isLogicalNode(node)) {
                const desiredWireIndex = showTrueBranch ? 0 : 1;
                if (newWireIndex !== desiredWireIndex) {
                    continue;
                }
            } else if (isDecisionNode(node)) {
                const desiredWireIndex = decisionOutputIndex - 1;
                if (newWireIndex !== desiredWireIndex) {
                    continue;
                }
            }
            for (const childNode of (runbook.nodes || [])) {
                if (node.wires[newWireIndex].includes(childNode.id)) {
                    createDatasetFromNodes(
                        childNode, runbook, showTrueBranch, decisionOutputIndex, apiSpec, customProperties, subflows, datasets
                    );
                }
            }
        }
    }
}

function getSubflowIndex(childNode: RunbookNode, subflowNode: RunbookNode, runbook: RunbookConfig): number | undefined {
    if (isSubflowNode(subflowNode)) {
        const parents: RunbookNode[] = getParents(childNode, runbook.nodes || []);
        if (parents) {
           // Go through all the parents and check for a data ocean node.
           for (let parentIndex = 0; parentIndex < parents.length; parentIndex++) {
                const parentNode = parents[parentIndex];
               if (isSubflowNode(parentNode)) {
                    if (parentNode.wires) {
                        for (let wireIndex = 0; wireIndex < parentNode.wires.length; wireIndex++) {
                            if (parentNode.wires[wireIndex].includes(childNode.id)) {
                                return wireIndex;
                            }
                        }
                    }
                    return undefined;
               }
           }
           // Next iterate through all the parents of the parents and look for a matching node.
           for (const parentNode of parents) {
               const parentIndex = getSubflowIndex(parentNode, subflowNode,  runbook);
               if (parentIndex !== undefined)  {
                   return parentIndex;
               }
           }
       }   
    }
    return undefined;
}

/** creates all the DataSets for the specified node.  Note that nodes can produce more than one DataSet, for example
 *      the data ocean node can produce a primate data set and a comparison data set.
 *  @param node the RunbookNode whose data is to be output.
 *  @param runbook the RunbookConfig object with the configuration of the runbook that is returned from the API.
 *  @param apiSpec the DataOceanMetadata object with the metadata that defines all the data ocean objects, metrics and keys.
 *  @param customProperties the array of CustomProperty objects with the custom properties for all the entity types.
 *  @param subflows the array of all the subflows. 
 *  @param subflowIndex .
 *  @returns the DataSets that were created. */
function createDatasetsForNode(
    node: RunbookNode, runbook: RunbookConfig, apiSpec: DataOceanMetadata, customProperties: CustomProperty[], 
    subflows: RunbookNode[], subflowIndex?: number
): DataSet[] {
    const datasets: DataSet[] = [];
    if (isDataOceanNode(node)) {
        // We found the data node
        let limit = node.properties.limit ? node.properties.limit : Number.MAX_SAFE_INTEGER;
        limit = Math.min(limit, getLimitFromFilters(node));
        const isTimeSeries = node.properties.timeSeries;
        const isMultiMetric = node.properties?.metrics?.length > 1;
        const isComparison = node.properties?.comparedTo;
        const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, getMaxLimitByType(node));
        let keyDefs: Array<GenericKey | Column> = NodeUtils.getExpandedKeys(apiSpec, node.properties.objType);
        let groupBys = node.properties?.groupBy || [];
        if (groupBys?.length > 0 && apiSpec.obj_types[node.properties.objType].group_by_required) {
            // If a group by is specified then we only want the keys in the group by, because it does not make sense
            // to show the other keys.  If the group by summed up N rows, then the string keys would just be the key
            // for one of the N rows.
            const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForKeyList(apiSpec, apiSpec.obj_types[node.properties.objType].keys || []);
            keyDefs = [];
            expandedKeys.forEach((keyDef) => {
                if (groupBys.includes(keyDef.id)) {
                    keyDefs.push(keyDef);
                }
            });
            //keyDefs = NodeUtils.getExpandedKeysForKeyList(apiSpec as DataOceanMetadata, groupBys);
        } else if (groupBys?.length > 0) {
            // If a group by is specified then we only want the keys in the group by, because it does not make sense
            // to show the other keys.  If the group by summed up N rows, then the string keys would just be the key
            // for one of the N rows.
            keyDefs = NodeUtils.getExpandedKeysForKeyList(apiSpec as DataOceanMetadata, groupBys);
        }
        const objKeys: string[] = apiSpec.obj_types[node.properties.objType]?.keys || [];
        for (const key of objKeys) {
            RunbookContext.addCustomPropertiesToExpandedKeys(keyDefs as GenericKey[], key, customProperties);
        }
        const metricDefs = getMetrics(node.properties.objType, node.properties.metrics, apiSpec);
        const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
        const datasetId = node.id + (appendWireIndexToDatasetId ? ":" + 0 : "");
        const dataset: DataSet = createDataset(
            datasetId, isTimeSeries, node.properties.duration, numDataPoints, getFilterInfo(node), keyDefs as Array<Column>, 
            metricDefs, debug
        );
        datasets.push(dataset);
        if (isComparison) {
            let comparisonOffset: number = 0;
            switch (node.properties.comparedTo) {
                case "yesterday":
                    comparisonOffset = 24 * 60 * 60;
                    break;
                case "last_week":
                    comparisonOffset = 7 * 24 * 60 * 60;
                    break;
                case "4_weeks_ago":
                    comparisonOffset = 4 * 7 * 24 * 60 * 60;
                    break;
            }
            const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
            const dataset: DataSet = createDataset(
                datasetId, isTimeSeries, node.properties.duration, numDataPoints, getFilterInfo(node), keyDefs as Array<Column>, 
                metricDefs, debug, comparisonOffset
            );
            datasets.push(dataset);
        }
    } else if (node && isAggregatorNode(node)){
        const synthMetrics = node.properties.synthMetrics;
        if (synthMetrics) {
            let limit = /*doNode && doNode.properties.limit ? doNode.properties.limit :*/ 2;
            const groupByAll = node.properties.groupBy && node.properties.groupBy.length > 0 && 
            node.properties.groupBy[0] ? false : true;
            // Group by needs to be added as one of the column
            const parentDoOrTransformNode: RunbookNode | null = getFirstParentOfType(node, runbook?.nodes || [], [...dataOceanNodes, ...transformNodes]);
            const synthKeys = parentDoOrTransformNode ? parentDoOrTransformNode.properties.synthKeys || [] : [];
            let keySet: Array<Column | GenericKey> = AggregateNodeUtil.getExpandedKeysForGroupBy(
                apiSpec as DataOceanMetadata, synthKeys, customProperties, node.properties.groupBy
            ) || [];
            let metricSet: Array<any> = synthMetrics.map((item)=>{
                if (item.included) {
                    let dataSetMetric= Object.assign({},item);
                    delete dataSetMetric.included;
                    delete dataSetMetric.originalField;
                    dataSetMetric.originalMetric = item.originalField.id;
                    dataSetMetric.synthetic= true;
                    if (item.originalField.isKey) {
                        delete dataSetMetric.unit;
                    }
                    return dataSetMetric;
                }
                return undefined;
            }).filter((item)=>item) || [];
            const numDataPoints = groupByAll ? 1 : limit ;
            const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
            const datasetId = node.id + (appendWireIndexToDatasetId ? ":" + 0 : "");
            const dataset: DataSet = createDataset(
                datasetId, false, 0, numDataPoints, "", keySet as Array<Column>, metricSet, debug
            );
            datasets.push(dataset);

            // Show comparisons if there is a parent DO node and it has comparisons turned on
            const comparedTo = parentDoOrTransformNode?.properties?.comparedTo;
            if (comparedTo) {
                let comparisonOffset: number = 0;
                switch (comparedTo) {
                    case "yesterday":
                        comparisonOffset = 24 * 60 * 60;
                        break;
                    case "last_week":
                        comparisonOffset = 7 * 24 * 60 * 60;
                        break;
                    case "4_weeks_ago":
                        comparisonOffset = 4 * 7 * 24 * 60 * 60;
                        break;
                }
                const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
                const dataset: DataSet = createDataset(
                    datasetId, false, node.properties.duration, numDataPoints, getFilterInfo(node), keySet as Array<Column>, 
                    metricSet, debug, comparisonOffset
                );
                datasets.push(dataset);
            }
        }
    } else if (node && isTransformNode(node)) {
        const synthKeys = node.properties.synthKeys || [];
        const synthMetrics = node.properties.synthMetrics;
        if (synthKeys || synthMetrics) {
            let limit = 10;
            let keySet: Array<Column | GenericKey> = TransformNodeUtils.getExpandedKeysForSynthKeys(
                apiSpec, customProperties, synthKeys
            );
            let metricSet: Array<any> = synthMetrics.map((item)=>{
                let dataSetMetric= Object.assign({},item);
                delete dataSetMetric.included;
                delete dataSetMetric.originalField;
                dataSetMetric.synthetic= true;
                return dataSetMetric;
            }).filter((item)=>item);
            const isTimeSeries = node.properties.outputDataFormat === "timeseries";
            const isMultiMetric = metricSet.length > 1;    
            const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, 10 /*getMaxLimitByType(doNode)*/);
            const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
            const datasetId = node.id + (appendWireIndexToDatasetId ? ":" + 0 : "");
            const dataset: DataSet = createDataset(
                datasetId, isTimeSeries, 900, numDataPoints, "", keySet as Array<Column>, metricSet, debug
            );
            datasets.push(dataset);
        }
    } else if (node && isHttpNode(node)) {
        const runbookContextMetadata: DataOceanMetadata = JSON.parse(JSON.stringify(RunbookContextKeys));
        let limit = 10;
        const isTimeSeries = false;
        const isMultiMetric = false;
        const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, 10);
        let keyDefs: Array<GenericKey> = [];
        let metricDefs: GenericKey[] = [];
        NodeUtils.expandKeyProperties(runbookContextMetadata.keys.http_response, "http_response", keyDefs);
        const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
        const datasetId = node.id + (appendWireIndexToDatasetId ? ":" + 0 : "");
        const dataset: DataSet = createDataset(
            datasetId, isTimeSeries, 900, numDataPoints, "", keyDefs as Array<Column>, 
            metricDefs as Column[], debug
        );
        datasets.push(dataset);
    } else if (node && isAiNode(node)) {
        const runbookContextMetadata: DataOceanMetadata = JSON.parse(JSON.stringify(RunbookContextKeys));
        let limit = 10;
        const isTimeSeries = false;
        const isMultiMetric = false;
        const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, 10);
        let keyDefs: Array<GenericKey> = [];
        let metricDefs: GenericKey[] = [];
        NodeUtils.expandKeyProperties(runbookContextMetadata.keys.gen_ai, "gen_ai", keyDefs);
        const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
        const datasetId = node.id + (appendWireIndexToDatasetId ? ":" + 0 : "");
        const dataset: DataSet = createDataset(
            datasetId, isTimeSeries, 900, numDataPoints, "", keyDefs as Array<Column>, 
            metricDefs as Column[], debug
        );
        datasets.push(dataset);
    } else if (node && isTriggerNode(node)) {
        //let rbContext = new RunbookContext(node, graphDef, objMetricMetaData);
        //const tContext: Context | undefined = rbContext.getTriggerContext();            
        let limit = /*doNode.properties.limit ? doNode.properties.limit : Number.MAX_SAFE_INTEGER*/ 10;
        //limit = Math.min(limit, getLimitFromFilters(doNode));
        const isTimeSeries = /*doNode.properties.timeSeries*/ false;
        const isMultiMetric = /*doNode.properties?.metrics?.length > 1*/ false;
        //const isComparison = /*doNode.properties?.comparedTo*/ false;
        const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, 10 /*getMaxLimitByType(doNode)*/);
        let keyDefs: Array<GenericKey | Column> = [];
        let metricDefs: GenericKey[] = [];

        if (isOnDemandInputNode(node)) {
            const synthKeys = node.properties.synthKeys || [];
            if (synthKeys?.length) {
                for (const synthKey of synthKeys) {
                    if (synthKey.dataOceanId) {
                        // We have a data ocean context so now we can get the runbook context from it.
                        const key: string = synthKey.dataOceanId;
                        const keyDef = apiSpec.keys[key];
                        NodeUtils.expandKeyProperties(keyDef, key, keyDefs as GenericKey[], true);
                        RunbookContext.addCustomPropertiesToExpandedKeys(keyDefs as GenericKey[], key, customProperties);
                    } else {
                        keyDefs.push({...synthKey, synthetic: true});
                    }
                }
            }
        } else {
            keyDefs = RunbookContext.getExpandedKeysForTriggerWithParent(node.properties.triggerType, customProperties);
            metricDefs = RunbookContext.sanitizeMetricsForOutput(
                RunbookContext.getMetricsForTrigger(node.properties.triggerType, apiSpec, getTypes()) as DataOceanMetric[],
                apiSpec
            );
        }

        const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
        const datasetId = node.id + (appendWireIndexToDatasetId ? ":" + 0 : "");
        const dataset: DataSet = createDataset(
            datasetId, isTimeSeries, 900/*doNode.properties.duration*/, numDataPoints, ""/*getFilterInfo(doNode)*/, keyDefs as Array<Column>, 
            metricDefs as Column[], debug
        );
        datasets.push(dataset);
    } else if (node && isSubflowNode(node)) {
        //let rbContext = new RunbookContext(node, graphDef, objMetricMetaData);
        //const tContext: Context | undefined = rbContext.getTriggerContext();            
        let limit = /*doNode.properties.limit ? doNode.properties.limit : Number.MAX_SAFE_INTEGER*/ 10;
        //limit = Math.min(limit, getLimitFromFilters(doNode));
        const isMultiMetric = /*doNode.properties?.metrics?.length > 1*/ false;
        //const isComparison = /*doNode.properties?.comparedTo*/ false;
        
        let keyDefs: Array<GenericKey | Column> = [];
        let metricDefs: GenericKey[] = [];

        if (subflows) {
            const subflowId = node.properties.configurationId;
            if (subflowId) {
                for (const subflow of subflows) {
                    if (subflow.id === subflowId) {
                        const outputDetails = subflow.out || [];
                        if (outputDetails?.length) {
                            for (let outputIndex = 0; outputIndex < outputDetails.length; outputIndex++) {
                                if (subflowIndex !== undefined && subflowIndex !== outputIndex) {
                                    continue;
                                }
                                const outputContext = outputDetails[outputIndex].wires[0].context;
                                let isTimeSeries = /*doNode.properties.timeSeries*/ false;
                                let numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, 10 /*getMaxLimitByType(doNode)*/);
                                if (outputContext) {
                                    keyDefs = outputContext.expandedKeys;
                                    metricDefs = outputContext.metrics;
                                    isTimeSeries = outputContext.isTimeseries === true;
                                    numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, 10 /*getMaxLimitByType(doNode)*/);
                                }
                                const datasetId = node.id + (appendWireIndexToDatasetId ? ":" + outputIndex : "");
                                const debug: Array<DebugOutput> = DEBUG_DATA[datasets.length % DEBUG_DATA.length];
                                const dataset: DataSet = createDataset(
                                    datasetId, isTimeSeries, 900/*doNode.properties.duration*/, numDataPoints, ""/*getFilterInfo(doNode)*/, keyDefs as Array<Column>, 
                                    metricDefs as Column[], debug
                                );
                                datasets.push(dataset);                            
                            }
                        }    
                        break;
                    }
                }
            }
        }        
    }
    return datasets;
}

/** outputs a dataset with fake data.
 *  @param datasetId a String with the dataset id.
 *  @param isTimeSeries a boolean value, true if we want time series data, false if we want summary data.
 *  @param duration the duration in seconds.
 *  @param numDataPoints the number of data points to show.
 *  @param filters the filters (only applicable for a DO node).
 *  @param keySet the array of key columns.
 *  @param metricSet the array of metric columns.
 *  @param debug the debug information to display.
 *  @param comparisonOffset the comparison offset which is the offset from the current time interval in seconds (
 *  only applicable for a DO node).
 *  @returns a DataSet object with the fake data that was generated in the format the visual widgets expect. */
export function createDataset(
    datasetId: string, isTimeSeries: boolean, duration: number = 3600, numDataPoints: number, filters: any, 
    keySet: Array<Column>, metricSet: Array<Column>,
    debug: Array<DebugOutput> | undefined, comparisonOffset: number = 0
): DataSet {
    const datapoints: Array<DataPoint> = [];
    const now = Math.floor(new Date().getTime() / (60 * 1000)) * 60;
    for (let dataPointIndex = 0; dataPointIndex < numDataPoints; dataPointIndex++) {
        datapoints.push({
            "keys": getKeysValues(keySet as Array<Column>, dataPointIndex),
            "data": isTimeSeries ? getTimeMetricsValues(metricSet, now - comparisonOffset, duration) : getMetricsValues(metricSet),
        });
    }
    const info: DataSetInfo = {
        actualTimes: [{startTime: (now - comparisonOffset - duration) + ".000000000", endTime: (now - comparisonOffset) + ".000000000", granularities: ["60"]}],
        dataSources: [{type: "NetProfiler", url: "http://www.riverbed.com"}],
        actualFilters: filters,
        /*
        error: {
            code: "ConnectionIssue",
            message: "",
            innerError: {
                properties: [{key: "dataSourceType", value: "NetProfiler"}, {key: "dataSourceBaseUrl", value: "https://www.riverbed.com"}]
            }
        }
        */
    };
    let refId = "", timeReference: any = undefined;
    if (comparisonOffset > 0) {
        refId = ":ref1";
        timeReference = {name: "ref1", startTime: String(now - comparisonOffset - duration), endTime: String(now - comparisonOffset)};
    }
    return {
        id: datasetId + refId,
        type: isTimeSeries ? DATA_TYPE.TIMESERIES : DATA_TYPE.SUMMARY,
        timeReference,
        keys: keySet,
        metrics: metricSet,
        datapoints,
        info,
        debug
    };
}

/** returns the data ocean node that is a parent of the specified node.
 *  @param node the node whose parents are to be checked for the data ocean node.
 *  @param nodes the array of nodes with all the nodes in the runbook.
 *  @returns the data ocean node or undefined if none is found. */
// function getDataOceanNodeFromParents(node: RunbookNode, nodes: Array<RunbookNode>): RunbookNode | undefined {
//     const parents: RunbookNode[] = getParents(node, nodes);
//     if (parents) {
//         // Go through all the parents and check for a data ocean node.
//         for (const parentNode of parents) {
//             if (isDataOceanNode(parentNode)) {
//                 return parentNode;
//             }
//         }
//         // Next go through all the parents of the parents and look for a data ocean node.  Do not move this in the 
//         // other loop because we want to find the nearest parent.
//         for (const parentNode of parents) {
//             const doNode = getDataOceanNodeFromParents(parentNode, nodes);
//             if (doNode)  {
//                 return doNode;
//             }
//         }
//     }
//     return undefined;
// }

/** Returns the first matched parent node of the type provided in the input. Does a Breadth first search.
 *  @param nodeType: Array of node types
 *  @param nodes the array of nodes with all the nodes in the runbook.
 *  @param excludeInputNode the input node will not be considered when set to true.
 *  @returns matched node or undefined if none is found. */
export function getFirstParentNodeOfType(node: RunbookNode,nodeTypes: Array<string>, nodes: Array<RunbookNode>, excludeInputNode: boolean = true): RunbookNode | undefined {
     if (nodeTypes.includes(node.type) && excludeInputNode === false  ) {
         return node;
     }
     const parents: RunbookNode[] = getParents(node, nodes);
     if (parents) {
        // Go through all the parents and check for a data ocean node.
        for (const parentNode of parents) {
            if (nodeTypes.includes(parentNode.type)) {
                return parentNode;
            }
        }
        // Next iterate through all the parents of the parents and look for a matching node.
        for (const parentNode of parents) {
            const matchedNode = getFirstParentNodeOfType(parentNode, nodeTypes,  nodes);
            if (matchedNode)  {
                return matchedNode;
            }
        }
    }
    return undefined;
}

/** returns the metric definitions for the specified object type and metric ids.
 *  @param objType the data ocean object type. 
 *  @param metricIds the array of metric id strings with the ids that the data ocean node is configured with.
 *  @param apiSpec the DataOceanMetadata object with the metadata that defines all the data ocean objects, metrics and keys.
 *  @returns an array with the metrics for the object type and metric ids or an empty array.*/
export function getMetrics(objType: string, metricIds: Array<string>, apiSpec: DataOceanMetadata): Array<Column> {
    if (apiSpec && apiSpec.obj_types && metricIds) {
        for (const apiObjTypeKey in apiSpec.obj_types) {
            if (apiObjTypeKey === objType) {
                const metrics: Array<Column> = [];
                for (const metricId of metricIds) {
                    if (apiSpec.obj_types[apiObjTypeKey].metrics.includes(metricId)) {
                        metrics.push({ 
                            "label": apiSpec.metrics[metricId].label, "id": metricId, 
                            "type": apiSpec.metrics[metricId].type as COLUMN_TYPE, "unit": apiSpec.metrics[metricId].unit,
                            "enum": apiSpec.metrics[metricId].enum, 
                            "order_by_weight": apiSpec.metrics[metricId].order_by_weight
                        });
                    }
                }
                return metrics;    
            }
        }
    }
    return [];
}

/** creates the values for the specified keys.
 *  @param keys the array of key Columns for this data ocean node.
 *  @param dataPointIndex the index in the data point array that these keys are being generated for.
 *  @returns the key values for the specified key configuration. */
export function getKeysValues(keys: Array<Column>, dataPointIndex: number): DataPointKeys {
    const values: DataPointKeys = {};
    for (const key of keys) {
        switch (key.id) {
            case "application.tags":
            case "network_client.tags":
            case "network_server.tags":
            case "network_host.tags":
            case "location.tags":
            case "client_location.tags":
            case "server_location.tags":
                values[key.id] = TAGS[dataPointIndex % TAGS.length];
                break;
            case "npm_plus.application.name":
            case "application":
            case "application.name":
            case "application_location.application.name":
            case "application_tcp":
            case "app_name":
                values[key.id] = APPS[dataPointIndex % APPS.length];
                break;
            case "application.type":
                values[key.id] = APP_TYPES[dataPointIndex % APP_TYPES.length];
                break;
            case "application.version":
                values[key.id] = APP_VERSIONS[dataPointIndex % APP_VERSIONS.length];
                break;
            case "network_interface":
            case "network_interface.name":
                values[key.id] = INTERFACES[dataPointIndex % INTERFACES.length];
                break;
            case "network_interface.ipaddr":
                values[key.id] = INTERFACE_IPS[dataPointIndex % INTERFACE_IPS.length];
                break;
            case "network_interface.ifindex":
                values[key.id] = INTERFACE_IFINDEXES[dataPointIndex % INTERFACE_IFINDEXES.length];
                break;
            case "network_interface.ifalias":
                values[key.id] = INTERFACE_IF_ALIASES[dataPointIndex % INTERFACE_IF_ALIASES.length];
                break;
            case "network_interface.ifdescr":
                values[key.id] = INTERFACE_IF_DESCRS[dataPointIndex % INTERFACE_IF_DESCRS.length];
                break;
            case "network_interface.ifipaddrs":
                values[key.id] = INTERFACE_IP_ARRAYS[dataPointIndex % INTERFACE_IP_ARRAYS.length];
                break;
            case "network_interface.type":
                values[key.id] = INTERFACE_TYPES[dataPointIndex % INTERFACE_TYPES.length];
                break;
            case "network_interface.inbound_speed":
                values[key.id] = INTERFACE_SPEEDS[dataPointIndex % INTERFACE_SPEEDS.length];
                break;
            case "network_interface.outbound_speed":
                values[key.id] = INTERFACE_SPEEDS[dataPointIndex % INTERFACE_SPEEDS.length];
                break;
            case "network_interface.location.name":
                values[key.id] = LOCATIONS[dataPointIndex % LOCATIONS.length];
                break;
            case "network_device":
            case "network_device.ipaddr":
                values[key.id] = DEVICES[dataPointIndex % DEVICES.length];
                break;
            case "network_device.name":
                values[key.id] = NAMES[dataPointIndex % NAMES.length];
                break;
            case "network_device.location.name":
                values[key.id] = LOCATIONS[dataPointIndex % LOCATIONS.length];
                break;
            case "network_device.serial_number":
                values[key.id] = SERIAL_NUMBERS[dataPointIndex % SERIAL_NUMBERS.length];
                break;
            case "network_device.os_version":
            case "os_version":
                values[key.id] = OS_VERSIONS[dataPointIndex % OS_VERSIONS.length];
                break;
            case "os_type":
                values[key.id] = OS_TYPES[dataPointIndex % OS_TYPES.length];
                break;
            case "network_device.model":
                values[key.id] = MODELS[dataPointIndex % MODELS.length];
                break;
            case "network_device.vendor":
                values[key.id] = VENDORS[dataPointIndex % VENDORS.length];
                break;
            case "network_device.type":
                values[key.id] = DEVICE_TYPES[dataPointIndex % DEVICE_TYPES.length];
                break;
            case "network_device.is_gateway":
                values[key.id] = DEVICE_GATEWAYS[dataPointIndex % DEVICE_GATEWAYS.length];
                break;
            case "network_host":
            case "network_host.ipaddr":
                values[key.id] = HOSTS[dataPointIndex % HOSTS.length];
                break;
            case "network_host.name":
                values[key.id] = NAMES[dataPointIndex % NAMES.length];
                break;
            case "network_host.location.name":
                values[key.id] = LOCATIONS[dataPointIndex % LOCATIONS.length];
                break;
            case "npm_plus.network_client.ipaddr":
            case "network_client":
            case "network_client.ipaddr":
            case "network_client_server.network_client.ipaddr":
            case "network_client_server_protoport.network_client.ipaddr":
            case "client_ip":
                values[key.id] = CLIENTS[dataPointIndex % CLIENTS.length];
                break;
            case "npm_plus.network_client.name":
            case "network_client.name":
            case "network_client_server.network_client.name":
            case "network_client_server_protoport.network_client.name":
                values[key.id] = CLIENT_NAMES[dataPointIndex % CLIENT_NAMES.length];
                break;
            case "network_client.location.name":
            case "network_client_server.network_client.location.name":
            case "network_client_server_protoport.network_client.location.name":
                values[key.id] = LOCATIONS[dataPointIndex % LOCATIONS.length];
                break;
            case "network_client.device_name":
            case "network_client_server.network_client.device_name":
            case "network_client_server_protoport.network_client.device_name":
                values[key.id] = CLIENT_DEVICE_NAMES[dataPointIndex % CLIENT_DEVICE_NAMES.length];
                break;
            case "network_client.username":
            case "network_client_server.network_client.username":
            case "network_client_server_protoport.network_client.username":
                values[key.id] = CLIENT_USERNAMES[dataPointIndex % CLIENT_USERNAMES.length];
                break;
            case "network_client.device_isp":
            case "network_client_server.network_client.device_isp":
            case "network_client_server_protoport.network_client.device_isp":
                values[key.id] = CLIENT_ISPS[dataPointIndex % CLIENT_ISPS.length];
                break;
            case "network_client.user_connection_location":
            case "network_client_server.network_client.user_connection_location":
            case "network_client_server_protoport.network_client.user_connection_location":
                values[key.id] = USER_CONN_LOCATIONS[dataPointIndex % USER_CONN_LOCATIONS.length];
                break;
            case "network_client.wifi_bssid":
            case "network_client_server.network_client.wifi_bssid":
            case "network_client_server_protoport.network_client.wifi_bssid":
                values[key.id] = WIFI_BSSIDS[dataPointIndex % WIFI_BSSIDS.length];
                break;
            case "network_client.wifi_channel":
            case "network_client_server.network_client.wifi_channel":
            case "network_client_server_protoport.network_client.wifi_channel":
                values[key.id] = WIFI_CHANNELS[dataPointIndex % WIFI_CHANNELS.length];
                break;
            case "network_client.wifi_ssid":
            case "network_client_server.network_client.wifi_ssid":
            case "network_client_server_protoport.network_client.wifi_ssid":
                values[key.id] = WIFI_SSIDS[dataPointIndex % WIFI_SSIDS.length];
                break;
            case "network_client.physical_location.name":
            case "network_client_server.network_client.physical_location.name":
            case "network_client_server_protoport.network_client.physical_location.name":
                values[key.id] = PHYSICAL_LOCATIONS[dataPointIndex % PHYSICAL_LOCATIONS.length];
                break;
            case "network_client.physical_location.geo.city":
            case "network_client_server.network_client.physical_location.geo.city":
            case "network_client_server_protoport.network_client.physical_location.geo.city":
                values[key.id] = GEO_CITIES[dataPointIndex % GEO_CITIES.length];
                break;
            case "network_client.physical_location.geo.state":
            case "network_client_server.network_client.physical_location.geo.state":
            case "network_client_server_protoport.network_client.physical_location.geo.state":
                values[key.id] = GEO_STATES[dataPointIndex % GEO_STATES.length];
                break;
            case "network_client.physical_location.geo.country":
            case "network_client_server.network_client.physical_location.geo.country":
            case "network_client_server_protoport.network_client.physical_location.geo.country":
                values[key.id] = GEO_COUNTRIES[dataPointIndex % GEO_COUNTRIES.length];
                break;
            case "npm_plus.network_server.ipaddr":
            case "network_server":
            case "network_server.ipaddr":
            case "network_client_server.network_server.ipaddr":
            case "network_client_server_protoport.network_server.ipaddr":
            case "server_ip":
                values[key.id] = SERVERS[dataPointIndex % SERVERS.length];
                break;
            case "npm_plus.network_server.name":
            case "network_server.name":
            case "network_client_server.network_server.name":
            case "network_client_server_protoport.network_server.name":
                values[key.id] = SERVER_NAMES[dataPointIndex % SERVER_NAMES.length];
                break;
            case "network_server.location.name":
            case "network_client_server.network_server.location.name":
            case "network_client_server_protoport.network_server.location.name":
                values[key.id] = LOCATIONS[dataPointIndex % LOCATIONS.length];
                break;
            case "location.name":
            case "application_location.location.name":
                values[key.id] = LOCATIONS[dataPointIndex % LOCATIONS.length];
                break;
            case "location.type":
            case "application_location.location.type":
                values[key.id] = LOCATION_TYPES[dataPointIndex % LOCATION_TYPES.length];
                break;
            case "server_location.name":
            case "client_server_location.server_location.name":
                values[key.id] = SERVER_LOCATIONS[dataPointIndex % SERVER_LOCATIONS.length];
                break;
            case "client_location.name":
            case "client_server_location.client_location.name":
                values[key.id] = CLIENT_LOCATIONS[dataPointIndex % CLIENT_LOCATIONS.length];
                break;
            case "npm_plus.protoport.protocol.number":
            case "npm_plus.protocol.number":
                case "protocol.number":
            case "protoport.protocol.number":
            case "network_client_server_protoport.protoport.protocol.number":
            case "ip_protocol_type":
                values[key.id] = PROTOCOLS[dataPointIndex % PROTOCOLS.length];
                break;
            case "protocol.name":
            case "protoport.protocol.name":
            case "network_client_server_protoport.protoport.protocol.name":
                values[key.id] = PROTOCOL_NAMES[dataPointIndex % PROTOCOL_NAMES.length];
                break;
            case "npm_plus.protoport.port.number":
            case "protoport.port.number":
            case "network_client_server_protoport.protoport.port.number":
                values[key.id] = PORTS[dataPointIndex % PORTS.length];
                break;
            case "dscp.number":
                values[key.id] = DSCPS[dataPointIndex % DSCPS.length];
                break;
            case "dscp.name":
                values[key.id] = DSCP_NAMES[dataPointIndex % DSCP_NAMES.length];
                break;
            case "resource":
            case "resource.name":
                values[key.id] = RESOURCES[dataPointIndex % RESOURCES.length];
                break;
            case "resource.type":
                values[key.id] = TYPES[dataPointIndex % TYPES.length];
                break;
            case "cpu_util_type":
            case "cpu_util_type.name":
                values[key.id] = CPU_TYPES[dataPointIndex % CPU_TYPES.length];
                break;
            case "memory_type":
            case "memory_type.name":
                values[key.id] = MEMORY_TYPES[dataPointIndex % MEMORY_TYPES.length];
                break;
            case "disk_path":
            case "disk_path.name":
                values[key.id] = DISK_TYPES[dataPointIndex % DISK_TYPES.length];
                break;
            case "user_application":
                values[key.id] = "unknown";
                break;
            case "business_location":
                values[key.id] = "unknown";
                break;
            case "device_type":
                values[key.id] = "unknown";
                break;
            case "device_name":
                values[key.id] = "unknown";
                break;
            case "client":
                values[key.id] = "unknown";
                break;
            case "location_country":
                values[key.id] = "unknown";
                break;
            case "location_city":
                values[key.id] = "unknown";
                break;
            case "user":
            case "user_name":
                values[key.id] = USERS[dataPointIndex % USERS.length];
                break;
            case "user_system_name":
                values[key.id] = USER_SYSTEM_NAMES[dataPointIndex % USER_SYSTEM_NAMES.length];
                break;
            case "process_name":
                values[key.id] = PROCESS_NAMES[dataPointIndex % PROCESS_NAMES.length];
                break;
            case "process_id":
                values[key.id] = PROCESS_IDS[dataPointIndex % PROCESS_IDS.length];
                break;
            case "machine_id":
                values[key.id] = MACHINE_IDS[dataPointIndex % MACHINE_IDS.length];
                break;
            case "interface_name":
                values[key.id] = INTERFACE_NAMES[dataPointIndex % INTERFACE_NAMES.length];
                break;
            case "client_port":
                values[key.id] = PORTS[dataPointIndex % PORTS.length];
                break;
            case "server_port":
                values[key.id] = PORTS[dataPointIndex % PORTS.length];
                break;
            case "data_source.name":
            case "location.data_source.name":
                values[key.id] = DATA_SOURCE_NAMES[dataPointIndex % DATA_SOURCE_NAMES.length];
                break;
            case "data_source.hostname":
            case "location.data_source.hostname":
                values[key.id] = DATA_SOURCE_HOST_NAMES[dataPointIndex % DATA_SOURCE_HOST_NAMES.length];
                break;
            case "data_source.id":
            case "location.data_source.id":
                values[key.id] = DATA_SOURCE_IDS[dataPointIndex % DATA_SOURCE_IDS.length];
                break;
            case "json_input.json":
            case "json":
                values[key.id] = JSON_DATA[dataPointIndex % JSON_DATA.length];
                break;
            case "requestQueryParameters":
            case "requestHeaders":
            case "requestBody":
                values[key.id] = RAW_JSON_DATA[dataPointIndex % RAW_JSON_DATA.length];
                break;
            case "requestPath":
                values[key.id] = PATH[dataPointIndex % PATH.length];
                break;
            case "http_response.status_code":
                values[key.id] = STATUS_CODES[dataPointIndex % STATUS_CODES.length];
                break;
            case "http_response.body":
                values[key.id] = BODIES[dataPointIndex % BODIES.length];
                break;
            case "http_response.headers":
                values[key.id] = HEADERS[dataPointIndex % HEADERS.length];
                break;
            case "gen_ai.response":
                values[key.id] = GENAI_RESP[dataPointIndex % GENAI_RESP.length];
                break;
            case "npm_plus.user_device.device_name":
            case "user_device":
            case "user_device.device_name":
                values[key.id] = CLIENT_DEVICE_NAMES[dataPointIndex % CLIENT_DEVICE_NAMES.length];
                break;
            case "user_device.location.name":
                values[key.id] = LOCATIONS[dataPointIndex % LOCATIONS.length];
                break;
            case "user_device.username":
                values[key.id] = CLIENT_USERNAMES[dataPointIndex % CLIENT_USERNAMES.length];
                break;
            case "user_device.device_isp":
                values[key.id] = CLIENT_ISPS[dataPointIndex % CLIENT_ISPS.length];
                break;
            case "user_device.user_connection_location":
                values[key.id] = USER_CONN_LOCATIONS[dataPointIndex % USER_CONN_LOCATIONS.length];
                break;
            case "user_device.wifi_bssid":
                values[key.id] = WIFI_BSSIDS[dataPointIndex % WIFI_BSSIDS.length];
                break;
            case "user_device.wifi_channel":
                values[key.id] = WIFI_CHANNELS[dataPointIndex % WIFI_CHANNELS.length];
                break;
            case "user_device.wifi_ssid":
                values[key.id] = WIFI_SSIDS[dataPointIndex % WIFI_SSIDS.length];
                break;
            case "user_device.physical_location.name":
                values[key.id] = PHYSICAL_LOCATIONS[dataPointIndex % PHYSICAL_LOCATIONS.length];
                break;
            case "user_device.physical_location.geo.city":
                values[key.id] = GEO_CITIES[dataPointIndex % GEO_CITIES.length];
                break;
            case "user_device.physical_location.geo.state":
                values[key.id] = GEO_STATES[dataPointIndex % GEO_STATES.length];
                break;
            case "user_device.physical_location.geo.country":
                values[key.id] = GEO_COUNTRIES[dataPointIndex % GEO_COUNTRIES.length];
                break;
            default:
                if ((key as any).synthetic) {
                    switch (key.type) {
                        case COLUMN_TYPE.JSON:
                            values[key.id] = RAW_JSON_DATA[dataPointIndex % RAW_JSON_DATA.length];
                            break;
                        case COLUMN_TYPE.INTEGER:
                            values[key.id] = (Math.round(100 * Math.random())).toString();
                            break;
                        case COLUMN_TYPE.FLOAT:
                            values[key.id] = (100 * Math.random()).toString();
                            break;
                        case COLUMN_TYPE.BOOLEAN:
                            values[key.id] = Math.random() > 0.5 ? "true" : "false";
                            break;
                        case COLUMN_TYPE.IPADDR:
                            values[key.id] = DEVICES[dataPointIndex % DEVICES.length];
                            break;
                        default:
                            values[key.id] = KEYS[dataPointIndex % KEYS.length];
                    }
                } else {
                    values[key.id] = "unknown";
                }
        }
        if (key.id.includes("custom.")) {
            values[key.id] = PROPERTIES[dataPointIndex % PROPERTIES.length];
        }
    }
    return values;
}

/** creates the summary values for the specified metrics.
 *  @param metrics the array of metrid Columns for this data ocean node.
 *  @returns the summary metric values for the specified metric configuration. */
export function getMetricsValues(metrics: Array<Column>): AverageData {
    const values: AverageData = {};
    for (const metric of metrics) {
        let maxValue = 2000;
        let minValue = 0;
        if (metric.enum) {
            let newMax = -1;
            let newMin = Number.MAX_SAFE_INTEGER;
            const keys = Object.keys(metric.enum);
            for (const key of keys) {
                const keyValue = parseInt(key);
                if (!Number.isNaN(keyValue)) {
                    newMax = Math.max(newMax, keyValue);
                    newMin = Math.min(newMin, keyValue)
                }
            }
            maxValue = newMax > 0 ? newMax : maxValue;
            minValue = newMin < Number.MAX_SAFE_INTEGER && newMin >= 0 ? newMin : 0;
        }
        switch (metric.type) {
            case COLUMN_TYPE.FLOAT:
                if (metric.unit && (metric.unit === "%" || metric.unit === "pct" || metric.unit === "percent")) {
                    maxValue = 100;
                }
                values[metric.id] = String(Math.random() * maxValue);
                break;
            case COLUMN_TYPE.INTEGER:
                let value = Math.round(Math.random() * maxValue);
                value = value >= minValue ?  value : minValue;
                values[metric.id] = String(value);
                break;
            default:
                values[metric.id] = "2540.3";
        }
    }
    return values;
}

/** creates the time series values for the specified metrics.
 *  @param metrics the array of metrid Columns for this data ocean node.
 *  @param endTime the end time for the time series data in seconds.
 *  @param duration the duration in seconds.
 *  @returns the time series metric values for the specified metric configuration. */
export function getTimeMetricsValues(metrics: Array<Column>, endTime: number, duration: number = 3600): TimeData {
    const values: TimeData = {};

    // Use the duration to figure out how many points to return
    const numMinutes = duration / 60;
    const start = endTime - (numMinutes * 60);

    for (let minute = 0; minute < numMinutes; minute++) {
        if (numMinutes >= 1440 && minute % 2 !== 0) {
            // Only show every other minute when we are showing a days worth of data.
            continue;
        }
        const timestamp = start + minute * 60;
        const metricValues: {[x: string]: string | number | null} = {};
        for (const metric of metrics) {
            let maxValue = 2000;
            if (metric.enum) {
                let newMax = -1;
                const keys = Object.keys(metric.enum);
                for (const key of keys) {
                    const keyValue = parseInt(key);
                    if (!Number.isNaN(keyValue)) {
                        newMax = Math.max(newMax, keyValue);
                    }
                }
                maxValue = newMax > 0 ? newMax : maxValue;
            }
            switch (metric.type) {
                case COLUMN_TYPE.FLOAT:
                    if (metric.unit && (metric.unit === "%" || metric.unit === "pct" || metric.unit === "percent")) {
                        maxValue = 100;
                    }
                    metricValues[metric.id] = String(Math.random() * maxValue);
                    break;
                case COLUMN_TYPE.INTEGER:
                    metricValues[metric.id] = String(Math.round(Math.random() * maxValue));
                    break;
                default:
                    metricValues[metric.id] = "2540.3";
            } 
        } 
        values[String(timestamp)] = metricValues;
    }
    return values;
}

/** returns any limits for each type.  If the type has no inherent limit 1000 is returned.
 *  @params doNode the data ocean node with the query parameters.
 *  @returns the limit for the type or 1000 if there is no limit for that type.*/
export function getMaxLimitByType(doNode: RunbookNode): number {
    const objType = doNode.properties.objType;
    switch (objType) {
        case "network_device.cpu":
            if (hasRunbookFilter("networkDevice", doNode)) {
                return CPU_TYPES.length;
            }
            break;
        case "network_device.memory":
            if (hasRunbookFilter("networkDevice", doNode)) {
                return MEMORY_TYPES.length;
            }
            break;
        case "network_device.server_disk_usage":
            if (hasRunbookFilter("networkDevice", doNode)) {
                return DISK_TYPES.length;
            }
            break;
    }
    const isTimeSeries = doNode?.properties?.timeSeries;
    return !isTimeSeries ? 1000 : 5;
}

/** returns the limits that are implied by the filter on the data ocean node.
 *  @params doNode the data ocean node with the query parameters.
 *  @returns the limit or Number.MAX_SAFE_INTEGER if the filters imply no limit.*/
export function getLimitFromFilters(doNode: RunbookNode): number {
    if (doNode.properties.filters) {
        const objType = doNode.properties.objType;
        switch (objType) {
            case "application.traffic":
                if (hasRunbookFilter("application", doNode)) {
                    return 1;
                }
                break;
            case "network_interface.traffic":
            case "network_interface.errors":
            case "network_interface.status":
                if (hasRunbookFilter("networkInterface", doNode)) {
                    return 1;
                }
                break;
            case "network_device.cpu":
                if (hasRunbookFilter("networkDevice", doNode)) {
                    return CPU_TYPES.length;
                }
                break;
            case "network_device.memory":
                if (hasRunbookFilter("networkDevice", doNode)) {
                    return MEMORY_TYPES.length;
                }
                break;
            case "network_device.server_disk_usage":
                if (hasRunbookFilter("networkDevice", doNode)) {
                    return DISK_TYPES.length;
                }
                break;
            case "network_device.traffic":
            case "network_device.availability":
            case "network_device.changes":
            case "network_device.status":
                if (hasRunbookFilter("networkDevice", doNode)) {
                    return 1;
                }
                break;
            case "location.traffic":
                if (hasRunbookFilter("location", doNode)) {
                    return 1;
                }
                break;
            case "client_location.traffic":
                if (hasRunbookFilter("clientLocation", doNode)) {
                    return 1;
                }
                break;
            case "server_location.traffic":
                if (hasRunbookFilter("serverLocation", doNode)) {
                    return 1;
                }
                break;
        }
    }
    return Number.MAX_SAFE_INTEGER;
}

/** returns true if the data ocean node has a filter which specifies the runbook as the source of the filter (trigger).
 *  @param key the key for the filter to check.
 *  @param doNode the data ocean node with the filters.
 *  @returns a boolean value, true if the do node was a filter from the trigger, false otherwise. */
function hasRunbookFilter(key: string, doNode: RunbookNode): boolean {
    return doNode.properties.filters[key]?.length > 0 && 
        (doNode.properties.filters[key][0] === TRIGGER_OPTION_VALUE);
}

/** returns the filter info string that would be representative of a filter info string sent by DAL.
 *  @param doNode the Data Ocean node with the filter.
 *  @returns a String with the stringified JSON filter. */
function getFilterInfo(doNode: RunbookNode | undefined): string {
    const dataOceanFiltersMap = getDataOceanFiltersMap(Variant.INCIDENT);
    let filters: any = {};
    if (doNode?.properties?.filters) {
        for (const filterKey in doNode.properties.filters) {
            let filterValue = doNode.properties.filters[filterKey];
            if (Array.isArray(filterValue)) {
                filterValue = filterValue.map((item) => {
                    if (hasTriggerOrConnectedNodeOrVariableOption(item)) {
                        switch (filterKey) {
                            case "networkDevice":
                                // The trigger also returned a location: {name: "Boston"}
                                return {ipaddr: DEVICES[0], name: NAMES[0], uuid: "f87b0f4b-0837-53e6-a1e0-8ccd64c71bdb"};
                            case "networkInterface":
                                return {ipaddr: INTERFACE_IPS[0], ifindex: INTERFACE_IFINDEXES[0], name: INTERFACES[0], uuid: "f87b0f4b-0837-53e6-a1e0-8ccd64c71bdb"};
                            case "application":
                                return {name: APPS[0]};
                            case "networkServer":
                                return {ipaddr: SERVERS[0], name: SERVER_NAMES[0], location: {name: LOCATIONS[0]}};
                            case "networkClient":
                                return {ipaddr: CLIENTS[0], name: CLIENT_NAMES[0], location: {name: LOCATIONS[0]}};
                            case "networkHost":
                                return {ipaddr: HOSTS[0], name: NAMES[0], location: {name: LOCATIONS[0]}};
                            case "location":
                                return {name: LOCATIONS[0]};
                            case "clientLocation":
                                return {name: CLIENT_LOCATIONS[0]};
                            case "serverLocation":
                                return {name: SERVER_LOCATIONS[0]};
                            case "protocol":
                                return {number: PROTOCOLS[0], name: PROTOCOL_NAMES[0]};
                            case "protoport":
                                return {protocol: {number: PROTOCOLS[0], name: PROTOCOL_NAMES[0]}, port: PORTS[0]};
                            case "dscp":
                                return {name: DSCP_NAMES[0], DSCPS: [0]};
                            case "expression":
                                return item;
                            case "networkClientServer":
                                return {ipaddr: CLIENTS[0], name: CLIENT_NAMES[0], location: {name: LOCATIONS[0]}};
                            case "networkClientServerProtoport":
                                return {ipaddr: CLIENTS[0], name: CLIENT_NAMES[0], location: {name: LOCATIONS[0]}};
                            case "clientServerLocation":
                                return {name: CLIENT_LOCATIONS[0]};
                        }
                        return item;
                    } else {
                        return item;
                    }
                });
            }
            let newFilterKey = filterKey;
            for (const key in dataOceanFiltersMap) {
                if (dataOceanFiltersMap[key].key === filterKey) {
                    newFilterKey = key;
                    break;
                }
            }
            delete filters[filterKey];
            filters[newFilterKey] = filterValue;
        }
    }
    return JSON.stringify(filters);
}
