/** This module contains the functional React component for rendering the data ocean editor.
 *  This control allows users to set all the data ocean node properties including the query type,
 *  time interval, metrics, etc.
 *  @module
 */
import React, { useEffect, useState } from 'react';
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { DataOceanFilters } from "./DataOceanFilters";
import { HELP, STRINGS } from "app-strings";
import { DataOceanMetric } from "./DataOceanMetric";
import { DataOceanUtils } from "./DataOceanUtils";
import { GraphDef, NodeDef } from "components/common/graph/types/GraphTypes";
import { getNodeFromGraphDef, triggerNodes } from "utils/runbooks/RunbookUtils";
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { DataOceanKey, DataOceanMetric as DataOceanMetricDef } from './DataOceanMetadata.type';
import { InlineHelp } from 'components/common/layout/inline-help/InlineHelp';
import { RunbookContextSummary } from '../RunbookContextSummary';
import { SHOW_CONTEXT } from 'components/enums/QueryParams';
import { DataOceanGroupBy } from "./DataOceanGroupBy";
import './DataOceanNodeEditor.scss';

/** Function to update the properties in selectedNode with values of corresponding property values in updatedProperties
 *      param.
 *  It will also delete the properties in selectedNode if they are missing in the updatedProperties
 *  @param updatedProperties - local key value pair object
 *  @param selectedNode - Object of type UniversalNode set when selecting the node from runbook
 *  @param libraryNode - metadata of the selected node category
 *  @returns undefined. */
const updateNode = (updatedProperties, selectedNode, libraryNode, graphDef): void => {
    libraryNode.properties.forEach((prop) => {

        if ( updatedProperties.hasOwnProperty(prop.name) ) {
            selectedNode.setProperty(prop.name, updatedProperties[prop.name]);
        } else {
            selectedNode.removeProperty(prop.name);
        }
    });

    const node = getNodeFromGraphDef(selectedNode.getId(), graphDef);
    if (node) {
        node.editedByUser = true;
    }
};

/** Renders the parent Data Ocean Node for a give node.
 *  @param nodeId a String with the node id.
 *  @param graphDef - List of all nodes and edges
 *  @returns React.ReactElement. */
const getParentNode = (nodeId: string | undefined, graphDef: GraphDef): NodeDef | null => {
    const parentNode = graphDef.nodes.filter(node => {
        const edges = graphDef.edges.filter(edge => {return edge.toNode === nodeId});
        return edges?.length > 0 && node.id === edges[0].fromNode
    });
    if (parentNode?.length > 0) {
        if (parentNode[0].type === "data_ocean") {
            return parentNode[0];
        } else if (triggerNodes.includes(parentNode[0].type)) {
            return null;
        }
        return getParentNode(parentNode[0].id, graphDef);
    }
    return null;
};

/** Renders the Data Ocean Editor UI.
 *  @param props - SimpleNodeEditor
 *  @param ref - React Forward Ref object of the current Component to be used in the parent component
 *  @returns The JSX with the data ocean editor React component. */
export const DataOceanNodeEditor = React.forwardRef((props: SimpleNodeEditorProps, ref): JSX.Element => {
    /* All the API calls should be invoked using executeSafely. This avoids any react state updates and any error
    * related to it when the response comes back but the component is already destroyed*/
    const [objectTypes] = useState(DataOceanUtils.dataOceanMetaData?.obj_types);
    const [keys] = useState(DataOceanUtils.dataOceanMetaData?.keys);
    const [metricsMap] = useState<Record<string, DataOceanMetricDef>>(DataOceanUtils.dataOceanMetaData?.metrics);
    const [groupByMap] = useState<Record<string, DataOceanKey>>(DataOceanUtils.dataOceanMetaData?.keys);
    const [parentNode, setParentNode] = useState<NodeDef | null>(null);
    const [loading, setLoading] = useState(true);
    const [metricsEnabled, setMetricsEnabled] = useState(false);
    const [topByMetric, setTopByMetric] = useState<string | undefined>();
    const [updateCount, setUpdateCount] = useState<number>(0);
    //const [showProperties, setShowProperties] = useState<boolean>(false);

    /*
    * This constant variable will have all the properties and the values from the selectedNode object.
    * When the fields are updated, their corresponding property values in this const are also updated
    * Eventually this object will be used to update the properties in the selectedNode when the user submits the changes
    */
    const [currentProperties, setCurrentProperties] = useState({});
    useEffect(() => {
        let existingProperties = {};
        props.selectedNode?.getProperties().forEach(prop => {
            existingProperties[prop.key] =  props.selectedNode?.getProperty(prop.key);
        });
        setCurrentProperties(JSON.parse(JSON.stringify(existingProperties)));
        setLoading(false);
    }, [props.selectedNode, setCurrentProperties]);

    /*
    * Set top by value in the state which is used in the metrics
    * component for default selection when metrics is toggled on
    */
    useEffect(() => {
        if (currentProperties["topBy"] && currentProperties["topBy"].length) {
            setTopByMetric(currentProperties["topBy"][0].id);
        }
    }, [currentProperties, setTopByMetric]);

    /* set an updateNode function on the reference of this component which will be invoked by the parent
    * component: NodeEditorPanel
    */
    // @ts-ignore
    ref.current = {
        updateNode: () => {
            updateNode(currentProperties, props.selectedNode, props.libraryNode, props.graphDef);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            DataOceanUtils.validateNodeProperties(
                props.nodeLibrary, props.selectedNode, currentProperties, errorMessages, 
                props.selectedNode?.getName() || STRINGS.runbookEditor.noLabelForErrorMessage
            );
            return errorMessages;
        }
    };

    useEffect(() => {
        setParentNode(getParentNode(props.selectedNode?.node?.id, props.graphDef));
    }, [props.graphDef, props.selectedNode])

    if(loading) {
        return <tr><td colSpan={2}><DataLoadFacade loading/></td></tr>;
    }
    const selectedObjectType = objectTypes && currentProperties['objType'] ? objectTypes[currentProperties['objType']] : undefined;
    return (
        <React.Fragment>
            {/*<LoadingOverlay visible={ loading }/>*/ }
            {/*This component takes care of rendering and logic around data ocean filters */}
            {((selectedObjectType?.group_by && selectedObjectType?.group_by?.length > 0) || selectedObjectType?.group_by_required) && <DataOceanGroupBy 
                currentProperties={currentProperties} selectedTypeGroupBy={selectedObjectType?.group_by_required ? selectedObjectType?.keys : selectedObjectType?.group_by}
                groupByMap={groupByMap} required={Boolean(props.libraryNode?.uiAttrs?.metricReqd)}
                onGroupByChanged={() => setUpdateCount(updateCount + 1)}
            />}
            <DataOceanFilters
                key={"dofilters-" + metricsEnabled}
                selectedObjectType={selectedObjectType}
                keys={ keys }
                selectedNode ={props.selectedNode}
                currentProperties={ currentProperties }
                activeRunbook={props.activeRunbook}
                parentNode={parentNode || undefined}
                libraryNode={props.libraryNode}
                graphDef={props.graphDef}
                setTopByMetric={setTopByMetric}
                onDurationChanged={() => setUpdateCount(updateCount + 1)}
                variant={props.variant}
            />
            {/*This component takes care of rendering and logic around data ocean metrics*/}
            <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>
                <InlineHelp 
                    helpMapping={HELP.RunbookNodeCategory.Dataquery[currentProperties['objType'].replace('.', '_')]?.metrics}>
                    {STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.metricsSection}
                </InlineHelp>
            </td></tr>
            <DataOceanMetric
                currentProperties={currentProperties} selectedTypeMetrics={selectedObjectType?.metrics}
                metricsMap={metricsMap} required={Boolean(props.libraryNode?.uiAttrs?.metricReqd)}
                onMetricsToggled={currentState => setMetricsEnabled(currentState)}
                supportedQueryTypes={selectedObjectType?.supported_query_types as Array<string>}
                topByMetric={topByMetric} onMetricsChanged={() => setUpdateCount(updateCount + 1)}
                onQueryTypeChanged={() => setUpdateCount(updateCount + 1)}
                onDurationChanged={() => setUpdateCount(updateCount + 1)}
            />
            <RunbookContextSummary
                currentProperties={JSON.parse(JSON.stringify(currentProperties))} showMetrics={SHOW_CONTEXT}
                node={props.graphDef.nodes.find(node => node.id === props.selectedNode?.getId())!} graphDef={props.graphDef}
                showOutputExample={true} showInputExample={SHOW_CONTEXT}
            />
            {/*This component takes care of rendering and logic around data ocean keys*/}
            {/*<tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>
                <div className="d-flex w-100 justify-content-between">
                    {STRINGS.runbookEditor.nodeLibrary.nodes.data_ocean.labels.keysSection}
                    <Icon className="" icon={showProperties ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
                        onClick={() => {
                            setShowProperties(!showProperties);
                        }}
                    />
                </div>
                    </td></tr>*/}
            {/*showProperties && <DataOceanKey
                currentProperties={currentProperties}
            />*/}
        </React.Fragment>
    );
});
