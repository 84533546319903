import React, { useEffect, useState } from 'react';
import { AuthService } from '../services/AuthService';
import { MessageService } from '../services/MessageService';
import { AuthError, ErrorProps } from '../error/AuthError';
import { STRINGS } from '../library_strings';
import { ENV_CONFIG } from '../constants/Constants';
import { SecuredContent } from './SecuredContent';
import { TenantMapProps } from '../tenants';

export type CloudAuthProps = {
  children: React.ReactElement;
  errorComponent?: React.FunctionComponent<{ error: ErrorProps }>;
  loadingComponent?: React.FunctionComponent<{
    visible?: boolean;
    loadingText?: string;
  }>;
  env: 'dev' | 'staging' | 'prod'; //use one of the 3 predefined configs based on environment
  authConfig?: {
    tenantServiceLocation: string;
    apiScope: string;
    staticTenants: TenantMapProps;
  }; //inject a specific config
  triggerDialog?: (data: any) => void; //handle show dialog in the main app
  isEmbedded?: boolean; //pass on if it is embedded
  forceTenant?: string; //force a specific tenant regardless of uri
};

export const TIRAuthentication = (props: CloudAuthProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const authConfig = props.authConfig
    ? props.authConfig
    : props.env
    ? ENV_CONFIG[props.env]
    : ENV_CONFIG['dev'];

  useEffect(() => {
    // @ts-ignore //figure out what does it want here
    AuthService.init(authConfig, props.forceTenant, props.isEmbedded).then(
      () => {
        //load the application
        setLoading(false);
      },
      (err: any) => {
        // requested tenant does not exist case
        setError(err);
        setLoading(false);
      }
    );

    if (props?.triggerDialog) {
      MessageService.setTriggerFunction(props.triggerDialog);
    }
  }, [authConfig, props.forceTenant]);

  if (!loading) {
    if (!error) {
      /**
       * we save the redirect uri here, because if we go through the login process,
       * AzureAD will send us to Tiramisu, this will let it know where we started the process
       * the Tiramisu mall can take care of redirecting to the correct place if needed
       */
      AuthService.authProvider.saveRedirectUrl();
      return (
        <SecuredContent {...props}>
          <>{props.children}</>
        </SecuredContent>
      );
    } else {
      return (
        <AuthError
          error={{
            // @ts-ignore not clear why null check flags this
            errorMessage: error.message,
          }}
          errorComponent={props.errorComponent}
        />
      );
    }
  } else {
    if (props.loadingComponent) {
      return props.loadingComponent({
        visible: true,
        loadingText: STRINGS.authenticating,
      });
    }
    return <div>{STRINGS.authenticating}</div>;
  }
};
