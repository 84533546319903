/** This module contains the component for displaying a navigator widget.  The navigator widget displays
 *      any visualization that is to be displayed in the navigator.
 *  @module
 */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Icon, IconNames, useStateSafePromise } from "@tir-ui/react-components";
import { Button } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { LAYOUT_TYPE } from "../../../../components/common/vis-framework/layout/Layout.type";
import { INTERACTION_TYPE } from "../../../../components/common/vis-framework/widget/Widget.type";
import { 
    Column, DATA_TYPE, DEBUG_OUTPUT_TYPE, DataPoint, DataPointKeys,
    DataSet, DataSetInfo, DebugOutput, GraphqlDataValue,
} from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import { Widget } from "components/common/vis-framework/widget/Widget.type";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil";
import { createDataset, getMetrics } from "utils/runbooks/RunbookFakeDataUtils";
import NavigatorWidgetConfigDialog from "./NavigatorWidgetConfigDialog";
import { IS_DASHBOARD, PARAM_NAME } from "components/enums/QueryParams";
import { useQueryParams/*, useNpmSearch*/ } from "utils/hooks";
import { AutoUpdateContext, DEFAULT_OBJ_TYPE, USE_DAL } from "pages/navigator/NavigatorPage";
import { InfoContent } from "pages/riverbed-advisor/views/runbook-view-container/InfoContent";
import { JsonViewer } from "pages/incident-details/views/primary-indicator/JsonViewer";
import { createWidget } from "components/common/vis-framework/widget/WidgetFactory";
import { FILTER_KEYS, FILTER_OPERATORS, FILTER_TYPE, FilterEntry, NavigatorWidgetConfig } from "pages/navigator/Navigator.type";
import { translateDatasetSchema } from "pages/riverbed-advisor/views/runbook-view/RunbookView";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import ReactFilterBox, {GridDataAutoCompleteHandler} from "react-filter-box";
import { DataOceanService } from "utils/services/DataOceanApiService";
import { isEqual } from 'lodash';
import datos from './data';
import "react-filter-box/lib/react-filter-box.css";
import "./NavigatorWidget.scss";

//extend this class to add custom operators
class CustomAutoComplete extends GridDataAutoCompleteHandler {
    // override this method to add new your operator
    needOperators(parsedCategory) {
        const result = super.needOperators(parsedCategory);
        return result.filter(x => !["==", "!=", "contains", "!contains"].includes(x)).concat(["equals", "startsWith", "endsWith"]);
    }
}
export enum filterOperator {
    equals      = "EQUAL",
    startsWith  = "STARTS_WITH",
    endsWith    = "ENDS_WITH",
}

// The height of the widget card
const CARD_WIDTH: string | undefined = "700px";
// The height of the widget card
const CARD_HEIGHT: string | undefined = IS_DASHBOARD ? "400px" : "500px";

/** used to toggle the `<ShowMore />` control */
const SHOW_MORE_CONTROLS = true;

/** this interface defines the properties passed into the NavigatorWidget React component. */
export interface NavigatorWidgetProps {
    /** the configuration of this current navigator widget. */
    config: NavigatorWidgetConfig;
    /** the list of all configured widgets, this is needed to setup interactions. */
    widgets: NavigatorWidgetConfig[];
    /** the current LAYOUT_TYPE. */
    layout: LAYOUT_TYPE;
    /** a boolean value, true if the navigator is being edited. */
    isEditing: boolean;
    /** notifies any listeners about a widget edit action. */
    notifyWidgetEditAction?: (type: WidgetEditAction, value?: any) => void;
    /** the handler for interaction events. */
    notifyInteraction?: (type: INTERACTION_TYPE, data: any[]) => void;
    /** notifies when the filter changes in the search control. */
    notifyFiltersetChange: (filterSet: any[]) => void;
}

/** this interface defines the functions available in the navigator widget ref. */
export interface NavigatorWidgetRef {
    /** toggles the edit widget functionality. */
    editWidget: (edit: boolean) => void;
    /** the notification for when new runbook input is received. */
    notifyNewRunbookInput: (entities: any[]) => void;
    /** returns the id of the widget. */
    getId: () => string;
}

/** this enum specifies all the supported widget edit action types.  The widget edit action type is passed
 *  to the handler for widget edit actions.*/
export enum WidgetEditAction {
    /** this enumerated type specifies that the user modified the widget's columns set. */
    COLUMNS_CHANGE = "columns_change",
    /** this enumerated type specifies that the user modified the widget's configuration. */
    CONFIG_CHANGE = "config_change",
    /** this enumerated type specifies that the user deleted the widget. */
    DELETE = "delete_widget",
}

/** Renders the navigator widget.
 *  @param props the properties passed in.
 *  @returns JSX with the navigator widget component.*/
const NavigatorWidget = React.forwardRef<NavigatorWidgetRef, NavigatorWidgetProps>((props: NavigatorWidgetProps, ref: any): JSX.Element => {
    let { params } = useQueryParams({ listenOnlyTo: [PARAM_NAME.debug] });
    const showDebugInformation = params[PARAM_NAME.debug] === "true";

    const initDialogState = { showDialog: false, title: STRINGS.navigator.debugDialogTitle, loading: false, dialogContent: <></>, dialogFooter: <></> };
    const [dialogState, setDialogState] = useState<any>(initDialogState);
    const [passedEntities, setPassedEntities] = useState<any[]>([]);
    const [tableLimit, setTableLimit] = useState(
        props?.config?.queryConfig?.properties?.limit ?? 10,
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Filter searchbar vars
    const [exp, setExp] = useState<any[]>([]);
    const [filterError, setFilterError] = useState<boolean>(false);
    const [data] = useState<any[]>(datos);

    let defaultGroupBy: string[] = [];
    if (DataOceanUtils.dataOceanMetaData.obj_types?.[DEFAULT_OBJ_TYPE]?.group_by_required) {
        const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForKeyList(
            DataOceanUtils.dataOceanMetaData, DataOceanUtils.dataOceanMetaData.obj_types[DEFAULT_OBJ_TYPE].keys || []
        );
        defaultGroupBy = expandedKeys.length > 0 ? [expandedKeys[0].id] : [];
    } else if (DataOceanUtils.dataOceanMetaData.obj_types?.[DEFAULT_OBJ_TYPE]?.group_by?.length) {
        defaultGroupBy = [DataOceanUtils.dataOceanMetaData.obj_types![DEFAULT_OBJ_TYPE].group_by![0]];
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [groupBys, setGroupBys] = useState<Array<string>>(defaultGroupBy);
    const [queryString, setQueryString] = useState<string>(getQueryString(props.config.queryConfig?.properties?.filters || []))

    const autoCompleteOptions = (DataOceanUtils.dataOceanMetaData?.obj_types?.[DEFAULT_OBJ_TYPE]?.group_by || []).map(gBy => {
        return {
            columnField: gBy,
            // columnText: DataOceanUtils.dataOceanMetaData.keys[gBy]?.label,
            type: 'selection'
        }
    })

    const [loading, setLoadingState] = useState<boolean>(false);
    const isRunningQuery = useRef<boolean>(false);
    useEffect(() => {
            ref({
                editWidget: (edit: boolean): void => {
                    //editCached.current = !editCached.current;
                    //setEdit(editCached.current);
                },
                notifyNewRunbookInput: (entities: any[]): void => {
                    setPassedEntities([...(entities || [])]);
                    if (isRunningQuery.current) {
                        isRunningQuery.current = false;
                    }
                    setLoadingState(!props.isEditing);
                },
                getId: (): string => {
                    return props.config.id;
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const autoUpdate = useContext(AutoUpdateContext);

    /** must run before you can crawl - call `npmSearch.run()` for data
     * retrieveal */
    //const npmSearch1 = useNpmSearch();
    const [npmSearch2, setNpmSearch] = useState<{loading: boolean, data: any}>({loading: false, data: undefined});
    const [executeSafely] = useStateSafePromise();
    const lastRunQuery = useRef<any>({});
    const npmSearch = USE_DAL ? /*npmSearch1*/ undefined : npmSearch2;

    // NOTE: this `useEffect()` controls the querying logic to retrieve data
    // from DAL via the `props.config.queryConfig.properties`
    useEffect(() => {
        if (!props.isEditing && !isRunningQuery.current) {
            try {
                if (props.config?.queryConfig?.properties) {
                    //setLoadingState(true);

                    const objType = props.config.queryConfig.properties.objType;

                    let groupBy = [...(props.config.queryConfig.properties?.groupBy || [])];

                    // Set the filter to be the filter in the query config
                    let filters: FilterEntry[] = [...(props.config.queryConfig.properties?.filters || [])];

                    // Add any filters passed in by interactivity
                    if (passedEntities?.length) {
                        for (const filterKey in passedEntities[0]) {
                            if (FILTER_KEYS.includes(filterKey)) {
                                filters.push({type: filterKey as FILTER_TYPE, values: [passedEntities[0][filterKey]]});
                            }
                        }
                    }

                    const duration = props.config.queryConfig.properties.duration;
                    const endTime: number = autoUpdate.time || new Date().getTime();
                    const startTime = endTime - (duration ? duration * 1000 : 4 * 60 * 60 * 1000);
                    let time: {startTime: string, endTime: string, granularity?: string} = {
                        startTime: (60 * Math.floor(startTime / 1000 / 60)).toString() + ".000000000", 
                        endTime: (60 * Math.floor(endTime / 1000 / 60)).toString() + ".000000000"
                    };
                    //time = {"startTime": "1684756800.000000000", "endTime": "1707487200.000000000"};
                    // let limit = tableLimit
                    // limit = typeof limit === "number" ? limit || 10 : parseInt(limit);
                    // NOTE: tableLimit defaults to 10, this ensures non-table
                    // widgets will work appropriately
                    let limit = tableLimit;

                    let top = !isNaN(limit) ? limit : 10;

                    if (props.config?.queryConfig?.properties?.timeSeries) {
                        time.granularity = "P1M";
                        if (!DataOceanUtils.dataOceanMetaData.obj_types[objType].group_by_required) {
                            groupBy = ["time", ...groupBy];
                        }
                        //top = top * 60;
                    }

                    const request = {
                        "dataset": objType,
                        "groupBy": groupBy,
                        "keys": groupBy?.length ? groupBy : DataOceanUtils.dataOceanMetaData.obj_types[objType].keys,
                        "metrics": props.config.queryConfig.properties.metrics || [],
                        "filter": {
                            "filters": filters,
                            "time": time
                        },
                        "order": getOrderByFromTopBy(props.config.queryConfig.properties.topBy as {id: string, direction: "desc" | "asc"}[]),
                        "skip": 0,
                        "top": top
                    };

                    if (USE_DAL) {
                        //npmSearch1.run({queryVariables: {input: request}});
                    } else {
                        (request as any).time_series = props.config?.queryConfig?.properties?.timeSeries === true;
                        if (!isEqual(lastRunQuery.current, request)) {
                            lastRunQuery.current = request;
                            const doPromise = executeSafely(DataOceanService.runDataOceanQuery(request));
                            doPromise.then(
                                (result: any) => {
                                    setNpmSearch({loading: false, data: result});
                                },
                                (error) => {
                                    setNpmSearch({loading: false, data: undefined});
                                }
                            );
                            if (!npmSearch2.loading) {
                                setNpmSearch({loading: true, data: undefined});
                            }    
                        }    
                    }

                    isRunningQuery.current = true;
                    setLoadingState(true);
                }
            } catch (error) {
                console.log("Could not create the navigator list");
            }
            setLoadingState(true);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [props.isEditing, props.config?.id, props.config.queryConfig, props.config.options, props.config.widgetType, loading, passedEntities, isRunningQuery.current, tableLimit]);

    // Whenever auto update runs, run the query
    const lastSequenceNumber = useRef(0);
    useEffect(
        () => {
            if (lastSequenceNumber.current !== autoUpdate.sequenceNumber) {
                lastSequenceNumber.current = autoUpdate.sequenceNumber;
                if (!loading) {
                    isRunningQuery.current = false;
                    setLoadingState(!props.isEditing);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoUpdate]
    );

    // Whenever isEditing changes run the query
    useEffect(
        () => {
            if (isRunningQuery.current) {
                isRunningQuery.current = false;
            }
            setLoadingState(!props.isEditing);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.isEditing]
    );

    const customAutoComplete = new CustomAutoComplete(data, autoCompleteOptions);

    const customRenderCompletionItem = (self, data) => {
        const className = ` hint-value cm-${data.type} ${['(', ')', 'OR'].includes(data.value) && "cm-hide"}`
        return  <div className={className}>
                    <span>{data.value}</span>
                </div>
    }

    let datasets: Array<DataSet> = [];
    const datasetId = "xxxx";
    let isComparison = false;

    if (props.isEditing) {
        let limit = props.config.queryConfig?.properties?.limit || 10;
        let metrics = props.config.queryConfig?.properties?.metrics || [];
        const objType = props.config.queryConfig?.properties?.objType ? props.config.queryConfig.properties.objType : DEFAULT_OBJ_TYPE;
//        limit = dataNode ? Math.min(limit, getLimitFromFilters(dataNode)) : limit;
        const isTimeSeries = props.config.queryConfig?.properties?.timeSeries === true;
        const isMultiMetric = metrics?.length > 1;
        isComparison = props.config.queryConfig?.properties?.comparedTo ? props.config.queryConfig.properties.comparedTo !== undefined : false;
        const numDataPoints = isTimeSeries && isMultiMetric ? 1 : Math.min(limit, /*(dataNode ? getMaxLimitByType(dataNode) : 10)*/ 10);
        let keyDefs: Array<GenericKey | Column> = NodeUtils.getExpandedKeys(DataOceanUtils.dataOceanMetaData, objType);
        let groupBys = props.config.queryConfig?.properties?.groupBy || [];
        if (groupBys?.length > 0 && DataOceanUtils.dataOceanMetaData.obj_types[objType].group_by_required) {
            const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForKeyList(
                DataOceanUtils.dataOceanMetaData, DataOceanUtils.dataOceanMetaData.obj_types[objType].keys || []
            );
            keyDefs = [];
            expandedKeys.forEach((keyDef) => {
                if (groupBys.includes(keyDef.id)) {
                    keyDefs.push(keyDef);
                }
            });
        } else if (groupBys?.length > 0) {
            // If a group by is specified then we only want the keys in the group by, because it does not make sense
            // to show the other keys.  If the group by summed up N rows, then the string keys would just be the key
            // for one of the N rows.
            keyDefs = NodeUtils.getExpandedKeysForKeyList(DataOceanUtils.dataOceanMetaData, groupBys);
        }
        const metricDefs = getMetrics(objType, metrics, DataOceanUtils.dataOceanMetaData);
        const duration = props.config.queryConfig?.properties?.duration || 3600;
        const debug: Array<DebugOutput> = []; //DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
        const dataset: DataSet = createDataset(
            datasetId, isTimeSeries, duration, numDataPoints, [], keyDefs as Array<Column>,
            metricDefs, debug
        );
        datasets.push(dataset);
        if (isComparison && props.config.queryConfig?.properties?.comparedTo) {
            let comparisonOffset: number = 0;
            switch (props.config.queryConfig.properties?.comparedTo) {
                case "yesterday":
                    comparisonOffset = 24 * 60 * 60;
                    break;
                case "last_week":
                    comparisonOffset = 7 * 24 * 60 * 60;
                    break;
                case "4_weeks_ago":
                    comparisonOffset = 4 * 7 * 24 * 60 * 60;
                    break;
            }
            //const debug: Array<DebugOutput> = DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
            const dataset: DataSet = createDataset(
                datasetId, isTimeSeries, duration, numDataPoints, [], keyDefs as Array<Column>,
                metricDefs, debug, comparisonOffset
            );
            dataset.isComparison = true;
            // We usually call process data set before pushing it on the list
            datasets.push(dataset);
        }
        // This is needed to turn on the interactions, maybe we should do this another way
        updateDatasetsWithEntities(datasets);
    }

    if (!props.isEditing && !npmSearch?.loading) {
        if (loading) {
            isRunningQuery.current = false;
            setLoadingState(false);
        }
        if (npmSearch?.data?.searchItems?.page?.length > 0) {
            //let limit = props.config.queryConfig?.properties?.limit || 10;
            let metrics = props.config.queryConfig?.properties?.metrics || [];
            const objType = props.config.queryConfig?.properties?.objType ? props.config.queryConfig.properties.objType : DEFAULT_OBJ_TYPE;
            const isTimeSeries = props.config.queryConfig?.properties?.timeSeries === true;
            isComparison = props.config.queryConfig?.properties?.comparedTo ? props.config.queryConfig.properties.comparedTo !== undefined : false;
            let keyDefs: Array<GenericKey | Column> = NodeUtils.getExpandedKeys(DataOceanUtils.dataOceanMetaData, objType);
            let groupBys = props.config.queryConfig?.properties?.groupBy || [];
            if (groupBys?.length > 0 && DataOceanUtils.dataOceanMetaData.obj_types[objType].group_by_required) {
                const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForKeyList(
                    DataOceanUtils.dataOceanMetaData, DataOceanUtils.dataOceanMetaData.obj_types[objType].keys || []
                );
                keyDefs = [];
                expandedKeys.forEach((keyDef) => {
                    if (groupBys.includes(keyDef.id)) {
                        keyDefs.push(keyDef);
                    }
                });
            } else if (groupBys?.length > 0) {
                // If a group by is specified then we only want the keys in the group by, because it does not make sense
                // to show the other keys.  If the group by summed up N rows, then the string keys would just be the key
                // for one of the N rows.
                keyDefs = NodeUtils.getExpandedKeysForKeyList(DataOceanUtils.dataOceanMetaData, groupBys);
            }
            const metricDefs = getMetrics(objType, metrics, DataOceanUtils.dataOceanMetaData);
            //const duration = props.config.queryConfig?.properties?.duration || 3600;
            const debug: Array<DebugOutput> = []; //DEBUG_DATA[fakeRunbookOutput.datasets.length % DEBUG_DATA.length];
            
            // Create the data set
            const info: DataSetInfo = {
                //actualTimes: [{startTime: (now - comparisonOffset - duration) + ".000000000", endTime: (now - comparisonOffset) + ".000000000", 
                //granularities: ["60"]}],
                //dataSources: [{type: "NetProfiler", url: "http://www.riverbed.com"}],
                //actualFilters: filters,
            };
            let refId = "", timeReference: any = undefined;
            //if (comparisonOffset > 0) {
            //    refId = ":ref1";
            //    timeReference = {name: "ref1", startTime: String(now - comparisonOffset - duration), endTime: String(now - comparisonOffset)};
            //}

            const datapoints: Array<DataPoint> = [];
            for (const dataPoint of npmSearch?.data?.searchItems?.page) {
                datapoints.push(dataPoint);
            }

            const dataset: DataSet = {
                id: datasetId + refId,
                type: isTimeSeries ? DATA_TYPE.TIMESERIES : DATA_TYPE.SUMMARY,
                timeReference,
                keys: keyDefs as Array<Column>,
                metrics: metricDefs,
                datapoints,
                info,
                debug
            };
            datasets.push(dataset);
            // This causes an error saying the hooks are conditional.  I don't see it
            //if (USE_DAL) {
            //    datasets = translateDatasetSchema(datasets);
            //}
            datasets = translateSchemaForDal(datasets);
            updateDatasetsWithEntities(datasets);
        }
    }

    const widget = { id: props.config.id, name: props.config.name, options: props.config.options } as Widget;
    const setTableLimitHandler = (item: number) => {
        setTableLimit(item);
    };
/*
    if (isComparison && widget.options && dataNode) {
        widget.options.comparedTo = dataNode.properties.comparedTo;
    }
*/
    let widgetComponent: JSX.Element = createWidget(props.config.widgetType, widget, datasets, loading, props.notifyInteraction, setTableLimitHandler, tableLimit, SHOW_MORE_CONTROLS);
    return <div
        id={props.config.id}
        style={props.layout !== LAYOUT_TYPE.CUSTOM ? {
            width: (props.layout !== LAYOUT_TYPE.VERTICAL ? CARD_WIDTH : "98.6%"), height: CARD_HEIGHT,
            borderRadius: "14px"
        } : {}} className={props.layout !== LAYOUT_TYPE.CUSTOM ? "my-3 bg-light overflow-auto widget-item" : 'widget-item'}>

        <BasicDialog dialogState={dialogState} className="navigator-page-dialog" onClose={() => setDialogState(updateDialogState(dialogState, false, false, []))} />
        <div className={`${props.layout === LAYOUT_TYPE.CUSTOM ? "widget-headings" : ''} bg-light d-flex flex-row justify-content-between p-2`} style={{width: "100%"}} >
            <span>{props.config.name}</span>
            <div>
                {!IS_DASHBOARD && <Icon icon={IconNames.LIST_COLUMNS} style={{ cursor: "pointer" }} className="mr-2" onClick={(event) => {
                    if (props.notifyWidgetEditAction) {
                        props.notifyWidgetEditAction(WidgetEditAction.COLUMNS_CHANGE);
                    }
                }} />}
                {IS_DASHBOARD && props.isEditing && <Icon icon={IconNames.COG} style={{ cursor: "pointer" }} onClick={(event) => {
                    //showRunbookEditorDialog(runbookConfig, dialogState, setDialogState);
                    setIsOpen(true);
                }} />}
                {!props.isEditing && <>
                    <Icon icon={IconNames.INFO_SIGN} style={{ cursor: "pointer" }} onClick={(event) => {
                        showInfoDialog(datasets || [], dialogState, setDialogState, showDebugInformation);
                    }} />
                    <Icon icon={IconNames.DIAGNOSIS} style={{ cursor: "pointer" }} className="ml-2" onClick={(event) => {
                        showDebugDialog(datasets || [], dialogState, setDialogState);
                    }} />
                </>}
                {(!IS_DASHBOARD || props.isEditing) && <Icon icon={IconNames.CROSS} style={{ cursor: "pointer" }} className="ml-2" onClick={(event) => {
                    if (props.notifyWidgetEditAction) {
                        props.notifyWidgetEditAction(WidgetEditAction.DELETE);
                    }
                }} />}
            </div>
        </div>
        {!IS_DASHBOARD && <>
            <div className="w-5 pl-2 pr-0" style={{display: "inline-table"}}>
                <ReactFilterBox
                    autoCompleteHandler={customAutoComplete}
                    customRenderCompletionItem={customRenderCompletionItem}
                    options={autoCompleteOptions}
                    data={data}
                    query={queryString}
                    onParseOk={(expressions) => setExp(expressions)}
                    onChange={(query, result) => {
                        setFilterError(result.isError);
                        setQueryString(query);
                    }}
                />
            </div>
            <Button style={{marginBottom: "5px"}} 
                icon={IconNames.SMALL_CROSS}
                minimal
                disabled={filterError} 
                onClick={() => {
                    setQueryString("")
                    props.notifyFiltersetChange([]);
            }} />
            <Button style={{marginBottom: "5px"}} 
                icon={IconNames.SEARCH}
                intent={"primary"} 
                disabled={filterError} 
                onClick={() => {
                    props.notifyFiltersetChange(exp);
            }} />
        </>}
        {widgetComponent}
        <NavigatorWidgetConfigDialog config={props.config} widgets={props.widgets} isOpen={isOpen}
            handleConfigChange={(config: NavigatorWidgetConfig) => {
                if (props.notifyWidgetEditAction) {
                    props.notifyWidgetEditAction(WidgetEditAction.CONFIG_CHANGE, config);
                }
            }}
            handleDialogClose={() => {
                setIsOpen(false);
            }}
        />
    </div>;
});

export default NavigatorWidget;

/** This function was created to fix an error that said the react hooks were called in the wrong order. 
 *  @param datasets the array of DataSets that have the data returned by DAL.
    @returns the DataSets translated into the DO schema. */
function translateSchemaForDal(datasets: DataSet[]): DataSet[] {
    if (USE_DAL) {
        datasets = translateDatasetSchema(datasets);
    }
    return datasets;
}

/** Updates the dataset rows with the entity that represents the row.
 *  @param {array} datasets the array of DataSets to add entities to. */
export function updateDatasetsWithEntities(datasets: DataSet[]): void {
    datasets.forEach((dataset) => {
        const keyDefs = dataset.keys;
        dataset.datapoints?.forEach((datapoint) => {
            // Assuming `datapoint.keys` can be directly accessed and modified.
            if (isDataPointKeys(datapoint.keys)) {
                const keys = datapoint.keys;
                const group = {};

                if (USE_DAL) {
                    // Populate the group object with the keys that are present in `FILTER_KEYS`
                    Object.entries(keys).forEach(([key, value]) => {
                        if (FILTER_KEYS.includes(key)) {
                            group[key] = value;
                        }
                    });    
                } else {
                    let keyHierarchy = convertKeysAndDataToOject(keyDefs as any as Column[], keys as Record<string, string>);
                    if (keyHierarchy?.npm_plus) {
                        // Need to remove the npm_plus in front of the keys.  We might need to revisit this later.
                        keyHierarchy = keyHierarchy.npm_plus;
                    }
                    for (const key in keyHierarchy) {
                        if (["network_host", "network_server", "network_client", "network_device"].includes(key)) {
                            group[key] = keyHierarchy[key].ipaddr;
                        } else if (["network_interface"].includes(key)) {
                            // As an object
                            //group[key] = {ipaddr: keyHierarchy[key].ipaddr, ifindex: keyHierarchy[key].ifindex};
                            // as a string ipaddress:ifindex
                            group[key] = keyHierarchy[key].ipaddr + ":" + keyHierarchy[key].ifindex;
                        } else if (["protocol", "dscp"].includes(key)) {
                            group[key] = keyHierarchy[key].number;
                            //group[key] = keyHierarchy[key].name;
                        } else {
                            group[key] = keyHierarchy[key].name;
                        }
                    }
                }

                keys.group = group;
            }
        });
    });
}

/** convert the keys and data to an object.  Here is an example of the format of the object for a host
 *  {network_host: {
 *      ipaddr: {type: "ipaddr", value: "1.1.1.1"},
 *      name: {type: "string", value: "www.riverbed.com"},
 *      location: {
 *          name: {type: "string", value: "Boston"}
 *      }
 *  }}
 *  @param keyDefs the definition of the keys.
 *  @param dataKeys the values for the keys in the data.
 *  @returns an object with the values. */
export function convertKeysAndDataToOject(keyDefs: Column[], dataKeys: Record<string, string>): any {
    const outputObject = {};
    for (const column of keyDefs) {
        const fieldNames: Array<string> = column.id.split(".");
        let objLocation = outputObject;
        for (let index = 0; index < fieldNames.length - 1; index++) {
            if (!objLocation[fieldNames[index]]) {
                objLocation[fieldNames[index]] = {};
            }
            objLocation = objLocation[fieldNames[index]];
        }
        objLocation[fieldNames[fieldNames.length -1]] = dataKeys[column.id];
    }
    return outputObject;
}


/** converts a filter into a query string.
 *  @param filters the array of FilterEntrys.
 *  @return a String with the query information. */
function getQueryString(filters: FilterEntry[]): string {
    let queryString: string = "";
    for (const filter of filters) {
        let operator: string = "equals";
        switch (filter.operator) {
            case FILTER_OPERATORS.EQUAL:
                operator = "equals";
                break;
            case FILTER_OPERATORS.STARTS_WITH:
                operator = "startsWith";
                break;
            case FILTER_OPERATORS.ENDS_WITH:
                operator = "endsWith";
                break;
        }
        let value = filter.values?.length ? filter.values[0] : "Unknown";
        if (value.includes(" ")) {
            value = "\"" + value + "\"";
        }
        queryString += (queryString.length !== 0 ? "AND" : "") + filter.type + " " + operator + " " + value;
    }
    return queryString;
}

/** predicate type guard to determine what `keys` are, used in
 * `updateDatasetsWithEntities()` */
const isDataPointKeys = (
    keys: DataPointKeys | GraphqlDataValue[],
): keys is DataPointKeys => {
    return Array.isArray(keys) === false;
};

/** Creates a popup that displays the widget query information.  Right now the popup assumes there is only one 
 *      time range per widget and one data source per widget.  This can easily be expanded to the case where
 *      there is more than one time range or data source.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the information from the Data Ocean.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function.
 *  @param debug a boolean value, if true show debug information, if false, do not. */
function showInfoDialog(
    datasets: Array<DataSet>, dialogState: any, setDialogState: (dialogState: any) => void, debug: boolean
): void {
    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.runbookOutput.infoDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <InfoContent datasets={datasets} />;
    newDialogState.dialogFooter = <>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** Creates a popup that displays the widget debug information.  The debug information is passed in the 
 *  dataset.
 *  @param datasets the Array of DataSets to use to obtain the DataSetInfo object with the debug information.
 *  @param dialogState the copied state object with the state setup to open the dialog.  The content
 *      needs to be appended and the title needs to be set in this function.
 *  @param setDialogState the set function from useState.  It should be called before exiting this function. */
function showDebugDialog(
    datasets: Array<DataSet>, dialogState: any, setDialogState: (dialogState: any) => void
): void {
    let jsonOutput = {};
    if (datasets) {
        let dsIndex = 1;
        for (const dataset of datasets) {
            if (dataset) {
                const dsName = "Dataset: " + dsIndex++;
                if (dataset.debug?.length) {
                    jsonOutput[dsName] = {};
                    for (const debugOutput of dataset.debug) {
                        if (debugOutput.type === DEBUG_OUTPUT_TYPE.JSON) {
                            jsonOutput[dsName][debugOutput.name] = JSON.parse(debugOutput.value);
                        }
                    }
                }
            }
        }
    }

    const newDialogState = Object.assign({}, dialogState);
    newDialogState.title = STRINGS.runbookOutput.debugDialogTitle;
    newDialogState.showDialog = true;
    newDialogState.dialogContent = <JsonViewer json={jsonOutput} />;
    newDialogState.dialogFooter = <>
        <CopyToClipboard text={JSON.stringify(jsonOutput || {}, null, 4)}>
            <Button active={true} outlined={true}
                text={STRINGS.primaryIndicatorView.copyBtnText} onClick={() => {
                    setDialogState(updateDialogState(newDialogState, false, false, []));
                }}
            />
        </CopyToClipboard>
        <Button active={true} outlined={true}
            text={STRINGS.runbookOutput.okBtnText}
            onClick={async (evt) => {
                setDialogState(updateDialogState(newDialogState, false, false, []));
            }}
        />
    </>;
    setDialogState(newDialogState);
}

/** converts the widget's topBy configuration to the orderBy object required by DAL.
 *  @param topBy an object with the top by information for the query as stored in the widget's configuration.
 *  @returns the graphql order by expression. */
function getOrderByFromTopBy(topBy: {id: string, direction: "desc" | "asc"}[]): {key: string, order: "ASCENDING" | "DESCENDING"}[] {
    const orderBy: {key: string, order: "ASCENDING" | "DESCENDING"}[] = [];
    if (topBy?.length) {
        for (const topByItem of topBy) {
            orderBy.push({key: topByItem.id, order: topByItem.direction === "desc" ? "DESCENDING" : "ASCENDING"});
        }
    }
    return orderBy;
}
