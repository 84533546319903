/** This file defines the BubbleChart React component.  The BubbleChart React component renders a
 *  a basic bubble chart with n-groups and one metric.  The bubble chart component can also render
 *  a donut chart.
 *  @module */
import React, { useCallback, useRef, useState } from "react";
import { Dialog } from "@blueprintjs/core";
import { Classes } from "@tir-ui/react-components";
import { CHART_COLORS } from "components/enums";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import OfflineExporting from "highcharts/modules/offline-exporting";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import fullscreen from "highcharts/modules/full-screen";
import { merge } from "lodash";
import { CHART_SERIES_COLORS } from "components/enums";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import {
    precise,
    scaleMetric,
} from "reporting-infrastructure/utils/formatters";
import { THEME, ThemeContext } from "utils/themes";
import {
    BaseChartProps,
    GroupMetricEvent,
    GroupMetricSource,
} from "../chart-base/ChartBase";
import { STRINGS } from "app-strings";
import {
    ChartType,
    BubbleChartSettings,
    showSettingsDialog,
    ToolbarAction,
} from "../chart-base/ChartToolbar";
import {
    BasicDialog,
    updateDialogState,
} from "components/common/basic-dialog/BasicDialog";
import { BASE_CHART_OPTIONS } from "components/reporting/charts/defaults/HighchartDefaults";
import "components/common/chart-base/ChartBase.css";

// This is needed to enable the highcharts no data functionality
NoDataToDisplay(Highcharts);
fullscreen(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

/** an interface that describes the properties that can be passed in to the bubble chart component.*/
export interface BubbleChartProps extends BaseChartProps {
    /** an array of BarData with the data for each bar. */
    bubbleData: Array<BubbleData>;
    /** A string with the label for the size metric. */
    sizeMetric: string;
    /** a Unit object with the unit to use for the size metric. */
    sizeUnit: Unit;
    /** A string with the label for the color metric. */
    colorMetric?: string;
    /** a Unit object with the unit to use for the color metric. */
    colorUnit?: Unit;
    /** the BubbleChartSetings object with the basic settings for the chart such as the style and legend position. */
    settings?: BubbleChartSettings;
    /** the suffix to use in the legend when displaying comparison data. */
    comparisonSuffix?: string;
}

/** an interface that describes the bubble data format. */
export interface BubbleData {
    /** the label for the bar. */
    label: string;
    /** the value for the size metric. */
    sizeValue: number;
    /** the value for the color metric. */
    colorValue?: number;
    /** the optional comparison value. */
    compSizeValue?: number;
    /** the optional comparison value. */
    compColorValue?: number;
    /** the data that is passed when there is a selection. */
    group?: any;
}

/** Creates the the bubble chart view.
 *  @param props an object with the properties passed to the bubble chart view.
 *  @returns JSX with the bubble chart component.*/
export const BubbleChart = (props: BubbleChartProps): JSX.Element => {
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
    const handleClose = useCallback(() => setIsOpen(false), []);

    const [settings, setSettings] = useState<BubbleChartSettings>(
        props.settings || {},
    );
    const [dialogState, setDialogState] = useState<any>({
        showDialog: false,
        loading: false,
        title: "",
        dialogContent: null,
        dialogFooter: null,
    });
    const handleSettingsOpen = useCallback(() => {
        showSettingsDialog(
            settings,
            ChartType.bubble,
            setDialogState,
            (action: ToolbarAction, value: any) => {
                if (action === ToolbarAction.SETTINGS_CHANGED) {
                    setSettings(value);
                }
            },
        );
    }, [settings]);

    const metricIds: string[] = [];
    if (props.sizeMetric) {
        metricIds.push(props.sizeMetric);
    }
    if (props.colorMetric) {
        metricIds.push(props.colorMetric);
    }

    let seriesData: Array<any> = [];
    if (props.bubbleData) {
        let series = {
            type: "packedbubble",
            data: [] as any,
            borderColor: "transparent",
        };
        seriesData.push(series);

        let minColorValue: number | undefined = undefined;
        let maxColorValue: number | undefined = undefined;
        for (let index = 0; index < props.bubbleData.length; index++) {
            const cv = props.bubbleData[index].colorValue;
            if (cv !== undefined) {
                if (minColorValue === undefined) {
                    minColorValue = cv;
                }
                if (maxColorValue === undefined) {
                    maxColorValue = cv;
                }
                minColorValue = Math.min(minColorValue, cv);
                maxColorValue = Math.max(maxColorValue, cv);
            }
        }

        for (let index = 0; index < props.bubbleData.length; index++) {
            let chartColor = CHART_SERIES_COLORS[index]
                ? CHART_SERIES_COLORS[index]
                : CHART_SERIES_COLORS[0];

            // We should colorize based on the min and max values
            chartColor = CHART_SERIES_COLORS[0];

            if (
                minColorValue !== undefined &&
                maxColorValue !== undefined &&
                maxColorValue > minColorValue &&
                props.bubbleData[index] &&
                props.bubbleData[index].colorValue !== undefined
            ) {
                chartColor = getGradientColor(
                    "FE0500",
                    "04FF00",
                    (props.bubbleData[index].colorValue! - minColorValue) /
                        (maxColorValue - minColorValue),
                );
            }

            let datum: any = {
                color: chartColor,
                name: props.bubbleData[index].label,
                value: props.bubbleData[index].sizeValue,
                colorValue: props.bubbleData[index].colorValue,
                groupData: props.bubbleData[index].group,
                metricData: metricIds,
            };

            if (props.comparisonSuffix) {
                datum.comparisonSuffix = props.comparisonSuffix;
                datum.comparisonValue = props.bubbleData[index].compSizeValue;
                datum.comparisonColorValue =
                    props.bubbleData[index].compColorValue;
            }

            series.data.push(datum);
        }
    }

    const getChart = (popup: boolean = false) => {
        return (
            <ThemeContext.Consumer>
                {(ctx) => (
                    <div
                        aria-label="bubbleChart card"
                        className={
                            popup
                                ? Classes.DIALOG_BODY
                                : "flex bubbleChart" +
                                  (props.transparent ? "" : " bg-light") +
                                  (props.transparent || props.hideShadow
                                      ? ""
                                      : " shadow") +
                                  (props.className ? " " + props.className : "")
                        }
                    >
                        <HighchartsReact
                            highcharts={Highcharts}
                            immutable={true}
                            options={getChartOptions(
                                seriesData,
                                props.sizeMetric,
                                props.sizeUnit,
                                props.colorMetric || "",
                                props.colorUnit || new Unit(),
                                settings,
                                ctx.theme === THEME.dark,
                                props.options,
                                handleOpen,
                                handleSettingsOpen,
                                handleClose,
                                props.onGroupMetricSelection,
                                props.fullScreenTitle,
                            )}
                            containerProps={{
                                style: {
                                    width: props.width ? props.width : "100%",
                                    height: popup
                                        ? 0.9 * window.innerHeight - 40 + "px"
                                        : props.height
                                          ? props.height
                                          : "100%",
                                    padding: popup ? "10px" : "",
                                },
                            }}
                            ref={chartRef}
                        />
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    };

    return (
        <>
            <BasicDialog
                dialogState={dialogState}
                onClose={() =>
                    setDialogState(
                        updateDialogState(dialogState, false, false, []),
                    )
                }
            />
            <Dialog
                title={props.fullScreenTitle ? props.fullScreenTitle : ""}
                isOpen={isOpen}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                onClose={handleClose}
                style={{
                    width: 0.75 * window.innerWidth,
                    height: 0.9 * window.innerHeight,
                }}
            >
                {getChart(true)}
            </Dialog>
            {getChart(false)}
        </>
    );
};

/** returns the bubble chart options for the specified series.
 *  @param series the data series to put in the bubble chart.
 *  @param sizeMetric a String with the size metric name.
 *  @param sizeUnit a Unit object with the size unit.
 *  @param colorMetric a String with the color metric name.
 *  @param colorUnit a Unit object with the color unit.
 *  @param settings the BarChartSettings object with some of the settings for the chart like the style and legend position.
 *  @param darkMode a boolean which specifies whether dark mode is enabled.
 *  @param options additional options that should be merged into the chart options.
 *  @param onGroupMetricSelection the handler for selection change events.
 *  @returns the chart options for the specified series.*/
function getChartOptions(
    series: any,
    sizeMetric: string,
    sizeUnit: Unit,
    colorMetric: string,
    colorUnit: Unit,
    settings: BubbleChartSettings,
    darkMode: boolean = true,
    options: Highcharts.Options | undefined,
    handleOpen: () => void,
    handleSettingsOpen: () => void,
    handleClose: () => void,
    onGroupMetricSelection?: (event: GroupMetricEvent) => void,
    chartTitle?: string,
): Highcharts.Options {
    const { showValue = false } = settings;

    let optionsCopy: Highcharts.Options = Highcharts.merge(BASE_CHART_OPTIONS, {
        chart: {
            type: "packedbubble",
            backgroundColor: "transparent",
        },
        title: {
            text: colorMetric
                ? sizeMetric + " (colors: " + colorMetric + ")"
                : sizeMetric,
            style: {
                color: darkMode
                    ? CHART_COLORS.LEGEND_DARKMODE
                    : CHART_COLORS.LEGEND_DEFAULT,
            },
        },
        exporting: {
            enabled: true,
            fallbackToExportServer: false,
            filename: `${chartTitle ? chartTitle : "bubblechart"}-riverbed`,
            buttons: {
                contextButton: {
                    menuItems: [
                        {
                            text: STRINGS.chartToolbar.toggleFullScreen,
                            onclick: () => {
                                handleOpen();
                            },
                        },
                        {
                            text: STRINGS.chartToolbar.settingsMenuItem,
                            onclick: () => {
                                handleClose();
                                handleSettingsOpen();
                            },
                        },
                        "downloadCSV",
                        "downloadPNG",
                    ],
                },
            },
            chartOptions: {
                chart: {
                    backgroundColor: "#fff",
                },
            },
        },
        series: [
            {
                type: "packedbubble",
                allowPointSelect: true,
                name: "",
                data: [],
            },
        ],
        legend: {
            enabled: false,
        },
        tooltip: {
            enabled: true,
            shared: true,
            split: false,
            useHTML: true,
            formatter: function (this: any) {
                const compText = getComparisonText(
                    this.y,
                    this.point,
                    sizeUnit,
                    colorMetric,
                    colorUnit,
                );
                const symbol = "&#9632;";
                const toolTip =
                    '<div><span style="font-size:16px;color:' +
                    this.color +
                    '">' +
                    symbol +
                    "</span>" +
                    "<b><span> " +
                    this.key +
                    "</span></b> : <b>" +
                    scaleMetric(this.y, sizeUnit).formatted +
                    (colorMetric && this.point.options.colorValue !== undefined
                        ? " (" +
                          colorMetric +
                          ": " +
                          scaleMetric(this.point.options.colorValue, colorUnit)
                              .formatted +
                          ")"
                        : "") +
                    "</b>" +
                    compText +
                    "</div>";
                return toolTip;
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            packedbubble: {
                allowPointSelect: true,
                animation: true,
                cursor: "pointer",
                minSize: 30,
                maxSize: 100,
                layoutAlgorithm: {
                    splitSeries: false,
                    gravitationalConstant: 0.02,
                },
                borderWidth: 0,
                states: {
                    hover: {
                        enabled: true,
                    },
                },
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    padding: 0,
                    formatter: function (this: any) {
                        return getLabelText(
                            this.point.name,
                            undefined,
                            showValue ? this.point.y : undefined,
                            sizeUnit,
                        );
                    },
                    style: {
                        color: darkMode
                            ? CHART_COLORS.LABEL_DARKMODE
                            : CHART_COLORS.LABEL_DEFAULT,
                        textOutline: "none",
                    },
                },
            },
            series: {
                allowPointSelect: true,
                point: {
                    events: {
                        click: function (event) {
                            if (onGroupMetricSelection) {
                                const selected = !event.point.selected;
                                onGroupMetricSelection({
                                    source: GroupMetricSource.SERIES,
                                    selected,
                                    groups: [(event.point as any).groupData],
                                    metrics:
                                        (event.point as any).metricData || [],
                                });
                            }
                        },
                        mouseOver: function (event) {
                            //event.target.slice();
                        },
                        mouseOut: function (event) {
                            //event.target.slice();
                        },
                    },
                },
            },
        },
    });

    merge(optionsCopy, {
        series: series,
    });
    if (options) {
        merge(optionsCopy, options);
    }
    return optionsCopy;
}

/** a String with the label for the bubble slice or legend item.
 *  @param name the name of the bubble chart slice.
 *  @param percentage the percentage for that slice.
 *  @param value the value for that slice.
 *  @param unit the unit for the metric.
 *  @returns a String with the label for the bubble slice.*/
function getLabelText(
    name: string,
    percentage: number | undefined,
    value: number | undefined,
    unit: Unit,
): string {
    let label = name;
    if (percentage !== undefined || value !== undefined) {
        const data: Array<string | number> = [];
        if (percentage !== undefined) {
            data.push(precise(percentage) + " %");
        }
        if (value !== undefined) {
            data.push(scaleMetric(value, unit).formatted);
        }
        label += " (" + data.join(", ") + ")";
    }
    return label;
}

/** returns a string with the html that contains the comparison text.
 *  @param value the value of slice.
 *  @param point the highcharts point object.
 *  @param sizeUnit the Unit for the size value.
 *  @param colorMetric a String with the color metric.
 *  @param colorUnit the Unit for the size value.
 *  @returns a String with the comparison text or empty string if none. */
function getComparisonText(
    value: any,
    point: any,
    sizeUnit: Unit,
    colorMetric: string,
    colorUnit: Unit,
): string {
    const compSuffix = point.comparisonSuffix;
    const compSizeValue = point.comparisonValue;
    const compColorValue = point.comparisonColorValue;
    let compText = "";
    let changeText = "";
    if (compSizeValue !== null && compSizeValue !== undefined) {
        compText =
            "<br /><b><span>" +
            compSuffix +
            "</span></b> : <b>" +
            scaleMetric(compSizeValue, sizeUnit).formatted +
            (compColorValue !== undefined
                ? " (" +
                  colorMetric +
                  ": " +
                  scaleMetric(compColorValue, colorUnit).formatted +
                  ")"
                : "") +
            "</b>";
        if (
            value !== undefined &&
            compSizeValue !== undefined &&
            !Number.isNaN(value) &&
            !Number.isNaN(compSizeValue)
        ) {
            let pctChange: number | undefined = undefined;
            if (compSizeValue !== 0) {
                pctChange = 100 * ((value - compSizeValue) / compSizeValue);
            } else if (value !== 0) {
                // We have number / 0 which is infinity
                pctChange =
                    value > 0
                        ? Number.POSITIVE_INFINITY
                        : Number.NEGATIVE_INFINITY;
            } else if (compSizeValue === 0 && value === 0) {
                // We have 0 / 0, that is undefined, but that really is no change
                pctChange = 0;
            }
            if (pctChange !== undefined) {
                const arrow =
                    pctChange > 0 ? "&uarr;" : pctChange < 0 ? "&darr;" : "";
                pctChange = pctChange < 0 ? -1.0 * pctChange : pctChange;
                pctChange = Math.min(pctChange, 1000);
                changeText =
                    "<br /><b><span>" +
                    STRINGS.incidents.runbookOutputs.changeValueTooltipLabel +
                    "</span></b> : <b>" +
                    arrow +
                    " " +
                    (pctChange >= 1000 ? "&gt; " : "") +
                    precise(pctChange) +
                    " %</b>";
            }
        }
    }
    return compText + changeText;
}

/** returns the hex color that is the percentage between hexColor1 and hexColor2.
 *  @param hexColor1 the low hex color as a string without the #.
 *  @param hexColor2 the high hex color as a wting with the #.
 *  @param ratio the perentage between the low and high.
 *  @returns the hex color that is the percentage between hexColor1 and hexColor2. */
function getGradientColor(hexColor1: string, hexColor2: string, ratio): string {
    var hex = function (x) {
        x = x.toString(16);
        return x.length === 1 ? "0" + x : x;
    };

    var r = Math.ceil(
        parseInt(hexColor1.substring(0, 2), 16) * ratio +
            parseInt(hexColor2.substring(0, 2), 16) * (1 - ratio),
    );
    var g = Math.ceil(
        parseInt(hexColor1.substring(2, 4), 16) * ratio +
            parseInt(hexColor2.substring(2, 4), 16) * (1 - ratio),
    );
    var b = Math.ceil(
        parseInt(hexColor1.substring(4, 6), 16) * ratio +
            parseInt(hexColor2.substring(4, 6), 16) * (1 - ratio),
    );

    var gradientColor = hex(r) + hex(g) + hex(b);
    return "#" + gradientColor;
}
