/** This module contains the MappingService
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';

/** this type defines a MappingConfig. */
export type ForcastRequest = {
    algorithm: string,
    entity_kind: string,
    source_id: string,
    ipaddr: string,
    ifindex: number,
    columns: string[],
};

// The URL for the API server.
export const FORECASTING_CONFIG_BASE_URL = '/api/affogato/';

/** this class defines the MappingApiService. */
class ForecastingApiService extends ApiService {
	/** the constructor for the class. */
	constructor() {
		super(FORECASTING_CONFIG_BASE_URL);
	}

	/** returns the base uri, this can be overridden in subclasses to allow the uri to change
	 *      after construction.
	 *  @returns a String with the base uri. */
	protected getBaseUri(): string {
		if (ApiService.USE_REGION) {
			const region = ApiService.AUTH_SERVICE.getRegion();
			return `/api/iq/${region}/`;
		} else {
			return this.baseApiUri;
		}
	}

	/** returns Forcast Metrics.
     *  @returns a Promise which resolves to the returned metrics. */
    getForecastData(requestBody: any, period: string): Promise<any> {
        const utid = ApiService.AUTH_SERVICE.getTenantId();
        return new Promise((resolve, reject) => {
            super.post(`forecasting/1.0/tenants/${utid}/query_compute?period=${period}`, requestBody).then(
                (results) => {
                    resolve(results);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }
}


/** a constant with an instance of the MappingApiService. */
const ForecastingService = new ForecastingApiService();
export { ForecastingService };
