import { AuthError } from 'msal';
import {
    AuthConfig, AuthConstants,
    REDIRECT_FROM_URI_SESSION_VARIABLE,
} from '../constants/Constants';
import {
    PublicClientApplication,
    AuthenticationResult,
    SilentRequest,
    AccountInfo,
    InteractionRequiredAuthError
} from '@azure/msal-browser';
import { MessageService } from '../services/MessageService';

export class AuthProvider extends PublicClientApplication {
    private apiScope: any;
    private authenticationState: string;
    private isEmbedded: boolean;

    static makeConfig(
        tenant: { tenant_id?: any; idp?: any }
    ) {
        return {
            auth: {
                /** tenant_id is the azure applicationID for this tenant */
                clientId: tenant.tenant_id,
                authority: AuthConfig.issuerUri + tenant.idp,
                /** always redirect to the base mall for auth, else we will receive an redirect URI mismatch error */
                redirectUri: `${AuthConfig.tenantUrl}`,
                /** preserve the login route */
                navigateToLoginRequestUrl: true,
                postLogoutRedirectUri: AuthConfig.logoutUri,
            },
            cache: {
                /** This configures where your cache will be stored */
                cacheLocation: "localStorage",
                /** Set this to "true" if you are having issues on IE11 or Edge */
                storeAuthStateInCookie: false,
            },
        };
    }

    constructor(
        authConfig: {
            tenantServiceLocation?: string;
            apiScope?: any;
        },
        tenant: { tenant_id?: any; idp?: any, api_scope?: string },
        isEmbedded: boolean
    ) {
        super(AuthProvider.makeConfig(tenant));
        this.authenticationState = AuthConstants.UNAUTHENTICATED;
        this.apiScope = tenant.api_scope || authConfig.apiScope;
        this.isEmbedded = isEmbedded;
    }

    init(idp?: string, ): Promise<any> {
        this.setAuthenticationState(AuthConstants.UNAUTHENTICATED);
        return new Promise<any>((resolve, reject) => {
            this.handleRedirectPromise().then((resp: AuthenticationResult | null) => {
                try {
                    this.handleResponse(resp, idp);
                    resolve(resp);
                } catch (error) {
                    if (error instanceof AuthError) {
                        reject({errorMessage: error.errorMessage || error.message});
                    }
                }

            }).catch((error) => {
                if (!error.errorCode) {
                    error.errorCode = "REDIRECT_PROMISE_ERROR";
                }
                reject({errorMessage: error.errorMessage || error.message});
            });
        });

    }

    setAuthenticationState(state: string): void {
        this.authenticationState = state;
    }

    getAuthenticationState() {
        return this.authenticationState;
    }

    /**
     * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
     * @param response
     */
    handleResponse(response: AuthenticationResult | null, idp?: string) {
        if (response != null || this.getUserAccount()) {
          this.setAuthenticationState(AuthConstants.AUTHENTICATED);
        } else {
            this.setAuthenticationState(AuthConstants.IN_PROGRESS);
            this.isEmbedded ? 
                this.handlePopupAuthentication(idp):
                this.handleStandardAuthentication(idp);
        }
    }

    /**
     * Handles the standard login
     * 
     * @param idp {string}
     */
    handleStandardAuthentication(idp: string | undefined) {
        this.loginRedirect({
            scopes: AuthConfig.scopes,
            domainHint: idp
        }).catch((error) => {
            this.setAuthenticationState(AuthConstants.AUTHENTICATED);
            throw error;
        });
    }

    /**
     * Handles the login from a popup
     * 
     * @param idp {string}
     */
    handlePopupAuthentication(idp: string | undefined) {
        this.loginPopup({
            scopes: AuthConfig.scopes,
            domainHint: idp
        })
            .then(function () {
                window.location.reload();
            })
            .catch(function (error) {
                const errorMessage = {
                    statusCode: error?.errorCode,
                };
                
                // send login failures error codes to the main app
                MessageService.notify(errorMessage);
            });
    }

    getUserAccount(): AccountInfo {
        const accounts = this.getAllAccounts();
        return accounts[0];
    }

    getToken(): Promise<string> {
        const authParams: SilentRequest = {
            scopes: [`${this.apiScope}/role.customer.admin`],
            account: this.getUserAccount(),
        };

        return new Promise<string>((resolve, reject) => {
            if (!this.getUserAccount()) {
                this.saveRedirectUrl();
                this.isEmbedded ? 
                    this.loginPopup() :
                    this.loginRedirect();
            } else {
                this.acquireTokenSilent(authParams).then(
                    (token: AuthenticationResult) => {
                        resolve(token.accessToken);
                    },
                    (error) => {
                        this.setAuthenticationState(AuthConstants.UNAUTHENTICATED);
                        if (
                            error instanceof InteractionRequiredAuthError
                            || error.errorCode == 'no_tokens_found'
                        ) {
                            // since this will also cause an out-of-flow redirect, save the uri
                            this.saveRedirectUrl();
                            this.isEmbedded ? 
                                this.loginPopup() : 
                                this.loginRedirect();
                        } else {
                            reject(error);
                        }
                    }
                );
            }
        });
    }

    saveRedirectUrl(): void {
        sessionStorage.setItem(
            REDIRECT_FROM_URI_SESSION_VARIABLE,
            window.location.pathname
        );
    }

    cleanRedirectUrl(): void {
        sessionStorage.removeItem(REDIRECT_FROM_URI_SESSION_VARIABLE);
    }
}
