/** This module contains the functional React component for rendering the http node editor.
 *  This control allows users to set all the http node properties.
 *  @module
 */
import { 
    Button, Callout, Classes, HTMLSelect, Icon, InputGroup, Intent, NumericInput, OptionProps, Radio, 
    RadioGroup, Spinner, SpinnerSize, Switch/*, TextArea */, AnchorButton
} from '@blueprintjs/core';
import { IconNames, LoadingOverlay, useStateSafePromise } from '@tir-ui/react-components';
import { HELP, STRINGS } from 'app-strings';
import React, { useCallback, useContext, useEffect, useRef, useState, ChangeEvent } from 'react';
import { sortBy, remove } from 'lodash';
import { getNodeFromGraphDef } from 'utils/runbooks/RunbookUtils';
import { ProfileInterface, ThirdPartyIntegrationService } from 'utils/services/ThirdPartyIntegrationApiService';
import { NodeLibraryNode } from 'pages/create-runbook/views/create-runbook/NodeLibrary';
import { UniversalNode } from '../../UniversalNode';
import { SimpleNodeEditorProps } from '../simple/SimpleNodeEditor';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import "@webscopeio/react-textarea-autocomplete/style.css";
import { RunbookContextSummary } from '../RunbookContextSummary';
import { HttpNodeUtil } from './HttpNodeUtil';
import { InlineHelp } from 'components/common/layout/inline-help/InlineHelp';
import { useQuery } from 'utils/hooks';
import { Query } from 'reporting-infrastructure/types/Query';
import { loader } from 'graphql.macro';
import { DURATION, durationToRoundedTimeRange } from 'utils/stores/GlobalTimeStore';
import { getURLPath } from 'config';
import { IS_EMBEDDED, PARAM_NAME, SHOW_CONTEXT } from 'components/enums/QueryParams';
import { setQueryParams } from 'utils/hooks/useQueryParams';
// Suggestions related (disabled for MVP 1)
// import { RunbookContext } from 'utils/runbooks/RunbookContext.class';
// import { DataOceanUtils } from '../data-ocean/DataOceanUtils';
// import { DataOceanMetadata } from '../data-ocean/DataOceanMetadata.type';
import { HttpNodeStatusTemplate } from './HttpNodeStatusTemplate';
import { BasicDialog, DialogState, updateDialogState } from 'components/common/basic-dialog/BasicDialog';
import { VariableContext } from 'utils/runbooks/VariableContext';
import { Context, RunbookContext, VariableContextByScope } from 'utils/runbooks/RunbookContext.class';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, PrimitiveVariableType, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils';
import { 
    InputType, LIFECYCLE_TRIGGER_TYPES, Variant, VARIANTS_WITH_AUTH_AND_EDGE_PROFILES_IN_HTTP_NODE, 
    VARIANTS_WITH_GLOBAL_VARS, VARIANTS_WITH_INCIDENT_VARS, VARIANTS_WITH_RUNTIME_BUILTIN_VARS 
} from '../../types/GraphTypes';
import useAutoSizeTextArea from 'utils/hooks/useAutoSizeTextArea';
import { LiquidTemplateEditor } from '../transform/LiquidTemplateEditor';
import { GenericKey } from 'utils/runbooks/NodeUtil';
import { DataOceanMetadata } from '../data-ocean/DataOceanMetadata.type';
import { DataOceanUtils } from '../data-ocean/DataOceanUtils';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { Form, InputField, SelectField } from 'components/common/form';
import './HttpNodeEditor.scss';

export enum HTTP_NODE_EDIT_PROPS {
    SYNC_CALL = 'syncCall',
    DEBUG = 'debug',
}

interface HttpRequestHeaderList {
    contentType: string,
    accept: string,
    additional?: string | object;
}

interface RetryOptions {
    totalWaitTime: string,
    timeMeasurementUnit: TimeMeasurementUnit,
    statusCheckTemplate: string
}

interface HttpRequestProperties {
    useAuthentication?: boolean,
    authenticationProfileId?: string,
    edgeDeviceId?: string,
    httpVerbTemplate?: string,
    endpointTemplate?: string,
    useHeaders?: boolean,
    headers?: HttpRequestHeaderList,
    useBody?: boolean,
    bodyTemplate?: string,
    timeout?: string,
    retry?: boolean,
    retryOptions?: RetryOptions
}

interface HttpNodeProperties {
    request?: HttpRequestProperties,
    response?: any,
    debug?: boolean
}

interface AdditionalHeaderRow {
    key: string;
    value: string;
}

/** an enum that specifies the possible endpoint options. */
export enum EndpointOptions {
    /** the enumerated value for private endpoint option. */
    PRIVATE_ENDPOINT = "PRIVATE_ENDPOINT",
    /** the enumerated value for public endpoint option. */
    PUBLIC_ENDPOINT = "PUBLIC_ENDPOINT",
}

export enum TimeMeasurementUnit {
    'Seconds',
    'Minutes',
    'Hours',
    'Days'
}

/** Component for editing the properties in the http node. */
export const HttpNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef, handleChange, variant }: SimpleNodeEditorProps, ref: any) => {
    const [executeSafely] = useStateSafePromise();
    const [loading, setLoading] = useState(true);
    const [dialogState, setDialogState] = useState<any>({showDialog: false, loading: true, title: STRINGS.runbookEditor.nodeEditor.transformTemplate, dialogContent: null, dialogFooter: null});
    const {getVariables} = useContext(VariableContext);
    const endpointTextAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const [endpointTextAreaErrors, setEndpointTextAreaErrors] = useState<Array<string>>([]);
    const [additionalHeaders, setAdditionalHeaders] = useState<Array<AdditionalHeaderRow>>([{ key: "", value: "" }]);
    const [showAdditionalHeaderVarsControl, setShowAdditionalHeaderVarsControl] = useState<number>(-1);
    const [additionalHeaderValueFocused, setAdditionalHeaderValueFocused] = useState<number>(-1);
    const [additionalHeaderRowsWithError, setAdditionalHeaderRowsWithError] = useState<{ keyIndex: number[], valueIndex: number[]}>({ keyIndex: [], valueIndex: []});
    const [showEndpointVarsControl, setShowEndpointVarsControl] = useState<boolean>(false);
    
    useAutoSizeTextArea(endpointTextAreaRef?.current)

    const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();

    const fetchData = useCallback(
        () => {
            return executeSafely(DataOceanUtils.init()).then((response: any) => {
                setObjMetricMetaData(response);
            }, error => {
                console.error(error);
            });
        },
        [executeSafely]
    );

    useEffect(() => {
        // Fetch Meta data on load.
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        let parsedAdditionalHeaders = '';
        if (additionalHeaders) {
            additionalHeaders.forEach(header => {
                if (parsedAdditionalHeaders) {
                    parsedAdditionalHeaders+='\n';
                }
                parsedAdditionalHeaders+= `${header.key}:${header.value}`;
            });
        }
        checkAndSetRequestProperty('additional', parsedAdditionalHeaders, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalHeaders]);

    const customProperties = useContext(CustomPropertyContext);

    const variables: VariableContextByScope = {
        runtime: getVariables(RUNTIME_SCOPE, false),
        incident: {primitiveVariables: [], structuredVariables: []},
        global: {primitiveVariables: [], structuredVariables: []}
    };

    const INITIAL_REQUEST_STATE: HttpRequestProperties = {
        useAuthentication: false,
        authenticationProfileId: undefined,
        edgeDeviceId: undefined,
        httpVerbTemplate: 'GET',
        endpointTemplate: undefined,
        useHeaders: false,
        headers: {
            contentType: 'application/json',
            accept: 'application/json',
            additional: ''
        },
        useBody: false,
        bodyTemplate: undefined,
        retry: false,
        retryOptions: {
            totalWaitTime: '10',
            timeMeasurementUnit: 1,
            statusCheckTemplate: HttpNodeStatusTemplate
        }
    }

    const [currentProperties, setCurrentProperties] = useState<HttpNodeProperties>({ request: INITIAL_REQUEST_STATE, response: {}, debug: false });
    // const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>(); Suggestions related (disabled for MVP 1)
    const [authProfiles, setAuthProfiles] = useState<ProfileInterface[]>();
    // const [autocompleteProperties, setAutocompleteProperties] = useState<any>(); Suggestions related (disabled for MVP 1)

    // Suggestions related (disabled for MVP 1)
    // const fetchData = useCallback(() => {
    //     return executeSafely(DataOceanUtils.init()).then(
    //         (response: DataOceanMetadata) => {
    //             setObjMetricMetaData(response);
    //         },
    //         (error) => {
    //             console.log(error);
    //         }
    //     );
    // }, [executeSafely]);

    const [sendRequestTo, setSendRequestTo] = useState(currentProperties?.request?.edgeDeviceId ? EndpointOptions.PRIVATE_ENDPOINT : EndpointOptions.PUBLIC_ENDPOINT);

    useEffect(() => {
        let existingProperties = {};

        selectedNode?.getProperties().forEach((prop) => {
            existingProperties[prop.key] = selectedNode?.getProperty(
                prop.key
            );
        });

        const currentProperties = JSON.parse(JSON.stringify(existingProperties));
        const currentPropertiesAdditonalHeaders: string | object | undefined = currentProperties?.syncCall?.request?.headers?.additional;

        if (currentPropertiesAdditonalHeaders) {
            if (typeof currentPropertiesAdditonalHeaders === "object") {
                const headers: AdditionalHeaderRow[] = [];
                for (const [key, value] of Object.entries(currentPropertiesAdditonalHeaders)) {
                    headers.push({ key, value});
                }
                if (headers.length) {
                    setAdditionalHeaders(headers);
                }
            } else {
                const parsedHeaders = HttpNodeUtil.parseStringToHttpHeaders(currentPropertiesAdditonalHeaders);
                if (parsedHeaders) {
                    const headers: AdditionalHeaderRow[] = [];
                    for (const [key, value] of Object.entries(parsedHeaders)) {
                        headers.push({ key, value: value as string});
                    }
                    if (headers.length) {
                        setAdditionalHeaders(headers);
                    }
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchProfiles = useCallback(() => {
        return executeSafely(ThirdPartyIntegrationService.getRunbookAndIntegrationAuthProfiles()).then(
            (response: ProfileInterface[]) => {
                setAuthProfiles(response.filter(profile => profile.isEnabled));
            },
            _ => {
                setAuthProfiles([]);
            }
        );
    }, [executeSafely]);

    const { data } = useQuery({
        name: 'EdgeConfig',
        query: new Query(loader('./../../../../../pages/edge-configuration/queries/edge-config.graphql')),
        queryVariables: {
            ...(durationToRoundedTimeRange(DURATION.HOUR_1) as any),
        },
    });

    // Suggestions related (disabled for MVP 1)
    // useEffect(() => {
    //     // Fetch Meta data on load.
    //     fetchData();
    // }, [fetchData]);

    useEffect(() => {
        // Fetch profiles on load.
        fetchProfiles();
    }, [fetchProfiles])

    useEffect(() => {
        let existingProperties = {};
        selectedNode?.getProperties().forEach((prop) => {
            existingProperties[prop.key] = selectedNode?.getProperty(
                prop.key
            );
        });

        const currentProperties = JSON.parse(JSON.stringify(existingProperties));
        if (currentProperties.syncCall) {
            // Initialize the useHeader/usePayload/useAuthentication if the request obj exists
            if (currentProperties.syncCall.request) {
                if (currentProperties.syncCall.request.bodyTemplate) {
                    currentProperties.syncCall.request.useBody = true;
                }
                if (currentProperties.syncCall.request.authenticationProfileId) {
                    currentProperties.syncCall.request.useAuthentication = true;
                }
                if (currentProperties.syncCall.request.headers?.additional) {
                    currentProperties.syncCall.request.useHeaders = true;
                }
                if (currentProperties.syncCall?.request?.edgeDeviceId) {
                    setSendRequestTo(currentProperties.syncCall?.request?.edgeDeviceId ? EndpointOptions.PRIVATE_ENDPOINT : EndpointOptions.PUBLIC_ENDPOINT);
                }
                if (currentProperties.syncCall.request?.retryOptions?.totalWaitTime || currentProperties.syncCall.request?.retryOptions?.timeMeasurementUnit) {
                    currentProperties.syncCall.request.retry = true;
                }
            }
            setCurrentProperties(currentProperties.syncCall);
        }

        setLoading(false);
    }, [selectedNode, setCurrentProperties]);

    // Suggestions related (disabled for MVP 1)
    // useEffect(() => {
    //     let selNodeId = selectedNode?.getId();
    //     selNodeId = selNodeId ? selNodeId : "";
    //     let nodeDefObj = getNodeFromGraphDef(selNodeId, graphDef);
    //     if (nodeDefObj && objMetricMetaData) {
    //         let context = new RunbookContext(nodeDefObj, graphDef, objMetricMetaData);
    //         let dataCols = context.getApplicableKeysMetrics();
    //         const keys = dataCols?.keys?.map(key => {
    //             return {name: key.label, char: `{{${key.id}}}`};
    //         }) || [];
    //         const metrics = dataCols?.metrics?.map(metric => {
    //             return {name: metric.label, char: `{{${metric.id}}}`};
    //         }) || [];

    //         setAutocompleteProperties(keys.concat(metrics));
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedNode, objMetricMetaData]);

    const checkAndSetRequestProperty = useCallback((property, value, useHeaders = false, retry = false) => {
        const currentPropertiesCopy = { ...currentProperties };
        // Check that the properties obj is in a good state or initialize it
        if (!currentPropertiesCopy.request) {
            currentPropertiesCopy.request = INITIAL_REQUEST_STATE;
        }
        // Convert switch's "on/off" value to boolean
        if (value === 'on' || value === 'off') {
            value = value === 'on' ? true : false;
        }

        // Check if value is header
        if (useHeaders) {
            if (!currentPropertiesCopy.request.headers) {
                currentPropertiesCopy.request.headers = INITIAL_REQUEST_STATE.headers;
            }
            if (currentPropertiesCopy.request) {
                if (currentPropertiesCopy.request.headers) {
                    if (property === 'additional') {
                        // Special case for additional header structure
                        currentPropertiesCopy.request.headers.additional = HttpNodeUtil.parseStringToHttpHeaders(value);
                    } else {
                        currentPropertiesCopy.request.headers[property] = value;
                    }
                }
            } else {
                console.error(`Undefined property: ${property}`);
            }
        } else if (retry) {
            if (!currentPropertiesCopy.request.retryOptions) {
                currentPropertiesCopy.request.retryOptions = INITIAL_REQUEST_STATE.retryOptions;
            }
            if (currentPropertiesCopy.request) {
                if (currentPropertiesCopy.request.retryOptions) {
                        currentPropertiesCopy.request.retryOptions[property] = value;
                }
            } else {
                console.error(`Undefined property: ${property}`);
            }
        } else {
            if (property === 'useAuthentication' && !currentPropertiesCopy.request.authenticationProfileId) {
                currentPropertiesCopy.request.authenticationProfileId = authProfiles?.length ? authProfiles[0].id : undefined;
            }
            currentPropertiesCopy.request[property] = value;
        }
        setCurrentProperties(currentPropertiesCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProperties, authProfiles]);

    function updateNode(properties: HttpNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        // Curently only using synCall for first version
        selectedNode.setProperty(HTTP_NODE_EDIT_PROPS.SYNC_CALL, currentProperties);

        let nodeId = selectedNode?.getId();
        nodeId = nodeId ? nodeId : "";
        const node = getNodeFromGraphDef(nodeId, graphDef);
        if (node) {
            node.editedByUser = true;
        }
    }

    // @ts-ignore
    ref.current = {
        updateNode: () => {
            let nodeProperties = { request: INITIAL_REQUEST_STATE };
            Object.assign(nodeProperties, currentProperties);
            if (!currentProperties.request?.useAuthentication) {
                nodeProperties.request.authenticationProfileId = undefined;
            }
            if (!currentProperties.request?.useBody) {
                nodeProperties.request.bodyTemplate = undefined;
            }
            if (!currentProperties.request?.useHeaders && nodeProperties.request.headers) {
                nodeProperties.request.headers.additional = undefined;
            }
            if (!currentProperties.request?.retry) {
                nodeProperties.request.retryOptions = undefined;
            }

            delete nodeProperties.request.useAuthentication;
            delete nodeProperties.request.useBody;
            delete nodeProperties.request.useHeaders;
            delete nodeProperties.request.retry;

            updateNode({ ...currentProperties, request: { ...nodeProperties.request } }, selectedNode, libraryNode);
        },
        validate: () => {
            let errorMessages = new Array<string>();
            if (!currentProperties.request?.retry) {
                checkAndSetRequestProperty('retryOptions', null);
            }
            errorMessages = errorMessages.concat(HttpNodeUtil.checkMandatoryFields(currentProperties, authProfiles));
            return errorMessages;
        }
    };

    const [triggerKeys, setTriggerKeys] = useState<GenericKey[]>([]);
    const [triggerMetrics, setTriggerMetrics] = useState<GenericKey[]>([]);
    const [parentKeys, setParentKeys] = useState<GenericKey[]>([]);
    const [parentMetrics, setParentMetrics] = useState<GenericKey[]>([]);
    useEffect(() => {
        if (selectedNode?.node) {
            let selNodeId = selectedNode?.getId();
            selNodeId = selNodeId ? selNodeId : "";
            let nodeDefObj = getNodeFromGraphDef(selNodeId, graphDef);
            if (nodeDefObj && objMetricMetaData) {
                let runbookContext = new RunbookContext(nodeDefObj, graphDef, objMetricMetaData, customProperties, {forLiquid: true});
                const nodeContexts: Context[] = runbookContext.getNodeContexts();
                let nodeContext: Context | undefined =  nodeContexts?.length ? nodeContexts[nodeContexts.length - 1] : undefined;
                if (nodeContext) {
                    setParentKeys(nodeContext.expandedKeys || []);
                    setParentMetrics(nodeContext.metrics || []);
                }
                if (runbookContext && runbookContext.getTriggerContext()) {
                    setTriggerKeys((runbookContext.getTriggerContext()?.expandedKeys || []).map((key) => {
                        return {...key, triggerType: runbookContext.getTriggerType(), liquid_key: runbookContext.getTriggerContext()?.liquid_key};
                    }));
                    if ([...LIFECYCLE_TRIGGER_TYPES, InputType.WEBHOOK].includes(runbookContext.getTriggerType() as InputType)) {
                        setTriggerMetrics([]);
                    } else {
                        setTriggerMetrics(runbookContext.getTriggerContext()?.metrics || []);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode, objMetricMetaData])

    const toolbarVariables = getVariables(RUNTIME_SCOPE, VARIANTS_WITH_RUNTIME_BUILTIN_VARS.includes(variant)).primitiveVariables.concat(
        VARIANTS_WITH_INCIDENT_VARS.includes(variant) ? getVariables(INCIDENT_SCOPE, true).primitiveVariables : []
    ).concat(
        VARIANTS_WITH_GLOBAL_VARS.includes(variant) ? getVariables(GLOBAL_SCOPE, true).primitiveVariables : []
    );

    function authProfileFromVariable() {
        const selectedProfileId = currentProperties?.request?.authenticationProfileId || '';
        return selectedProfileId?.startsWith("subflow.") === true || selectedProfileId?.startsWith("runtime.") === true;
    }

    function getNrOfEdgeDeviceIdsForSelectedProfile() {
        const selectedProfileId = currentProperties?.request?.authenticationProfileId || '';
        let numberOfEdgeDeviceIds = -1;
        if (authProfileFromVariable()) {
            // We are in a subflow, so we don't know this
            numberOfEdgeDeviceIds = 1;
        } else if (selectedProfileId) {
            numberOfEdgeDeviceIds = authProfiles?.find(item => item.id === selectedProfileId)?.edgeDeviceIds?.length || 0;
        }
        return numberOfEdgeDeviceIds;
    }

    function getSelectAlluvioEdgeOptions() {
        const selectedProfileId = currentProperties?.request?.authenticationProfileId || '';
        const edgeDeviceIds = authProfiles?.find(item => item.id === selectedProfileId)?.edgeDeviceIds || [];
        if (selectedProfileId && !authProfileFromVariable()) {
            return data?.edges?.filter(item => edgeDeviceIds?.includes(item.id)).map(item => {return item.name}) || [];
        } else {
            return [
                ...(variables?.runtime?.primitiveVariables?.filter(variable => {
                        return [PrimitiveVariableType.ALLUVIO_EDGE, PrimitiveVariableType.CONNECTOR].includes(variable.type);
                    }).map(variable => {return {label: variable.name + (PrimitiveVariableType.CONNECTOR === variable.type ? ".edge" : ""), value: variable.name}}) || []
                ),
                ...(VARIANTS_WITH_AUTH_AND_EDGE_PROFILES_IN_HTTP_NODE.includes(variant)
                    ? data?.edges?.map(item => {return {label: item.name ? item.name : STRINGS.Unknown, value: item.name ? item.name : STRINGS.Unknown}}) || []
                    : []
                )
            ];    
        }
	}

	function getSelectedAlluvioEdgeName() {
        const edgeId = currentProperties.request?.edgeDeviceId;
        if (edgeId?.startsWith("subflow.") || edgeId?.startsWith("runtime.")) {
            return edgeId;
        } else {
            return data?.edges?.find(item => item.id === currentProperties.request?.edgeDeviceId)?.name || '';
        }
	}


    /**
     * Open the transform template modal
     */
    function openTransformTemplateDialog(variant: 'retry' | 'payload', runbookVariant: Variant) {
        const defaultValue = variant === 'retry' ?
                                currentProperties?.request?.retryOptions?.statusCheckTemplate :
                                currentProperties?.request?.bodyTemplate;
        let updatedTransformTemplateValue = defaultValue || '';
        const placeholder = variant === 'retry' ?
                            STRINGS.runbookEditor.nodeEditor.http.retry.retryHint: 
                            STRINGS.runbookEditor.nodeEditor.http.payload.payloadHint
                            
        setDialogState({
            showDialog: true,
            doNotAllowOutsideClick: true,
            title: STRINGS.runbookEditor.nodeEditor.transformTemplate,
            dialogContent: <>
                <LiquidTemplateEditor
                    data-testid="template_modal"
                    placeholder={placeholder}
                    value={defaultValue}
                    style={{ resize: "both", width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                    className="bg-white text-black mt-3"
                    variables={toolbarVariables}
                    triggerExpandedKeys={triggerKeys} triggerMetrics={triggerMetrics}
                    parentExpandedKeys={parentKeys} parentMetrics={parentMetrics}
                    onChange={value => {
                        updatedTransformTemplateValue = value;
                    }}
                    variant={runbookVariant}
                />
            </>,
            closeable: true,
            dialogFooter: <div className="d-flex justify-content-between flex-grow-1">
                <Button
                    className="ml-0"
                    text={STRINGS.runbookEditor.discardAndCloseBtn}
                    intent={Intent.DANGER}
                    onClick={() => {
                        setDialogState({ ...dialogState, showDialog: false, closeable: true });
                    }}
                />
                <Button
                    icon={IconNames.SAVED}
                    text={STRINGS.runbookEditor.saveAndCloseBtn}
                    intent={Intent.SUCCESS}
                    onClick={() => {
                        variant === 'retry' ?
                            checkAndSetRequestProperty('statusCheckTemplate', updatedTransformTemplateValue, false, true):
                            checkAndSetRequestProperty('bodyTemplate', updatedTransformTemplateValue);

                        setDialogState({ ...dialogState, showDialog: false, closeable: true });
                    }}
                />
            </div>,
        } as DialogState);
    }

    if (loading && !objMetricMetaData) {
        return (
            <tr>
                <td>
                    <LoadingOverlay visible={true} />
                </td>
            </tr>
        );
    }

    const authProfileList = authProfiles?.length ? [
    ...(VARIANTS_WITH_AUTH_AND_EDGE_PROFILES_IN_HTTP_NODE.includes(variant) ? authProfiles.map(profile => {
        const option: OptionProps = { label: profile.name, value: profile.id || '' };
        return option;
    }) : []), 
    ...variables?.runtime?.primitiveVariables?.filter(variable => {
        return [PrimitiveVariableType.AUTH_PROFILE, PrimitiveVariableType.CONNECTOR].includes(variable.type);
    }).map(variable => {
        const option: OptionProps = { 
            label: variable.name + ([PrimitiveVariableType.CONNECTOR].includes(variable.type) ? ".authProfile" : "") , 
            value: variable.name || ''
        };
        return option;
    })]
    : [{ label: STRINGS.runbookEditor.nodeEditor.http.authentication.noProfiles, value: '' }] as OptionProps[];

    const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;

    const handleAddAdditionalHeaderRow = () => {
        const newRow: AdditionalHeaderRow = { key: "", value: "" };
        setAdditionalHeaders([...(additionalHeaders || []), newRow]);
    };

    const handleRemoveAdditionalHeaderRow = (index: number) => {
        if (handleChange) {    
            handleChange();
        }
        setAdditionalHeaders(
            additionalHeaders.filter((_row: AdditionalHeaderRow, i: number) => i !== index)
        );
    };

    const handleAdditionalHeaderRowInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement> | { target: { name: string, value: string } }) => {
        const { name, value } = e.target;
        const newAdditionalHeaderRowsWithError = additionalHeaderRowsWithError;
        let hasInputError = false;

        if (name === "key" && !value) {
            newAdditionalHeaderRowsWithError.keyIndex.push(index);
            hasInputError = true;
        } else if (name === "key") {
            remove(newAdditionalHeaderRowsWithError.keyIndex, (item: number) => {
                return item === index
            });
            hasInputError = true;
        }

        if (name === "value" && !value) {
            newAdditionalHeaderRowsWithError.valueIndex.push(index);
            hasInputError = true;
        } else if (name === "value") {
            remove(newAdditionalHeaderRowsWithError.valueIndex, (item: number) => {
                return item === index
            });
            hasInputError = true;
        }

        if (hasInputError) {
            setAdditionalHeaderRowsWithError({...newAdditionalHeaderRowsWithError});
        }

        setAdditionalHeaders(
            additionalHeaders.map((row, i) => 
                i === index ? { ...row, [name]: value } : row
            )
        );
    };

    const additionalHeaderVariableNames = [STRINGS.TextEditor.insertVariable];
    
    toolbarVariables.forEach((item) => {
        additionalHeaderVariableNames.push(item['name']);
    });

    const hasAdditionalHeaderWithEmptyValue = () => {
        if (additionalHeaders.length) {
            return !!additionalHeaders.find(header => !header.key || !header.value);
        }
        return false;
    };

    return (
        <>
            <BasicDialog dialogState={dialogState} className="transform-template-dialog" onClose={() => {
                setDialogState(updateDialogState(dialogState, false, false, []));
            }} />
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <InlineHelp helpMapping={HELP.RunbookNodeCategory.Integration.HttpNode.authentication}>
                        {STRINGS.runbookEditor.nodeEditor.http.authentication.label}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td className="p-1 pt-3" colSpan={2}>
                    <Switch
                        id="node-use-auth-profile"
                        checked={currentProperties?.request?.useAuthentication ?
                            currentProperties.request.useAuthentication : false}
                        label={STRINGS.runbookEditor.nodeEditor.http.authentication.useAuthMethod}
                        onChange={(event) => {
                            checkAndSetRequestProperty('useAuthentication', event.currentTarget.checked);
                            checkAndSetRequestProperty('edgeDeviceId', '');
                            checkAndSetRequestProperty('authenticationProfileId', '');
                            setSendRequestTo(EndpointOptions.PUBLIC_ENDPOINT);
                        }}>
                    </Switch>
                </td>
            </tr>
            <tr style={{ visibility: currentProperties.request?.useAuthentication && authProfiles ? 'visible' : 'collapse' }}>
                <td>
                    <label className="mb-0 pb-1">{STRINGS.runbookEditor.nodeEditor.http.authentication.authMethod}</label><br />
                    <HTMLSelect
                        id="node-auth-profile-select"
                        fill={true}
                        options={[({label: "Select a profile", value: 'no-profile-selected'} as OptionProps), ...sortBy(authProfileList, (el) => el.label.toLowerCase())]}
                        value={currentProperties?.request?.authenticationProfileId ?? 'no-profile-selected'}
                        onChange={
                            (event) => {
                                let profileId = event.currentTarget.value;
                                profileId = profileId === 'no-profile-selected' ? '' : profileId;
                                checkAndSetRequestProperty('authenticationProfileId', profileId);
                                if (profileId?.startsWith("subflow.") || profileId?.startsWith("runtime.")) {
                                    setSendRequestTo(EndpointOptions.PUBLIC_ENDPOINT);
                                } else if (authProfiles?.length) {
                                    const selectedProfile = authProfiles?.find(profile => profile.id === profileId);
                                    let selectedEndpointType = EndpointOptions.PUBLIC_ENDPOINT;
                                    if (selectedProfile?.edgeDeviceIds) {
                                        selectedEndpointType = selectedProfile?.edgeDeviceIds?.length > 0 ? EndpointOptions.PRIVATE_ENDPOINT : EndpointOptions.PUBLIC_ENDPOINT;
                                    }
                                    setSendRequestTo(selectedEndpointType);
                                    if (selectedEndpointType === EndpointOptions.PUBLIC_ENDPOINT) {
                                        checkAndSetRequestProperty('edgeDeviceId', '');
                                    }
                                }
                            }
                        }
                    />
                    <br />
                    {!IS_EMBEDDED && <a className="p-1 text-nowrap d-block mb-3" 
                        href={getURLPath('third-party-authentication')} 
                        target='_blank' rel="noreferrer"
                    >
                        {STRINGS.runbookEditor.nodeEditor.http.authentication.defineNew}
                    </a>}
                </td>
            </tr>
            {IS_EMBEDDED && <tr style={{ visibility: currentProperties.request?.useAuthentication && authProfiles ? 'visible' : 'collapse' }}>
                <td className='d-flex w-100'>
                    <a className="p-1 text-nowrap mr-4" 
                        href={getURLPath('third-party-authentication')} 
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            setQueryParams({[PARAM_NAME.view]: "authProfile", [PARAM_NAME.createNew]: "true"} , true);  
                        }}
                    >
                        {STRINGS.runbookEditor.nodeEditor.http.authentication.defineNew}
                    </a>
                    <a className="p-1 text-nowrap" 
                        href={getURLPath('third-party-authentication')} 
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            setQueryParams({[PARAM_NAME.view]: "authProfile"} , true);  
                        }}
                    >
                        {STRINGS.runbookEditor.nodeEditor.http.authentication.viewAll}
                    </a>
                </td>
            </tr>}
            {authProfiles || !currentProperties.request?.useAuthentication ? null : <tr><td className='w-100 pt-3' colSpan={2}><Spinner size={SpinnerSize.SMALL}></Spinner></td></tr>}
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <InlineHelp helpInfo={STRINGS.fieldHelpText.HttpNode.Endpoint}>
                        {STRINGS.runbookEditor.nodeEditor.http.endpoint.label}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td className="p-1 fixed-textarea" colSpan={2}>
                    {
                        // Use a textarea and make it look like an InputGroup for suggestions
                        <>
                            <div className="d-flex align-items-md-center justify-content-md-end">
                                <AnchorButton
                                    title={STRINGS.TextEditor.variableTooltip}
                                    className={showEndpointVarsControl ? 'variables-checked' : ''}
                                    icon={IconNames.VARIABLE}
                                    onClick={() => {
                                        setShowEndpointVarsControl(!showEndpointVarsControl);
                                    }}
                                />
                                {showEndpointVarsControl && (
                                    <div className="ml-2">
                                        <HTMLSelect
                                            fill={true}
                                            options={additionalHeaderVariableNames}
                                            defaultValue={''}
                                            onChange={
                                                (event) => {
                                                    if (event.target.value !== STRINGS.TextEditor.insertVariable) {
                                                        const errors = HttpNodeUtil.validateURL(
                                                            `${currentProperties?.request?.endpointTemplate ?? ""}{{variables["${event.target.value}"]}}`, 
                                                            variables
                                                        );

                                                        setEndpointTextAreaErrors([...errors || []]);
                                                        checkAndSetRequestProperty(
                                                            'endpointTemplate', 
                                                            `${currentProperties?.request?.endpointTemplate ?? ""}{{variables["${event.target.value}"]}}`
                                                        );  
                                                    }
                                                }
                                            }
                                            disabled={!toolbarVariables || toolbarVariables.length === 0}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="textarea-hack textarea-group">
                                <div className='textarea-group-left'>
                                    <HTMLSelect
                                        id="node-verb-select"
                                        fill={true}
                                        options={Object.values(STRINGS.thirdPartyIntegrations.addAuthProfile.panels
                                            .testQuery.fields.requestUrl.requestVerb)}
                                        defaultValue={currentProperties?.request?.httpVerbTemplate}
                                        onChange={
                                            (event) => {
                                                checkAndSetRequestProperty('httpVerbTemplate', event.currentTarget.value);
                                            }
                                        }
                                    />
                                </div>
                                <ReactTextareaAutocomplete
                                    id="node-endpoint"
                                    placeholder={STRINGS.runbookEditor.nodeEditor.http.endpoint.hint}
                                    value={currentProperties?.request?.endpointTemplate}
                                    onChange={
                                        (event) => {
                                            const errors = HttpNodeUtil.validateURL(event.target.value, variables);

                                            setEndpointTextAreaErrors([...errors || []]);
                                            checkAndSetRequestProperty('endpointTemplate', event.target.value);
                                        }
                                    }
                                    innerRef={textarea => {
                                        endpointTextAreaRef.current = textarea;
                                    }}                     
                                    minChar={0}
                                    trigger={{
                                        "{{": {
                                            dataProvider: token => {
                                                // return autocompleteProperties ? autocompleteProperties : []; Disabled for MVP1
                                                return []
                                            },
                                            allowWhiteSpace: true,
                                            component: Item,
                                            output: (item, trigger) => item.char
                                        }
                                    }}
                                    title={currentProperties?.request?.endpointTemplate}
                                    loadingComponent={() => <Spinner size={10}></Spinner>}
                                    className={Classes.INPUT}
                                    renderToBody={true}
                                    style={{whiteSpace: "pre-wrap", overflowX: "hidden", overflowY: "visible"}}
                                    dropdownClassName={'float-list'}
                                    rows={1}
                                />
                            </div>

                            {endpointTextAreaErrors?.length > 0 && 
                                <Callout intent="warning" className='mb-3'>
                                    <div className='small'>
                                        {endpointTextAreaErrors.map((error, index) => <span key={"endpoint-error-" + (index + 1)}>{error}</span>)}
                                    </div>
                                </Callout>
                            }
                        </>
                    }
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    {STRINGS.runbookEditor.nodeEditor.http.endpoint.sendRequestTo}
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <RadioGroup
                        name="endpoint_control"
                        onChange={(event) => {
                            checkAndSetRequestProperty('edgeDeviceId', '');
                            setSendRequestTo(event.currentTarget.value as EndpointOptions);
                        }}
                        selectedValue={sendRequestTo}
                        inline={true}
                        className="pl-4 align-self-center"
                    >
                        <Radio
                            label={STRINGS.runbookEditor.nodeEditor.http.endpoint.publicEndpoint}
                            value={EndpointOptions.PUBLIC_ENDPOINT}
                            disabled={(getNrOfEdgeDeviceIdsForSelectedProfile() > 0) && !authProfileFromVariable()}
                            className="mb-0 pt-2 position-relative d-block"
                        />
                        <Radio
                            label={STRINGS.runbookEditor.nodeEditor.http.endpoint.privateEndpoint}
                            value={EndpointOptions.PRIVATE_ENDPOINT}
                            disabled={(getNrOfEdgeDeviceIdsForSelectedProfile() === 0) && !authProfileFromVariable()}
                            className="mb-0 mr-2 mt-1"
                        />
                        <br />
                        <HTMLSelect
                            className='ml-4 mt-2'
                            name="alluvio_edge_selector"
                            disabled={(getNrOfEdgeDeviceIdsForSelectedProfile() === 0 && !authProfileFromVariable()) || sendRequestTo === EndpointOptions.PUBLIC_ENDPOINT}
                            onChange={(event) => {
                                const edgeId = event.target.value;
                                if (edgeId?.startsWith("subflow.") || edgeId?.startsWith("runtime.")) {
                                    checkAndSetRequestProperty('edgeDeviceId', edgeId || '');
                                } else {
                                    checkAndSetRequestProperty('edgeDeviceId', data?.edges.find(item => item.name === edgeId)?.id || '');
                                }
                            }}
                            options={[
                                {label: STRINGS.runbookEditor.nodeEditor.http.endpoint.selectAlluvioEdge, value: STRINGS.runbookEditor.nodeEditor.http.endpoint.selectAlluvioEdge}, 
                                ...getSelectAlluvioEdgeOptions().sort((a: any, b: any) => a.label.localeCompare(b.label))
                            ]}
                            value={getSelectedAlluvioEdgeName()}
                            >
                        </HTMLSelect>
                        
                        {variant === Variant.SUBFLOW && 
                            <small id="alluvio_edge_selector" className="form-text text-muted ml-4">
                                {STRINGS.runbookEditor.nodeEditor.http.endpoint.privateEndpointHelpText}
                            </small>
                        }
                    </RadioGroup>
                </td>
            </tr>
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <InlineHelp helpInfo={STRINGS.fieldHelpText.HttpNode.HttpHeader}>
                        {STRINGS.runbookEditor.nodeEditor.http.headers.label}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td className="pt-2">
                    <label className="mb-0 pb-1">{STRINGS.runbookEditor.nodeEditor.http.headers.contentHeader}</label><br />
                    <InputGroup
                        id="node-content-header"
                        fill={true}
                        placeholder={STRINGS.runbookEditor.nodeEditor.http.headers.contentHint}
                        defaultValue={currentProperties?.request?.headers?.contentType ?
                            currentProperties?.request?.headers?.contentType
                            : 'application/json'}
                        onChange={
                            (event) => {
                                checkAndSetRequestProperty('contentType', event.currentTarget.value, true);
                            }
                        }
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <label className="mb-0 pb-1">{STRINGS.runbookEditor.nodeEditor.http.headers.acceptHeader}</label><br />
                    <InputGroup
                        id="node-accept-header"
                        fill={true}
                        placeholder={STRINGS.runbookEditor.nodeEditor.http.headers.acceptHint}
                        defaultValue={currentProperties?.request?.headers?.accept ?
                            currentProperties?.request?.headers?.accept
                            : 'application/json'}
                        onChange={
                            (event) => {
                                checkAndSetRequestProperty('accept', event.currentTarget.value, true);
                            }
                        }
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={2} className='p-1 pt-3'>
                    <Switch
                        id="node-use-additional-headers"
                        label={STRINGS.runbookEditor.nodeEditor.http.headers.includeAdditionalHeaders}
                        checked={currentProperties?.request?.useHeaders ?
                            currentProperties.request.useHeaders : false}
                        onChange={
                            (event) => {
                                checkAndSetRequestProperty('useHeaders', event.currentTarget.checked);
                            }
                        }>
                    </Switch>
                </td>
            </tr>
            {currentProperties.request?.useHeaders ?
                <>
                    {/* <tr>
                        <td colSpan={2} className='fixed-textarea'>
                            <TextArea
                                id="node-additional-headers"
                                rows={5}
                                placeholder={STRINGS.runbookEditor.nodeEditor.http.headers.additionalHint}
                                fill={true}
                                defaultValue={typeof currentProperties?.request?.headers?.additional === 'object' ?
                                    HttpNodeUtil.parseHttpHeadersToString(currentProperties!.request!.headers!.additional!) :
                                    currentProperties?.request?.headers?.additional
                                }
                                onChange={
                                    (event) => {
                                        checkAndSetRequestProperty('additional', event.currentTarget.value, true);
                                    }
                                }
                            />
                        </td>
                    </tr> */}
                    <tr>
                        <td>
                        {<>
                            <Form
                                className="http-node-additional-headers"
                                initialValues={{
                                    key: "",
                                    value: "",
                                }}
                                loading={false}
                            >
                                {additionalHeaders.length > 0 && additionalHeaders.map((row: AdditionalHeaderRow, index: number) => (
                                    <div className="additional-header-row" key={index}>
                                        {additionalHeaderValueFocused === index &&  <div className="additional-header-row-insert-variable justify-content-md-end">
                                            <div className="d-flex align-items-md-center">
                                                <AnchorButton
                                                    title={
                                                        STRINGS.TextEditor.variableTooltip
                                                    }
                                                    className={
                                                        showAdditionalHeaderVarsControl === index
                                                            ? 'variables-checked'
                                                            : ''
                                                    }
                                                    icon={IconNames.VARIABLE}
                                                    onClick={() =>
                                                        setShowAdditionalHeaderVarsControl(showAdditionalHeaderVarsControl !== index ? index : -1)
                                                    }
                                                />
                                                {showAdditionalHeaderVarsControl === index && (
                                                    <div className="ml-2">
                                                        <SelectField
                                                            name="variables"
                                                            options={additionalHeaderVariableNames}
                                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                                handleAdditionalHeaderRowInputChange(index, {
                                                                    target: {
                                                                        name: "value",
                                                                        value: `${row.value}{{variables["${event.target.value}"]}}`
                                                                    }
                                                                });
                                                                setAdditionalHeaderValueFocused(-1);
                                                            }}
                                                            disabled={
                                                                !toolbarVariables ||
                                                                toolbarVariables
                                                                    .length === 0
                                                            }
                                                            value={''}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        <div className="additional-header-row-inputs" key={index}>
                                            <div className="additional-header-input">
                                                <InputField
                                                    name="key"
                                                    type="text"
                                                    required={true}
                                                    label={index === 0 ? STRINGS.runbookEditor.nodeEditor.http.headers.headerKey : ""}
                                                    placeholder={STRINGS.runbookEditor.nodeEditor.http.headers.headerKeyPlaceholder}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleAdditionalHeaderRowInputChange(index, e)}
                                                    onFocus={() => setAdditionalHeaderValueFocused(-1)}
                                                    value={row.key}
                                                    disabled={false}
                                                    autoComplete="off"
                                                    className={additionalHeaderRowsWithError.keyIndex.includes(index) ? "additional-header-input-error" : ""}
                                                />
                                            </div>
                                            <div className="additional-header-input">
                                                <InputField
                                                    name="value"
                                                    type="text"
                                                    required={true}
                                                    label={index === 0 ? STRINGS.runbookEditor.nodeEditor.http.headers.headerValue : ""}
                                                    placeholder={STRINGS.runbookEditor.nodeEditor.http.headers.headerValuePlaceholder}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleAdditionalHeaderRowInputChange(index, e)}
                                                    onFocus={() => {
                                                        setAdditionalHeaderValueFocused(index);
                                                    }}
                                                    value={row.value}
                                                    disabled={false}
                                                    autoComplete="off"
                                                    className={additionalHeaderRowsWithError.valueIndex.includes(index) ? "additional-header-input-error" : ""}
                                                />
                                            </div>
                                            <div className="remove-additional-header">
                                                {index > 0 && <Icon 
                                                    onClick={() => {
                                                        handleRemoveAdditionalHeaderRow(index);
                                                        setAdditionalHeaderValueFocused(-1);
                                                    }}
                                                    intent={Intent.NONE}
                                                    icon={IconNames.CROSS} 
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className={"add-additional-header-control" + (hasAdditionalHeaderWithEmptyValue() ? " disabled" : "")}>
                                    <Button
                                        id="add_additional_header"
                                        minimal
                                        className="font-weight-bold"
                                        icon={IconNames.ADD}
                                        text={STRINGS.runbookEditor.nodeEditor.http.headers.addAdditionalHeader}
                                        onClick={() => {
                                            handleAddAdditionalHeaderRow();
                                            setAdditionalHeaderValueFocused(-1);
                                        }}
                                    />
                                </div>
                            </Form>
                        </>}
                        </td>
                    </tr>
                </> : null}
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <InlineHelp helpInfo={STRINGS.fieldHelpText.HttpNode.Payload}>
                        {STRINGS.runbookEditor.nodeEditor.http.payload.label}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td colSpan={2} className="pt-3 p-1">
                    <Switch
                        id="node-use-body"
                        checked={currentProperties?.request?.useBody ?
                            currentProperties.request.useBody : false}
                        label={STRINGS.runbookEditor.nodeEditor.http.payload.includePayload}
                        onChange={
                            (event) => {
                                checkAndSetRequestProperty('useBody', event.currentTarget.checked);
                            }
                        }>
                    </Switch>
                </td>
            </tr>
            {currentProperties.request?.useBody ?
                <>
                    <tr>
                        <td colSpan={2}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="display-8 font-weight-bold pt-2">{STRINGS.runbookEditor.nodeEditor.transformTemplate}</div>
                                <div className="display-7 pt-2 float-right">
                                    <Button
                                        aria-label="transform-template-button"
                                        className={Classes.MINIMAL}
                                        icon={<Icon icon="fullscreen" />}
                                        onClick={() => openTransformTemplateDialog('payload', variant)}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td data-testid="node-body-template" colSpan={2} className='p-1 fixed-textarea'>
                            <LiquidTemplateEditor
                                data-testid="node-body-template"
                                placeholder={STRINGS.runbookEditor.nodeEditor.http.payload.payloadHint}
                                value={currentProperties?.request?.bodyTemplate}
                                style={{ width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                                className="bg-white text-black mt-3"
                                variables={toolbarVariables}
                                triggerExpandedKeys={triggerKeys} triggerMetrics={triggerMetrics}
                                parentExpandedKeys={parentKeys} parentMetrics={parentMetrics}
                                onChange={value => {
                                    checkAndSetRequestProperty('bodyTemplate', value);
                                }}
                                variant={variant}
                            />                            
                        </td>
                    </tr>
                </> : null}
            <tr>
                <td className="display-7 font-weight-bold pt-2" colSpan={2}>
                    <InlineHelp helpInfo={STRINGS.fieldHelpText.HttpNode.RetryReady}>
                        {STRINGS.runbookEditor.nodeEditor.http.retry.label}
                    </InlineHelp>
                </td>
            </tr>
            <tr>
                <td colSpan={2} className="pt-3 p-1">
                        <Switch
                            id="node-retry"
                            checked={currentProperties?.request?.retry ?
                                currentProperties.request.retry : false}
                            labelElement={<span>{STRINGS.runbookEditor.nodeEditor.http.retry.retryText1}
                                <b>{STRINGS.runbookEditor.nodeEditor.http.retry.retryText2}</b><br/>
                                {STRINGS.runbookEditor.nodeEditor.http.retry.retryText3}
                            </span>}
                            onChange={
                                (event) => {
                                    checkAndSetRequestProperty('retry', event.currentTarget.checked);
                                    if (!event.currentTarget.checked) {
                                        checkAndSetRequestProperty('retryOptions', undefined);
                                    }

                                }
                            }>
                        </Switch>
                        <div className='d-flex' style={{marginLeft: "40px"}}>
                            <NumericInput
                                style={{width: "100px"}}
                                id="node-retry-value"
                                min={currentProperties?.request?.retryOptions?.timeMeasurementUnit === 0 ? 60 : 1}
                                max={currentProperties?.request?.retryOptions?.timeMeasurementUnit === 0 ? 604800 :
                                    currentProperties?.request?.retryOptions?.timeMeasurementUnit === 1 ? 10080 :
                                    currentProperties?.request?.retryOptions?.timeMeasurementUnit === 2 ? 168 :
                                    currentProperties?.request?.retryOptions?.timeMeasurementUnit === 3 ? 7 : 10080}
                                disabled={!currentProperties.request?.retry}
                                defaultValue={currentProperties?.request?.retryOptions?.totalWaitTime ? currentProperties?.request?.retryOptions?.totalWaitTime : '10'}
                                onValueChange={(valueAsNumber: number) => {
                                    checkAndSetRequestProperty('totalWaitTime', valueAsNumber, false, true);
                                    if (handleChange) {    
                                        handleChange();
                                    }
                            }}
                            />
                            <HTMLSelect
                                id="node-retry-unit"
                                disabled={!currentProperties.request?.retry}
                                onChange={(event) => {
                                    checkAndSetRequestProperty('timeMeasurementUnit', TimeMeasurementUnit[event.target.value], false, true);
                                }}
                                options={Object.keys(TimeMeasurementUnit).filter((x) => Number.isNaN(Number(x)))}
                                value={TimeMeasurementUnit[currentProperties?.request?.retryOptions?.timeMeasurementUnit !== undefined && currentProperties?.request?.retryOptions?.timeMeasurementUnit !== null ? currentProperties?.request?.retryOptions?.timeMeasurementUnit : 1] }
                            />
                        </div>
                </td>
            </tr>
            {currentProperties.request?.retry ?
                <>
                    <tr>
                        <td colSpan={2}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="display-8 font-weight-bold pt-2">{STRINGS.runbookEditor.nodeEditor.transformTemplate}</div>
                                <div className="display-7 pt-2 float-right">
                                    <Button
                                        aria-label="transform-template-button"
                                        className={Classes.MINIMAL}
                                        icon={<Icon icon="fullscreen" />}
                                        onClick={() => openTransformTemplateDialog('retry', variant)}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} data-testid="node-retry-template" className='p-1 fixed-textarea'>
                            <LiquidTemplateEditor
                                data-testid="node-retry-template"
                                placeholder={STRINGS.runbookEditor.nodeEditor.http.retry.retryHint}
                                value={currentProperties?.request?.retryOptions?.statusCheckTemplate}
                                style={{ width: "100%", height: "180px", fontFamily: "monospace", fontSize: "small", borderColor: "#999" }}
                                className="bg-white text-black mt-3"
                                variables={toolbarVariables}
                                triggerExpandedKeys={triggerKeys} triggerMetrics={triggerMetrics}
                                parentExpandedKeys={parentKeys} parentMetrics={parentMetrics}
                                onChange={value => {
                                    checkAndSetRequestProperty('statusCheckTemplate', value, false, true);
                                }}
                                variant={variant}
                            />                            
                        </td>
                    </tr>
                </> : null}
            <RunbookContextSummary
                currentProperties={JSON.parse(JSON.stringify(currentProperties))}
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef}
                showOutputExample={true} showInputExample={SHOW_CONTEXT}
            />
        </>
    )
});
