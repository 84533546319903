/** This file defines the ai node editor React component. The ai node editor allows you 
 *  to configure the instructions and query for the AI node.
 *  @module */
import React, { useContext, useMemo, useState } from "react";
import { SimpleNodeEditorProps } from "../simple/SimpleNodeEditor";
import { HELP, STRINGS } from "app-strings";
import { UniversalNode } from "../../UniversalNode";
import { VARIANTS_WITH_GLOBAL_VARS, VARIANTS_WITH_INCIDENT_VARS } from "../../types/GraphTypes";
import { Button, HTMLSelect, Intent, TextArea } from "@blueprintjs/core";
import { AiNodeUtils, AI_NODE_EDIT_PROPS } from "./AiNodeUtils";
import { SHOW_CONTEXT } from "components/enums/QueryParams";
import { RunbookContextSummary } from "../RunbookContextSummary";
import { NodeLibraryNode } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { VariableContext } from 'utils/runbooks/VariableContext';
import { VariableContextByScope } from "utils/runbooks/RunbookContext.class";
import { AI_PRIM_VAR_TYPES, GLOBAL_SCOPE, INCIDENT_SCOPE, RUNTIME_SCOPE } from "utils/runbooks/VariablesUtils";
import { InlineHelp } from "components/common/layout/inline-help/InlineHelp";
import { GenerateInstructionsDialog } from "./GenerateInstructionsDialog";
import { TestDialog } from "./TestDialog";

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface AiNodeProperties {
    /** a String with the instructions location. */
    instructionsLocation?: string;
    /** a String with the instructions content. */
    instructions?: string;
    /** a String with the query location. */
    queryLocation?: string;
    /** a String with the query content. */
    query?: string;
}

/** Component for editing the properties of a ai node
 *  @param selectedNode - Currently selected active ai node
 *  @param libraryNode- Selected ai node's meta data.
 *  @param graphDef - Graphdef object that defines the entire runbook. Provides a way to access all the nodes in the graph 
 *  @returns a JSX component with the ai node editor. */
export const AiNodeEditor = React.forwardRef(({ selectedNode, libraryNode, graphDef, ...props }: SimpleNodeEditorProps, ref: any): JSX.Element => {
    const {getVariables} = useContext(VariableContext);
    const [curProperties, setCurProperties] = useState<AiNodeProperties>({
        instructionsLocation: selectedNode?.getProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC) ? selectedNode?.getProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC) : undefined,
        instructions: selectedNode?.getProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS) ? selectedNode?.getProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS) : undefined,
        queryLocation: selectedNode?.getProperty(AI_NODE_EDIT_PROPS.QUERY_LOC) ? selectedNode?.getProperty(AI_NODE_EDIT_PROPS.QUERY_LOC) : undefined,
        query: selectedNode?.getProperty(AI_NODE_EDIT_PROPS.QUERY) ? selectedNode?.getProperty(AI_NODE_EDIT_PROPS.QUERY) : undefined,
    });

    const [isGeneratingInstr, setIsGeneratingInstr] = useState<boolean>(false);
    const [isTesting, setIsTesting] = useState<boolean>(false);

    /** Update the selected node properties. This is invoked when done button is clicked
     *      on the node editor dialog.
     *  @param properties -  Properties in selected node that need to updated
     *  @param selectedNode - node being editied
     *  @param libraryNode - object that specifies all the editable properties for a given node. */
    function updateNode(properties: AiNodeProperties, selectedNode: UniversalNode | undefined, libraryNode: NodeLibraryNode | undefined) {
        if (!selectedNode || !libraryNode || !properties) {
            console.warn("updateNode has invlaid inputs. Node update failed");
            return;
        }
        selectedNode.setProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC, curProperties?.instructionsLocation);
        selectedNode.setProperty(AI_NODE_EDIT_PROPS.INSTRUCTIONS, curProperties?.instructions);
        selectedNode.setProperty(AI_NODE_EDIT_PROPS.QUERY_LOC, curProperties?.queryLocation);
        selectedNode.setProperty(AI_NODE_EDIT_PROPS.QUERY, curProperties?.query);
    }

    ref.current = {
        updateNode: () => {
            updateNode(curProperties, selectedNode, libraryNode);
        },
        validate: () => {
            const errorMessages = new Array<string>();
            AiNodeUtils.validateNodeProperties(
                curProperties,
                errorMessages,
            );
            return errorMessages;
        }
    };

    const variant = props.variant;
    const optionsForFilterDropDown: {label: string, value: string}[] = useMemo<{label: string, value: string}[]>(() => {
        // The options need to be set only if the filter field type is a select drop-down
        const variables: VariableContextByScope = {
            runtime: getVariables(RUNTIME_SCOPE),
            incident: !VARIANTS_WITH_INCIDENT_VARS.includes(variant!)
                ? {primitiveVariables: [], structuredVariables: []} 
                : getVariables(INCIDENT_SCOPE),
            global: !VARIANTS_WITH_GLOBAL_VARS.includes(variant!) 
                ? {primitiveVariables: [], structuredVariables: []} 
                : getVariables(GLOBAL_SCOPE)
        };
        const options: any[] = [{label: "Select An Item", value: "none"}];
        for (const scope in variables) {
            const varCollection = variables[scope];
            if (varCollection.primitiveVariables) {
                for (const variable of varCollection.primitiveVariables) {
                    if (AI_PRIM_VAR_TYPES.includes(variable.type)) {
                        options.push({
                            label: variable.name, 
                            value: variable.name, 
                            //disabled: !optionsValidity[optionKey]
                        });
                    }
                }
            }
            if (varCollection.structuredVariables) {
                for (const variable of varCollection.structuredVariables) {
                    options.push({
                        label: variable.name, 
                        value: variable.name, 
                        //disabled: !optionsValidity[optionKey]
                    });
                }
            }
        }
        options.push({label: "Enter The Query Text", value: "enter"});
        return options;
    },[getVariables, variant]);

    const showInstructionsEntry: boolean = curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC] === "enter";
    const showQueryEntry: Boolean = curProperties[AI_NODE_EDIT_PROPS.QUERY_LOC] === "enter";
    
    return (
        <>
            <GenerateInstructionsDialog open={isGeneratingInstr} 
                onClose={() => setIsGeneratingInstr(false)}
            />
            <TestDialog open={isTesting} instructions={curProperties?.instructions || ""}
                query={curProperties?.query || ""} onClose={() => setIsTesting(false)}
            />
            <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>
                <InlineHelp helpMapping={HELP.RunbookNodeCategory.Ai.AiNode.Instructions}>
                    {STRINGS.runbookEditor.nodeLibrary.nodes.genai.instructionsSection.label}
                </InlineHelp>
            </td></tr>
            <tr><td className="display-8 pt-2" colSpan={2}>
                {STRINGS.runbookEditor.nodeLibrary.nodes.genai.instructionsSection.instructions}
            </td></tr>
            {/*<tr>
                <td className="p-1" colSpan={2}>
                    <label className='mb-0 pb-1'>{ "Instructions" }</label>
                    { true /@isRequired@/ ? <Icon icon={ IconNames.ASTERISK } intent={ Intent.DANGER } iconSize={ 8 }/> : null }
                    <br />
                    <HTMLSelect
                        data-testid={ "instructions-prompat" }
                        required={ true /@isRequired@/ }
                        key={ "instructions-prompt" }
                        name={ "instructions-prompt" }
                        fill={ true }
                        options={ [...optionsForFilterDropDown, {label: "From Trigger", value: "$trigger"}] }
                        value={ curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC] }
                        onChange={ e => {
                            const newInstructionsType = e.target.value;
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS_LOC]: newInstructionsType });
                        } }
                    />
                </td>
            </tr>*/}
            {showInstructionsEntry && <tr>
                <td className="p-1">
                    <TextArea
                        required={ true }
                        defaultValue={ curProperties[AI_NODE_EDIT_PROPS.INSTRUCTIONS] || ""}
                        name={ "instructions-content" }
                        growVertically={ true }
                        fill={ true }
                        placeholder={STRINGS.runbookEditor.nodeLibrary.nodes.genai.instructionsSection.placeholder}
                        style={{minHeight: "90px"}}
                        onBlur={ e => {
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS]: e.target.value });
                        } }
                    />
                    {/*<br />
                    <div className="w-100 d-flex justify-content-end pt-1">
                        <Button intent={Intent.SUCCESS} aria-label="generate" disabled={false} type="button"
                            onClick={() => setIsGeneratingInstr(true)}
                        >
                            { STRINGS.runbookEditor.nodeLibrary.nodes.genai.instructionsSection.generateBtn }
                        </Button>
                    </div>*/}
                </td>
            </tr>}
            <tr><td className="display-7 font-weight-bold pt-2" colSpan={2}>
                <InlineHelp helpMapping={HELP.RunbookNodeCategory.Ai.AiNode.Query}>
                    {STRINGS.runbookEditor.nodeLibrary.nodes.genai.querySection.label}
                </InlineHelp>
            </td></tr>
            <tr><td className="display-8 pt-2" colSpan={2}>
                {STRINGS.runbookEditor.nodeLibrary.nodes.genai.querySection.instructions}
            </td></tr>
            <tr>
                <td className="p-1" colSpan={2}>
                    {/*<label className='mb-0 pb-1'>{ "Query" }</label>*/}
                    { /*isRequired ? <Icon icon={ IconNames.ASTERISK } intent={ Intent.DANGER } iconSize={ 8 }/> : null*/ }
                    <br />
                    <HTMLSelect
                        data-testid={ "query-prompt" }
                        required={ true /*isRequired*/ }
                        key={ "query-prompt" }
                        name={ "query-prompt" }
                        fill={ true }
                        options={ [...optionsForFilterDropDown, {label: "From Parent Node", value: "$parent"}, {label: "From Trigger", value: "$trigger"}] }
                        value={ curProperties[AI_NODE_EDIT_PROPS.QUERY_LOC] }
                        onChange={ e => {
                            const newQueryType = e.target.value;
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.QUERY_LOC]: newQueryType });
                        } }
                    />
                </td>
            </tr>
            {showQueryEntry && <tr>
                <td className="p-1">
                    <TextArea
                        required={ true }
                        defaultValue={ curProperties[AI_NODE_EDIT_PROPS.QUERY] || ""}
                        name={ "query-content" }
                        growVertically={ true }
                        fill={ true }
                        placeholder={STRINGS.runbookEditor.nodeLibrary.nodes.genai.querySection.placeholder}
                        style={{minHeight: "90px"}}
                        onBlur={ e => {
                            setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.QUERY]: e.target.value });
                        } }
                    />
                    <br />
                    <div className="w-100 d-flex justify-content-end pt-1">
                        <Button intent={Intent.SUCCESS} aria-label="test" disabled={false} type="button"
                            onClick={() => setIsTesting(true)}
                        >
                            { STRINGS.runbookEditor.nodeLibrary.nodes.genai.querySection.testBtn }
                        </Button>
                    </div>
                </td>
            </tr>}
            <RunbookContextSummary 
                currentProperties={JSON.parse(JSON.stringify(curProperties))} 
                node={graphDef.nodes.find(node => node.id === selectedNode?.getId())!} graphDef={graphDef} 
                showOutputExample={true} showInputExample={SHOW_CONTEXT}
            />
        </>
    )
});
