/** This module contains the SchedulerApiService that can be used to query runbook data
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';
import { InputType, Variant } from 'components/common/graph/types/GraphTypes';
import { VariableCollection } from 'utils/runbooks/VariablesUtils';

/** the latest version of the runbook orchestrator service. */
const VERSION = "1.0";

// The URL for the API server.
export const SCHEDULER_URL = '/api/scheduler/';

/** this type defines a node red environment setting. */
export type RunbookEnvSetting = {
    /** a String with the name for the environment setting. */
    name: string;
    /** a String with the type of the environment setting. */
    type: string;
    /** the value of the environment setting. */
    value?: any;
};

/** this type defines a RunbookNode. */
export type RunbookNode = {
    /** a String with the id of the node. */
    id: string;
    /** a String with the type of the node. */
    type: string;
    /** a String with the label for the node. */
    label?: string;
    /** a String with the name for the node instance. */
    name?: string;
    /** a boolean value true if disabled, false otherwise. */
    disabled?: boolean;
    /** a String with the node description. */
    info?: string;
    /** the x-coordinate of the node. */
    x?: string | number;
    /** the y-coordinate of the node. */
    y?: string | number;
    /** the id of the runbook the node is in. */
    z?: string;
    /** the nodes this node's outputs are connected to. */
    wires?: any;
    /** a string with the name of the category. This is used in subflows. */
    category?: string;
    /** a string with the hex color for the node. This is used in subflows. */
    color?: string;
    /** an array of node red environment settings. */
    env?: Array<RunbookEnvSetting>;
    /** an array with the input settings.  */
    in?: Array<any>;
    /** an array with the input labels. */
    inputLabels?: Array<string>;
    /** an array with the output settings.  */
    out?: Array<any>;
    /** an array with the output labels.  */
    outputLabels?: Array<string>;
    /** a catch-all for any additional properies of the node. */
    [anyOtherKey: string]: any;
};

/** this type defines the information about the runbook versions. */
export type RunbookVersionInfo = {
    /** a String with the id of the runbook. */
    id?: string;
    /** a String with the label for the runbook.  This is set in the azure function back-end. */
    name?: string;
    /** a String with the runbook description.  This is set in the azure function back-end. */
    description?: string;
    /** a String with the version. */
    version?: string;
}

/** this type defines a RunbookConfig. */
export type RunbookConfig = {
    /** a String with the id of the runbook. */
    id?: string;
    /** a String with the type of the runbook. */
    type?: string;
    /** a String with the label for the runbook.  This is used in NodeRed. */
    label?: string;
    /** a String with the label for the runbook.  This is set in the azure function back-end. */
    name?: string;
    /** a boolean value true if disabled, false otherwise.  This is set in NodeRed. */
    disabled?: boolean;
    /** a boolean value true if iaReady, false otherwise.  This is set in azure function back-end. */
    isReady?: boolean;
    /** a String with the runbook description.  This is set in NodeRed. */
    into?: string;
    /** a String with the runbook description.  This is set in the azure function back-end. */
    description?: string;
    /**  the type of input the runbook will accept, for Alpha this can be interface, device, or application. */
    triggerType?: InputType;
    /** the variant of runbook, "incident" or "lifecycle". */
    variant?: Variant;
    /** a string with the last update time in seconds. */
    lastUpdate?: string;
    /** a boolean value that should be true if the runbook has been validated, and false otherwise. */
    isValidated?: boolean;
    /** an array of nodes in the runbook. */
    nodes?: Array<RunbookNode>;
    /** the array of configs in the runbook. */
    configs?: Array<any>;
    /** the array of subflows in the runbook. */
    subflows?: Array<any>;
    /** the etag is placed in the object when the API is returning an array of items. */
    eTag?: string;
    /** runtime variables definition. */
    runtimeVariables?: VariableCollection;
    /** subflow variables definitions. */
    subflowVariables?: VariableCollection;
    /** if the runbook is scheduled */
    isScheduled?: boolean;
    /** the current version, potentially after user changes. */
    version?: string;
    /** the series id that identifies all the versions of a particular subflow. */
    seriesId?: string;
    /** a structure with all the other versions of this particular runbook or subflow. */
    otherVersions?: RunbookVersionInfo[];
    
    /** a catch-all for any additional properies of the runbook. */
    [anyOtherKey: string]: any;
};

/** we have two runbook services, this service and node red, this interface defines the common
 *  functions that the two services implement. */
export interface RunbookServiceIfc {
    /** saves a new runbook.
     *  @param runbook the new runbook to be saved.
     *  @returns a Promise which resolves to the newly created runbook.*/
    saveSchedule: (runbook: RunbookConfig) => Promise<RunbookConfig>;
}

/** this class defines the SchedulerApiService. */
class SchedulerApiService extends ApiService implements RunbookServiceIfc {
    /** the constructor for the class. */
    constructor() {
        const baseUri = SCHEDULER_URL;
        super(baseUri);
    }
    
    protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/scheduler/`;    
        } else {
            return this.baseApiUri;
        }
    }

    saveSchedule(payload: RunbookConfig): Promise<RunbookConfig> {
        return new Promise((resolve, reject) => {
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.post<RunbookConfig>(`${VERSION}/tenants/${tenantId}/schedules`, payload).then((response) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }  

    updateSchedule(payload: RunbookConfig): Promise<RunbookConfig> {
        return new Promise((resolve, reject) => {
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.put<RunbookConfig>(`${VERSION}/tenants/${tenantId}/schedules`, payload).then((response) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }  

    getSchedules(): any {
        return new Promise((resolve, reject) => {
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.get(`${VERSION}/tenants/${tenantId}/schedules`).then((response) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }

    deleteSchedule(scheduleName): any {
        return new Promise((resolve, reject) => {
            const tenantId = ApiService.AUTH_SERVICE.getTenantId();
            super.delete(`${VERSION}/tenants/${tenantId}/schedules/${scheduleName}`).then((response) => {
                resolve(response);
            }, err => {
                reject(err);
                console.error(err);
            });
        });
    }

    getTenantId(): string {
        return ApiService.AUTH_SERVICE.getTenantId();
    }
}

const SchedulerService = new SchedulerApiService();
export { SchedulerService };
