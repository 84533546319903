import React, { useEffect, useRef, useState } from "react";
import { LangEN, STRINGS } from "app-strings";
import { ErrorToaster, LoadingOverlay, Modal, SuccessToaster, Table, useStateSafePromise } from "@tir-ui/react-components";
import { Button, Callout, Intent, SpinnerSize, Tag } from "@blueprintjs/core";
import DOMPurify from "dompurify";
import { sortBy, uniqBy } from 'lodash';
import { useHistory } from "react-router-dom";
import { getURL } from "utils/hooks/useQueryParams";
import { getURLPath } from "config";
import { PARAM_NAME } from "components/enums/QueryParams";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { NodeLibrary, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import { runbookService, subflowNodes } from "utils/runbooks/RunbookUtils";
import { IntegrationLibraryService } from "utils/services/IntegrationLibraryApiService";
import IncidentRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library.json";
import LifecycleRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/lifecycle_node_library.json";
import SubflowRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/subflow_node_library.json";
import ExternalRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library_external.json";
import OnDemandRunbookNodeLibrary from "pages/create-runbook/views/create-runbook/on_demand_node_library.json";
import { InputType, Variant } from "components/common/graph/types/GraphTypes";
import { AvailableIntegration, InstalledIntegration } from "pages/integrations/types/IntegrationTypes";
import { RunbookDependency, RunbookDependencyStatus } from "utils/runbooks/RunbookTypes";
import { DEFAULT_TAB_URL_STATE_KEY } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import ImportRunbookConnectorSelector from "./ImportRunbookConnectorSelector";
import { RunbookConfig, RunbookService } from "utils/services/RunbookApiService";
import { Method } from "components/common/graph/editors/subflow/SubflowVariableMappingEditor";
import './ImportRunbookModal.scss';

export type ImportRunbookModalActions = {
    handleOpen: () => void;
    setVariant: (variant: Variant) => void;
};

interface Props {
    ref: React.MutableRefObject<any>,
    refreshData: () => void;
}

type ImportProcessState = 'compatible' | 'warning' | 'error';
type IntegrationFromDependencies = {
    name: string,
    description: string,
    status: RunbookDependencyStatus,
    order: number
}

export const ImportRunbookModal = React.forwardRef((props: Props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const translations: LangEN = STRINGS;
    const [dialogState, setDialogState] = useState({
        showDialog: false,
        title: "My Dialog",
        loading: false,
        dialogContent: <></>,
        dialogFooter: <></>,
    });
    const [loading, setLoading] = useState(false);
    const [dialogClassName, setDialogClassName] = useState("");
    const [importProcessState, setImportProcessState] = useState<ImportProcessState>('compatible');
    const [pageVariant, setPageVariant] = useState<Variant>(Variant.INCIDENT);
    const [dependencies, setDependencies] = useState<RunbookDependency[]>([]);
    const dependencyConnectorMap = useRef<any>({});
    const [runbookForExport, setRunbookForExport] = useState<any>({});
    const runbookContainsCustomSubflows = dependencies.find(el => el.sourceLocation === 'custom');
    const [errors, setErrors] = useState<Array<any>>([]);
    const availableIntegrationsCache = useRef<AvailableIntegration[] | undefined>();
    const [availableIntegrations, setAvailableIntegrations] = useState<AvailableIntegration[] | undefined>(undefined);
    const installedIntegrationsCache = useRef<InstalledIntegration[] | undefined>();
    const [installedIntegrations, setInstalledIntegrations] = useState<InstalledIntegration[] | undefined>(undefined);
    const [isRunbookFileImported, setIsRunbookFileImported] = useState(false);
    const [isProceedWithImportDisabled, setIsProceedWithImportDisabled] = useState(false);
    const STATUSES_ORDER = {
        unknown: 2,
        invalid: 0,
        missing: 1,
        compatible: 7,
        manualInterventionRequired: 5,
        updateRecommended: 6,
        updateRequired: 4,
        installationRequired: 3,
        default: 8
    }
    const [executeSafely] = useStateSafePromise();
    const history = useHistory();

    // Get Installed Integrations List
    useEffect(() => {
        const integrationsPromise = new Promise<InstalledIntegration[]>(
            async (resolve, reject) => {
                try {
                    const newIntegrations =
                        await IntegrationLibraryService.getInstalledIntegrations();
                    resolve(newIntegrations);
                } catch (error) {
                    reject(error);
                }
            }
        );
        executeSafely(integrationsPromise).then(
            (integrations) => {
                installedIntegrationsCache.current = integrations;
                setInstalledIntegrations(integrations);
            },
            () => {
                installedIntegrationsCache.current = installedIntegrations;
                setInstalledIntegrations([]);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get Available Integrations List
    useEffect(() => {
        const integrationsPromise = new Promise<AvailableIntegration[]>(
            async (resolve, reject) => {
                try {
                    const newIntegrations =
                        await IntegrationLibraryService.getAvailableIntegrations();
                    resolve(newIntegrations);
                } catch (error) {
                    reject(error);
                }
            }
        );
        executeSafely(integrationsPromise).then(
            (integrations) => {
                availableIntegrationsCache.current = integrations;
                setAvailableIntegrations(integrations);
            },
            () => {
                availableIntegrationsCache.current = availableIntegrations;
                setAvailableIntegrations([]);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    /**
     * Actions to control the Connector Modal from outside
     */
    React.useImperativeHandle(ref, () => ({
        handleOpen() {
            setIsOpen(!isOpen);
        },
        setVariant(variant: Variant) {
            setPageVariant(variant);
        },
    }));


    /**
     * Get the list of integrations that are used by the dependencies (subflows)
     * 
     * @param dependencies 
     * 
     * @returns {IntegrationFromDependencies[]} integrations list from dependencies
     */
    function getIntegrationsListFromDependencies(dependencies: RunbookDependency[]): IntegrationFromDependencies[] {
        const integrationsUsedByRunbook = dependencies.map((dependency) => {
            const integrationDetails = availableIntegrations?.find(
                (el) => el.id === dependency.sourcePackageId
            );
            const isInstalled = installedIntegrations?.find(el => el.integrationId === dependency.sourcePackageId);

            // The integration is missing from the tenant (not available)
            if (!integrationDetails) {
                return {
                    name: translations.runbooks.importModal.integrationStatus.missing.text,
                    description: dependency.nodeLabel,
                    status: 'missing' as RunbookDependencyStatus,
                    order: STATUSES_ORDER.missing
                };
            }

            // The integrations is not yet installed on the tenant
            if (!isInstalled) {
                return {
                    name: integrationDetails.name,
                    description: integrationDetails?.description.short,
                    status: 'installationRequired' as RunbookDependencyStatus,
                    order: STATUSES_ORDER.installationRequired
                };
            }

            // The integrations is installed on the tenant
            const integrationStatus: RunbookDependencyStatus = dependency.status === 'installationRequired' ? 'updateRequired' : dependency.status;

            return {
                name: integrationDetails?.name || dependency.name,
                description: integrationDetails?.description.short,
                status: integrationStatus,
                order: STATUSES_ORDER[integrationStatus]
            };
        });

        return integrationsUsedByRunbook.sort((a, b) => (a.order - b.order));
    }

    /**
     * Render the Modal Content
     *
     * @returns {JSX.Element}
     */
    function renderModalContent() {
        const integrationsUsedByRunbook = getIntegrationsListFromDependencies(dependencies,);

        return (
            <div className="import-runbook-modal">
                <>
                    <LoadingOverlay
                        visible={loading}
                        loadingText={translations.runbooks.importModal.loading}
                    />
                    {!isRunbookFileImported && (
                        <>
                            <div className="mb-3">
                                <span>
                                    {STRINGS.formatString(
                                        translations.runbooks.importDialog.text,
                                        { variant: translations.runbooks.runbookTextForVariant[pageVariant] }
                                    )}
                                </span>
                            </div>
                            <input id="runbook-file" type="file" data-testid="input_uploadFile" />
                        </>
                    )}

                    {isRunbookFileImported && (
                        <>
                            {renderCalloutMessage(integrationsUsedByRunbook, dependencies)}
                            {renderIntegrationsTable(integrationsUsedByRunbook)}
                            {renderSubflowsTable(dependencies)}
                        </>
                    )}

                    {errors?.length > 0 && (
                        <Callout
                            className="callout-message mt-2"
                            intent={Intent.DANGER}
                        >
                            {errors.map((error, index) => (
                                <p key={index}>{error}</p>
                            ))}
                        </Callout>
                    )}
                </>
            </div>
        );
    }

    /**
     * Render the table with the subflow nodes that the runbook depends on
     * 
     * @param dependencies - the list of runbook dependencies
     * 
     * @returns {JSX.Element}
     */
    function renderIntegrationsTable(integrations: IntegrationFromDependencies[]) {
        function getRowControl(
            status: RunbookDependencyStatus,
            searchTerm: string
        ) {
            switch (status) {
                case "unknown":
                    return <></>;
                case "invalid":
                    return <></>;
                case "missing":
                    return <></>;
                case "compatible":
                    return <></>;
                case "manualInterventionRequired":
                    return <></>;
                case "updateRecommended":
                    return (
                        <>
                            <Button
                                className="ml-2"
                                intent={Intent.WARNING}
                                onClick={() => {
                                    history.push(
                                        getURL(getURLPath("integrations"), {
                                            [PARAM_NAME.searchText]: searchTerm,
                                            [DEFAULT_TAB_URL_STATE_KEY]: 'installed'
                                        })
                                    );
                                }}
                            >
                                {translations.runbooks.importModal.integrationStatus.updateRecommended.btn}
                            </Button>
                        </>
                    );
                case "updateRequired":
                    return (
                        <>
                            <Button
                                className="ml-2"
                                intent={Intent.DANGER}
                                onClick={() => {
                                    history.push(getURL(getURLPath("integrations"), {
                                        [PARAM_NAME.searchText]: searchTerm,
                                        [DEFAULT_TAB_URL_STATE_KEY]: 'installed'
                                    }));
                                }}
                            >
                                {translations.runbooks.importModal.integrationStatus.updateRequired.btn}
                            </Button>
                        </>
                    );

                case "installationRequired":
                    return (
                        <>
                            <Button
                                className="ml-2"
                                intent={Intent.DANGER}
                                onClick={() => {
                                    history.push(
                                        getURL(getURLPath("integrations"), {
                                            [PARAM_NAME.searchText]: searchTerm,
                                            [DEFAULT_TAB_URL_STATE_KEY]: 'available'
                                        })
                                    );
                                }}
                            >
                                {translations.runbooks.importModal.integrationStatus.installationRequired.btn}
                            </Button>
                        </>
                    );
            }
        }

        function renderStatusTag(intent, label) {
            return (
                <Tag minimal intent={intent}>
                    {label}
                </Tag>
            );
        }

        const STATUS_NEXT_STEP_MAP = {
            unknown: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.unknown.text
            ),
            invalid: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.invalid.text
            ),
            missing: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.missing.text
            ),
            compatible: <></>,
            manualInterventionRequired: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.manualInterventionRequired.text
            ),
            updateRecommended: renderStatusTag(
                Intent.WARNING,
                translations.runbooks.importModal.integrationStatus.updateRecommended.text
            ),
            updateRequired: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.updateRequired.text
            ),
            installationRequired: renderStatusTag(
                Intent.DANGER,
                translations.runbooks.importModal.integrationStatus.installationRequired.text
            ),
        };

        const columns = [
            {
                id: "name",
                Header: translations.runbooks.importModal.table.integrations.columns.name,
                accessor: "name",
                sortable: false,
                showFilter: false,
            },
            {
                id: "description",
                Header: translations.runbooks.importModal.table.integrations.columns.description,
                accessor: "description",
                sortable: false,
                showFilter: false,
            },
            {
                id: "nextStep",
                Header: translations.runbooks.importModal.table.integrations.columns.nextStep,
                sortable: false,
                showFilter: false,
            },
            {
                id: "action",
                Header: "",
                sortable: false,
                showFilter: false,
            },
        ];
        const hasMissingDependencies = dependencies.find(el => ['unknown', 'missing'].includes(el.status));
        const shouldHideTable = hasMissingDependencies || runbookContainsCustomSubflows;

        if (shouldHideTable) {
            return <></>
        }

        // We need to show only integrations that are not compatible in this table
        const preparedIntegrationsList = uniqBy(integrations, "name").filter(el => el.status !== 'compatible').map(el => {
            return {
                name: el.name,
                description: el.description,
                nextStep: STATUS_NEXT_STEP_MAP[el.status],
                action: getRowControl(el.status, el.name)
            }
        });
    
        if (!preparedIntegrationsList || preparedIntegrationsList.length === 0) {
            return <></>;
        }

        return (
            <Table
                className="mt-2"
                columns={columns}
                data={preparedIntegrationsList as Array<any>}
                interactive={false}
            />
        );
    }

    /**
     * Render the table with the subflow nodes that the runbook depends on
     * 
     * @param dependencies - the list of runbook dependencies
     * 
     * @returns {JSX.Element}
     */
    function renderSubflowsTable(dependencies: any) {
        const columns = [
            {
                id: "name",
                Header: translations.runbooks.importModal.table.subflows.columns.name,
                accessor: "name",
                sortable: false,
                showFilter: false,
            },
            {
                id: "nodeLabel",
                Header: translations.runbooks.importModal.table.subflows.columns.nodeName,
                accessor: "nodeLabel",
                sortable: false,
                showFilter: false,
            },
            {
                id: "connector",
                Header: translations.runbooks.importModal.table.subflows.columns.connectorName,
                formatter: (subflow) => {
                    if (!installedIntegrations) {
                        return <></>
                    }

                    const selectedConnector = dependencyConnectorMap.current[subflow.id];

                    return <ImportRunbookConnectorSelector
                        integrations={installedIntegrations}
                        integrationId={subflow?.sourcePackageId}
                        subflowId={subflow.id}
                        requiresProfile={subflow?.sourceLocation === 'custom'}
                        connectorId={selectedConnector}
                        handleConnectorChange={handleConnectorChange}
                    />
                }
            }
        ];

        const hasErrors = dependencies.find(el => ['installationRequired', 'updateRequired', 'invalid', 'unknown', 'missing'].includes(el.status));

        // If there is an error we don't show the subflow list
        if (hasErrors || runbookContainsCustomSubflows) {
            return <></>
        }

        return (
            <>
                <p className="mt-5">
                    {translations.runbooks.importModal.table.subflows.header}
                </p>
                <Table
                    columns={columns}
                    data={dependencies}
                    interactive={false}
                />
            </>
        );
    }

    /**
     * Handle connector selection
     * 
     * @param connectorId 
     * @param ev
     */
    function handleConnectorChange(connectorId: string, ev: any) {
        ev.persist()

        if (!ev?.target?.value) {
            return;
        }

        dependencyConnectorMap.current[connectorId] = ev?.currentTarget?.value;
        validateProceedWithImport(dependencyConnectorMap.current);
    }

    /**
     * Render the messages on top of the modal which inform the user about the import state
     * 
     * @param integrations - the list of integrations used by the runbook dependencies
     * @param dependencies - the list of runbook dependencies
     * 
     * @returns {JSX.Element}
     */
    function renderCalloutMessage(integrationsUsedByRunbook: IntegrationFromDependencies[], dependencies: RunbookDependency[]) {
        if (integrationsUsedByRunbook?.length === 0) {
            return <></>;
        }

        // Filter out duplicate messages, and sort them
        const statuses = (uniqBy(dependencies, "status").map(el => el.status));
        const hasWarningsOrErrors = !!(statuses.find(el => el !== 'compatible'));
        const STATUS_INTENT_MAP = {
            unknown: Intent.DANGER,
            invalid: Intent.DANGER,
            missing: Intent.DANGER,
            compatible: Intent.SUCCESS,
            manualInterventionRequired: Intent.WARNING,
            updateRecommended: Intent.WARNING,
            updateRequired: Intent.DANGER,
            installationRequired: Intent.DANGER,
        };

        // Show the list of dependencies that are missing, unknown or invalid;
        const PROBLEMATIC_STATUSES: Array<keyof typeof STATUS_INTENT_MAP> = ['missing', 'unknown', 'invalid'];
        const problematicDependencies = dependencies.filter(el => PROBLEMATIC_STATUSES.includes(el.status));

        // Show warning if custom subflows are included
        if (runbookContainsCustomSubflows) {
            //setIsProceedWithImportDisabled(false);

            return <Callout
                key={0}
                intent={Intent.WARNING}
                className="py-4"
                icon={null}
            >
                <div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                translations.runbooks.importModal.calloutMessages.customSubflow
                            ),
                        }}
                    ></div>
                    <p className="mt-4">{translations.runbooks.importModal.calloutMessages.problematicSubflows}</p>
                    <ul>
                        {dependencies.map(dependency =>
                            <li key={`${dependency.id} - ${dependency.originalVersion}`}>
                                {`${dependency.nodeLabel} (v${dependency.originalVersion})`}
                            </li>
                        )}
                    </ul>
                </div>
            </Callout>
        }

        if (problematicDependencies.length > 0) {
            return (
                <Callout
                    key={0}
                    intent={Intent.DANGER}
                    className="py-4"
                    icon={null}
                >
                    {renderProblematicDependencies(problematicDependencies)}
                </Callout>
            )
        }

        return (
            <div className="d-flex flex-column" style={{ gap: "8px" }}>
                {statuses.map((status, index) => {
                    // Hide compatible callout message if there are errors or warnings
                    if (hasWarningsOrErrors && status === 'compatible') {
                        return <></>;
                    }

                    return (
                        <Callout
                            key={index}
                            intent={STATUS_INTENT_MAP[status]}
                            className="py-4"
                            icon={null}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        translations.runbooks.importModal
                                            .calloutMessages[status]
                                    ),
                                }}
                            />
                        </Callout>
                    );
                })}
            </div>
        );
    }

    /**
     * Render the list of dependencies that have a problematic status
     * 
     * @param dependencies 
     * 
     * @returns {JSX.Element}
     */
    function renderProblematicDependencies(dependencies: RunbookDependency[]) {
        return (
            <div>
                <p dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        translations.runbooks.importModal
                            .calloutMessages.missing
                    ),
                }} />
                <p>{translations.runbooks.importModal.calloutMessages.problematicSubflows}</p>
                <ul>
                    {dependencies.map(dependency =>
                        <li key={`${dependency.id} - ${dependency.originalVersion}`}>
                            {`${dependency.nodeLabel} (v${dependency.originalVersion})`}
                        </li>
                    )}
                </ul>
            </div>
        )
    }
    /**
     * Handles closing of the modal
     */
    function handleCloseModal() {
        setErrors([]);
        setIsRunbookFileImported(false);
        setDependencies([]);
        dependencyConnectorMap.current = {};
        setIsOpen(false);
        setImportProcessState('compatible');
    }

    /**
     * Check if the proceed with import button should be disabled
     * @param {any} dependencyConnectorMap - dependency id -connector id map
     * @returns {Boolean}
     */
    function validateProceedWithImport(dependencyConnectorMap) {
        const hasUnsetConnectors = Object.values(dependencyConnectorMap).some(el => typeof el === 'undefined');

        setIsProceedWithImportDisabled(hasUnsetConnectors);
    }

    /**
     * Get an array containing the buttons that are going to be shown in the Modal Footer
     *
     * @returns {JSX.Element}
     */
    function getModalButtons() {
        const buttons: Array<any> = [];

        if (importProcessState === 'compatible') {
            buttons.push({
                action: isRunbookFileImported ? () => processImport(runbookForExport) : handleFileImport,
                label: isRunbookFileImported ? translations.runbooks.importModal.buttons.importBtn : translations.runbooks.importModal.buttons.readFileBtn,
                intent: Intent.PRIMARY,
                disabled: isRunbookFileImported && isProceedWithImportDisabled
            })
        }

        if (importProcessState === 'warning') {
            buttons.push({
                action: isRunbookFileImported ? () => processImport(runbookForExport) : handleFileImport,
                label: isRunbookFileImported ? translations.runbooks.importModal.buttons.importAnywayBtn : translations.runbooks.importModal.buttons.readFileBtn,
                intent: Intent.PRIMARY,
                disabled: isRunbookFileImported && isProceedWithImportDisabled
            })
        }

        return buttons;
    }

    /**
     * Handle the import of the file
     *
     * @returns
     */
    async function handleFileImport() {
        const fileList = (document.getElementById("runbook-file") as any).files;
        setErrors([]);
        setDependencies([])
        setRunbookForExport({});
        setIsRunbookFileImported(false);

        if (fileList && fileList?.length === 1) {
            const fileReader = new FileReader();
            fileReader.onload = async function () {
                const text: string = fileReader.result as string;

                if (text && text.length > 0) {
                    let fileErrors: Array<string> = [];

                    try {
                        const parsedText = JSON.parse(text);
                        let runbookExportFormat = parsedText?.data || parsedText;

                        // Check if the format is correct
                        if (!runbookExportFormat?.runbook || !runbookExportFormat?.dependencies) {
                            fileErrors.push(translations.runbooks.importDialog.wrongExportFormat);

                            return;
                        }

                        setLoading(true);

                        // Transform the Incident Runbook Webhook to External Runbook variant 
                        const isWebhookRunbook = runbookExportFormat?.runbook?.triggerType === InputType.WEBHOOK;
                        const importedRunbookVariant: Variant = runbookExportFormat?.runbook?.variant || '';
                        const effectiveVariant: Variant = isWebhookRunbook ? Variant.EXTERNAL : importedRunbookVariant;

                        // Make sure name is unique
                        let runbookName = await getUniqueName(runbookExportFormat, pageVariant);
                        runbookExportFormat = { ...runbookExportFormat, runbook: { ...runbookExportFormat?.runbook, name: runbookName, } }

                        setRunbookForExport(runbookExportFormat);

                        // Check the node types, do not allow someone to copy in a node type from another runbook
                        fileErrors.concat(getNodesErrors(effectiveVariant, runbookExportFormat?.runbook));
                        const hasMatchingVariant = effectiveVariant.toLowerCase() === pageVariant.toLowerCase();

                        if (!hasMatchingVariant) {
                            fileErrors.push(
                                STRINGS.formatString(
                                    translations.runbooks.importDialog.wrongVariantErrorText,
                                    {
                                        variant: translations.runbooks.runbookTextForVariant[pageVariant],
                                    }
                                ),
                            );

                            return;
                        }

                        // We can proceed with the import process
                        if (fileErrors.length === 0) {
                            const result =
                                await runbookService.checkImportRunbookDependencies(
                                    runbookExportFormat
                                );

                            const uniqueDependencies = uniqBy(result?.dependencies as RunbookDependency[], 'globalId');
                            const dependenciesList = sortBy(uniqueDependencies, 'name')
                                // Filter out same tenant dependencies
                                .filter((el) => {
                                    const isSameDependency = el.resolvedId === el.id && el.originalVersion === el.resolvedVersion;
                                    
                                    return !isSameDependency;
                                }
                            );

                            // If there are no dependencies, we can proceed with the actual import
                            if (dependenciesList.length === 0) {
                                processImport(runbookExportFormat);

                                return;
                            }

                            setDependencies(dependenciesList);
                            dependenciesList.forEach(el => {
                                dependencyConnectorMap.current[el.id] = undefined;
                            })
                            setProcessStatus(result);
                            setIsRunbookFileImported(true);
                        }
                    } catch (error) {
                        fileErrors.concat(
                            getErrorsFromResponse(
                                error,
                                STRINGS.formatString(
                                    translations.runbooks.importDialog.errorText,
                                    {
                                        variant: translations.runbooks.runbookTextForVariant[pageVariant],
                                    }
                                ) +
                                "<br/>" +
                                error
                            )
                        );
                        console.error(error);
                    } finally {
                        if (fileErrors.length > 0) {
                            setErrors(fileErrors);
                        }
                        setLoading(false);
                    }
                }
            };
            fileReader.readAsText(fileList[0]);
        }
    }

    /**
     * Get the list of errors related to runbook nodes
     * 
     * @param variant Runbook Variant
     * @param runbook Imported Runbook JSON Content
     */
    function getNodesErrors(variant: Variant, runbook: RunbookConfig) {
        let errors: Array<string> = [];

        if (runbook?.nodes?.length) {
            const nodeTypes: string[] = getNodeTypes(variant);

            if (variant !== Variant.SUBFLOW) {
                nodeTypes.push(...subflowNodes);
            }

            const unsupportedTypes: string[] = [];
            for (const node of runbook.nodes) {
                if (!nodeTypes.includes(node.type)) {
                    if (!unsupportedTypes.includes(node.type)) {
                        unsupportedTypes.push(node.type);
                    }
                }
            }

            if (unsupportedTypes?.length) {
                errors.push(
                    STRINGS.formatString(
                        translations.runbooks.importDialog.invalidTypeErrorText,
                        {
                            variant: translations.runbooks.runbookTextForVariant[variant],
                            types: unsupportedTypes.join(", "),
                        }
                    )
                );
            }
        }

        return errors;
    }

    async function getUniqueName(runbookExportFormat: any, variant: Variant) {
        let runbookName = runbookExportFormat?.runbook?.name || '';
        // Get the current list of runbook names
        const usedNames: Array<string> = [];
        const runbooks = await runbookService.getRunbooks(variant === Variant.EXTERNAL ? Variant.INCIDENT : variant);
        for (const savedRunbook of runbooks) {
            if (savedRunbook.name) {
                usedNames.push(savedRunbook.name as string);
            }
            if (savedRunbook.otherVersions?.length) {
                for (const versionInfo of savedRunbook.otherVersions) {
                    if (versionInfo.name) {
                        usedNames.push(versionInfo.name as string);
                    }
                }
            }
        }

        // Make sure the name is unique
        const nameRoot = runbookName;
        let nameIndex = 1;
        while (usedNames.includes(runbookName)) {
            runbookName = nameRoot + ' ' + nameIndex++;
        }
        return runbookName;
    }

    /**
     * Set the process state (in error / with warnings / compatible) for the import
     * 
     * @param result - the import/export object returned by the endpoint
     * 
     * @returns void
     */
    function setProcessStatus(result: any) {
        // Check for errors
        const dependenciesWithErrors = result?.dependencies.filter(el => ['installationRequired', 'updateRequired', 'invalid', 'unknown'].includes(el.status));
        if (dependenciesWithErrors.length > 0) {
            setImportProcessState('error');

            return;
        }

        // Check for warnings
        const dependenciesWithWarnings = result?.dependencies.filter(el => ['missing', 'updateRecommended', 'manualInterventionRequired'].includes(el.status));
        if (dependenciesWithWarnings.length > 0) {
            setImportProcessState('warning');
            return
        }

        setImportProcessState('compatible');
    }

    /**
     * Perform the runbook import
     * 
     * @param {object} runbook - the JSON payload for the import/export object
     */
    async function processImport(runbook) {
        const payload = getPayload(runbook);

        try {
            await RunbookService.importRunbook(payload);
            SuccessToaster({ message: translations.runbooks.importModal.toastMessage.success })
            props?.refreshData();
        } catch (error) {
            console.error(error);
            ErrorToaster({ message: translations.runbooks.importModal.toastMessage.error })
        } finally {
            handleCloseModal();
        }
    }

    /** extract any error
     *  @param error the error object from the catch.
     *  @param defaultError the default error to return if there is no known error code in the response.
     *  @returns a String array with any errors parsed from the response object, or the default error message. */
    function getErrorsFromResponse(error: any, defaultError): string[] {
        let errors: Array<string> = [];
        const errorMsg = error?.response?.data?.code
            ? STRINGS.formatString(
                translations.runbookEditor.errors.codes[
                error?.response?.data?.code
                ],
                error?.response?.data?.innererror || {}
            )
            : defaultError;
        errors.push(errorMsg);
        return errors;
    }

    /**
     * Get Node Library
     * 
     * @param variant Runbook Variant
     * 
     * @returns Array containing the node library for each runbook type
     */
    function getNodeTypes(variant: Variant) {
        let nodeLibrary: NodeLibrary;

        switch (variant) {
            case Variant.INCIDENT:
                nodeLibrary = new NodeLibrary(IncidentRunbookNodeLibrary as NodeLibrarySpec);
                break;
            case Variant.LIFECYCLE:
                nodeLibrary = new NodeLibrary(LifecycleRunbookNodeLibrary as NodeLibrarySpec);
                break;
            case Variant.SUBFLOW:
                nodeLibrary = new NodeLibrary(SubflowRunbookNodeLibrary as NodeLibrarySpec);
                break;
            case Variant.ON_DEMAND:
                nodeLibrary = new NodeLibrary(OnDemandRunbookNodeLibrary as NodeLibrarySpec);
                break;
            case Variant.EXTERNAL:
                nodeLibrary = new NodeLibrary(ExternalRunbookNodeLibrary as NodeLibrarySpec);

                break;
            default:
                nodeLibrary = new NodeLibrary(IncidentRunbookNodeLibrary as NodeLibrarySpec);
                break;
        }

        return nodeLibrary.getNodeTypes();
    }

    /**
     * Parse JSON and add user connector selection to the payload
     * 
     * @param runbookForExport 
     * 
     * @returns {JSON}
     */
    function getPayload(runbookForExport: any) {
        const payload = { ...runbookForExport };

        if (!payload?.runbook?.nodes || dependencies?.length === 0) {
            return payload
        }

        dependencies.forEach((dependency) => {
            const nodes = payload.runbook.nodes;
            const node = nodes.find(el => el?.properties?.configurationId === dependency.id);
            const currentProperties = node?.properties;
            const connectorProperties = currentProperties?.in?.find(el => el.method === Method.CONNECTOR) ||
                                        currentProperties?.in?.find(el => el?.inner?.match(/Connector/gi));

            if (connectorProperties) {
                connectorProperties.outer = dependencyConnectorMap.current[dependency.id];
            } else {
                // Create a subflow-connector property
                currentProperties?.in?.unshift({
                    inner: `subflow.${dependency.sourcePackageId}_Connector`,
                    outer: dependencyConnectorMap.current[dependency.id],
                    method: Method.CONNECTOR
                })
            }
        })

        return payload;
    }

    const modalButtons = getModalButtons();

    if (!isOpen) {
        return <></>;
    }

    /** Render the MODAL */
    return (
        <Modal
            title={translations.runbooks.importModal.title}
            buttons={modalButtons}
            onSubmit={() => { }}
            onClose={() => handleCloseModal()}
            usePortal={false}
            hideSubmit={true}
        >
            {renderModalContent()}
            <BasicDialog
                portalClassName="error-dialog"
                className={dialogClassName}
                dialogState={dialogState}
                onClose={() => {
                    setDialogState(
                        updateDialogState(dialogState, false, false, [])
                    );
                    setDialogClassName("");
                }}
            />
            <LoadingOverlay
                visible={false}
                spinnerSize={SpinnerSize.SMALL}
                loadingText={"Loading"}
            />
        </Modal>
    );
});

