/** This file defines the prediction summary react component.  The prediction summary view React component 
 *  displays a prototype component for the EmpoweredX demo that displays the results of predictive AI.
 *  @module */
import React from "react";
import { PageWithHeader } from "components/sdwan/layout/page-with-header/PageWithHeader";
import { IconNames } from "@tir-ui/react-components";
import { HELP, STRINGS } from "app-strings";
import { CardsHolder } from "components/common/layout/cards-holder/CardsHolder";
import { SummaryCard } from "components/common/layout/summary-card/SummaryCard";
import { SIZE } from "components/enums";
import { CapacityPlanningTableView } from "./views/capacity-planning-table/CapacityPlanningTableView";

/** Renders the prediction summary dashboard page.
 *  @param props the properties passed in.
 *  @returns JSX with the prediction summary page component.*/
export default function PredictionSummaryPage(): JSX.Element {
    return (
        <PageWithHeader
            name="PredictionSummaryPage"
            showGlobalFilters={false}
            title={STRINGS.predictionSummaryDashboard.title}
            icon={IconNames.AI_BIS_1} 
            showTimeBar={false}
            helpInfo={HELP.predictiveAiDashboard}
        >
            <CardsHolder className="mx-3 mt-3">
                <SummaryCard size="flex" height={SIZE.l} title={STRINGS.predictionSummaryDashboard.capacityPlanningTable.title} titlePadding={false} isSummary
                    className="w-min-4-force capacity-planning-list-card" alignTitle="left" padding="" titleFontWeight="font-weight-bold" >
                    <CapacityPlanningTableView />
                </SummaryCard>
            </CardsHolder>
        </PageWithHeader>
    );
};
