/** This module contains the component for displaying a blade with the runbook output.
 *  @module
 */
import React, { useEffect, useMemo, useState } from "react";
import { STRINGS } from "app-strings";
import { IconTitle } from "components/common/icon-title/IconTitle";
import { BladeContainer } from "components/common/layout/containers/blade-container/BladeContainer";
import { PRIORITY, SIZE } from "components/enums";
import { PARAM_NAME } from "components/enums/QueryParams";
import { DetailsPanel } from "components/reporting";
import { SDWAN_ICONS } from "components/sdwan/enums";
import { getURLPath } from "config";
// import { IncidentImpactSummaryView } from "pages/incident-details/views/impact-summary/IncidentImpactSummaryView";
import { AuthServiceProvider } from "utils/providers/AuthServiceProvider";
import { PriorityLEDFormatter } from "reporting-infrastructure/utils/formatters/priority-led-formatter/PriorityLEDFormatter";
import { FILTER_NAME, useQuery } from "utils/hooks";
import { getURL, useQueryParams } from "utils/hooks/useQueryParams";
import { EntryType, PriorityReasonsView } from "pages/incident-details/views/priority-reason/PriorityReasonsView";
import { PrimaryIndicatorView } from "pages/incident-details/views/primary-indicator/PrimaryIndicatorView";
import { RunbookOutputsTabView } from "pages/incident-details/views/runbook-outputs-tab/RunbookOutputsTabView";
import { Incident } from "pages/incident-list/Incident.type";
import { Query } from "reporting-infrastructure/data-hub";
import { loader } from "graphql.macro";
import { showTitleIcons } from "utils/runbooks/RunbookUtils";
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
import { AnchorButton, Button, PopoverPosition, Tag } from "@blueprintjs/core";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { Tab, TabbedSubPages } from "components/common/layout/tabbed-sub-pages/TabbedSubPages";
import { WrapInTooltip } from "components/common/wrap-in-tooltip/WrapInTooltip";
import { ErrorToaster, IconNames, SuccessToaster, Table, TableColumnDef } from "@tir-ui/react-components";
import { isEmpty, isArray } from "lodash";
import { useApolloClient, useMutation } from "@apollo/client";
import { useCustomProperties } from "utils/hooks/useCustomProperties";
import { CustomPropertyBlade } from "pages/incident-list/views/custom-props-blade/CustomPropertyBlade";
import { openModal } from "components/common/modal";
import { getLocationSearchNameIdForEngine } from "./../../../incident-search/IncidentSearchUtils";
import { SEARCH_ENGINE } from "utils/services/UserPrefsTypes";
import { RunbookOutput } from "pages/riverbed-advisor/views/runbook-view/Runbook.type";
import OnDemandRunbooksBlade from "../on-demand-runbooks-blade/OnDemandRunbooksBlade";
import { CUSTOM_PROPERTY_PERMISSION, SEARCH_TYPE, getRunbookViewPage } from "pages/incident-search/IncidentSearchPage";
import "./IncidentBriefBlade.scss";

/** this constant refers to the auth service where you can get the tenant, user, etc. */
const AuthService = AuthServiceProvider.getService();

/** This interface defines the properties passed into the runbook brief blade React component.*/
interface IncidentBriefBladeProps {
    /** a string with id of the incident. */
    id?: string;
    /** the priority determined by the runbook. */
    priority?: PRIORITY;
    /** a string with description of the runbook output (not the config). */
    description?: string;
    /** the incident that is being displayed in the blade. */
    incident?: Incident;
    /** the handler for the blade closed event. */
    onBladeClosed?: () => void;
    /** the handler for the apply set custom property event. */
    onSetProperty?: () => void;
    /** the entity/runbook data for on demand runbooks analyses */
    entityType?: any;
    /** the name of the runbook data for on demand runbooks analyses */
    name?: string; 
    /** a boolean value, true if the detailed version is being shown, false otherwise. */
    detailed?: boolean;
    /** the end time of the incident. */
    incidentEndTime?: string;
    /** tyoe of engine */
    engine?: SEARCH_ENGINE;
}

/** Renders the incident brief blade view.
 *  @param props the properties passed into the component.
 *  @returns JSX with the incident brief blade view component.*/
export function IncidentBriefBlade({
    id,
    priority,
    description,
    onBladeClosed,
    onSetProperty,
    detailed = false,
    incidentEndTime,
    ...props
}: IncidentBriefBladeProps): JSX.Element {
    const userHasWritePermissions = AuthService.userHasWriteAccess('gelato');
    const incidentHeaderQuery = useQuery({
        name: "IncidentHeader",
        query: new Query(loader("../../../incident-details/incident-header-query.graphql")),
        requiredFilters: [FILTER_NAME.incidentId],
        filters: { [FILTER_NAME.incidentId]: props.incident?.id },
        timeNotRequired: true,
        skipGlobalFilters: true
    });
    const incident: Incident | undefined = incidentHeaderQuery?.data?.incidents?.nodes?.length ? incidentHeaderQuery.data.incidents.nodes[0] : undefined;

    const QUERY_DATA = {
        application: {
            ids: "applicationIds",
            qData: "applications",
            allowed: ["name"],
            cpType: 'APPLICATION',
            validType: 'application',
            query: new Query(loader("../../../incident-search/queries/applications.graphql"))
        },
        device: {
            ids: "deviceIds",
            qData: "networkDevices",
            allowed: ["name", "ipAddress", "vendor", "type", "model", "location", "serialNumber", "osVersion", "isGateway"],
            cpType: 'NETWORK_DEVICE',
            validType: 'network_device',
            query: new Query(loader("../../../incident-search/queries/network-devices.graphql"))
        },
        interface: {
            ids: "interfaceIds",
            qData: "networkInterfaces",
            allowed: ["name", "ifIpAddresses", "inboundSpeed", "outboundSpeed", "ifDescription", "ifAlias", "ifIndex", "location", "type"],
            cpType: 'NETWORK_INTERFACE',
            validType: 'network_interface',
            query: new Query(loader("../../../incident-search/queries/network-interfaces.graphql"))
        },
        location: {
            ids: "locationIds",
            qData: "locations",
            allowed: ["name"],
            cpType: 'LOCATION',
            validType: 'location',
            query: new Query(loader("../../../incident-search/queries/locations.graphql"))
        },
        properties: {
            ids: 'customPropertyIds',
            qData: "customProperties",
            cpType: 'PROPERTIES',
            query: new Query(loader("../../../incident-search/queries/custom-properties.graphql"))
        },
        ondemandrunbooks: {
            ids: 'onDemandRunbookIds',
            qData: "onDemandRunbooks",
            cpType: 'ON_DEMAND_RUNBOOKS',
            query: new Query(loader("../../../incident-search/queries/on-demand-runbooks.graphql"))
        }
    }

    const { params } = useQueryParams({ listenOnlyTo: ["searchType", PARAM_NAME.allowMultiType] });
    const searchType = params?.searchType === 'incident' ? 'application' : params.searchType;
    const [entityID] = useState(props.incident?.id);    
    const runbookData = searchType === "ondemandrunbooks" ? { runbookId: id} : false;
    const apolloClient = useApolloClient();
    const [ runbook, setRunbook] = useState<RunbookOutput>();

    /*
    const consumedFiltersList = [
        FILTER_NAME.incidentId, FILTER_NAME.priority, FILTER_NAME.incidentStatus, 
        FILTER_NAME.completionStatus, 
        FILTER_NAME.deviceName, FILTER_NAME.interfaceName, FILTER_NAME.applicationName, 
        //FILTER_NAME.locationName,
        FILTER_NAME.impactedApplicationId, FILTER_NAME.impactedLocationId, FILTER_NAME.impactedUserId
    ];
    */

    /** Query to invoque all entities (Dynamic query) */
    const { loading, data, error, run } = useQuery(
        /*
        searchType === 'on-demand-runbooks' ? 
            {
                query: new Query(loader("../../../runbook-invocations/views/runbook-invocations-list/runbook-invocations.graphql")),
                requiredFilters: [FILTER_NAME.runbookSourceTypes],
                consumedFilters: consumedFiltersList,
                supportedGlobalFilters: consumedFiltersList,
                filters: {
                    [FILTER_NAME.runbookSourceTypes]: "ON_DEMAND"
                },
                skipGlobalFilters: false,
                timeNotRequired: true,
                lazy: true,
            }
        : 
        */
            {
                name: searchType,
                query: QUERY_DATA[searchType].query,
                queryVariables: {
                    filter: {
                        [QUERY_DATA[searchType].ids]: [entityID]
                    }
                },
                lazy: true,
            }
    );

    useEffect(() => {
        // The runbook blade will get info based on runbookId and incidentId
        if (searchType !== 'ondemandrunbooks') {
            run({
                fetchPolicy: 'network-only',
                queryVariables: {
                    filter: {
                        [QUERY_DATA[searchType].ids]: [entityID]
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // The onDemandRunbookBladeTitle title 
    const onDemandRunbookBladeTitle = searchType === 'ondemandrunbooks' ? `${props?.name} (${props.entityType})` : false;

    /** main raw data object */
    const entityData = searchType === 'ondemandrunbooks' ? { name:  onDemandRunbookBladeTitle} : data?.[QUERY_DATA[searchType].qData]?.page?.length ? data[QUERY_DATA[searchType].qData].page[0] : {};
    const customProps = entityData.customProperties?.length ? entityData.customProperties : {};
    const customPropertiesQuery = useCustomProperties({});
    const availableCustomProperties = useMemo(() => {
        return customPropertiesQuery.data || [];
    }, [customPropertiesQuery.data]);
    
    /** Function to remap object with only 'allowed properties' */
    const remappedData = (cData, sType = searchType) => {
        return Object.keys(cData)
            .filter(key => QUERY_DATA[sType].allowed.includes(key))
            .reduce((obj, key) => {
                obj[key] = cData[key];
                return obj;
            }, {});
    }

    interface PropertyType {
        type: string;
    }
    interface PropertyValueSet {
        id: string;
        name: string;
    }
    interface PropertyValueCreate {
        name: string;
    }
    interface CustomPropertyMutationInput {
        id: string;
        customProperty: {
            name: string;
            description: string;
            validTypes: Array<PropertyType>;
        };
        valuesToCreate: Array<PropertyValueCreate>;
        valuesToSet: Array<PropertyValueSet>;
        valuesToDelete: Array<string>;
    }
    interface RemovePropertyMutationInput {
        removeCustomPropertyValueId: string;
        valueId: string;
        type: string;
    }

    const [setCustomProperty] = useMutation<any, CustomPropertyMutationInput>(
        loader(
            "src/pages/incident-search/queries/set-custom-property-mutation.graphql"
        ),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message:
                        STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade
                            .messages.propertyUpdated,
                });
                onBladeClosed && onBladeClosed();
            },
            onError: (err) => {
                ErrorToaster({
                    message: err.message,
                });
                console.error(err?.message);
                return Promise.reject(err.message);
            },
            refetchQueries: ['CustomPropertyDetails'],
        }
    );

    const [removeCustomPropertyValue] = useMutation<any, RemovePropertyMutationInput>(
        loader(
            "src/pages/incident-search/queries/mutation-remove-custom-property-value.graphql"
        ),
        {
            onCompleted: (data) => {
                SuccessToaster({
                    message:
                        STRINGS.CUSTOM_PROPERTIES_PAGE.customPropertyBlade
                            .messages.propertyUnset,
                });
                apolloClient.refetchQueries({
                    include: "active",
                })
            },
            onError: (err) => {
                ErrorToaster({
                    message: err.message,
                });
                console.error(err?.message);
                return Promise.reject(err.message);
            },
        }
    );

    const deleteCustomProperty = async (cProp) => {
        try {
            await removeCustomPropertyValue({
                variables: {
                    removeCustomPropertyValueId: entityID!,
                    valueId: cProp?.assignedValue.id,
                    type: QUERY_DATA[searchType].cpType
                },
            });
        } catch (err) {
            console.error(err);
        }
    }

    /** 
     * Show set custom properties dialog 
     * */
    const setCustomPropertiesModal = (customProperty?) => {       
        return openModal("setCustomPropertiesModal", {
            selectedIds: [entityID],
            totalCount: 0,
            facets: '',
            searchType: searchType,
            searchRequest: {},
            customPropertyId: customProperty?.id,
            assignedValue: customProperty?.assignedValue.id,
            onSuccess: () => {
                onSetProperty && onSetProperty();
                apolloClient.refetchQueries({
                    include: "active",
                })
            }
        });
    }

    const columns: Array<TableColumnDef> = searchType === 'location' ? [
        {
            id: 'name',
            Header: 'Name',
            accessor: 'name',
            showFilter: true,
            headerClassName: 'text-nowrap',
        },
        {
            id: 'ip',
            Header: 'IP',
            accessor: 'ip',
            showFilter: true,
            headerClassName: 'text-nowrap',
        },
        {
            id: 'type',
            Header: 'Device Type',
            accessor: 'type',
            showFilter: true,
            headerClassName: 'text-nowrap',
        }
    ] : searchType === 'device' ? [
        {
            id: 'name',
            Header: 'Name',
            accessor: 'name',
            showFilter: true,
            sortable: true,
            headerClassName: 'text-nowrap',
        },
        {
            id: 'alias',
            Header: 'Alias',
            accessor: 'alias',
            showFilter: true,
            headerClassName: 'text-nowrap',
        },
        {
            id: 'ip',
            Header: 'IP',
            accessor: 'ip',
            showFilter: true,
            headerClassName: 'text-nowrap',
        },
        {
            id: 'description',
            Header: 'Description',
            accessor: 'description',
            showFilter: true,
            headerClassName: 'text-nowrap',
        },
    ] : [];

    const tablesData = () => {
        if (!isEmpty(entityData?.networkDevices) && searchType === 'location') {
            return entityData.networkDevices.map(location => {
                return {
                    name: location.name.indexOf(':') > -1 ? location.name.slice(location.name.indexOf(':') + 1, location.name.length) : location.name,
                    ip: location.ipAddress === "null" ? "" : location.ipAddress.replace(/[\[\]"]+/g,""), // eslint-disable-line
                    type: location.type,
                }
            });
        } else if (!isEmpty(entityData?.networkInterfaces) && searchType === 'device') {
            return entityData.networkInterfaces.map(interFace => {
                return {
                    name: interFace.name.indexOf(':') > -1 ? interFace.name.slice(interFace.name.indexOf(':') + 1, interFace.name.length) : interFace.name,
                    alias: interFace.ifAlias,
                    ip: interFace.ifIpAddresses === "null" ? "" : interFace.ifIpAddresses.replace(/[\[\]"]+/g,""), // eslint-disable-line
                    description: interFace.ifDescription,
                }
            });
        }
    }

    /**
     * Function to build the Properties List markup
     * @param cData Either an array or an Object with data properties
     * @param sType String to filter/remap object; deault to queryParam 'searchType'
     * @returns 
     */
    const propertyItems = (cData, sType = searchType) => {
        const isCustomProp = isArray(cData);
        const iterable = isCustomProp ? cData : Object.entries(remappedData(cData, sType));
        return iterable.map((item, i) => {
            if ((!isCustomProp && !!item[1]) || isCustomProp) {
                let showUnsetOption: boolean | undefined = true;
                let allowSetValue: boolean = false;
                if (isCustomProp) {
                    let permissionKey: any = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_UNASSIGN_PROPERTY_VALUE)?.[0];
                    showUnsetOption = item?.permissions?.includes(permissionKey);
                    permissionKey = Object.entries(CUSTOM_PROPERTY_PERMISSION).find(([_, value]) => value === CUSTOM_PROPERTY_PERMISSION.CAN_ASSIGN_PROPERTY_VALUE)?.[0];
                    allowSetValue = item?.permissions?.includes(permissionKey) || false;
                }
                return (
                    <div key={i} className="custom-property">
                        <div className="custom-property-wrapper">
                            <span className="custom-property-keys">{isCustomProp ? item.name : STRINGS.CUSTOM_PROPERTIES_PAGE.cProps[item[0]]}: </span>
                            <span className="custom-property-values">
                                {isCustomProp ? item.assignedValue?.name : item[0] === 'location' ? (
                                    <a href={`${getURLPath('explorer')}?searchType=location&facets=%7B"${getLocationSearchNameIdForEngine(props.engine).toUpperCase()}"%3A%5B"${item[1]?.name}"%5D%7D`}>{item[1]?.name}</a>) :
                                    (item[0] === 'isGateway' && item[1]) ? "Yes" : item[1]}
                            </span>
                        </div>
                        {isCustomProp && <div className="custom-property-icons">
                            {allowSetValue && <WrapInTooltip tooltip="Edit" >
                                <AnchorButton icon={IconNames.EDIT} minimal onClick={() => setCustomPropertiesModal(item)} />
                            </WrapInTooltip>}
                            {showUnsetOption && <WrapInTooltip tooltip="Unset" >
                                <AnchorButton icon={IconNames.TRASH} minimal onClick={() => deleteCustomProperty(item)} />
                            </WrapInTooltip>}
                        </div>}
                    </div>
                )
            } else {
                return null;
            }
        })
    };

    return (<>
        <DetailsPanel persistSize={params.searchType === 'incident'} size={detailed ? SIZE.l : SIZE.m} >
            <BladeContainer className="incident-brief-blade-container"
                centeredTitle
                title={params.searchType !== 'incident' ? (
                    <>
                        <div className="custom-property-title">{entityData.name}</div>
                        {!isEmpty(customProps) &&
                            <div className="custom-property-title">{customProps.map((cPropVal, i) => (
                                <Tag key={i} className="ml-1 my-1 py-1 px-2 align-middle font-weight-100"
                                    style={{ backgroundColor: '#B041DA', borderRadius: '6px' }}>
                                    {cPropVal.assignedValue?.name}
                                </Tag>
                            ))}
                            </div>
                        }
                    </>
                ) : <div className="d-inline-flex align-items-center" style={{ width: "340px" }}>
                    <PriorityLEDFormatter priority={priority} showStatusAsBackground />
                    <Tooltip2
                        className={Classes.TOOLTIP2_INDICATOR + " border-0 ml-2 text-truncate"}
                        content={description}
                        position={PopoverPosition.AUTO}
                        hoverOpenDelay={400}
                        transitionDuration={100}
                    >
                        <span>{description}</span>
                    </Tooltip2>
                </div>
                }
                onCloseClicked={() => onBladeClosed && onBladeClosed()}
                showDetailsLink={!params.searchType || params.searchType === SEARCH_TYPE.incident || params.searchType === SEARCH_TYPE.ondemandrunbooks}
                detailsLink={getDetailsLink()}
                detailsLinkLabel={searchType === SEARCH_TYPE.incident ? STRINGS.incidents.moreMenu.view : STRINGS.runbooks.onDemandRunbookBlade.linkDescription}
            >
                {(params.searchType && !['properties', 'incident', 'ondemandrunbooks'].includes(params.searchType)) &&
                    <DataLoadFacade key="properties" loading={loading} data={data} error={error}>
                        <TabbedSubPages className="pt-2">
                            <Tab id="tab-properties" title={"Properties"}>
                                {/* default properties list (non editable) */}
                                {propertyItems(entityData)}
                                {/* custom properties list */}
                                {!isEmpty(customProps) && <>
                                    {propertyItems(customProps)}
                                   </>}
                                {!isEmpty(availableCustomProperties) && userHasWritePermissions &&  
                                    <Button 
                                        icon={IconNames.PLUS} 
                                        fill 
                                        large 
                                        className="mt-4 btn-dotted display-8"
                                        onClick={() => setCustomPropertiesModal()}>
                                        {STRINGS.CUSTOM_PROPERTIES_PAGE.tabs.setCPropCTA}
                                    </Button>}
                            </Tab>
                            {/* 2nd Tab */}
                            {['device', 'interface', 'location'].includes(searchType) &&
                                <Tab id="tab-table" title={searchType === 'device' ? 'Interfaces' : searchType === 'interface' ? 'Device' : searchType === 'location' ? 'Devices' : ''}>
                                    {(searchType === 'interface' && !isEmpty(entityData.networkDevice)) && propertyItems(entityData.networkDevice, 'device')}
                                    {['location', 'device'].includes(searchType) && <>
                                        <Table id="properties-table" columns={columns} data={tablesData()} />
                                    </>
                                    }
                                </Tab>
                            }
                        </TabbedSubPages>
                    </DataLoadFacade>
                }
                {/* Facet blade Incident contents */}
                {(params.searchType === 'incident') && <>
                    <div className="d-flex flex-wrap mt-2" >
                        {/* <IncidentImpactSummaryView key={"incident-impact-" + id} incidentId={id} showTitleIcons={showTitleIcons} showFooter={false} /> */}
                        <PriorityReasonsView 
                            type={EntryType.PRIORITY}
                            incidentId={id} showTitleIcons={showTitleIcons} showFooter={false} runbookOutput={runbook}
                        />
                    </div>
                    <PrimaryIndicatorView incident={incident} showTitleIcons={showTitleIcons} />
                    <IconTitle icon={showTitleIcons ? SDWAN_ICONS.TRIGGER : undefined} title={STRINGS.incidents.runbookTitle} size={SIZE.m} className="mb-4 font-weight-bold" />
                    <RunbookOutputsTabView
                        key={"runbook-outputs"/* + (lastUpdated ? "-" + lastUpdated : "")*/}
                        incidentId={props.incident?.id || ""}
                        enableRerunRunbook={true /*PM asked to always enable this props.incident?.isOngoing === true*/}
                        setRunbook={(runbook) => {setRunbook(runbook)} }
                    />
                </>}
                {(params.searchType === 'properties') && 
                   <CustomPropertyBlade loading={loading} data={entityData} error={error} onBladeClosed={ onBladeClosed } setCustomProperty={ setCustomProperty } allowMultiType={ Boolean(params.allowMultiType) } />
                }
                {/* On Demand Runbooks BLADE */}
                {(params.searchType === 'ondemandrunbooks') && 
                   <OnDemandRunbooksBlade loading={loading} data={runbookData} error={error} onBladeClosed={ onBladeClosed }/>
                }
            </BladeContainer>
        </DetailsPanel >
    </>
    )

    function getDetailsLink(): string | undefined {
        if (searchType === SEARCH_TYPE.ondemandrunbooks) {
            return getRunbookViewPage(id)
        }

        return getURL(getURLPath("incident"), { [PARAM_NAME.incidentId]: id }, { replaceQueryParams: true });
    }
}
