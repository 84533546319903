/** This module contains the component that displays a chart that displays the 
 *  training and prediction data for the trend analysis.
 *  @module
 */

import React, { useState, useEffect } from "react";
import { Switch } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { TimeseriesTileForDemo } from "components/reporting/tiles/timeseries-tile/TimeseriesTileForDemo";
import { formatToLocalTimestamp, scaleMetric } from "reporting-infrastructure/utils/formatters";
import { TIME_FORMAT } from "components/enums";
import { getLabelForMetric } from "utils/stores/GlobalUnitsStore";
import { Unit } from "reporting-infrastructure/types/Unit.class";
import { Icon, IconNames } from "@tir-ui/react-components";

/** the color for the threshold line. */
const THRESHOLD_LINE_COLOR = "red";
/** the opacity for the threshold line. */
const THRESHOLD_LINE_OPACITY = 0.5;

/** this interface defines the properties that are passed in to the AnalysisChart React component. */
export interface AnalysisChartProps {
    /** a number with the height of the chart. */
    height: number;
    /** aal the row data */
    rowData: any;
}

/** Renders the analysis chart React component.
 *  @param props the properties passed in.
 *  @returns JSX with the incident table actions component.*/
const AnalysisChart = (props: AnalysisChartProps) => {
    const [showTraining, setShowTraining] = useState<boolean>(true);
    
    let [timeRange, setTimeRange] = useState<any>();
    let [indicatorData, setIndicatorData] = useState<any>();
    let [seriesData, setSeriesData] = useState<any>();

    useEffect(() => {
        const getData = () => {
            const newSeriesData = [
                {
                    type: "line",
                    data: [],
                    animation: {
                        duration: 500
                    },
                    borderColor: "transparent",
                    step: true
                },
                {
                    type: "arearange",
                    fillColor: "green",
                    opacity: 0.2,
                    data: [],
                    step: true
                }
            ];

            // Merge training data and predicted data 
            const allData = [...props.rowData.metadata?.training_data, ...props.rowData.data];

            // Toggle between show only trainingData or allData
            const filteredData = () => showTraining ? allData : props.rowData.metadata.training_data

            // Map to render the 1st base line chart
            const newIndicator = filteredData().map(datum => ({
                x: parseInt(datum[0]) * 1000, y: datum[1]
            }));

            // Area range 
            props.rowData.data.forEach(datum => {
                const time = parseInt(datum[0]) * 1000;
                newSeriesData[1].data.push({x: time, low: datum[3], high: datum[2]});
            });

            // Start and end times 
            let startTime = Number.MAX_SAFE_INTEGER;
            let endTime = 0;
            newIndicator.forEach(datum => {
                const time = datum.x;
                if (time < startTime) {
                    startTime = time;
                }
                if (time > endTime) {
                    endTime = time;
                }    
            });
            let timeRange = {startTime, endTime};

            // Threshold lines
            newSeriesData.push({
                type: "line",
                color: THRESHOLD_LINE_COLOR,
                dashStyle: "Dash",
                opacity: THRESHOLD_LINE_OPACITY,
                data: [{
                    x: timeRange.startTime,
                    y: props.rowData.threshold1,
                }, {
                    x: timeRange.endTime,
                    y: props.rowData.threshold1,
                }],
                enableMouseTracking: false,
            } as any);

            newSeriesData.push({
                type: "line",
                color: THRESHOLD_LINE_COLOR,
                dashStyle: "Dash",
                opacity: THRESHOLD_LINE_OPACITY,
                data: [{
                    x: timeRange.startTime,
                    y: props.rowData.threshold2,
                }, {
                    x: timeRange.endTime,
                    y: props.rowData.threshold2,
                }],
                enableMouseTracking: false,
            } as any);

            setTimeRange(timeRange);
            setIndicatorData(newIndicator);
            setSeriesData(newSeriesData);
        };

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTraining]);

    function formatTooltipData(this: any) {
        let toolTip = formatToLocalTimestamp(new Date(this.x), TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT);
        const symbol = '&#9632;';
        const color = true/*isBaseline*/ ? this.points[0].color : this.point.color;
        const opacity = true/*isBaseline*/ ? this.points[0].series.options.opacity : this.point.series.options.opacity;
        const actualValue =
                '<div><span style="font-size:16px;color:' +
                color + ';opacity:' + opacity +
                '">' +
                symbol +
                '</span>' +
                '<b><span> ' +
                STRINGS.incidents.columns.actual +
                '</span></b> : <b>' +
                scaleMetric(this.y, Unit.parseUnit(indicatorData?.unit || "")).formatted +
                '</b></div>';
        toolTip += actualValue;

        if (true/*isBaseline*/ && this.points[1]) {
            const expectedRange =
                    '<div><span style="font-size:16px;color:' +
                    this.points[1].series.options.fillColor + ';opacity:' + this.points[1].series.options.opacity +
                    '">' +
                    symbol +
                    '</span>' +
                    '<b><span> ' +
                    STRINGS.incidents.timeseriesChart.expectedRange +
                    '</span></b> : <b>' +
                    scaleMetric(this.points[1].point.options.low, Unit.parseUnit(indicatorData?.unit || "")).formatted + '&ndash;' +
                    scaleMetric(this.points[1].point.options.high, Unit.parseUnit(indicatorData?.unit || "")).formatted +
                    '</b></div>';
            toolTip += expectedRange;
        } 
        return toolTip;
    }

    const setPlotLine = (arrays, threshold) => {
        const firstArrayLargerThanThreshold = arrays.find(array => array[1] > threshold);
        if (firstArrayLargerThanThreshold) {
            return parseInt(firstArrayLargerThanThreshold[0]) * 1000
        }
    }

    return (
		<>
            <div className="d-flex justify-content-between align-items-center mb-4 ml-4">
                <div className="d-flex align-items-center">
                    <Icon icon={IconNames.LAN} iconSize={32} className="mr-4" />
                    <div className="d-flex flex-column align-items-center mr-4">
                        <div className="font-weight-bold">{props.rowData.name}</div>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <Icon icon={IconNames.TIME} iconSize={32} className="mr-4" />
                    <div className="d-flex flex-column align-items-center">
                        <div className="period">{props.rowData.period} months</div>
                        <div>
                            <Switch
                                data-testid="show-only-training-data"
                                checked={showTraining}
                                disabled={false}
                                inline
                                label={STRINGS.predictionSummaryDashboard.analysisModal.switch}
                                onChange={(e) => {
                                    const checked = e.currentTarget.checked;
                                    setShowTraining(checked);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DataLoadFacade showContentsWhenLoading loading={!indicatorData}>
                {timeRange && 
                <TimeseriesTileForDemo
                    chartData={indicatorData}
                    yMax={100}
                    yMin={0}
                    className="h-min-3"
                    config={{
                        chart: {
                            height: props.height,
                        },
                        exporting: {
                            enabled: false
                        },
                        xAxis: {
                            visible: true,
                            type: "datetime",
                            min: timeRange.startTime,
                            max: timeRange.endTime,
                            labels: {
                                formatter: function (this:any) {
                                    return formatToLocalTimestamp(this.value, TIME_FORMAT.DISPLAY_DATE_TIME_SHORT_FORMAT);
                                }
                            },
                            crosshair: {
                                snap: true,
                                width: 1,
                            },
                            plotBands: [
                                {
                                  from: parseInt(props.rowData.metadata.training_start_timestamp) * 1000,
                                  to: parseInt(props.rowData.metadata.training_end_timestamp) * 1000,
                                  color: "#fff9df"
                                }
                            ],
                            plotLines: [
                                {
                                    color: "red",
                                    value: setPlotLine(props.rowData.data, props.rowData.threshold1),
                                },
                                {
                                    color: "red",
                                    value: setPlotLine(props.rowData.data, props.rowData.threshold2),
                                },
                            ]
                        },
                        yAxis: {
                            visible: true,
                            type: "number",
                            title: {
                                text: `${getLabelForMetric(props.rowData.entity_info?.metric)} (%)` ,
                            },
                            labels: {
                                formatter: function (this: any) {
                                    return scaleMetric(this.value, new Unit()).formatted;
                                }
                            }
                        },
                        tooltip: {
                            useHTML: true,
                            shared: true,
                            split: false,
                            formatter: formatTooltipData
                        },
                        series: seriesData
                    }}
                />}

                {(props.rowData.days1 || props.rowData.days2) &&
                    <div className="d-flex align-items-center mt-4 ml-4 px-3 pt-3 pb-2" style={{backgroundColor: '#137cbd26', borderRadius: '12px'}}>
                    <Icon icon={IconNames.AI_BIS_1} iconSize={32} className="mr-4" />
                    <div>
                        <p>{STRINGS.formatString(STRINGS.predictionSummaryDashboard.analysisModal.callout.base, <b style={{fontSize: "16px"}}>{props.rowData.period}</b>)}</p>
                        {props.rowData.days1 && <p>{STRINGS.formatString(STRINGS.predictionSummaryDashboard.analysisModal.callout.day1, <b style={{fontSize: "16px"}}>{props.rowData.threshold1}</b>, <b style={{fontSize: "16px"}}>{props.rowData.days1}</b>)}</p>}
                        {props.rowData.days2 && <p>{STRINGS.formatString(STRINGS.predictionSummaryDashboard.analysisModal.callout.day2, <b style={{fontSize: "16px"}}>{props.rowData.days2}</b>, <b style={{fontSize: "16px"}}>{props.rowData.threshold2}</b>)}</p>}
                    </div>
                </div>}
            </DataLoadFacade>
		</>
	);
};

export default AnalysisChart;
