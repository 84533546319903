/** This module contains the component for selecting a new widget to add to the Navigator.
 *  @module
 */

import React, { useState, useEffect, useMemo } from "react";
import { STRINGS } from "app-strings";
import { Icon, IconNames } from "@tir-ui/react-components";
import { WIDGET_TYPE } from "components/common/vis-framework/widget/Widget.type";
import { Button, Checkbox, InputGroup, Intent } from "@blueprintjs/core";
import { DataOceanUtils } from "components/common/graph/editors/data-ocean/DataOceanUtils";
import { APP_ICONS } from "components/sdwan/enums";
import { FilterEntry } from "pages/navigator/Navigator.type";
import { clone } from "lodash";
import { DEFAULT_OBJ_TYPE } from "pages/navigator/NavigatorPage";
import { GenericKey, NodeUtils } from "utils/runbooks/NodeUtil";
import "./AddNewWidgetControl.scss";

/** this interface defines the properties passed into the AddNewWidgetControl React component. */
export interface AddNewWidgetControlProps {
    /** a String array with the list of columns to be displayed. */
    displayColumns: Array<string>;
    /** the array of filter entries. */
    filters: FilterEntry[];
    /** the handler for the add new widget event. */
    onAddNewWidget: (widgetType: WIDGET_TYPE, columns: string[], groupBys: string[], filters: FilterEntry[]) => void;
    /** the handler for the blade closed event. */
    bladeClose: () => void;
}

const ALLOW_SECTION_COLLAPSE: boolean = false;

const group_column = {
    group_column: {
        id: "group_column",
        label: "Name & Details",
        type: "string",
        category: "Group column",
    },
};

/** Renders the add new widget control.
 *  @param props the properties passed in.
 *  @returns JSX with the add new widget control component.*/
const AddNewWidgetControl = (props: AddNewWidgetControlProps): JSX.Element => {
    const metrics = useMemo<any>(() => {
        return { ...group_column, ...DataOceanUtils.dataOceanMetaData.metrics };
    }, []);

    const [openSections, setOpenSections] = useState<string[]>(ALLOW_SECTION_COLLAPSE ? [] : ["metrics", "drilldowns"]); 
    const [selectedWidgetType, setSelectedWidgetType] = useState<WIDGET_TYPE>(WIDGET_TYPE.TABLE);

    const [columns, setColumns] = useState<Array<string>>(props.displayColumns);
    const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
    const [columnFilterValue, setColumnFilterValue] = useState<string>("");
    const [openColumnCategories, setOpenColumnCategories] = useState<Array<string>>([]);

    let defGroupBys: GenericKey[] = [];
    if (DataOceanUtils.dataOceanMetaData.obj_types?.[DEFAULT_OBJ_TYPE]?.group_by_required) {
        const expandedKeys: GenericKey[] = NodeUtils.getExpandedKeysForKeyList(
            DataOceanUtils.dataOceanMetaData, DataOceanUtils.dataOceanMetaData.obj_types[DEFAULT_OBJ_TYPE].keys || []
        );
        defGroupBys = expandedKeys;
    } else if (DataOceanUtils.dataOceanMetaData.obj_types?.[DEFAULT_OBJ_TYPE]?.group_by?.length) {
        defGroupBys = (DataOceanUtils.dataOceanMetaData.obj_types[DEFAULT_OBJ_TYPE].group_by || []).map((key) => {
            return {id: key, ...DataOceanUtils.dataOceanMetaData.keys[key]} as GenericKey;
        });
    }

    const [groupBys, setGroupBys] = useState<Array<GenericKey>>(defGroupBys);
    const [selectedGroupBys, setSelectedGroupBys] = useState<Array<string>>([]);
    const [groupBysFilterValue, setGroupBysFilterValue] = useState<string>("");
    const [openDrilldownCategories, setOpenDrilldownCategories] = useState<Array<string>>([]);

    const columnCategories = Object.values(metrics).map((metric: any) => {
        return metric.category;
    }).filter((value, index, array) => array.indexOf(value) === index);

    useEffect(() => {
        const columns = props.displayColumns;
        if (columnFilterValue) {
            setColumns(
                columns?.filter((columnId) => {
                    const name = columnId === "group_column" ? group_column.group_column.label : metrics[columnId]?.label;
                    return name.toLocaleLowerCase().includes(columnFilterValue.toLowerCase());
                })
            );
        } else {
            setColumns(columns);
        }
    }, [columnFilterValue, metrics, props.displayColumns]);

    const columnFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={() => {
                setColumnFilterValue("");
            }}
        />
    );

    const handleListsFilterChange = (event: any) => {
        setColumnFilterValue(event.target.value);
    };

    const handleColumnsChange = (event: any) => {
        if (!event.target.checked) {
            const columns = selectedColumns.filter(function(column) {
                return column !== event.target.id
            });
            setSelectedColumns(columns);
        } else {
            setSelectedColumns(selectedColumns => [...selectedColumns, event.target.id]);
        }
    };

    const drilldownCategories: string[] = groupBys.map((groupByDef: any) => {
        return groupByDef.category;
    }).filter((value, index, array) => array.indexOf(value) === index) as string[];

    useEffect(() => {
        if (groupBysFilterValue) {
            setGroupBys(defGroupBys?.filter(groupByDef => {
                const name = groupByDef.label;
                return name.includes(groupBysFilterValue);
            }));
        } else {
            setGroupBys(defGroupBys);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupBysFilterValue]);

    const groupBysFilterClearIcon = (
        <Button
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={() => {
                setGroupBysFilterValue("");
            }}
        />
    );

    const handleGroupByListsFilterChange = (event: any) => {
        setGroupBysFilterValue(event.target.value);
    };

    const handleGroupBysChange = (event: any) => {
        if (!event.target.checked) {
            const groupBys = selectedGroupBys.filter(function(groupBy) {
                return groupBy !== event.target.id
            });
            setSelectedGroupBys(groupBys);
        } else {
            setSelectedGroupBys(selectedGroupBys => [...selectedGroupBys, event.target.id]);
        }
    };

    return <div className="add-widget-control p-2 pt-3">
            <div className="pb-2">
                <table><tbody>
                <tr>
                    <td className="p-2"><Button icon={IconNames.TH} className={"filter-toggle-button mr-3"} 
                        intent={selectedWidgetType === WIDGET_TYPE.TABLE ? Intent.SUCCESS : Intent.NONE}
                        text={STRINGS.navigator.addNewWidgetControl.tableText}
                        onClick={() => {
                            setSelectedWidgetType(WIDGET_TYPE.TABLE);
                        }}
                    /></td>
                    <td className="p-2"><Button icon={IconNames.TIMELINE_LINE_CHART} className={"filter-toggle-button mr-3"} 
                        intent={selectedWidgetType === WIDGET_TYPE.TIMESERIES ? Intent.SUCCESS : Intent.NONE}
                        text={STRINGS.navigator.addNewWidgetControl.timeText}
                        onClick={() => {
                            setSelectedWidgetType(WIDGET_TYPE.TIMESERIES);
                        }}
                    /></td>
                </tr>
                <tr>
                    <td className="p-2"><Button icon={IconNames.DOUGHNUT_CHART} className={"filter-toggle-button mr-3"} 
                        intent={selectedWidgetType === WIDGET_TYPE.PIE ? Intent.SUCCESS : Intent.NONE}
                        text={STRINGS.navigator.addNewWidgetControl.pieText}
                        onClick={() => {
                            setSelectedWidgetType(WIDGET_TYPE.PIE);
                        }}
                    /></td>
                    <td className="p-2"><Button icon={IconNames.SCATTER_PLOT} className={"filter-toggle-button mr-3"} 
                        intent={selectedWidgetType === WIDGET_TYPE.CORRELATION ? Intent.SUCCESS : Intent.NONE}
                        text={STRINGS.navigator.addNewWidgetControl.scatterText}
                        onClick={() => {
                            setSelectedWidgetType(WIDGET_TYPE.CORRELATION);
                        }}
                    /></td>
                </tr>
            </tbody></table>
        </div>
        <div className="pb-2 display-8 font-weight-bold"
            onClick={() => {
                if (ALLOW_SECTION_COLLAPSE) {
                    if (openSections.includes("metrics")) {
                        openSections.splice(openSections.indexOf("metrics"), 1);
                        const newAr = [].concat(openSections as any);
                        setOpenSections(newAr);
                    } else {
                        setOpenSections(["metrics"].concat(openSections))
                    }    
                }
            }}
        >
            {ALLOW_SECTION_COLLAPSE && <Icon icon={openSections.includes("metrics") ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />}
            {STRINGS.navigator.addNewWidgetControl.metricsSectionHeader}
        </div>
        {(!ALLOW_SECTION_COLLAPSE || openSections.includes("metrics")) && <div className="add-widget-control-list-items font-weight-normal pb-4">
            <InputGroup id={"columnsFilterInput"} leftIcon="search" rightElement={columnFilterClearIcon}
                onChange={handleListsFilterChange} placeholder={STRINGS.incidents.impactSummaryView.search}
                value={columnFilterValue} className="mb-3 mt-2"
            />
            {columnCategories.length ? (columnCategories.map((category, index) => {
                let showCategory = false;
                Object.keys(metrics).map((metric) => {
                    if (metrics[metric].category === category && columns.includes(metric)) {
                        showCategory = true;
                    }
                    return undefined;
                });
                if (showCategory) {
                    return (
                        <div key={index} className="add-widget-control-category mb-1">
                            <div key={"metric-category-" + index}
                                onClick={() => {
                                    if (!openColumnCategories.includes(category)) {
                                        setOpenColumnCategories((columnCategoryOpen) => [...columnCategoryOpen, category]);
                                    } else {
                                        let openedCategories = clone(openColumnCategories);
                                        openedCategories.splice(openedCategories.indexOf(category), 1);
                                        setOpenColumnCategories(openedCategories);
                                    }
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <Icon icon={openColumnCategories.includes(category) ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED}/>
                                <span className="pl-1 font-weight-bold">{category}</span>
                            </div>
                            {openColumnCategories.includes(category) &&
                                Object.keys(metrics).map((metric) => {
                                    if (columns.includes(metric)) {
                                        return (
                                            metrics[metric].category ===
                                                category && (
                                                <div key={"column-" + metric} className="widget-editor-item display-9 mt-2 ml-3">
                                                    <Checkbox type="checkbox" id={metric}
                                                        label={category === group_column.group_column.category
                                                                ? group_column.group_column.label
                                                                : DataOceanUtils.dataOceanMetaData.metrics[metric]?.label
                                                        }
                                                        checked={selectedColumns.includes(metric)}
                                                        onChange={handleColumnsChange}
                                                    />
                                                </div>
                                            )
                                        );
                                    } else return null;
                                })}
                        </div>
                    );
                } else return null;
            })
            ) : (
                <div className="widget-editor-item display-9">
                    {STRINGS.navigators.addNewWidgetControl.noColumnsToEdit}
                </div>
            )}
            {/*columns?.length ? (
                columns.map((columnId, index) => (
                    <div  className="add-widget-control-item display-9">
                        <Checkbox type="checkbox" 
                            id={columnId}
                            label={columnId === "group_column" ? "Name & Details" : DataOceanUtils.dataOceanMetaData.metrics[columnId]?.label}
                            checked={selectedColumns.includes(columnId)}
                            onChange={handleColumnsChange}
                        />
                    </div>
                ))
            ) : (
                <div className="add-widget-control-item display-9">
                    {STRINGS.navigator.addNewWidgetControl.noColumnsText}
                </div>
            )*/}
        </div>}
        <div className="pb-2 display-8 font-weight-bold"
            onClick={() => {
                if (ALLOW_SECTION_COLLAPSE) {
                    if (openSections.includes("drilldowns")) {
                        openSections.splice(openSections.indexOf("drilldowns"), 1);
                        const newAr = [].concat(openSections as any);
                        setOpenSections(newAr);
                    } else {
                        setOpenSections(["drilldowns"].concat(openSections))
                    }    
                }
            }}
        >
            {ALLOW_SECTION_COLLAPSE && <Icon icon={openSections.includes("drilldowns") ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED} />}
            {STRINGS.navigator.addNewWidgetControl.drilldownsSectionHeader}
            <span className="font-weight-normal">{STRINGS.navigator.addNewWidgetControl.drilldownsSectionSubHeader}</span>
        </div>
        {(!ALLOW_SECTION_COLLAPSE || openSections.includes("drilldowns")) && <div className="add-widget-control-list-items font-weight-normal pb-4">
            <InputGroup id={"groupBysFilterInput"} leftIcon="search" rightElement={groupBysFilterClearIcon}
                onChange={handleGroupByListsFilterChange} placeholder={STRINGS.incidents.impactSummaryView.search}
                value={groupBysFilterValue} className="mb-3 mt-2"
            />
            {drilldownCategories.length ? (drilldownCategories.map((category, index) => {
                let showCategory = false;
                groupBys.forEach((groupByDef) => {
                    if (groupByDef?.category === category) {
                        showCategory = true;
                    }
                });
                if (showCategory) {
                    return (
                        <div key={index} className="add-widget-control-category mb-1">
                            <div key={"drillown-category-" + index}
                                onClick={() => {
                                    if (!openDrilldownCategories.includes(category)) {
                                        setOpenDrilldownCategories((drilldownCategoryOpen) => [...drilldownCategoryOpen, category]);
                                    } else {
                                        let openedCategories = clone(openDrilldownCategories);
                                        openedCategories.splice(openedCategories.indexOf(category), 1);
                                        setOpenDrilldownCategories(openedCategories);
                                    }
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <Icon icon={openDrilldownCategories.includes(category) ? APP_ICONS.SECTION_OPEN : APP_ICONS.SECTION_CLOSED}/>
                                <span className="pl-1 font-weight-bold">{category}</span>
                            </div>
                            {openDrilldownCategories.includes(category) &&
                                groupBys.map((groupByDef) => {
                                    return (
                                        groupByDef.category === category && (
                                            <div key={"drilldown-" + groupByDef.id} className="widget-editor-item display-9 mt-2 ml-3">
                                                <Checkbox type="checkbox" id={groupByDef.id}
                                                    label={groupByDef.label}
                                                    checked={selectedGroupBys.includes(groupByDef.id)}
                                                    onChange={handleGroupBysChange}
                                                />
                                            </div>
                                        )
                                    );
                                })}
                        </div>
                    );
                } else return null;
            })
            ) : (
                <div className="add-widget-control-item display-9">
                    {STRINGS.navigator.addNewWidgetControl.noDrilldownsText}
                </div>
            )}
            {/*groupBys?.length ? (
                groupBys.map((groupById, index) => (
                    <div  className="add-widget-control-item display-9">
                        <Checkbox type="checkbox" 
                            id={groupById}
                            label={DataOceanUtils.dataOceanMetaData.keys[groupById]?.label}
                            checked={selectedGroupBys.includes(groupById)}
                            onChange={handleGroupBysChange}
                        />
                    </div>
                ))
            ) : (
                <div className="add-widget-control-item display-9">
                    {STRINGS.navigator.addNewWidgetControl.noDrilldownsText}
                </div>
            )*/}
        </div>}
        <div>
            <Button className="mr-2" aria-label="cancel" type="button"
                onClick={() => { props.bladeClose(); }}
            >
                {STRINGS.navigator.addNewWidgetControl.closeButtonText}
            </Button>
            <Button intent={Intent.SUCCESS} aria-label="submit" disabled={false} type="submit"
                onClick={() => { 
                    props.onAddNewWidget(selectedWidgetType, selectedColumns, selectedGroupBys, props.filters); 
                }}
            >
                {STRINGS.navigator.addNewWidgetControl.applyButtonText}
            </Button>
        </div>
    </div>;
};
  
export default AddNewWidgetControl;
