/** This module contains the GenAiService that can be used to query the generative AI API
 *  @module
 */
import { ApiService } from 'utils/services/ApiService';

export enum GenAiRequestRole {
    system = "system",
    user = "user"
}

export enum GenAiRequestContentType {
    text = "text"
}

export interface GenAiRequestContent {
    type: GenAiRequestContentType;
    text: string;
}
export interface GenAiRequestMessage {
    role: GenAiRequestRole;
    content: GenAiRequestContent[];
}

export interface GenAiRequest {
    messages: GenAiRequestMessage[];
}

export enum GenAiResponseType {
    text = "text"
}

export enum GenAiResponseRole {
    assistant = "assistant"
}

export enum GenAiResponseContentType {
    text = "text"
}

export interface GenAiResponseContent {
    type: GenAiResponseContentType;
    text: string;
}

export interface GenAiResponseMessage {
    role: GenAiResponseRole;
    content: GenAiResponseContent[];
}

export interface GenAiResponse {
    messages: GenAiResponseMessage[];
}

// The URL for the API server.
export const GEN_AI_BASE_URL = '/api/affogato/';

/** this class defines the GenAiApiService. */
class GenAiApiService extends ApiService {
    /** the constructor for the class. */
    constructor() {
        super(GEN_AI_BASE_URL);
    }

    /** returns the base uri, this can be overridden in subclasses to allow the uri to change
     *      after construction.
     *  @returns a String with the base uri. */
     protected getBaseUri(): string {
        if (ApiService.USE_REGION) {
            const region = ApiService.AUTH_SERVICE.getRegion();
            return `/api/iq/${region}/`;    
        } else {
            return this.baseApiUri;
        }
    }

    /** runs the specified generative AI query and returns the generative AI response.
     *  @param request the GenAiRequest object with the question infomration.
     *  @returns a Promise which resolves to the returned data. */
    runAiRequest(request: GenAiRequest): Promise<GenAiResponse> {
        return new Promise((resolve, reject) => {
            const utid = ApiService.AUTH_SERVICE.getTenantId();
            super
                .post<any>(`genai/1.0/tenants/${utid}/chat`, request)
                .then(
                    (response: GenAiResponse) => {
                        resolve(response);
                    },
                    (err) => {
                        reject(err.response);
                    }
                );
        });
    }
}

/** a constant with an instance of the GenAiApiService. */
const GenAiService = new GenAiApiService();
export { GenAiService };
