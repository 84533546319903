/** This module contains the component for displaying the visualization step in the widget config dialog.  The
 *      visualization step configures the visualizati0n properties.
 *  @module
 */

import React, { useRef, useState, useEffect } from "react";
import { HTMLSelect, InputGroup } from "@blueprintjs/core";
import { STRINGS } from "app-strings";
import { WIDGET_TYPE_TO_LABEL, WIDGET_TYPE } from "components/common/vis-framework/widget/Widget.type";
import { NavigatorWidgetConfig, QueryConfig } from "pages/navigator/Navigator.type";
import { TableNodeEditor } from "components/common/graph/editors/table/TableNodeEditor";
import { SimpleNodeEditor } from "components/common/graph/editors/simple/SimpleNodeEditor";
import { GraphDef, NodeProperty, RunbookInfo, Variant } from "components/common/graph/types/GraphTypes";
import { UniversalNode } from "components/common/graph/UniversalNode";
import { Node } from "react-flow-renderer";
import rvbdLibraryJSON from "pages/create-runbook/views/create-runbook/node_library.json";
import { RunbookConfig, RunbookNode } from "utils/services/RunbookApiService";
import { getGraphDefFromRunbookConfig } from "pages/create-runbook/views/create-runbook/CreateRunbookView";
import { NavigatorUtils } from "pages/navigator/NavigatorUtils";
import { NodeLibrary, NodeLibraryCategory, NodeLibraryNode, NodeLibrarySpec } from "pages/create-runbook/views/create-runbook/NodeLibrary";
import RunbookNodeLibrary from "pages/create-runbook/views/create-runbook/node_library.json";

/** Node type to editor mapping. */
const NodeEditors = {
    [WIDGET_TYPE.TABLE]: TableNodeEditor,
    [WIDGET_TYPE.TEXT]: SimpleNodeEditor,
    [WIDGET_TYPE.BAR]: SimpleNodeEditor,
    [WIDGET_TYPE.PIE]: SimpleNodeEditor,
    [WIDGET_TYPE.TIMESERIES]: SimpleNodeEditor,
    [WIDGET_TYPE.BUBBLE]: SimpleNodeEditor,
    [WIDGET_TYPE.CORRELATION]: SimpleNodeEditor,
    [WIDGET_TYPE.CONNECTION_GRAPH]: SimpleNodeEditor,
    [WIDGET_TYPE.GAUGES]: SimpleNodeEditor,
    [WIDGET_TYPE.CARDS]: SimpleNodeEditor,
    [WIDGET_TYPE.DEBUG]: SimpleNodeEditor,
    // We need a map editor but use table for now
    [WIDGET_TYPE.GEO_MAP]: TableNodeEditor
};

/** this interface defines the properties passed into the WidgetConfigVisualizationStep React component. */
export interface WidgetConfigVisualizationStepProps {
    /** the configuration of this current dashboard widget. */
    config: NavigatorWidgetConfig;
    /** the current runbook configuration. */
    //runbookConfig?: RunbookConfig;
    /** the query configuration that descripts what data is being queried.  This object has all the changes
     *  incorporated into it from the multi-step dialog. */
    queryConfig?: QueryConfig;
    /** the handler for widget name change events. */
    onWidgetNameChange?: (name: string) => void;
    /** the handler for widget type change events. */
    onWidgetTypeChange?: (type: WIDGET_TYPE) => void;
}

/** this interface defines the functions available in the WidgetConfigVisualizationStep widget ref. */
export interface WidgetConfigVisualizationStepRef {
    /** returns the options from the node editor. */
    getOptions: () => any;
}

/** Renders the dashboard widget.
 *  @param props the properties passed in.
 *  @returns JSX with the dashboard widget component.*/
const WidgetConfigVisualizationStep = React.forwardRef<WidgetConfigVisualizationStepRef, WidgetConfigVisualizationStepProps>((props: WidgetConfigVisualizationStepProps, ref: any): JSX.Element => { 
    const widgetName = useRef<string>(props.config.name);
    const [widgetType, setWidgetType] = useState<WIDGET_TYPE>(props.config.widgetType);
    
    // The dashboards have their own node library, but here we use the runbooks node library because we are 
    // taking the dashboard runbook and creating a standard runbook from it with a visual node in it.  The 
    // dashboard runbooks don't have visualization nodes.
    const nodeLibrary = useRef<NodeLibrary>(new NodeLibrary(RunbookNodeLibrary as NodeLibrarySpec));
    let libraryNode: NodeLibraryNode | undefined = getNodeLibraryNode(widgetType);

    const mockSelectedNode = useRef<UniversalNode>(NavigatorUtils.createSelectedNodeForNodeEditor(widgetType));
    NavigatorUtils.updateSelectedNodeProperties(mockSelectedNode.current, libraryNode, props.config.options);

    // Create a graph def that hooks up the chart node to the data ocean node.
    //let runbookConfig: RunbookConfig = NavigatorUtils.appendVisualizationNodeToRunbookConfig(props.runbookConfig, widgetType);
    let runbookConfig: RunbookConfig = NavigatorUtils.createGraphDefFromQueryAndWidgetInfo(props.queryConfig, widgetType);
    const graphDef: GraphDef = getGraphDefFromRunbookConfig(nodeLibrary.current, runbookConfig, []);

    const EditorComponent = NodeEditors[widgetType];
    const editorRef = useRef<any>(undefined);
    const editorProps = {
        selectedNode: mockSelectedNode.current,
        libraryNode: libraryNode,
        globalNodes: [],
        activeRunbook: {} as RunbookInfo,
        graphDef: graphDef,
        nodeLibrary: nodeLibrary.current
    };

    useEffect(() => {
            ref({
                getOptions: () => {
                    let options: any = undefined;
                    const editor = editorRef.current;
                    if (editor) {
                        options = {};
                        let errorMessages = [];
                        if (editor.validate) {
                            errorMessages = editor.validate();
                        }
                        if (errorMessages && errorMessages.length) {
                            //this.setState({errorMessages: errorMessages});
                            editor.updateNode();
                            if (mockSelectedNode.current?.node) {
                                for (const prop of ((mockSelectedNode.current?.node as Node)?.data?.properties || []) as NodeProperty[]) {
                                    options[prop.key] = prop.value;
                                }
                            }
                        } else {
                            editor.updateNode();
                            if (mockSelectedNode.current?.node) {
                                for (const prop of ((mockSelectedNode.current?.node as Node)?.data?.properties || []) as NodeProperty[]) {
                                    options[prop.key] = prop.value;
                                }
                            }
                        }
                    }
                    return options;                
                }
            });
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    let doNode: RunbookNode | undefined = props.queryConfig as any as RunbookNode /*NavigatorUtils.getDataNode(props.runbookConfig)*/;
    const isTimeseries = doNode?.properties?.timeSeries === true;
    const tsWidgets = [WIDGET_TYPE.TIMESERIES, WIDGET_TYPE.CARDS, WIDGET_TYPE.CORRELATION];

    return <div className="h-100"><table><tbody>
        <tr>
            <td className="p-1" colSpan={2}>
                <label>{STRINGS.viewDashboard.widget.visStep.nameLabel}</label><br />
                <InputGroup id="widget-name" type="text" 
                    defaultValue={widgetName.current} style={{width: "300px"}} 
                    onChange={(event) => {
                        widgetName.current = event.currentTarget.value;
                        if (props.onWidgetNameChange) {
                            props.onWidgetNameChange(widgetName.current);
                        }
                    }}
                />
            </td>
        </tr>
        <tr>
            <td className="p-1" colSpan={2}>
                <label>{STRINGS.viewDashboard.widget.visStep.visLabel}</label><br />
                <HTMLSelect defaultValue={widgetType} 
                    options={Object.keys(WIDGET_TYPE).filter(key => {
                        return Boolean(isTimeseries) === Boolean(tsWidgets.includes(WIDGET_TYPE[key])) || WIDGET_TYPE[key] === WIDGET_TYPE.CARDS; 
                    }).map(key => {
                        return {value: WIDGET_TYPE[key], label: WIDGET_TYPE_TO_LABEL[WIDGET_TYPE[key]]};
                    })}
                    onChange={(event) => {
                        const newWidgetType = event.currentTarget.value as WIDGET_TYPE;
                        if (widgetType !== newWidgetType) {
                            (mockSelectedNode.current?.node as Node).data!.properties = [];
                            setWidgetType(newWidgetType);
                            if (props.onWidgetTypeChange) {
                                props.onWidgetTypeChange(newWidgetType);
                            }
                        }
                    }} 
                />
            </td>
        </tr>
        <EditorComponent {...editorProps} ref={editorRef} variant={Variant.INCIDENT} subflows={[]} />
    </tbody></table></div>;
});
  
export default WidgetConfigVisualizationStep;

/** returns the library node for the specified widget.
 *  @param widgetType the WIDGET_TYPE whose node is requested.
 *  @returns the NodeLibraryNode for the widget or undefined. */
export function getNodeLibraryNode(widgetType: WIDGET_TYPE): NodeLibraryNode | undefined {
    if (rvbdLibraryJSON?.categories?.length) {
        const category: NodeLibraryCategory | undefined = (rvbdLibraryJSON as NodeLibrarySpec)?.categories?.find((category) => category.name === "rvbdCharts");
        if (category?.nodes?.length) {
            const node = category.nodes?.find((node) => {
                return NavigatorUtils.getRunbookNodeTypeForWidget(widgetType) === node.type;
            });
            if (node) {
                const propsToRemove = ["title", "row", "notes", "notesPosition"];
                for (let propIndex = (node.properties || []).length -1; propIndex >= 0; propIndex--) {
                    if (propsToRemove.includes(node.properties![propIndex].name)) {
                        node.properties?.splice(propIndex, 1);
                    }
                }
                return node;
            }
        }        
    }
    return undefined;
}
