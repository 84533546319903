import { Node, Edge, Elements, isNode, isEdge } from "react-flow-renderer";
import { STRINGS } from "app-strings";
import { LayoutOptions } from "elkjs/lib/elk.bundled.js";
import { getLayoutedGraph, overlapRemovalOption } from "./ELKLayout";
import { PROVIDER_TYPES } from "components/enums";

/** this interface defines the CloudIM GraphDef object. The CloudIM GraphDef object defines the data in 
 *  a graph.  It consists of nodes and edges. */
export interface CloudIMGraphDef {
    /** the array of nodes. */
    nodes: Node[];
    /** the array of edges. */
    edges: Edge[];
}

/** this interface defines the raw CloudIM GraphDef object. */
interface CloudIMResourceGraphDef {
    nodes: Array<any>;
    edges: Array<any>;
}

/** this enum specifies other supported types. */
export enum CloudIMSupportedTypes {
    INTERFACE = "interface",
}

export interface CloudIMDataInfo {
    /** the display name for the data. */
    label: string;
    /** the type of data. */
    type: AWSSupportedTypes | NetIMSupportedDeviceTypes | NetIMSupportedLinkTypes | CloudIMSupportedTypes;
    /** the timestamp of the data, when it was last updated. */
    timestamp: string;
    /** the attributes of the data, contains more info on the data. */
    attributes?: any;
}

/** outputs the react-flow elements based on the CloudIM GraphDef object.
 *  @param graphDef the CloudIM GraphDef object with the graph data.
 *  @returns the Elements with the graph nodes and edges.*/
export function getElements(graphDef: CloudIMGraphDef): Elements {
    const elements: Elements = [];

    for (const node of graphDef.nodes) {
        let nodeEl: Node<any> = node;
        elements.push(nodeEl);
    }

    for (const edge of graphDef.edges) {
        let edgeEl: Edge<any> = edge;
        elements.push(edgeEl);
    }

    return elements;
}

/** outputs the CloudIM GraphDef object based on the react-flow elements.
 *  @param the Elements with the graph nodes and edges.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export function getGraphDef(elements: Elements): CloudIMGraphDef {
    let nodes: Node[] = [];
    let edges: Edge[] = [];
    if (elements) {
        for (const element of elements) {
            if (isNode(element)) {
                nodes.push(element as Node);
            } else if (isEdge(element)) {
                edges.push(element as Edge);
            }
        }
    }

    return {
        nodes: nodes,
        edges: edges
    };
}

/** this enum specifies the supported AWS types. */
export enum AWSSupportedTypes {
    ACCOUNT = "account",
    OWNER = "owner",
    VPC = "vpc",
    VPCPEERINGCONNECTION = "vpcPeeringConnection",
    SUBNET = "subnet",
    COMPUTEINSTANCE = "compute_instance",
    BLOBSTORAGE = "blob_storage",
    LAMBDA = "lambda"
}

/** a constant which maps the aws type enum value to a label. */
export const AWS_TYPES_TO_LABEL_MAP: Record<string, string> = {
    [AWSSupportedTypes.ACCOUNT]: STRINGS.cloudim.topology.aws.account,
    [AWSSupportedTypes.OWNER]: STRINGS.cloudim.topology.aws.owner,
    [AWSSupportedTypes.VPC]: STRINGS.cloudim.topology.aws.vpc,
    [AWSSupportedTypes.VPCPEERINGCONNECTION]: STRINGS.cloudim.topology.aws.vpcPeeringConnection,
    [AWSSupportedTypes.SUBNET]: STRINGS.cloudim.topology.aws.subnet,
    [AWSSupportedTypes.COMPUTEINSTANCE]: STRINGS.cloudim.topology.aws.computeInstance,
    [AWSSupportedTypes.BLOBSTORAGE]: STRINGS.cloudim.topology.aws.blobStorage,
    [AWSSupportedTypes.LAMBDA]: STRINGS.cloudim.topology.aws.lambda,
}

/** this enum specifies the operation performed on ResourceOperation function. */
enum OperationTypes {
    // Adds data attributes, save time to check.
    ADD = "Add",
    // Check and adds (if does not exist), Does not add attributes.
    CHECKANDADD = "CheckAndAdd",
    // Check and update (add if does not exist), Adds the attribute.
    CHECKANDUPDATE = "CheckAndUpdate",
}

/** outputs index of an AWS resource id if it exist (to prevent adding to the dataset)
 *  @param kind: what we are checking.
 *  @param attributes: the data.
 *  @param nodes: our dataset we're checking.
 *  @returns number: returns index of found id, -1 if none found */
function AWSIdIndex(kind: AWSSupportedTypes, attributes: any, nodes: Array<any>): number {
    let id = "";

    switch (kind) {
        case AWSSupportedTypes.ACCOUNT:
        case AWSSupportedTypes.OWNER:
            // Ids that are named differently
            const ownerIds = [attributes?.OwnerId, attributes?.ownerId];
            id = ownerIds.find(id => id !== undefined);
            break;
        case AWSSupportedTypes.VPC:
            // Ids that are named differently
            const vpcIds = [attributes?.VpcId, attributes?.vpcId];
            id = vpcIds.find(id => id !== undefined);
            break;
        case AWSSupportedTypes.VPCPEERINGCONNECTION:
            id = attributes?.VpcPeeringConnectionId;
            break;
        case AWSSupportedTypes.SUBNET:
            id = attributes?.SubnetId;
            break;
        case AWSSupportedTypes.COMPUTEINSTANCE:
            id = attributes?.InstanceId;
            break;
        case AWSSupportedTypes.BLOBSTORAGE:
            id = attributes?.S3BucketId;
            break;
        case AWSSupportedTypes.LAMBDA:
            id = attributes?.Arn;
            break;
        default:
            break;
    }

    if (id === undefined || id === null) {
        return -1;
    }

    return nodes.findIndex(node => node.id === id);
}

/** outputs the object of what we're adding.
 *  @param kind: what we are checking.
 *  @param attributes: the data.
 *  @param saveAttributes?: saves the data.
 *  @returns CloudIMResourceGraphDef: object containing what we're adding. */
function AWSAddResource(kind: AWSSupportedTypes, attributes: any, saveAttributes?: boolean): CloudIMResourceGraphDef {
    let graph: CloudIMResourceGraphDef = { nodes: [], edges: [] };
    let { nodes, edges } = graph;

    // Ids that are named differently
    const ownerIds = [attributes?.OwnerId, attributes?.ownerId];
    const ownerId = ownerIds.find(id => id !== undefined);
    const vpcIds = [attributes?.VpcId, attributes?.vpcId];
    const vpcId = vpcIds.find(id => id !== undefined);
    const timestamp = attributes.timestamp;

    switch (kind) {
        case AWSSupportedTypes.ACCOUNT:
            // Check if id is null or undefined
            if (ownerId === null || ownerId === undefined) break;
            nodes.push({
                id: ownerId,
                type: "awsNode",
                width: 40,
                height: 40,
                data: {
                    label: ownerId,
                    type: kind,
                    timestamp: timestamp,
                } as CloudIMDataInfo
            });

            break;
        case AWSSupportedTypes.OWNER:
            // Check if id is null or undefined
            if (ownerId === null || ownerId === undefined) break;
            nodes.push({
                id: ownerId,
                type: "awsNode",
                width: 40,
                height: 40,
                data: {
                    label: attributes.OwnerName ? attributes.OwnerName : ownerId,
                    type: kind,
                    timestamp: timestamp,
                } as CloudIMDataInfo
            });

            break;
        case AWSSupportedTypes.VPC:
            // Check if id is null or undefined
            if (vpcId === null || vpcId === undefined) break;
            // For vpcPeeringConnections, connecting VPC may be in another region.
            const region = attributes.region ? " (" + attributes.region + ")" : "";

            nodes.push({
                id: vpcId,
                type: "awsNode",
                width: 40,
                height: 40,
                data: {
                    label: vpcId + region,
                    type: kind,
                    timestamp: timestamp,
                    attributes: saveAttributes ? attributes : {},
                } as CloudIMDataInfo
            });

            // Check if id is null or undefined
            if (vpcId === null || vpcId === undefined || ownerId === null || ownerId === undefined) break;
            edges.push({
                id: ownerId + "-" + vpcId,
                source: ownerId,
                target: vpcId,
                timestamp: timestamp,
            });

            break;
        case AWSSupportedTypes.VPCPEERINGCONNECTION:
            // Check if id is null or undefined
            if (attributes.VpcPeeringConnectionId === null || attributes.VpcPeeringConnectionId === undefined) break;
            nodes.push({
                id: attributes.VpcPeeringConnectionId,
                type: "awsNode",
                width: 40,
                height: 40,
                data: {
                    label: attributes.VpcPeeringConnectionId,
                    type: kind,
                    timestamp: timestamp,
                    attributes: saveAttributes ? attributes : {},
                } as CloudIMDataInfo
            });

            // Check if id is null or undefined
            if (attributes.VpcPeeringConnectionId === null || attributes.VpcPeeringConnectionId === undefined || attributes.RequesterVpcInfo.vpcId === null || attributes.RequesterVpcInfo.vpcId === undefined) break;
            edges.push({
                id: attributes.RequesterVpcInfo.vpcId + "-" + attributes.VpcPeeringConnectionId,
                source: attributes.RequesterVpcInfo.vpcId,
                target: attributes.VpcPeeringConnectionId,
                timestamp: timestamp,
            });

            // Check if id is null or undefined
            if (attributes.VpcPeeringConnectionId === null || attributes.VpcPeeringConnectionId === undefined || attributes.AccepterVpcInfo.vpcId === null || attributes.AccepterVpcInfo.vpcId === undefined) break;
            edges.push({
                id: attributes.VpcPeeringConnectionId + "-" + attributes.AccepterVpcInfo.vpcId,
                source: attributes.VpcPeeringConnectionId,
                target: attributes.AccepterVpcInfo.vpcId,
                timestamp: timestamp,
            });

            break;
        case AWSSupportedTypes.SUBNET:
            // Check if id is null or undefined
            if (attributes.SubnetId === null || attributes.SubnetId === undefined) break;
            nodes.push({
                id: attributes.SubnetId,
                type: "awsNode",
                width: 40,
                height: 40,
                data: {
                    label: attributes.SubnetId,
                    // TODO: Not sure if it is a public or private subnet, so currently using custom subnet without background
                    type: "subnet",
                    timestamp: timestamp,
                    attributes: saveAttributes ? attributes : {},
                } as CloudIMDataInfo
            });

            // Check if id is null or undefined
            if (attributes.VpcId === null || attributes.VpcId === undefined || attributes.SubnetId === null || attributes.SubnetId === undefined) break;
            edges.push({
                id: attributes.VpcId + "-" + attributes.SubnetId,
                source: attributes.VpcId,
                target: attributes.SubnetId,
                timestamp: timestamp,
            });

            break;
        case AWSSupportedTypes.COMPUTEINSTANCE:
            // Check if id is null or undefined
            if (attributes.InstanceId === null || attributes.InstanceId === undefined) break;
            nodes.push({
                id: attributes.InstanceId,
                type: "awsNode",
                width: 40,
                height: 40,
                data: {
                    label: attributes.InstanceId,
                    type: kind,
                    timestamp: timestamp,
                    attributes: saveAttributes ? attributes : {},
                } as CloudIMDataInfo
            });

            // Check if id is null or undefined
            if (attributes.InstanceId === null || attributes.InstanceId === undefined || attributes.SubnetId === null || attributes.SubnetId === undefined) break;
            edges.push({
                id: attributes.SubnetId + "-" + attributes.InstanceId,
                source: attributes.SubnetId,
                target: attributes.InstanceId,
                timestamp: timestamp,
            });

            break;
        case AWSSupportedTypes.BLOBSTORAGE:
            // Check if id is null or undefined
            if (attributes.S3BucketId === null || attributes.S3BucketId === undefined) break;
            nodes.push({
                id: attributes.S3BucketId,
                type: "awsNode",
                width: 40,
                height: 40,
                data: {
                    label: attributes.Name,
                    type: kind,
                    timestamp: timestamp,
                    attributes: saveAttributes ? attributes : {},
                } as CloudIMDataInfo
            });

            // Check if id is null or undefined
            if (attributes.S3BucketId === null || attributes.S3BucketId === undefined || ownerId === null || ownerId === undefined) break;
            edges.push({
                id: ownerId + "-" + attributes.S3BucketId,
                source: ownerId,
                target: attributes.S3BucketId,
                timestamp: timestamp,
            });

            break;
        case AWSSupportedTypes.LAMBDA:
            // Check if id is null or undefined
            if (attributes.Arn === null || attributes.Arn === undefined) break;
            nodes.push({
                id: attributes.Arn,
                type: "awsNode",
                width: 40,
                height: 40,
                data: {
                    label: attributes.Name,
                    type: kind,
                    timestamp: timestamp,
                    attributes: saveAttributes ? attributes : {},
                } as CloudIMDataInfo
            });

            // Check if id is null or undefined
            if (attributes.Arn === null || attributes.Arn === undefined || ownerId === null || ownerId === undefined) break;
            edges.push({
                id: ownerId + "-" + attributes.Arn,
                source: ownerId,
                target: attributes.Arn,
                timestamp: timestamp,
            });
            break;
        default:
            break;
    }

    return graph;
}

/** Modifies the graph based on the operations done to it.
 *  @param operation: what we want to do.
 *  @param kind: what we are checking. 
 *  @param attributes: the data we are working with.
 *  @param graph: the dataset. */
function AWSResourceOperation(operation: OperationTypes, kind: AWSSupportedTypes, attributes: any, graph: CloudIMResourceGraphDef): void {
    switch (operation) {
        case OperationTypes.ADD:
            const addResources = AWSAddResource(kind, attributes, true);
            graph.nodes = graph.nodes.concat(addResources.nodes);
            graph.edges = graph.edges.concat(addResources.edges);
            break;
        case OperationTypes.CHECKANDADD:
            const checkAndAddResources = AWSAddResource(kind, attributes, false);
            if (AWSIdIndex(kind, attributes, graph.nodes) === -1) {
                graph.nodes = graph.nodes.concat(checkAndAddResources.nodes);
                graph.edges = graph.edges.concat(checkAndAddResources.edges);
            }
            break;
        case OperationTypes.CHECKANDUPDATE:
            const checkAndUpdateResources = AWSAddResource(kind, attributes, true);
            const index = AWSIdIndex(kind, attributes, graph.nodes);
            if (index === -1) {
                graph.nodes = graph.nodes.concat(checkAndUpdateResources.nodes);
                graph.edges = graph.edges.concat(checkAndUpdateResources.edges);
            } else {
                graph.nodes[index] = checkAndUpdateResources.nodes[0];
            }
            break;
        default:
            break;
    }
}

/** outputs the CloudIM GraphDef object based on the cloudim aws data, it also performs a layout to the data.
 *  @param AWS data, we will transform this data to fit our schema.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export async function AWSLayoutGraph(data: Array<any>, options?: LayoutOptions, overlapRemoval?: boolean): Promise<CloudIMGraphDef> {
    let layoutNodes: Node[] = [];
    let layoutEdges: Edge[] = [];

    if (data) {
        let graph: CloudIMResourceGraphDef = { nodes: [], edges: [] };

        for (const resource of data) {
            const kind = resource?.entityKind;
            const dataSourceId = resource?.source?.rootElement?.id;
            const attributes = { ...resource?.entityAttributes.rootElement, ...{ timestamp: resource?.timestamp, dataSourceId: dataSourceId } };

            // Handle each supported type
            switch (kind) {
                case AWSSupportedTypes.VPC:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, graph);
                    AWSResourceOperation(OperationTypes.CHECKANDUPDATE, AWSSupportedTypes.VPC, attributes, graph);
                    break;
                case AWSSupportedTypes.VPCPEERINGCONNECTION:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, { ...attributes.RequesterVpcInfo, ...{ timestamp: resource?.timestamp } }, graph);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, { ...attributes.RequesterVpcInfo, ...{ timestamp: resource?.timestamp } }, graph);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, { ...attributes.AccepterVpcInfo, ...{ timestamp: resource?.timestamp } }, graph);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, { ...attributes.AccepterVpcInfo, ...{ timestamp: resource?.timestamp } }, graph);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.VPCPEERINGCONNECTION, attributes, graph);
                    break;
                case AWSSupportedTypes.SUBNET:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, graph);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes, graph);
                    AWSResourceOperation(OperationTypes.CHECKANDUPDATE, AWSSupportedTypes.SUBNET, attributes, graph);
                    break;
                case AWSSupportedTypes.COMPUTEINSTANCE:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, graph);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.VPC, attributes, graph);
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.SUBNET, attributes, graph);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.COMPUTEINSTANCE, attributes, graph);
                    break;
                case AWSSupportedTypes.BLOBSTORAGE:
                    /** In AWS, the concept of the "owner" of an S3 bucket is different from the "account ID" used in other services like EC2. 
                     * When you create a S3 bucket, the bucket is associated with an owner. 
                     * The owner ID is a canonical user ID, which is a unique identifier for the AWS account that owns the bucket. 
                     * This canonical user ID is specific to the S3 service.
                     */
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.OWNER, attributes, graph);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.BLOBSTORAGE, attributes, graph);
                    break;
                case AWSSupportedTypes.LAMBDA:
                    // Save AWS account if we haven't already
                    AWSResourceOperation(OperationTypes.CHECKANDADD, AWSSupportedTypes.ACCOUNT, attributes, graph);
                    AWSResourceOperation(OperationTypes.ADD, AWSSupportedTypes.LAMBDA, attributes, graph);
                    break;
                default:
                    break;
            }
        }

        let layoutedGraph = await getLayoutedGraph(graph.nodes, graph.edges, options);
        if (overlapRemoval) {
            layoutedGraph = await getLayoutedGraph(layoutedGraph.nodes, layoutedGraph.edges, overlapRemovalOption);
        }

        layoutNodes = layoutedGraph.nodes;
        layoutEdges = layoutedGraph.edges;
    }

    return {
        nodes: layoutNodes,
        edges: layoutEdges
    }
}

/** this enum specifies the supported NetIM entity. */
enum NetIMSupportedEntityKind {
    NETWORKDEVICE = "network_device",
    NETWORKLINK = "network_link",
    NETWORKEDGE = "network_edge",
}

/** this enum specifies the supported NetIM Device types. */
export enum NetIMSupportedDeviceTypes {
    SWITCH = "Switch",
    ROUTER = "Router",
    FIREWALL = "Firewall",
    LOADBALANCER = "Load Balancer",
    HOST = "Host",
    WANACCELERATOR = "WAN Accelerator",
    MULTILAYERSWITCH = "Multi-Layer Switch",
    PRINTER = "Printer",
    UNIFIEDCOMMUNICATION = "Unified Communication",
    WIRELESS = "Wireless",
    SDWAN = "SD-WAN",
    OTHER = "Device - Other",
}

/** this enum specifies the supported NetIM Link types. */
export enum NetIMSupportedLinkTypes {
    AGGREGATE = "Aggregate",
    AGGREGATE_SERIAL_CLOUD = "Aggregate Serial Cloud",
    ETHERNET = "Ethernet",
    FAST_ETHERNET = "Fast Ethernet",
    GIGABIT_ETHERNET = "Gigabit Ethernet",
    TEN_GIGABIT_ETHERNET = "Ten Gigabit Ethernet",
    HUNDRED_GIGABIT_ETHERNET = "Hundred Gigabit Ethernet",
    LAN = "LAN",
    LAN_CLOUD = "LAN Cloud",
    ATM_CLOUD = "ATM Cloud",
    ATM = "ATM",
    ATM_PVC = "ATM PVC",
    ATM_PVP = "ATM PVP",
    ATM_SPVP = "ATM SPVP",
    ATM_SVC = "ATM SVC",
    ATM_FRAME_RELAY_PVC = "ATM-Frame Relay PVC",
    DATA_CONNECTION = "Data Connection",
    E1 = "E1",
    E3 = "E3",
    FDDI = "FDDI",
    FRAME_RELAY_CLOUD = "Frame Relay Cloud",
    FRAME_RELAY_PVC = "Frame Relay PVC",
    HSSI = "HSSI",
    IP_CLOUD = "IP Cloud",
    IP_RELAY_CLOUD = "IP Relay Cloud",
    IPSEC_TUNNEL = "IPSec Tunnel",
    ISDN = "ISDN",
    LOGICAL_IP_CLOUD = "Logical IP Cloud",
    LOOPBACK = "Loopback",
    MPLS_LSP = "MPLS LSP",
    OC3 = "OC3",
    OC12 = "OC12",
    OC192 = "OC192",
    PASSPORT_TRUNK = "Passport Trunk",
    PNNI = "Pnni",
    POINT_TO_POINT = "Point To Point",
    RADIO = "Radio",
    SDWAN_VPN = "SDWAN VPN",
    SERIAL_CLOUD = "Serial Cloud",
    SONET = "Sonet",
    STAR_LAN = "Star LAN",
    T1 = "T1",
    T3 = "T3",
    TOKEN_RING = "Token Ring",
    TUNNEL = "Tunnel",
    VIRTUAL_LAN = "Virtual LAN",
    VOICE_CONNECTION = "Voice Connection",
    OTHER = "Link - Other",
}

/** a constant which maps the netim type enum value to a label. */
export const NETIM_TYPES_TO_LABEL_MAP: Record<string, string> = {
    // NetIMSupportedDeviceTypes
    [NetIMSupportedDeviceTypes.SWITCH]: STRINGS.cloudim.topology.netim.switch,
    [NetIMSupportedDeviceTypes.ROUTER]: STRINGS.cloudim.topology.netim.router,
    [NetIMSupportedDeviceTypes.FIREWALL]: STRINGS.cloudim.topology.netim.firewall,
    [NetIMSupportedDeviceTypes.LOADBALANCER]: STRINGS.cloudim.topology.netim.loadBalancer,
    [NetIMSupportedDeviceTypes.HOST]: STRINGS.cloudim.topology.netim.host,
    [NetIMSupportedDeviceTypes.WANACCELERATOR]: STRINGS.cloudim.topology.netim.wanAccelerator,
    [NetIMSupportedDeviceTypes.MULTILAYERSWITCH]: STRINGS.cloudim.topology.netim.multilayeredSwitch,
    [NetIMSupportedDeviceTypes.PRINTER]: STRINGS.cloudim.topology.netim.printer,
    [NetIMSupportedDeviceTypes.UNIFIEDCOMMUNICATION]: STRINGS.cloudim.topology.netim.unifiedCommunication,
    [NetIMSupportedDeviceTypes.WIRELESS]: STRINGS.cloudim.topology.netim.wireless,
    [NetIMSupportedDeviceTypes.SDWAN]: STRINGS.cloudim.topology.netim.sdwan,
    [NetIMSupportedDeviceTypes.OTHER]: STRINGS.cloudim.topology.netim.device,
    // NetIMSupportedLinkTypes
    [NetIMSupportedLinkTypes.AGGREGATE]: STRINGS.cloudim.topology.netim.aggregate,
    [NetIMSupportedLinkTypes.AGGREGATE_SERIAL_CLOUD]: STRINGS.cloudim.topology.netim.aggregateSerialCloud,
    [NetIMSupportedLinkTypes.ETHERNET]: STRINGS.cloudim.topology.netim.ethernet,
    [NetIMSupportedLinkTypes.FAST_ETHERNET]: STRINGS.cloudim.topology.netim.fastEthernet,
    [NetIMSupportedLinkTypes.GIGABIT_ETHERNET]: STRINGS.cloudim.topology.netim.gigabitEthernet,
    [NetIMSupportedLinkTypes.TEN_GIGABIT_ETHERNET]: STRINGS.cloudim.topology.netim.tenGigabitEthernet,
    [NetIMSupportedLinkTypes.HUNDRED_GIGABIT_ETHERNET]: STRINGS.cloudim.topology.netim.hundredGigabitEthernet,
    [NetIMSupportedLinkTypes.LAN]: STRINGS.cloudim.topology.netim.lan,
    [NetIMSupportedLinkTypes.LAN_CLOUD]: STRINGS.cloudim.topology.netim.lanCloud,
    [NetIMSupportedLinkTypes.ATM]: STRINGS.cloudim.topology.netim.atm,
    [NetIMSupportedLinkTypes.ATM_CLOUD]: STRINGS.cloudim.topology.netim.atmCloud,
    [NetIMSupportedLinkTypes.ATM_PVC]: STRINGS.cloudim.topology.netim.atmPVC,
    [NetIMSupportedLinkTypes.ATM_PVP]: STRINGS.cloudim.topology.netim.atmPVP,
    [NetIMSupportedLinkTypes.ATM_SPVP]: STRINGS.cloudim.topology.netim.atmSPVP,
    [NetIMSupportedLinkTypes.ATM_SVC]: STRINGS.cloudim.topology.netim.atmSVC,
    [NetIMSupportedLinkTypes.ATM_FRAME_RELAY_PVC]: STRINGS.cloudim.topology.netim.atmFrameRelayPVC,
    [NetIMSupportedLinkTypes.DATA_CONNECTION]: STRINGS.cloudim.topology.netim.dataConnection,
    [NetIMSupportedLinkTypes.E1]: STRINGS.cloudim.topology.netim.e1,
    [NetIMSupportedLinkTypes.E3]: STRINGS.cloudim.topology.netim.e3,
    [NetIMSupportedLinkTypes.FDDI]: STRINGS.cloudim.topology.netim.fddi,
    [NetIMSupportedLinkTypes.FRAME_RELAY_CLOUD]: STRINGS.cloudim.topology.netim.frameRelayCloud,
    [NetIMSupportedLinkTypes.FRAME_RELAY_PVC]: STRINGS.cloudim.topology.netim.frameRelayPVC,
    [NetIMSupportedLinkTypes.HSSI]: STRINGS.cloudim.topology.netim.hssi,
    [NetIMSupportedLinkTypes.IP_CLOUD]: STRINGS.cloudim.topology.netim.ipCloud,
    [NetIMSupportedLinkTypes.IP_RELAY_CLOUD]: STRINGS.cloudim.topology.netim.ipRelayCloud,
    [NetIMSupportedLinkTypes.IPSEC_TUNNEL]: STRINGS.cloudim.topology.netim.ipSecTunnel,
    [NetIMSupportedLinkTypes.ISDN]: STRINGS.cloudim.topology.netim.isdn,
    [NetIMSupportedLinkTypes.LOGICAL_IP_CLOUD]: STRINGS.cloudim.topology.netim.logicalIPCloud,
    [NetIMSupportedLinkTypes.LOOPBACK]: STRINGS.cloudim.topology.netim.loopback,
    [NetIMSupportedLinkTypes.MPLS_LSP]: STRINGS.cloudim.topology.netim.mplsLSP,
    [NetIMSupportedLinkTypes.OC3]: STRINGS.cloudim.topology.netim.oc3,
    [NetIMSupportedLinkTypes.OC12]: STRINGS.cloudim.topology.netim.oc12,
    [NetIMSupportedLinkTypes.OC192]: STRINGS.cloudim.topology.netim.oc192,
    [NetIMSupportedLinkTypes.PASSPORT_TRUNK]: STRINGS.cloudim.topology.netim.passportTrunk,
    [NetIMSupportedLinkTypes.PNNI]: STRINGS.cloudim.topology.netim.pnni,
    [NetIMSupportedLinkTypes.POINT_TO_POINT]: STRINGS.cloudim.topology.netim.pointToPoint,
    [NetIMSupportedLinkTypes.RADIO]: STRINGS.cloudim.topology.netim.radio,
    [NetIMSupportedLinkTypes.SDWAN_VPN]: STRINGS.cloudim.topology.netim.sdwanVPN,
    [NetIMSupportedLinkTypes.SERIAL_CLOUD]: STRINGS.cloudim.topology.netim.serialCloud,
    [NetIMSupportedLinkTypes.SONET]: STRINGS.cloudim.topology.netim.sonet,
    [NetIMSupportedLinkTypes.STAR_LAN]: STRINGS.cloudim.topology.netim.starLAN,
    [NetIMSupportedLinkTypes.T1]: STRINGS.cloudim.topology.netim.t1,
    [NetIMSupportedLinkTypes.T3]: STRINGS.cloudim.topology.netim.t3,
    [NetIMSupportedLinkTypes.TOKEN_RING]: STRINGS.cloudim.topology.netim.tokenRing,
    [NetIMSupportedLinkTypes.TUNNEL]: STRINGS.cloudim.topology.netim.tunnel,
    [NetIMSupportedLinkTypes.VIRTUAL_LAN]: STRINGS.cloudim.topology.netim.virtualLAN,
    [NetIMSupportedLinkTypes.VOICE_CONNECTION]: STRINGS.cloudim.topology.netim.voiceConnection,
    [NetIMSupportedLinkTypes.OTHER]: STRINGS.cloudim.topology.netim.link,
    // Interface
    [CloudIMSupportedTypes.INTERFACE]: STRINGS.cloudim.topology.netim.interface,
}

/** outputs index of an NetIM resource id if it exist (to prevent adding to the dataset)
 *  @param kind: what we are checking.
 *  @param resource: the data.
 *  @param nodes: our dataset we're checking.
 *  @returns number: returns index of found id, -1 if none found */
function NetIMIdIndex(kind: NetIMSupportedEntityKind, resource: any, nodes: Array<any>): number {
    let id = "";

    switch (kind) {
        case NetIMSupportedEntityKind.NETWORKDEVICE:
        case NetIMSupportedEntityKind.NETWORKLINK:
        case NetIMSupportedEntityKind.NETWORKEDGE:
            id = resource?.netImEntityId;
            break;
        default:
            break;
    }

    if (id === undefined || id === null) {
        return -1;
    }

    return nodes.findIndex(node => node.id === id);
}

/** outputs the object of what we're adding.
 *  @param kind: what we are checking.
 *  @param resource: the data.
 *  @returns CloudIMResourceGraphDef: object containing what we're adding. */
function NetIMAddResource(kind: NetIMSupportedEntityKind, resource: any): CloudIMResourceGraphDef {
    let graph: CloudIMResourceGraphDef = { nodes: [], edges: [] };
    let { nodes, edges } = graph;

    const timestamp = resource.timestamp;

    // Check NetIM Supported Entity Kinds
    switch (kind) {
        case NetIMSupportedEntityKind.NETWORKDEVICE:
            const deviceId = resource?.netImEntityId;
            const deviceName = resource?.entityAttributes?.rootElement?.name;
            const deviceType = resource?.entityAttributes?.rootElement?.type;

            // check if deviceId is null or undefined
            if (deviceId === null || deviceId === undefined) break;
            // Check NetIM Support Types
            switch (deviceType) {
                case NetIMSupportedDeviceTypes.SWITCH:
                case NetIMSupportedDeviceTypes.ROUTER:
                case NetIMSupportedDeviceTypes.FIREWALL:
                case NetIMSupportedDeviceTypes.LOADBALANCER:
                case NetIMSupportedDeviceTypes.HOST:
                case NetIMSupportedDeviceTypes.WANACCELERATOR:
                case NetIMSupportedDeviceTypes.MULTILAYERSWITCH:
                case NetIMSupportedDeviceTypes.PRINTER:
                case NetIMSupportedDeviceTypes.UNIFIEDCOMMUNICATION:
                case NetIMSupportedDeviceTypes.WIRELESS:
                case NetIMSupportedDeviceTypes.SDWAN:
                    nodes.push({
                        id: deviceId,
                        type: "netimNode",
                        width: 40,
                        height: 40,
                        data: {
                            label: deviceName,
                            type: deviceType,
                            timestamp: timestamp,
                            attributes: resource?.entityAttributes?.rootElement,
                        } as CloudIMDataInfo
                    });

                    break;
                default:
                    nodes.push({
                        id: deviceId,
                        type: "netimNode",
                        width: 40,
                        height: 40,
                        data: {
                            label: deviceName,
                            type: NetIMSupportedDeviceTypes.OTHER,
                            timestamp: timestamp,
                            attributes: resource?.entityAttributes?.rootElement,
                        } as CloudIMDataInfo
                    });

                    break;
            }
            break;
        case NetIMSupportedEntityKind.NETWORKLINK:
            const linkId = resource?.netImEntityId;
            const linkName = resource?.entityAttributes?.rootElement?.name;
            const linkType = resource?.entityAttributes?.rootElement?.linkType;

            // Check if linkId is null or undefined
            if (linkId === null || linkId === undefined) break;
            // Check NetIM Support Types
            switch (linkType) {
                case NetIMSupportedLinkTypes.AGGREGATE:
                case NetIMSupportedLinkTypes.AGGREGATE_SERIAL_CLOUD:
                case NetIMSupportedLinkTypes.ETHERNET:
                case NetIMSupportedLinkTypes.FAST_ETHERNET:
                case NetIMSupportedLinkTypes.GIGABIT_ETHERNET:
                case NetIMSupportedLinkTypes.TEN_GIGABIT_ETHERNET:
                case NetIMSupportedLinkTypes.HUNDRED_GIGABIT_ETHERNET:
                case NetIMSupportedLinkTypes.LAN:
                case NetIMSupportedLinkTypes.LAN_CLOUD:
                case NetIMSupportedLinkTypes.ATM_CLOUD:
                case NetIMSupportedLinkTypes.ATM:
                case NetIMSupportedLinkTypes.ATM_PVC:
                case NetIMSupportedLinkTypes.ATM_PVP:
                case NetIMSupportedLinkTypes.ATM_SPVP:
                case NetIMSupportedLinkTypes.ATM_SVC:
                case NetIMSupportedLinkTypes.ATM_FRAME_RELAY_PVC:
                case NetIMSupportedLinkTypes.DATA_CONNECTION:
                case NetIMSupportedLinkTypes.E1:
                case NetIMSupportedLinkTypes.E3:
                case NetIMSupportedLinkTypes.FDDI:
                case NetIMSupportedLinkTypes.FRAME_RELAY_CLOUD:
                case NetIMSupportedLinkTypes.FRAME_RELAY_PVC:
                case NetIMSupportedLinkTypes.HSSI:
                case NetIMSupportedLinkTypes.IP_CLOUD:
                case NetIMSupportedLinkTypes.IP_RELAY_CLOUD:
                case NetIMSupportedLinkTypes.IPSEC_TUNNEL:
                case NetIMSupportedLinkTypes.ISDN:
                case NetIMSupportedLinkTypes.LOGICAL_IP_CLOUD:
                case NetIMSupportedLinkTypes.LOOPBACK:
                case NetIMSupportedLinkTypes.MPLS_LSP:
                case NetIMSupportedLinkTypes.OC3:
                case NetIMSupportedLinkTypes.OC12:
                case NetIMSupportedLinkTypes.OC192:
                case NetIMSupportedLinkTypes.PASSPORT_TRUNK:
                case NetIMSupportedLinkTypes.PNNI:
                case NetIMSupportedLinkTypes.POINT_TO_POINT:
                case NetIMSupportedLinkTypes.RADIO:
                case NetIMSupportedLinkTypes.SDWAN_VPN:
                case NetIMSupportedLinkTypes.SERIAL_CLOUD:
                case NetIMSupportedLinkTypes.SONET:
                case NetIMSupportedLinkTypes.STAR_LAN:
                case NetIMSupportedLinkTypes.T1:
                case NetIMSupportedLinkTypes.T3:
                case NetIMSupportedLinkTypes.TOKEN_RING:
                case NetIMSupportedLinkTypes.TUNNEL:
                case NetIMSupportedLinkTypes.VIRTUAL_LAN:
                case NetIMSupportedLinkTypes.VOICE_CONNECTION:
                    nodes.push({
                        id: linkId,
                        type: "netimNode",
                        width: 40,
                        height: 40,
                        data: {
                            label: linkName,
                            type: linkType,
                            timestamp: timestamp,
                            attributes: resource?.entityAttributes?.rootElement,
                        } as CloudIMDataInfo
                    });

                    break;
                default:
                    nodes.push({
                        id: linkId,
                        type: "netimNode",
                        width: 40,
                        height: 40,
                        data: {
                            label: linkName,
                            type: NetIMSupportedLinkTypes.OTHER,
                            timestamp: timestamp,
                            attributes: resource?.entityAttributes?.rootElement,
                        } as CloudIMDataInfo
                    });

                    break;
            }
            break;
        case NetIMSupportedEntityKind.NETWORKEDGE:
            const edgeId = resource?.netImEntityId;
            const source = resource?.entityAttributes?.rootElement?.source;
            const target = resource?.entityAttributes?.rootElement?.target;
            
            const attributes = resource?.networkInterface?.entityAttributes?.rootElement;
            // Check if edgeId, source, or target is null or undefined
            if (edgeId === null || edgeId === undefined || source === null || source === undefined || target === null || target === undefined) break;

            edges.push({
                id: edgeId + "-" + source + "-" + target,
                type: "netimEdge",
                source: source,
                target: target,
                data: {
                    label: attributes?.name,
                    type: CloudIMSupportedTypes.INTERFACE,
                    timestamp: timestamp,
                    attributes: attributes,
                } as CloudIMDataInfo
            });

            break;
        default:
            break;
    }

    return graph;
}

/** Modifies the graph based on the operations done to it.
 *  @param operation: what we want to do.
 *  @param kind: what we are checking. 
 *  @param resource: the data we are working with.
 *  @param graph: the dataset. */
function NetIMResourceOperation(operation: OperationTypes, kind: NetIMSupportedEntityKind, resource: any, graph: CloudIMResourceGraphDef): void {
    const resources = NetIMAddResource(kind, resource);

    switch (operation) {
        case OperationTypes.ADD:
            graph.nodes = graph.nodes.concat(resources.nodes);
            graph.edges = graph.edges.concat(resources.edges);
            break;
        case OperationTypes.CHECKANDADD:
            if (NetIMIdIndex(kind, resource, graph.nodes) === -1) {
                graph.nodes = graph.nodes.concat(resources.nodes);
                graph.edges = graph.edges.concat(resources.edges);
            }
            break;
        case OperationTypes.CHECKANDUPDATE:
            const index = NetIMIdIndex(kind, resource, graph.nodes);
            if (index === -1) {
                graph.nodes = graph.nodes.concat(resources.nodes);
                graph.edges = graph.edges.concat(resources.edges);
            } else {
                graph.nodes[index] = resources.nodes[0];
            }
            break;
        default:
            break;
    }
}

/** outputs the CloudIM GraphDef object based on the cloudim NetIM data, it also performs a layout to the data.
 *  @param NetIM data, we will transform this data to fit our schema.
 *  @returns graphDef the CloudIM GraphDef object with the graph data.*/
export async function NetIMLayoutGraph(data: Array<any>, options?: LayoutOptions, overlapRemoval?: boolean): Promise<CloudIMGraphDef> {
    let layoutNodes: Node[] = [];
    let layoutEdges: Edge[] = [];

    if (data) {
        let graph: CloudIMResourceGraphDef = { nodes: [], edges: [] };

        for (const resource of data) {
            const kind = resource?.entityKind;

            // Handle each supported type
            switch (kind) {
                case NetIMSupportedEntityKind.NETWORKDEVICE:
                    NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKDEVICE, resource, graph);
                    break;
                case NetIMSupportedEntityKind.NETWORKLINK:
                    NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKLINK, resource, graph);
                    break;
                case NetIMSupportedEntityKind.NETWORKEDGE:
                    NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKEDGE, resource, graph);
                    break;
                default:
                    break;
            }
        }

        let layoutedGraph = await getLayoutedGraph(graph.nodes, graph.edges, options);
        if (overlapRemoval) {
            layoutedGraph = await getLayoutedGraph(layoutedGraph.nodes, layoutedGraph.edges, overlapRemovalOption);
        }

        layoutNodes = layoutedGraph.nodes;
        layoutEdges = layoutedGraph.edges;
    }

    return {
        nodes: layoutNodes,
        edges: layoutEdges
    }
}

/** outputs the pinpointed properties data based on the filters provided.
 *  @param data, the data from the queries that we're working with.
 *  @param provider, what provider is this data from.
 *  @param type, filtering and matching based on type.
 *  @param id, filtering and matching based on id.
 *  @returns the pinpointed CloudIM data.*/
export function getFilteredPropertiesData(data: any, provider: PROVIDER_TYPES, type: AWSSupportedTypes | NetIMSupportedDeviceTypes | NetIMSupportedLinkTypes | CloudIMSupportedTypes, id: string): CloudIMDataInfo | undefined {
    let graph: CloudIMResourceGraphDef = { nodes: [], edges: [] };
    
    // Saving and formatting the data the same way we do the layouting. This is for consistency.
    switch (provider) {
        case PROVIDER_TYPES.AWS:
            for (const resource of data) {
                const kind = resource?.entityKind;
                const dataSourceId = resource?.source?.rootElement?.id;
                const attributes = { ...resource?.entityAttributes.rootElement, ...{ timestamp: resource?.timestamp, dataSourceId: dataSourceId } };
    
                if (kind === type && resource?.entityAttributes?.rootElement?.entity_id === id) {
                    AWSResourceOperation(OperationTypes.CHECKANDUPDATE, kind, attributes, graph);
                    break;
                }
            }
            break;

        case PROVIDER_TYPES.ONPREM:
            for (const resource of data) {
                const kind = resource?.entityKind;

                // Handle each supported type
                switch (kind) {
                    case NetIMSupportedEntityKind.NETWORKDEVICE:
                        NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKDEVICE, resource, graph);
                        break;
                    case NetIMSupportedEntityKind.NETWORKLINK:
                        NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKLINK, resource, graph);
                        break;
                    case NetIMSupportedEntityKind.NETWORKEDGE:
                        NetIMResourceOperation(OperationTypes.ADD, NetIMSupportedEntityKind.NETWORKEDGE, resource, graph);
                        break;
                    default:
                        break;
                }
            }
            break;
        default:
            break;
    }

    for (const graphData of [...graph.nodes, ...graph.edges]) {
        const filteredData: CloudIMDataInfo = graphData.data;

        // Matching based on type
        if (filteredData?.type === type) {
            // Matching based on provider and id
            if (provider === PROVIDER_TYPES.AWS && filteredData?.attributes?.entity_id === id) {
                return filteredData;
            } else if (provider === PROVIDER_TYPES.ONPREM && filteredData?.attributes?.name === id) {
                return filteredData;
            }
        }
    }

    return undefined;
}
