import React, { useEffect, useState } from "react";
import { loader } from "graphql.macro";
import { Query } from "reporting-infrastructure/types/Query";
import { useQuery, FILTER_NAME } from "utils/hooks";
import { Classes, Dialog } from "@blueprintjs/core";
import { IconName } from "@tir-ui/react-components";
import { AWS_TYPES_TO_LABEL_MAP, CloudIMDataInfo, NETIM_TYPES_TO_LABEL_MAP } from "utils/cloudim/TopologyUtils";
import { PROVIDER_TYPES } from "components/enums";
import { STRINGS } from "app-strings";
import { getFilteredPropertiesData } from "utils/cloudim/TopologyUtils";
import CloudIMPropertiesContainer, { PropertiesFilters } from "components/common/graph/cloudim-react-flow/components/properties/CloudIMPropertiesContainer";

import './PropertiesModal.scss';

/* To Use PropertiesModal
Without Query (AWS/NetIM):
    openModal('cloudIMPropertiesModal', {
        icon: iconToUse,
        filter: {
            provider: PROVIDER_TYPES.AWS / PROVIDER_TYPES.ONPREM,
            type: data.type,
        },
        nodeData: data,
        onClose: () => {},
    } as PropertiesModalProps);
With Query (AWS):
    openModal('cloudIMPropertiesModal', {
        icon: iconToUse,
        filter: {
            provider: PROVIDER_TYPES.AWS,
            type: data.type,
            id: data.label,
            region: data?.attributes?.Region,
            tokens: [data.label, data.type],
        },
        useQueryData: true,
        onClose: () => {},
    } as PropertiesModalProps);
With Query (NetIM):
    openModal('cloudIMPropertiesModal', {
        icon: iconToUse,
        filter: {
            provider: PROVIDER_TYPES.ONPREM,
            type: data.type,
            id: data.label,
            latitude: data?.attributes?.geo?.latitude,
            longitude: data?.attributes?.geo?.longitude,
            tokens: [data.label],
        },
        useQueryData: true,
        onClose: () => {},
    } as PropertiesModalProps);
*/

export interface PropertiesModalProps {
    icon: string | IconName;
    filter: PropertiesFilters;
    useQueryData?: boolean;
    nodeData?: CloudIMDataInfo;
    onClose: () => void;
}

const PropertiesModal = ({
    icon,
    filter,
    useQueryData = false,
    nodeData,
    onClose
}: PropertiesModalProps) => {
    const [modalOpen, setModalOpen] = useState(true);
    const [data, setData] = useState<CloudIMDataInfo | undefined>(nodeData);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function handleClose() {
        setModalOpen(false);
        if (onClose) {
            onClose();
        }
    }

    // TODO: Modify this query for a more precise one once it is available
    const awsQuery = useQuery({
        query: new Query(loader("../graphql/aws-properties.graphql")),
        name: "Filtered AWS Resource",
        requiredFilters: [FILTER_NAME.regions],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.regions]: filter.region,
            [FILTER_NAME.token]: filter.tokens || [""]
        },
        queryVariables: {
            unique: true
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    // TODO: Modify this query for a more precise one once it is available
    const netImQuery = useQuery({
        query: new Query(loader("../graphql/netim-properties.graphql")),
        name: "Filtered NetIM Resource",
        requiredFilters: [FILTER_NAME.latitude, FILTER_NAME.longitude],
        consumedFilters: [FILTER_NAME.token],
        filters: {
            [FILTER_NAME.latitude]: filter.latitude,
            [FILTER_NAME.longitude]: filter.longitude,
            [FILTER_NAME.token]: filter.tokens || [""]
        },
        timeNotRequired: true,
        skipGlobalFilters: true,
        lazy: true
    });

    let typeLabel: string = "";

    useEffect(
        () => {
            if (useQueryData) {
                setIsLoading(true);
                if (filter.provider === PROVIDER_TYPES.AWS) {
                    awsQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.regions]: filter.region, [FILTER_NAME.token]: filter.tokens || [""] }
                    }).then((queryData) => {
                        return getFilteredPropertiesData(queryData.resources, filter.provider, filter.type, filter.id);
                    }).then((filteredData) => {
                        setData(filteredData);
                    }).catch(error => console.error(error));
                } else if (filter.provider === PROVIDER_TYPES.ONPREM) {
                    netImQuery.run({
                        fetchPolicy: "no-cache",
                        filters: { [FILTER_NAME.latitude]: filter.latitude, [FILTER_NAME.longitude]: filter.longitude, [FILTER_NAME.token]: filter.tokens || [""] }
                    }).then((queryData) => {
                        return getFilteredPropertiesData(queryData.resources, filter.provider, filter.type, filter.id);
                    }).then((filteredData) => {
                        setData(filteredData);
                    }).catch(error => console.error(error));
                }
                setIsLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [useQueryData, filter]
    );

    if (filter.provider === PROVIDER_TYPES.AWS) {
        typeLabel = AWS_TYPES_TO_LABEL_MAP[filter.type];
    } else if (filter.provider === PROVIDER_TYPES.ONPREM) {
        typeLabel = NETIM_TYPES_TO_LABEL_MAP[filter.type];
    }

    return (
        <Dialog
            className="propertiesModal"
            isOpen={modalOpen}
            isCloseButtonShown={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            autoFocus={true}
            enforceFocus={true}
            usePortal={true}
            title={typeLabel + " " + STRINGS.cloudim.topology.modal.properties}
            aria-labelledby={typeLabel + " " + STRINGS.cloudim.topology.modal.properties}
            onClose={() => handleClose()}
        >
            <CloudIMPropertiesContainer
                className={Classes.DIALOG_BODY}
                icon={icon}
                filter={filter}
                // useQueryData set to false. Use the useQueryData in this component.
                useQueryData={false}
                loading={isLoading}
                nodeData={data}
            />
        </Dialog>
    );
};

export default PropertiesModal;
