/** This file defines the generate AI instructions dialog React component. The generate AI instructions 
 *  dialog allows you to call a service to generate AI instructions for the user.
 *  @module */
import React, { useState, useEffect } from "react";
import { BasicDialog, updateDialogState } from "components/common/basic-dialog/BasicDialog";
import { Button, TextArea } from "@blueprintjs/core";

/** this interface defines the properties that are used to store the nodes configurable parameters. */
interface GenerateInstructionsDialogProperties {
    /** a boolean value, true if the dialog should be opened. */
    open: boolean;
    /** the handler for dialog close events. */
    onClose: () => void
}

/** Component for displaying a dialog to generate instructions for the ai node
 *  @param props the properties passed into the React component.
 *  @returns a JSX component with the generate instructions dialog. */
export function GenerateInstructionsDialog(props: GenerateInstructionsDialogProperties): JSX.Element {
    const initDialogState = {
        showDialog: props.open,
        title: "Help Generating Instructions to the AI Model",
        loading: false,
        dialogContent: <>
            <TextArea
                required={ true }
                defaultValue={ ""}
                name={ "generate-instruction-content" }
                growVertically={ true }
                fill={ true }
                placeholder={"Describe what you want the model to do, e.g. analyze a time series of round trip time"}
                style={{minHeight: "120px"}}
                onBlur={ e => {
                    //setCurProperties({ ...curProperties, [AI_NODE_EDIT_PROPS.INSTRUCTIONS]: e.target.value });
                } }
            />
        </>,
        dialogFooter: <>
            <Button
                active={true}
                outlined={true}
                onClick={(evt) => {
                    props.onClose();
                }}
                text="Close"
            />
            <Button active={true} outlined={true}
                text="Generate"
                onClick={async (evt) => {
                    props.onClose();
                }}
            />
        </>,
    };
    const [dialogState, setDialogState] = useState<any>(initDialogState);

    useEffect(
        () => {
            setDialogState(updateDialogState(initDialogState, props.open, false, []));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.open]
    )

    return <BasicDialog 
        dialogState={dialogState} className="generate-instructions-dialog" 
        onClose={() => props.onClose()} 
    />
}
