/** This file defines the capacity planning table view react component.  The 
 *  capacity planning table view react component displays the interface predictions from
 *  AI in a table.
 *  @module */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon, IconNames, Table, useStateSafePromise } from "@tir-ui/react-components";
import { DataLoadFacade } from "components/reporting/data-load-facade/DataLoadFacade";
import { STRINGS } from "app-strings";
import { Button, Intent, Tag } from "@blueprintjs/core";
import { ForecastingService } from "utils/services/ForecastingService";
import CapacityPlanningDialog from "pages/incident-search/modals/capacity-planning/CapacityPlanningDialog";
import { Prompt, useLocation } from "react-router-dom";
import moment from 'moment';
import "./CapacityPlanningTableView.scss";
import { Tooltip2 } from "@blueprintjs/popover2";

/** Renders the capacity planning table react component.
 *  @param props the properties passed in.
 *  @returns JSX with the capacity planning table component.*/
export function CapacityPlanningTableView(): JSX.Element {
    const [executeSafely] = useStateSafePromise();
    const location = useLocation() as { state: any };

    const { rowData, forecastConfig} = location.state;
    const [data, setData] = useState<Array<any>>([]);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
            const unloadHandler = (event) => {
                event.returnValue = STRINGS.predictionSummaryDashboard.capacityPlanningTable.warning;
            };
            window.addEventListener('beforeunload', unloadHandler);
            return () => {
                window.removeEventListener("beforeunload", unloadHandler);
            }
        }, 
        []
    );

    const fetchData = useCallback(() => {
        const requestData = () =>
            rowData.flatMap(row => 
                ["in_utilization", "out_utilization"].map(column => ({
                    entity_kind: "network_interface",
                    algorithm: "HW",
                    source_id: row.reportedBy[0].dataSourceId,
                    ipaddr: row.ipaddr,
                    ifindex: row.ifIndex,
                    columns: [column],
                }))
            );
    
        return executeSafely(
            ForecastingService.getForecastData(requestData(), forecastConfig?.period)
        );
    }, [executeSafely, forecastConfig?.period, rowData]);    

    useEffect(() => {
        fetchData().then(forecastData => {
            const result = (forecastData?.values ?? []).map((item, index) => ({
                ...item,
                name: `${(rowData?.[Math.floor(index / 2)]?.name ?? "Unknown")} on ${(rowData?.[Math.floor(index / 2)]?.parentNetworkDevice?.name ?? "Unknown")}`,
                period: forecastConfig?.period,
                days1: getDaysUntilFail(item.data, parseInt(forecastConfig?.minThreshold)),
                days2: getDaysUntilFail(item.data, parseInt(forecastConfig?.maxThreshold)),
                threshold1: parseInt(forecastConfig?.minThreshold),
                threshold2: parseInt(forecastConfig?.maxThreshold),
                trend7: item.metadata?.trend_7d,
                trend30: item.metadata?.trend_30d,
                metric: item.entity_info?.metric
            }));
            setData(result);
        })
        .catch(error => {
            setError("Failed to fetch Forecasting data: " + error);
        })
        .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    // Capacity Planning Modal
    const capacityPlanningDialog = useRef();
    const openCapacityPlanningDialog = (editInfos = null) => {
        if (capacityPlanningDialog.current) {
            // @ts-ignore
            capacityPlanningDialog.current.handleOpen(editInfos);
        }
    }

    const WARNING_THRESHOLD_DAYS = 70;
    const DANGER_THRESHOLD_DAYS = 30;

    const getDaysUntilFail = (arrays, threshold) => {
        const firstArrayLargerThanThreshold = arrays?.find(array => array[1] > threshold);
        if (firstArrayLargerThanThreshold) {
            return moment.unix(parseInt(firstArrayLargerThanThreshold[0])).diff(moment(), 'days') + 1
        }
    }

    const columns = [
        {
            id: "interface",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.interface,
            accessor: "interface",
            formatter: (record) =>
                <span className="overflow-elipsis-table-cell position-relative d-block overflow-hidden text-nowrap">
                    <Icon icon={IconNames.LAN} iconSize={20} className="mr-2" /> {record.name} - {STRINGS.METRICS[record.metric]}
                </span>
            ,
            className: 'summary-interface-widget-max-width'
        },
        {
            id: "trend1",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.trend1,
            accessor: "trend1",
            formatter: (record) => {
                if (record.code) {
                    return <Icon icon={IconNames.MINUS} iconSize={16} />
                };
                return <div>
                    <Icon
                        icon={record.trend7 > 0 ? IconNames.CIRCLE_ARROW_UP : IconNames.CIRCLE_ARROW_DOWN}
                        intent={record.trend7 > 0 ? Intent.DANGER : Intent.SUCCESS }
                        className="mr-1"
                    />
                    <div className="comparison-value-container">
                        <div className="value">{Math.round(record.trend7 * 100) / 100}%</div>
                    </div>
                </div>;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
        {
            id: "trend2",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.trend2,
            accessor: "trend2",
            formatter: (record) => {
                if (record.code) {
                    return <Icon icon={IconNames.MINUS} iconSize={16} />
                };
                return <div>
                    <Icon
                        icon={record.trend30 > 0 ? IconNames.CIRCLE_ARROW_UP : IconNames.CIRCLE_ARROW_DOWN}
                        intent={record.trend30 > 0 ? Intent.DANGER : Intent.SUCCESS }
                        className="mr-1"
                    />
                    <div className="comparison-value-container">
                        <div className="value">{Math.round(record.trend30 * 100) / 100}%</div>
                    </div>
                </div>;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
        {
            id: "threshold1",
            Header: `${STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.threshold1} of ${forecastConfig?.minThreshold}%`,
            accessor: "threshold1",
            sortable: true,
            sortDescFirst: false,
            formatter: (record) => {
                return <div className="d-flex flex-column align-items-center">
                    <div>
                        <Tag style={{width: "65px"}}
                            intent={
                                (record.days1 || 1000) < DANGER_THRESHOLD_DAYS ? Intent.DANGER 
                                    : (record.days1 || 1000) < WARNING_THRESHOLD_DAYS ? Intent.WARNING : Intent.NONE
                            }
                        > 
                            {!record.days1 ? <Icon icon={IconNames.MINUS} iconSize={16} /> : record.days1 + " days"}
                        </Tag>
                    </div>
                </div>;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
        {
            id: "threshold2",
            Header:  `${STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.threshold2} of ${forecastConfig?.maxThreshold}%`,
            accessor: "threshold2",
            sortable: true,
            sortDescFirst: false,
            formatter: (record) => {
                return <div className="d-flex flex-column align-items-center">
                    <div>
                        <Tag style={{width: "65px"}}
                            intent={
                                (record.days2 || 1000) < DANGER_THRESHOLD_DAYS ? Intent.DANGER 
                                    : (record.days2 || 1000) < WARNING_THRESHOLD_DAYS ? Intent.WARNING : Intent.NONE
                            }
                        > 
                            {!record.days2 ? <Icon icon={IconNames.MINUS} iconSize={16} /> : record.days2 + " days"}
                        </Tag>
                    </div>
                </div>;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        },
        {
            id: "view",
            Header: STRINGS.predictionSummaryDashboard.capacityPlanningTable.columns.view,
            accessor: "view",
            formatter: (record) => {
                if (record.code) {
                    return <Tooltip2 
                        content={
                        <div>
                            {STRINGS.predictionSummaryDashboard.performanceTable.errors[record.code]}
                        </div>}>
                        <Icon icon={IconNames.ERROR} intent={Intent.DANGER} iconSize={16} />
                    </Tooltip2>
                };
                return <Button
                    icon={IconNames.CHART}
                    className="special-chart-ai"
                    minimal
                    onClick={(e) => {
                        openCapacityPlanningDialog(record);
                    }}
                />;
            },
            className: 'text-center',
            headerClassName: 'text-center'
        }
    ];

    return <>
        <Prompt message={() => STRINGS.predictionSummaryDashboard.capacityPlanningTable.warning}/>
        <DataLoadFacade loading={loading} loadingText="Forecasting..." error={error} data={data}>
            <Table className="capacity-planning-table-view" id="usersList"
                columns={columns}
                data={data}
                interactive={false}
                defaultPageSize={10}
                bordered
                striped
                condensed
                transparent
            />
        </DataLoadFacade>
        <CapacityPlanningDialog
            ref={capacityPlanningDialog}
            threshold1={+forecastConfig?.minThreshold}
            threshold2={+forecastConfig?.maxThreshold} 
        />
    </>;
};
