import React, { useState } from 'react'
import { Modal } from '@tir-ui/react-components'
import { Form, InputField, SelectField } from 'components/common/form'
import * as yup from 'yup';
import { Intent, Tag } from '@blueprintjs/core'
import "./ForecastingModal.scss"
import { STRINGS } from 'app-strings';

const ForecastingModal = (props) => {
    const [forecastConfig, setForecastConfig] = useState({
        period: "6",
        minThreshold: 80,
        maxThreshold: 100
    });

    const validationSchema = yup.object().shape({
        minThreshold: yup
            .number()
            .typeError(STRINGS.predictionSummaryDashboard.forecastModal.errors.type)
            .min(0, STRINGS.predictionSummaryDashboard.forecastModal.errors.lower)
            .max(100, STRINGS.predictionSummaryDashboard.forecastModal.errors.larger)
            .label(STRINGS.predictionSummaryDashboard.forecastModal.errors.middle)
            .required()
            .test(
                "is-integer",
                STRINGS.predictionSummaryDashboard.forecastModal.errors.decimal,
                (value) => Number.isInteger(value)
            )
            .test(
                "less-than-max",
                STRINGS.predictionSummaryDashboard.forecastModal.errors.lessThanMax,
                function (value) {
                    const { maxThreshold } = this.parent;
                    return value < maxThreshold;
                }
            ),
        maxThreshold: yup
            .number()
            .typeError(STRINGS.predictionSummaryDashboard.forecastModal.errors.type)
            .min(0, STRINGS.predictionSummaryDashboard.forecastModal.errors.lower)
            .max(100, STRINGS.predictionSummaryDashboard.forecastModal.errors.larger)
            .label(STRINGS.predictionSummaryDashboard.forecastModal.errors.upper)
            .required()
            .test(
                "is-integer",
                STRINGS.predictionSummaryDashboard.forecastModal.errors.decimal,
                (value) => Number.isInteger(value)
            )
            .test(
                "greater-than-min",
                STRINGS.predictionSummaryDashboard.forecastModal.errors.greaterThanMin,
                function (value) {
                    const { minThreshold } = this.parent;
                    return value > minThreshold;
                }
            ),
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForecastConfig({
            ...forecastConfig,
            [name]: value,
        });
    };

    return (
        <Modal
            title={STRINGS.predictionSummaryDashboard.forecastModal.title}
            hideSubmit
            hideCancel
            buttons={[
                {
                    label: STRINGS.predictionSummaryDashboard.forecastModal.close,
                    action: () => document.querySelector('.bp3-dialog-close-button').click()

                },
                {
                    label: STRINGS.predictionSummaryDashboard.forecastModal.submit,
                    intent: Intent.PRIMARY,
                    action: () => {
                        props.handleShowForecast(forecastConfig);
                        document.querySelector('.bp3-dialog-close-button').click()
                    },
                    disabled: !validationSchema.isValidSync(forecastConfig)
                }
            ]}
            usePortal={false}
            {...props}
        >
            <Form className={"forecast-modal container"}
                initialValues={forecastConfig}
                validationSchema={validationSchema}
                loading={false} >
                <div className="row mt-3">
                    <div className="col-8 mt-1">{STRINGS.predictionSummaryDashboard.forecastModal.period}</div>
                    <div className="col-2">
                        <SelectField
                            name={"period"}
                            onChange={handleChange}>
                            <option value={"1"}>{STRINGS.predictionSummaryDashboard.forecastModal.m1}</option>
                            <option value={"3"}>{STRINGS.predictionSummaryDashboard.forecastModal.m3}</option>
                            <option value={"6"}>{STRINGS.predictionSummaryDashboard.forecastModal.m6}</option>
                        </SelectField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 mt-1">
                        {STRINGS.predictionSummaryDashboard.forecastModal.threshold}
                    </div>
                </div>
                <div className="mt-3 row special-alignment-thresholds">
                    <div className="offset-4 col-4">
                        <p>First Threshold: </p>
                    </div>
                    <div className="col-2">
                        <InputField
                            name={"minThreshold"}
                            rightElement={<Tag minimal>%</Tag>}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row special-alignment-thresholds">
                    <div className="offset-4 col-4">
                        <p>Second Threshold: </p>
                    </div>
                    <div className="col-2">
                        <InputField
                            name={"maxThreshold"}
                            rightElement={<Tag minimal>%</Tag>}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

export default ForecastingModal
