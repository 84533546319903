/** This module contains the functional React component for rendering the chart node editor.
 *  This control allows users to set all the chart node properties including the row index,
 *  notes, notes position, and columns, etc.
 *  @module
 */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import { InputGroup, HTMLSelect, Switch } from '@blueprintjs/core';
import { LoadingOverlay } from '@tir-ui/react-components';
import { TableNodeUtils } from './TableNodeUtils';
import { useStateSafePromise } from 'utils/hooks';
import { SimpleNodeEditorProps } from '../simple/SimpleNodeEditor';
import { TextEditor } from 'components/common/texteditor/TextEditor';
import { MultiSelectInput } from 'components/common/multiselect/MultiSelectInput';
import { getNodeFromGraphDef, } from 'utils/runbooks/RunbookUtils';
import { STRINGS } from 'app-strings';
import { DataLoadFacade } from 'components/reporting/data-load-facade/DataLoadFacade';
import { DataOceanMetadata } from '../data-ocean/DataOceanMetadata.type';
import { DataOceanUtils } from '../data-ocean/DataOceanUtils';
import { GLOBAL_SCOPE, INCIDENT_SCOPE, PrimitiveVariableType, RUNTIME_SCOPE } from 'utils/runbooks/VariablesUtils';
import { useVariables } from 'utils/hooks/useVariables';
import { CustomPropertyContext } from 'pages/create-runbook/views/create-runbook/CustomPropertyTypes';
import { VARIANTS_WITH_GLOBAL_VARS, VARIANTS_WITH_INCIDENT_VARS, VARIANTS_WITH_RUNTIME_BUILTIN_VARS } from '../../types/GraphTypes';

/** this constant contains the options for the notes position control. */
const notesPositionOptions = [
	{ label: 'notesPositionOptionLeft', value: 'left' },
	{ label: 'notesPositionOptionRight', value: 'right' },
	{ label: 'notesPositionOptionAbove', value: 'above' },
	{ label: 'notesPositionOptionBelow', value: 'below' },
];

/** this constant defines the group column option. */
const groupColumn = {
	value: 'group_column',
	label: STRINGS.runbookEditor.nodeLibrary.nodes.table.groupLabel,
};

/** this constant defines the sort order options. */
const sortOrder = [
	{
		label: STRINGS.runbookEditor.nodeLibrary.nodes.table.sortOrder.asc,
		value: 'asc',
	},
	{
		label: STRINGS.runbookEditor.nodeLibrary.nodes.table.sortOrder.desc,
		value: 'desc',
	},
	{
		label: STRINGS.runbookEditor.nodeLibrary.nodes.table.sortOrder.none,
		value: '',
	},
];

/** this enum defines the keys for all the table node properties. */
export enum TABLE_NODE_EDIT_PROPS {
    /** the attribute for the title to display for the table in the runbook output. */
	TITLE           = 'title',
    /** the attribute that specifies which row the table should be displayed in. */
	ROW             = 'row',
    /** the attribute that specifies where the notes should be positioned. */
	NOTES_POSITION  = 'notesPosition',
    /** the attribute that specifies the notes content. */
	NOTES           = 'notes',
    /** that attribute that specifies the table sort column. */
	SORT_COLUMN     = 'sortColumn',
    /** the attribute that specifies the table sort column sort order. */
	SORT_ORDER      = 'sortOrder',
    /** the attribute that specifies the list of columns to display. */
	COLUMNS         = 'columns',
	/** the attribute that specifies the list of all columns to display. */
	INCLUDE_ALL_COLUMNS = 'includeAllColumns',
	/** the attribute that specifies if the table should be flipped. */
	FLIP_TABLE      = 'flipTable'
}

/** this interface defines the properties passed into the table node editor React component. */
interface TableNodeProperties {
    /** a String with the title to be displayed in the runbook output. */
	title?: string;
    /** a String with the row index to display the table in the runbook output.  The widgets are sorted by row, they can have the same value. */
	row?: string;
    /** a String with the notes to display with the widget in the runbook output. */
	notes?: string;
    /** a String with the position the notes should have relative to the widget. */
	notesPosition?: string;
    /** a String with the sort column for the table. */
	sortColumn?: string;
    /** a String with the sort order for the sort column. */
	sortOrder?: string;
    /** an array with the list of columns to display in the table. */
	columns?: any;
	/** boolean flag - all columns to include */
	includeAllColumns?: boolean;
	/** boolean flag which is true if the table needs to be flipped or false otherwise */
	flipTable?: boolean;
}

/** Renders the Table Editor UI.
 *  @param props - SimpleNodeEditor
 *  @param ref - React Forward Ref object of the current Component to be used in the parent component
 *  @returns React.ReactElement. */
export const TableNodeEditor = React.forwardRef(
	(props: SimpleNodeEditorProps, ref) => {
		const [executeSafely] = useStateSafePromise();
		const [loading, setLoading] = useState(true);

		const [currentProperties, setCurrentProperties] = useState<TableNodeProperties>({});
		const [columns, setColumns] = useState<Array<{ label: string; value: string }>>([]);
		const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
		const [includeAllColumns, setIncludeAllColumns] = useState<boolean | undefined>(false);
		const [objMetricMetaData, setObjMetricMetaData] = useState<DataOceanMetadata>();
		const [getVariables] = useVariables({runbookInfo: props.activeRunbook});

        const customProperties = useContext(CustomPropertyContext);

		const fetchData = useCallback(() => {
			return executeSafely(DataOceanUtils.init()).then(
				(response: any) => {
					setObjMetricMetaData(response);
				},
				(error) => {
					console.error(error);
				}
			);
		}, [executeSafely]);

		useEffect(() => {
			// Fetch Meta data on load.
			fetchData();
		}, [fetchData]);

		useEffect(() => {
			let existingProperties = {};
			props.selectedNode?.getProperties().forEach((prop) => {
				existingProperties[prop.key] = props.selectedNode?.getProperty(
					prop.key
				);
			});

			const currentProperties = JSON.parse(JSON.stringify(existingProperties));
            if (!currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS]) {
                currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS] = [];
            }
			if (!currentProperties[TABLE_NODE_EDIT_PROPS.FLIP_TABLE]) {
                currentProperties[TABLE_NODE_EDIT_PROPS.FLIP_TABLE] = false;
            }
			setCurrentProperties(currentProperties);
			setSelectedColumns(currentProperties.columns);

			setLoading(false);
		}, [props.selectedNode, setCurrentProperties]);
		
		useEffect(() => {
			if (objMetricMetaData) {
				const metrics = TableNodeUtils.getMetricsAndKeys(
                    objMetricMetaData, customProperties, getNodeFromGraphDef(props.selectedNode?.getId() || "", props.graphDef), 
                    props.graphDef
                );
				const keys = TableNodeUtils.getKeys(
                    objMetricMetaData, customProperties, getNodeFromGraphDef(props.selectedNode?.getId() || "", props.graphDef), 
                    props.graphDef
                );
				const columns = _.uniqBy(metrics.concat(keys).concat(groupColumn), 'value');

				setColumns(columns);

				if (currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS].length === 0) {
					currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS] = metrics
						.concat(keys)
						.concat(groupColumn)
						.filter((column) => { return column !== undefined; });

					setSelectedColumns(
						currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS]
					);
				}
				setIncludeAllColumns(currentProperties[TABLE_NODE_EDIT_PROPS.INCLUDE_ALL_COLUMNS]);

				currentProperties[TABLE_NODE_EDIT_PROPS.INCLUDE_ALL_COLUMNS] && setSelectedColumns(columns);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [objMetricMetaData]);

		/* set an updateNode function on the reference of this component which will be invoked by the parent
		 * component: NodeEditorPanel
		 */
		// @ts-ignore
		ref.current = {
			updateNode: () => {
				TableNodeUtils.updateNode(
					currentProperties,
					props.selectedNode,
					props.libraryNode,
					props.graphDef
				);
			},
			validate: () => {
				const errorMessages = new Array<string>();
				TableNodeUtils.validateNode(
					props.selectedNode,
					currentProperties,
					errorMessages,
					props.graphDef
				);
				return errorMessages;
			}
		};

		if (loading) {
			return (
				<tr>
					<td>
						<LoadingOverlay visible={true} />
					</td>
				</tr>
			);
		}

		if (!objMetricMetaData || !columns?.length) {
			return <tr><td colSpan={2}><DataLoadFacade loading/></td></tr>;
		}

		const options: Array<{ label: string; value: string }> = [];
		for (const option of notesPositionOptions) {
			const optionLabel = STRINGS.runbookEditor.nodeLibrary
				.propertyLabels[option.label]
				? STRINGS.runbookEditor.nodeLibrary.propertyLabels[option.label]
				: option.label;
			options.push({ label: optionLabel, value: option.value });
		}

        // Setup the sort column options
        const sortColumns = columns.filter((column) => {
			if (column.value === "group_column" && columns.length > 1) return false;
            return includeAllColumns ? column : selectedColumns.includes(column.value);
        }).map((column) => {
            return {
                label: column.label,
                value: column.value,
            };
        });
        // Add an option for none
        sortColumns.push({label: STRINGS.runbookEditor.nodeLibrary.nodes.table.noSortColumn, value: ""});
        let selectedSortColumn = currentProperties[TABLE_NODE_EDIT_PROPS.SORT_COLUMN] || "";
        selectedSortColumn = selectedColumns.includes(selectedSortColumn) ? selectedSortColumn : "";

        const variableList = getVariables(RUNTIME_SCOPE, VARIANTS_WITH_RUNTIME_BUILTIN_VARS.includes(props.variant)).primitiveVariables.concat(
            VARIANTS_WITH_INCIDENT_VARS.includes(props.variant) ? getVariables(INCIDENT_SCOPE, true).primitiveVariables : []
        ).concat(
            VARIANTS_WITH_GLOBAL_VARS.includes(props.variant) ? getVariables(GLOBAL_SCOPE, true).primitiveVariables : []
        ).filter(variable => ![PrimitiveVariableType.JSON].includes(variable.type))

        return (
			<React.Fragment>
				<>
					{currentProperties[TABLE_NODE_EDIT_PROPS.TITLE] !== undefined && <tr>
						<td className="p-1" colSpan={2}>
                            <label className='mb-0 pb-1'>
								{STRINGS.runbookEditor.nodeLibrary.nodes.table.title}
							</label><br />
							<InputGroup
								type={'text'}
								id={TABLE_NODE_EDIT_PROPS.TITLE}
								name={TABLE_NODE_EDIT_PROPS.TITLE}
								defaultValue={currentProperties.title}
								className="editor-input-standard"
								onChange={(e) => {
									currentProperties[
										TABLE_NODE_EDIT_PROPS.TITLE
									] = e.currentTarget.value;
								}}
							/>
						</td>
					</tr>}
					{currentProperties[TABLE_NODE_EDIT_PROPS.ROW] !== undefined && <tr>
						<td className="p-1" colSpan={2}>
                            <label className="mb-0 pb-1">
								{STRINGS.runbookEditor.nodeLibrary.nodes.table.row}
							</label><br />
							<InputGroup
								type={'text'}
								id={TABLE_NODE_EDIT_PROPS.ROW}
								name={TABLE_NODE_EDIT_PROPS.ROW}
								defaultValue={currentProperties.row}
								className="editor-input-standard"
								onChange={(e) => {
									currentProperties[
										TABLE_NODE_EDIT_PROPS.ROW
									] = e.currentTarget.value;
								}}
							/>
						</td>
					</tr>}
					<tr>
						<td className="p-1">
							<label className='mr-3 mb-0'>
								{STRINGS.runbookEditor.nodeLibrary.nodes.table.tableColumns}
							</label>
                            <Switch
                                label={STRINGS.runbookEditor.nodeLibrary.nodes.table.includeAllColumns}
                                inline={true}
                                className='mb-0'
                                defaultChecked={includeAllColumns}
                                onChange={(event) => {
                                    setIncludeAllColumns(event.currentTarget.checked);
                                    currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS] =
                                        columns.map((column) => {
                                            return column.value;
                                        })
                                    currentProperties[
                                        TABLE_NODE_EDIT_PROPS.INCLUDE_ALL_COLUMNS
                                    ] = event.currentTarget.checked;
                                }}
                            /> <br />
                            {!includeAllColumns &&
                                <MultiSelectInput
                                    sortable
                                    items={columns.map((column) => {
                                        return {
                                            display: column.label,
                                            value: column.value,
                                        };
                                    })}
                                    selectedItems={(currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS] || [])
                                        .map((columnId) => {
                                            for (const column of columns) {
                                                if (column.value === columnId) {
                                                    return {
                                                        display: column.label,
                                                        value: column.value,
                                                    };
                                                }
                                            }
                                            return null;
                                        })
                                        .filter((item) =>
                                            item !== null && item !== undefined && item?.value !== "group_column"
                                        )}
                                    onChange={(updatedValues) => {
                                        currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS] =
                                            updatedValues ? updatedValues.map((column) => { return column.value; }) : [];
                                        setSelectedColumns(currentProperties[TABLE_NODE_EDIT_PROPS.COLUMNS]);
                                    }}
                                    placeholder={
                                        STRINGS.runbookEditor.nodeLibrary.nodes
                                            .table.tableColumnsPlaceholder
                                    }
                                    disabled={false}
                                />
                            }
						</td>
                    </tr>

					<tr>
						<td className="p-1">
                            <label className='mb-0 pb-1'>
								{STRINGS.runbookEditor.nodeLibrary.nodes.table.sortColumn}
							</label><br />
							<HTMLSelect
								id={TABLE_NODE_EDIT_PROPS.SORT_COLUMN}
								name={TABLE_NODE_EDIT_PROPS.SORT_COLUMN}
								defaultValue={selectedSortColumn}
								options={sortColumns}
								className="editor-input-standard"
								onChange={(e) => {
									currentProperties[
										TABLE_NODE_EDIT_PROPS.SORT_COLUMN
									] = e.currentTarget.value;
								}}
								disabled={selectedColumns?.length === 0 ? true : false}
							/>{' '}
							<HTMLSelect
								id={TABLE_NODE_EDIT_PROPS.SORT_ORDER}
								name={TABLE_NODE_EDIT_PROPS.SORT_ORDER}
								defaultValue={currentProperties.sortOrder || ""}
								options={sortOrder}
								className="editor-input-standard"
								onChange={(e) => {
									currentProperties[
										TABLE_NODE_EDIT_PROPS.SORT_ORDER
									] = e.currentTarget.value;
								}}
								disabled={
									selectedColumns?.length === 0 ? true : false
								}
							/>
						</td>
					</tr>
					{currentProperties[TABLE_NODE_EDIT_PROPS.NOTES] !== undefined && <tr>
						<td className="p-1" colSpan={2}>
                            <label className='mb-0 pb-1'>
								{STRINGS.runbookEditor.nodeLibrary.nodes.table.notes}
							</label><br />
							<TextEditor
								initialValue={currentProperties.notes}
								onChange={(value) => {
									return (currentProperties[
										TABLE_NODE_EDIT_PROPS.NOTES
									] =
										value.match(/\S/g)[0] !== '​'
											? DOMPurify.sanitize(value)
											: null);
								}}
								placeholder={
									STRINGS.TextEditor.notesPlaceholder
								}
								includeVariables={true}
								variables={variableList}
							/>
						</td>
					</tr>}
					{currentProperties[TABLE_NODE_EDIT_PROPS.NOTES_POSITION] !== undefined && <tr>
						<td className="p-1">
                            <label className='mb-0 pb-1'>
								{STRINGS.runbookEditor.nodeLibrary.nodes.table.notesPosition}
							</label>
							<HTMLSelect
								id={TABLE_NODE_EDIT_PROPS.NOTES_POSITION}
								name={TABLE_NODE_EDIT_PROPS.NOTES_POSITION}
								defaultValue={currentProperties.notesPosition}
								options={options}
								className="editor-input-standard ml-3"
								onChange={(e) => {
									currentProperties[
										TABLE_NODE_EDIT_PROPS.NOTES_POSITION
									] = e.currentTarget.value;
								}}
							/>
						</td>
					</tr>}
					{currentProperties[TABLE_NODE_EDIT_PROPS.FLIP_TABLE] !== undefined && <tr>
						<td className="p-1" colSpan={2}>
							<Switch
								id={TABLE_NODE_EDIT_PROPS.FLIP_TABLE}
								name={TABLE_NODE_EDIT_PROPS.FLIP_TABLE}
								label={"Flip table if only one row"}
								inline={true}
								defaultChecked={currentProperties.flipTable}
								onChange={(e) => {
									currentProperties[
										TABLE_NODE_EDIT_PROPS.FLIP_TABLE
									] = e.currentTarget.checked;
								}}
							/>
						</td>
					</tr>}
				</>
			</React.Fragment>
		);
	}
);
